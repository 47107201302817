import React, { useState, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getDevice, getDeviceUsagebyId, getFirmwareHistory, getOnboardedHistory, getReportbyId, getDiagnosticsData } from "../../action/report";
import DeviceDetails from "../device/DeviceDetails";
import customConfig from "../../config";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import DeviceUpdateLogs from "./../device/DeviceUpdateLogs";
import DeviceDACLogs from "./../device/DeviceDACLogs";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  activeTab: {
    border: '1px solid #bdbdbd',
    backgroundColor: '#e0e0e0',
    textTransform: 'capitalize'
  },
  nonActiveTab: {
    border: 'none',
    backgroundColor: 'none',
    textTransform: 'capitalize'
  },
});

const ReportDevices = ({ 
  user: { user, umV1AuthToken },
  tenant: { tenantInfo },
  userRoleInfo, 
  getDevice, 
  report: { device, deviceUsage, latestFirmwareData,
  firmwareData, onBoardedHistoryData, diagnosticData }, 
  selectedData, getDeviceUsagebyId, getFirmwareHistory, getOnboardedHistory,getDiagnosticsData, getReportbyId, homeId }) => {
  const [Id, setId] = useState(null);
  const [isEdit, setIsEdit] = useState(false)

  const classes = useStyles()
  const [activeTab, setActiveTab] = React.useState(0)

  useEffect(() => {
    setId(null);
  }, [selectedData]);

  const viewAcDetails = tab => {
    if(isEdit) return null
    getDevice(tab.deviceId, customConfig.deviceSearchByDeviceId, userRoleInfo, umV1AuthToken, tenantInfo.tenantId);
    setId(tab.deviceId);
    getFirmwareHistory(tab.deviceId);
    getDeviceUsagebyId(tab.deviceId);
    getOnboardedHistory(tab.deviceId);
    getDiagnosticsData(tab.deviceId);
  };

  const handleNotifyOffboard = () => {
    getReportbyId(homeId);
  }

  return (
    <div className="p-15">
      <div id="tab3" className="col s12">

        <div className="hd-style-2">
          <h5 className="mb-5">Devices in {selectedData.spaceName} {selectedData.devices.length > 0 ? "(" + selectedData.devices.length + ")" : ""}</h5>
          <div className="font-14 gray-txt mb-15">
            Select an option to view device detail reports{" "}
          </div>
        </div>



        <div className="row">
          <div className="col s12">
            {selectedData.devices.length === 0 ? (
              <div className="noDeviceFound radius-10 light-gray">
                <div className="text-box"><i className="no-device-icon"></i><label>No device found </label></div>
              </div>
            ) : (
              <ul className="tabs detail-tab reset-thumb scroll-set">
                {selectedData &&
                  selectedData.devices.map((mydevice, i) => (
                    <li key={i} className="tab">
                      <Link
                        to={"#"}
                        onClick={() => viewAcDetails(mydevice)}
                        className={Id === mydevice.deviceId ? "active" : ""}
                      >
                        {" "}
                        {/* <i className="tabs-icon smart-room-ac"></i> */}
                        <i className={`tabs-icon ${mydevice.categoy === 'AC' ? "smart-room-ac" : mydevice.categoy === 'Panasonic Fan' ? "smart-fan" : mydevice.categoy === 'Panasonic Television' ? "television" : mydevice.categoy === 'ODMFRONTLOADWM' ? "washing-maching" : mydevice.categoy === 'REFRIGERATOR' ? "refrigerator" : mydevice.categoy === 'Panasonic Smart Plug' ? "smart-plug" : mydevice.categoy === 'Panasonic Switch Device' ? "switch-device" : mydevice.categoy === 'Panasonic door-phone' ? "door-phone" : "default-device"}`}></i>
                        <div className="title">{mydevice.deviceName} </div>

                      </Link>
                    </li>
                  ))}
              </ul>
            )}
          </div>
        </div>

        {Id && selectedData && selectedData.devices.length > 0 ? (
          <Fragment>
            <Tabs
              value={activeTab}
              onChange={(e, newValue) => {
                setActiveTab(newValue)
              }}
              TabIndicatorProps={{
                style: {
                  backgroundColor: "#bdbdbd",
                  float: 'right'
                }
              }}
              style={{ marginTop: '20px' }}
            >
              <Tab
                label="Device Info"
                className={activeTab == 0 ? classes.activeTab : classes.nonActiveTab}
                style={{ border: '1px solid black' }}
              />
              <Tab
                label="DAC Logs"
                className={activeTab == 1 ? classes.activeTab : classes.nonActiveTab}
                style={{ border: '1px solid black' }}
              />
              <Tab
                label="Device Logs"
                className={activeTab == 2 ? classes.activeTab : classes.nonActiveTab}
                style={{ border: '1px solid black' }}
              />
            </Tabs>
            <div hidden={activeTab != 0}>
              <div className="row mb-0">
                <div className="s12 p-0 col">
                  <div className="content-block position-relative">
                    <div className="p-15">
                      <DeviceDetails showSeparator={true} device={device} deviceUsage={deviceUsage} latestFirmwareData={latestFirmwareData} firmwareData={firmwareData} onBoardedHistoryData={onBoardedHistoryData} diagnosticData={diagnosticData} notifyOffboard={handleNotifyOffboard} homeId={homeId} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div hidden={activeTab != 1}>
              <DeviceDACLogs
                deviceDetails={device ? device.length > 1 ? device[1] : device[0] : {} ?? {}}
              />
            </div>
            <div hidden={activeTab != 2}>
              <DeviceUpdateLogs
                deviceDetails={device ? device.length > 1 ? device[1] : device[0] : {} ?? {}}
              />
            </div>
          </Fragment>
        ) : null}

      </div>
    </div>
  );
};
const mapStateToProps = state => ({
  report: state.report,
  userRoleInfo: {
    level: state.auth.userLevel,
    roles: state.auth.userRoles,
  },
  user: state.user,
  tenant: state.tenant,
});

export default connect(mapStateToProps, { getDevice, getDeviceUsagebyId, getFirmwareHistory, getOnboardedHistory, getReportbyId, getDiagnosticsData })(ReportDevices);
