import React, { Fragment, useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Login from "./components/auth/Login";
import Routes from "./components/routing/Routes";
import "materialize-css/dist/css/materialize.min.css";
import M from "materialize-css/dist/js/materialize.min.js";
import ResponseInterceptor from "../src/utils/interceptor";
//Redux
import { connect } from "react-redux";

import "./App.css";
// import "./color.css";

const App = ({ tenantInfo }) => {
  useEffect(() => {
    // Init Materialize JS
    M.AutoInit();
    ResponseInterceptor();
  }, []);

  useEffect(() => {
    const favicon = document.getElementById("favicon");
    favicon.setAttribute("href",tenantInfo.tenantFaviconUrl)
    // favicon.href = logo;
  }, [tenantInfo.tenantFaviconUrl]);
  let className = "main-wrapper ";
  if (tenantInfo.primaryColor) {
    className +=
      tenantInfo.primaryColor +
      "-theme " +
      tenantInfo.secondaryColor +
      "-theme-secondary ";
  } else {
    className += "blue-theme lime-theme-secondary ";
  }
  if (
    tenantInfo.selectedTheme &&
    ["round", "rectangular"].includes(tenantInfo.selectedTheme.toLowerCase())
  ) {
    className += tenantInfo.selectedTheme.toLowerCase() + " roundOrRectangular";
  }


  return (
    <Router>
      <Fragment>
        <section className={className}>
          <Switch>
            <Route exact path="/" component={Login} />
            <Route component={Routes} />
          </Switch>
        </section>
      </Fragment>
    </Router>
  );
};

const mapStateToProps = (state) => {
  return {
    tenantInfo: state.tenant.tenantInfo,
  };
};

export default connect(mapStateToProps)(App);
