import { DOWNLOAD_DEVICE_STATISTICS_FAIL } from "../action/types";

const initialState = {
  isDeviceDownloadFail: false,
  isMobileDownloadFail: false,
};

export default function Statistics(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case DOWNLOAD_DEVICE_STATISTICS_FAIL:
      return {
        ...state,
        isDeviceDownloadFail: true,
      };

    default:
      return state;
  }
}
