import React, { useEffect, useState } from "react";
import M from "materialize-css/dist/js/materialize.min.js";
import { connect } from "react-redux";
import { getMqttUsedKeys } from "../../../action/metrics";
import { deviceCategory, series_x_data, seriesXaxisData, mqttKeysSeriesData } from "./GraphParameter";
import DatePicker from "react-datepicker";
import Graph from "./Graph";

function MqttUsedKeysChart({ getMqttUsedKeys, mqttKeysUsed, formateDate, selectedGraph, allowedDeviceCategories }) {
  const [devicesCatogary, setDevicesCatogary] = useState("AC");
  const [startDate, setStartDate] = useState();
  const [disableDaily, setDisableDaily] = useState(true);

  useEffect(() => {
    M.AutoInit();
  }, []);
  useEffect(() => {
    const que = {
      "category":devicesCatogary
    }
    getMqttUsedKeys(que);
  }, [getMqttUsedKeys,devicesCatogary]);
  const handleDeviceCategory = (event) => {
    setDevicesCatogary(event.target.value);
  };
  const handleStartDateChange = (date) => {
    setStartDate(date);
  };
  const HandleDeviceControlFilter = (date) => {
    if (date) {
      const que = {
        "onDate": formateDate(date),
        "category":devicesCatogary
      };
      getMqttUsedKeys(que);
      setDisableDaily(false)
    }
  };
  let key_data = [];
  let y_data = [];
  let x_data = [];
  if(mqttKeysUsed.length) {
    key_data = series_x_data(mqttKeysUsed);
    x_data = seriesXaxisData(mqttKeysUsed,selectedGraph)
    y_data = mqttKeysSeriesData(mqttKeysUsed,key_data)
  }
  const handleDeviceControl = () => {
    setDisableDaily(true);
    const que = {
      "category":devicesCatogary
    }
    getMqttUsedKeys(que);
    setStartDate("");
  };
  return (
    <div>
      <div className="row reset-device-block mb-0">
        <div className="s6 col">
          <div className="control-capabilities">
            <div className="model-srch reset full-w  date__picker">
              <DatePicker
                selected={startDate}
                maxDate={new Date()}
                onChange={handleStartDateChange}
                placeholderText="Select Date"
                dateFormat="dd/MM/yyyy"
                className="add-input calendar input_date"
              />
              <button
                className="date_button orange-bg filter_btn w-100"
                onClick={() => HandleDeviceControlFilter(startDate)}
              >
                Search
              </button>
              {!disableDaily && (
            <button
              className="date_button orange-bg filter_btn w-100 ml-20"
              onClick={handleDeviceControl}
            >
              Daily
            </button>
          )}
            </div>
          </div>
        </div>
        <div className="s6 col">
          <div className="control-capabilities">
            <div className="model-srch reset full-w">
              <label className="field-name mb-0">Device Category </label>
              <select
                className="custom-select select-reset radius-20"
                name="searchBy"
                onChange={handleDeviceCategory}
              >
                {allowedDeviceCategories.map((category) => (
                  <option value={category}>{category}</option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </div>
      <div className="chart_view">
        <Graph
          text={" mqtt key "}
          x_data={x_data}
          title={"Number Of mqtt Keys "}
          y_data={y_data}
          stacked = {true}
        />
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    mqttKeysUsed: state.metrics.mqttKeysUsed,
  allowedDeviceCategories:state.auth.allowedDeviceCategories,

  };
};

export default connect(mapStateToProps, { getMqttUsedKeys })(MqttUsedKeysChart);
