import React, { Fragment, useState, useEffect } from "react";
import Spinner from "../layout/Spinner";
import ReportDevices from "./ReportDevices";
import { Link } from "react-router-dom";
const ReportSpace = ({ getAllDevice, allDevice, report: { spaces, loading }, homeId }) => {
  const [selectedData, setSelectedData] = useState({});
  const [Id, setId] = useState(null);
  useEffect(() => {
    if (!(spaces && spaces.length > 0)) {
      return;
    }else{
  let deviceIds = [];
  let deviceData=[];
      for (let i = 0; i < spaces.length; i++) {
        if (spaces[i].devices.length > 0) {
          deviceData.push(spaces[i].devices.length);
          for (let j = 0; j < spaces[i].devices.length; j++) {
            deviceIds.push(spaces[i].devices[j].deviceId);
          }
        }
      }
      if (allDevice.length === 0 && deviceData.length > 0)
        getAllDevice(deviceIds.join(','));

      if (allDevice.length !== 0) {
        for (let k = 0; k < allDevice.length; k++) {
          for (let i = 0; i < spaces.length; i++) {
            if (spaces[i].devices.length > 0) {
              for (let j = 0; j < spaces[i].devices.length; j++) {
                if (spaces[i].devices[j].deviceId === allDevice[k].deviceId) {
                  spaces[i].devices[j]['categoy'] = allDevice[k].category
                }
              }
            }
          }
        }
      }
    }
    
    setSelectedData(spaces[0]);
    setId(spaces[0].spaceId);

  }, [allDevice]);
  const changeTab = tab => {
    setSelectedData(tab);
    setId(tab.spaceId);
  };

  return loading ? (
    <Spinner />
  ) : (

      (spaces && spaces.length > 0) &&
        <Fragment>
          <div className="col s12">
            <ul className="tabs nested mb-15">
              {spaces &&
                spaces.map(space => (
                  <li key={space.spaceId} className="tab dsdsf">
                    <Link
                      to={"#"}
                      onClick={() => changeTab(space)}
                      className={Id === space.spaceId ? "active" : ""}
                    >
                      {space.spaceName}
                    </Link>
                  </li>
                ))}
            </ul>

          </div>

          {Object.keys(selectedData).length > 0 && (
            <ReportDevices selectedData={selectedData} homeId={homeId} />
          )}
        </Fragment>

    );
};
ReportSpace.propTypes = {};
export default ReportSpace;
