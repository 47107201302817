
import React, { Fragment, useState } from "react";
import Grid from '@material-ui/core/Grid';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { notificationValidations } from "./notificationConstant";



const SMSNotification = ({
    handleMessageData,
    messageData
}) => {

    return (
        <Fragment>
            <label className="title" style={{ fontSize: "14px", fontWeight: "bold" }}>Message</label>
            <div className="content-block position-relative" style={{ marginTop: "10px" }}>
                <div className="py-15">
                    <Grid container style={{ marginBottom: "20px" }}>
                        <Grid item xs={2} style={{ marginLeft: "10px" }}>
                            <label className="title" style={{ color: 'black', fontSize: '14px' }}>Message: </label>
                        </Grid>
                        <Grid item xs={4}>
                            <TextareaAutosize
                                aria-label="minimum height"
                                placeholder="Write a Message"
                                style={{ height: '100px', padding: "10px" }}
                                onChange={(e) => {
                                    handleMessageData((e.target.value).substring(0, notificationValidations?.sms?.body?.maxLength))
                                }}
                                value={messageData}
                            />
                            <p>{(messageData?.length > notificationValidations?.sms?.body?.maxLength ? notificationValidations?.sms?.body?.maxLength : messageData?.length) + "/" + notificationValidations?.sms?.body?.maxLength}</p>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </Fragment>
    );
}


export default SMSNotification