import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import customConfig from "../../../config";
import { updateEmailIDbyUserID } from "../../../action/user";
import { connect } from "react-redux";

const UserInfoWrapper = ({
  children,
  withoutOnboard = false,
  updateEmailIDbyUserID,
  UserData,
  userRoleInfo,
  userById,
  tenantInfo
}) => {
  const [showUpdateEmailID, setShowUpdateEmailID] = useState(false);
  const [disabledEmailUpdateButton, setDisabledEmailUpdateButton] =
    useState(false);
  const [emailValidationMessage, setEmailValidationMessage] = useState(false);
  const user = userById ? userById : UserData.user;
  const { emailUpdateError, emailUpdateSuccess } = UserData;
  let [userEmailId, setUserEmailId] = useState("");
  useEffect(() => {
    setUserEmailId(user && user.email ? user.email : "");
  }, [user]);
  useEffect(() => {
    if (emailUpdateError) {
      cancelEmailPopup();
    }
  }, [emailUpdateError]);

  useEffect(() => {
    if (emailUpdateSuccess) {
      user.email = userEmailId;
    }
  }, [emailUpdateSuccess]);

  // Visible email update form
  const showUpdateEmailPopup = () => {
    setDisabledEmailUpdateButton(userEmailId.length <= 0);
    setShowUpdateEmailID(!showUpdateEmailID);
  };

  const cancelEmailPopup = () => {
    setUserEmailId(user && user.email ? user.email.trim() : "");
    setShowUpdateEmailID(false);
    setDisabledEmailUpdateButton(false);
    setEmailValidationMessage(false);
  };

  // Hit api to update email id
  const updateEmailIdClick = () => {
    const updatedEmailID = userEmailId.trim();
    const isValid = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
      updatedEmailID
    );
    if (!isValid) {
      setEmailValidationMessage(true);
      return;
    }
    setEmailValidationMessage(false);
    updateEmailIDbyUserID(user.userId, updatedEmailID, UserData.umV1AuthToken, tenantInfo.tenantId);
    setShowUpdateEmailID(false);
  };

  const onChange = (e) => {
    if (e.target.value.trim().length > 0) setDisabledEmailUpdateButton(false);
    else setDisabledEmailUpdateButton(true);

    setUserEmailId(e.target.value);
  };
  return (
    <>
      {children({
        user,
        emailUpdateError,
        emailUpdateSuccess,
        userEmailId,
        showUpdateEmailID,
        disabledEmailUpdateButton,
        emailValidationMessage,
        withoutOnboard,
        userRoleInfo,
        setShowUpdateEmailID,
        setDisabledEmailUpdateButton,
        setEmailValidationMessage,
        showUpdateEmailPopup,
        cancelEmailPopup,
        updateEmailIdClick,
        onChange,
      })}
    </>
  );
};

UserInfoWrapper.propTypes = {
  children: PropTypes.func,
};

const mapStateToProps = (state) => ({
  UserData: state.user,
  user: state.user.user,
  userRoleInfo: {
    level: state.auth.userLevel,
    roles: state.auth.userRoles,
  },
  tenantInfo: state.tenant.tenantInfo
});

export default connect(mapStateToProps, { updateEmailIDbyUserID })(
  UserInfoWrapper
);
