import React from "react";
import PropTypes from "prop-types";
import customConfig from "../../../../config";
import UserInfoWrapper from "../../../Common/user/UserInfoWrapper";

const Info = () => {
  return (
    <UserInfoWrapper>
      {({
        showUpdateEmailID,
        disabledEmailUpdateButton,
        emailValidationMessage,
        userRoleInfo,
        withoutOnboard,
        showUpdateEmailPopup,
        cancelEmailPopup,
        updateEmailIdClick,
        onChange,
        user,
        userEmailId,
      }) => (
        <div className="col s4">
          <div className="blue-bg user-information-bx radius-6">
            <h3 className="font-20 p-15 white-text font-700 bottom-white-border">
              User Information
            </h3>
            <div className="p-15 position-relative">
              {(user && user.userName && withoutOnboard == false) ? (
                <>
                  <div className="user mb-25">
                    <label className="font-14 white-text">Name</label>
                    <p className="font-16 white-text font-600">
                      {user.firstName} {user.lastName}
                    </p>
                  </div>
                  <div className="user mb-25">
                    <label className="font-14 white-text">
                      Email Id
                      {userRoleInfo.level > 1 && !showUpdateEmailID && (
                        <button
                          className="edit-link"
                          onClick={showUpdateEmailPopup}
                        >
                          <i className="material-icons icn">mode_edit</i>
                        </button>
                      )}
                    </label>
                    {!showUpdateEmailID && (
                      <div className="font-16 white-text font-600">
                        {" "}
                        {userEmailId}{" "}
                      </div>
                    )}
                  </div>
                  <div className="emailPopup">
                    <div className="s4 col">
                      <div className="d-flex info-box">
                        {/* <div className="info">
                          <label className="label">
                            Email Id
                            {userRoleInfo.level > 1 && !showUpdateEmailID && (
                              <button
                                className="edit-link"
                                onClick={showUpdateEmailPopup}
                              >
                                <i className="material-icons icn">mode_edit</i>
                              </button>
                            )}
                          </label>
                          {!showUpdateEmailID && (
                            <div className="user-about medium">
                              {" "}
                              {userEmailId}{" "}
                            </div>
                          )}
                        </div> */}
                        {showUpdateEmailID && (
                          <div className="emailPopup-set">
                            <div className="d-flex info-box flex-end">
                              <div className="update-bx">
                                <div>
                                  <input
                                    placeholder="Enter Email Id"
                                    className="font-16 update-inpt medium"
                                    value={userEmailId}
                                    type="text"
                                    onChange={onChange}
                                  />
                                  {emailValidationMessage && (
                                    <p className="mtopBot10 is-danger emailError">
                                      Email is not valid
                                    </p>
                                  )}
                                </div>
                                <button
                                  disabled={disabledEmailUpdateButton}
                                  className="btn btn-style-2 orange-bg mr-10"
                                  onClick={() => updateEmailIdClick()}
                                >
                                  Update
                                </button>
                                <button
                                  className="btn btn-style-2 dark-text gray-bg"
                                  onClick={() => cancelEmailPopup()}
                                >
                                  Cancel
                                </button>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="user mb-0">
                    <label className="font-14 white-text">Phone Number</label>
                    <p className="font-16 white-text font-600">{user.mobile}</p>
                  </div>
                </>
              ) : (
                <p className="center">
                  {" "}
                  {withoutOnboard
                    ? customConfig.noOnboardMsg
                    : customConfig.noDataMsg}{" "}
                </p>
              )}
            </div>
          </div>
        </div>
      )}
    </UserInfoWrapper>
  );
};

Info.propTypes = {};

export default Info;
