import axios from "axios";
import env from "../env";
import {
    UPLOAD_PSN_DELAER_MAPPING_FILE,
    UPLOAD_PSN_DELAER_MAPPING_FILE_SUCCESS,
    UPLOAD_PSN_DELAER_MAPPING_FILE_ERROR
} from "./types";
import { setAlert } from "./alert";

export const createDealerInformation = (data) => {

    const config = {
        headers: {
            "Content-Type": "application/json",
        }
    }

    return new Promise((resolve, reject) => {
        axios
            .post(
                `${env.GET_DEVICEMANAGEMENT_API_URL()}/simplifi/v1/deviceservices/dealer`, data, config)
            .then((res) => resolve(res.data))
            .catch((err) => reject(err));
    });
};

export const updateDealerInformation = (dealerId, data) => {

    const config = {
        headers: {
            "Content-Type": "application/json",
        }
    }

    return new Promise((resolve, reject) => {
        axios
            .put(
                `${env.GET_DEVICEMANAGEMENT_API_URL()}/simplifi/v1/deviceservices/dealer?id=${dealerId}`, data, config)
            .then((res) => resolve(res.data))
            .catch((err) => reject(err));
    });
};

export const getDeviceDealerData = (params) => {
    let filteredParams = {};
    if (params) {
        Object.keys(params).forEach((key) => {
            if (params[key] || params[key] === 0) {
                filteredParams[key] = params[key];
            }
        });
    }
    return new Promise((resolve, reject) => {
        axios
            .get(
                `${env.GET_DEVICEMANAGEMENT_API_URL()}/simplifi/v1/deviceservices/dealers`,
                {
                    params: filteredParams,
                }
            )
            .then((res) => resolve(res.data))
            .catch((err) => reject(err));
    });
}

export const getDeviceDealerInfoByPSN = (productSerialNumber) => {

    return new Promise((resolve, reject) => {
        axios
            .get(
                `${env.GET_DEVICEMANAGEMENT_API_URL()}/simplifi/v1/deviceservices/device/${productSerialNumber}/dealer`)
            .then((res) => resolve(res.data))
            .catch((err) => reject(err));
    });
}

export const getDeviceDealerInfoByDealerId = (dealerId) => {

    return new Promise((resolve, reject) => {
        axios
            .get(
                `${env.GET_DEVICEMANAGEMENT_API_URL()}/simplifi/v1/deviceservices/dealer?id=${dealerId}`)
            .then((res) => resolve(res.data))
            .catch((err) => reject(err));

    });
}

export const deleteDealerInformation = (dealerId) => {

    const config = {
        headers: {
            "Content-Type": "application/json",
        }
    }

    return new Promise((resolve, reject) => {
        axios
            .delete(
                `${env.GET_DEVICEMANAGEMENT_API_URL()}/simplifi/v1/deviceservices/dealer?id=${dealerId}`, config)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

export const uploadPSNDealerFile = (formData) => async (dispatch) => {
    try {
        dispatch({
            type: UPLOAD_PSN_DELAER_MAPPING_FILE,
        });

        const config = {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        };

        const res = await axios.post(
            `${env.GET_DEVICEMANAGEMENT_API_URL()}/simplifi/v1/deviceservices/device/dealer`, formData, config);

        dispatch({
            type: UPLOAD_PSN_DELAER_MAPPING_FILE_SUCCESS,
            payload: {},
        });

        dispatch(setAlert(res?.data?.message ?? "Sucessfully Uploaded the PSN Dealer File", "success"));
        window.scrollTo(0, 0);

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message ?? "Failed to Upload the PSN Dealer File", "danger"));
        window.scrollTo(0, 0);

        dispatch({
            type: UPLOAD_PSN_DELAER_MAPPING_FILE_ERROR,
            payload: {},
        });
    }
}