import axios from "axios";
import { GET_HOME, HOME_ERROR } from "./types";
import env from "../env";
import { setAlert } from "./alert";
import customConfig from "../config";

//GET Search User

export const getHome = (userId) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const res = await axios.get(
      `${env.GET_DEVICEMANAGEMENT_API_URL()}/simplifi/v1/homeManagement/users/${userId}/homes`,
      config
    );
    dispatch({
      type: GET_HOME,
      payload: res.data,
    });
  } catch (err) {
    if (err.response) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }else{
      dispatch(setAlert(customConfig.serverDownMsg, "danger"));
    }
    dispatch({
      type: HOME_ERROR,
      payload: err,
    });
  }
};
