import React, { useEffect, useState } from "react";
import M from "materialize-css/dist/js/materialize.min.js";
import { getFeedbackData, updateFeedback,downloadFeedbackReport } from "../../../action/feedback";
import CircularProgress from "@material-ui/core/CircularProgress";
import Alert from "../../layout/Alert";
import { setAlert } from "../../../action/alert";
import { connect } from "react-redux";
import "./feedback.css";
import DatePicker from "react-datepicker";

const Feedback = ({ setCustomAlert, allowedDeviceCategories,allowCompanyBrand }) => {
  const typeFilters = [
    { label: "Select Type", value: undefined },
    { label: "Onboarding Failure", value: "ONBOARDING_FAILURE" },
    { label: "Control Failure", value: "CONTROL_FAILURE" },
    { label: "Device Offline", value: "DEVICE_OFFLINE" },
    { label: "New Feature", value: "NEW_FEATURE" },
    { label: "Product Info", value: "PRODUCT_INFO" },
    { label: "Warranty", value: "WARRANTY" },
    { label: "WIFI Update", value: "WIFI_UPDATE" },
    { label: "FW Upgrade", value: "FW_UPGRADE" },
    { label: "Device Offboarding", value: "DEVICE_OFFBOARDING" },
    { label: "Other", value: "OTHER" },
  ];
  const categoryFilters = [
    { label: "Select Category", value: undefined },
    { label: "AC", value: "AC" },
    { label: "Fan Controller", value: "FANCONTROLLER" },
    { label: "Washing Machine", value: "ODMFRONTLOADWM" },
    { label: "Water Heater", value: "WATERHEATER" },
    { label: "Refrigerator", value: "REFRIGERATOR" },
    { label: "Switches", value: "SWITCHES" },
    { label: "Fans", value: "ANCHORFAN" },
    { label: "VDP", value: "VDP" },
    { label: "TV", value: "TV" },
  ];

  const brandFilters = [
    { label: "Select Brand", value: undefined },
    { label: "Panasonic", value: "PANASONIC" },
    { label: "Sanyo", value: "SANYO" },
    { label: "Nokia", value: "NOKIA" },
    { label: "Voltas", value: "VOLTAS" },
  ];
  
  const statusFilters = [
    { label: "Select Status", value: undefined },
    { label: "New", value: "NEW" },
    { label: "In-Progress", value: "IN-PROGRESS" },
    { label: "Closed", value: "CLOSED" },
  ];
  // add key value pair for any new column to be added to the table. Key should be same as the key in the response and value should be what you want the header for the column to be
  const headers = {
    type: "Type",
    deviceBrand: "Device Brand",
    deviceCategory: "Device Category",
    deviceId: "Device Id",
    mobile: "Mobile Number",
    description: "Description",
    status: "Status",
    comments: "Comments",
    errorCode: "Error Code",
    appVersion: "App Version",
    mobileOSVersion: "Mobile OS Version",
    mobileName: "Mobile Name",
    mobileModel: "Mobile Model",
    mobileOSBuildNumber: "Mobile OS Build Number",
    feedbackId: "Feedback Id",
    // userId: "User Id",
    // tenant: "Tenant",
    createdAt: "Created At",
    updatedAt: "Updated At",
  };

  const [feedbackData, setFeedbackData] = useState([]);
  const [typeFilter, setTypeFilter] = useState(undefined);
  const [categoryFilter, setCategoryFilter] = useState(undefined);
  const [brandFilter, setBrandFilter] = useState(undefined);
  const [statusFilter, setStatusFilter] = useState(undefined);
  const [mobileFilter, setMobileFilter] = useState(undefined);
  const [startDateFilter, setStartDateFilter] = useState(undefined);
  const [endDateFilter, setEndDateFilter] = useState(undefined);
  const [updateFlag, setUpdateFlag] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [paginationStartIndex, setPaginationStartIndex] = useState(0);
  const [lastApiPageFetched, setLastApiPageFetched] = useState(0);
  const [pageEndReached, setPageEndReached] = useState(false);

  const filterCategoryFeedbackData = (data) => {
    let tempData = []
    if(data.length && allowedDeviceCategories.length){
      for(let i = 0 ; i < data.length ; i++){
        (function() { 
        for(let j = 0 ; j < allowedDeviceCategories.length ; j++) {
          if(data[i].deviceCategory === allowedDeviceCategories[j] ) {
            tempData.push(data[i])
            return 
          }
        }
      })();
      }
      return tempData;
    }
     return tempData;
  }

  const pageSize = 20;
  const totalPageCount = Math.ceil(feedbackData?.length / pageSize);
  const startIndex = pageSize * currentPage;
  const endIndex = startIndex + pageSize;

  const pageNumbersToShow = () => {
    if (totalPageCount === 0) {
      return [0];
    } else {
      let i = paginationStartIndex;
      let pagesToShow = [];
      while (i < totalPageCount && i < paginationStartIndex + 5) {
        pagesToShow.push(i);
        i += 1;
      }
      return pagesToShow;
    }
  };

  const getPaginationData = () => {
    const pageArr = pageNumbersToShow();
    return (
      <ul className="pagination">
        <li
          className={paginationStartIndex === 0 ? "disabled" : "waves-effect"}
          onClick={() => {
            if (!(paginationStartIndex === 0)) {
              setPaginationStartIndex(Math.max(0, paginationStartIndex - 5));
            }
          }}
        >
          <a href="#!">
            <i className="material-icons">chevron_left</i>
          </a>
        </li>
        {pageArr.map((element) => {
          return (
            <li
              className={
                parseInt(element) === parseInt(currentPage) ? "active" : ""
              }
            >
              <a href="#" onClick={() => setCurrentPage(element)}>
                {element + 1}
              </a>
            </li>
          );
        })}
        <li
          className={
            paginationStartIndex >= totalPageCount - 5
              ? "disabled"
              : "waves-effect"
          }
          onClick={() => {
            if (!pageEndReached) {
              const pageArr = pageNumbersToShow();
              if (pageArr[-1] + 1 === totalPageCount) {
                setPaginationStartIndex(
                  Math.min(totalPageCount, paginationStartIndex + 5)
                );
              } else {
                updateFeedbackData(
                  lastApiPageFetched + 1,
                  nextPaginationResolveFunc
                );
              }
            } else {
              setCustomAlert("You have reached end of page.");
            }
          }}
        >
          <a href="#!">
            <i className="material-icons">chevron_right</i>
          </a>
        </li>
      </ul>
    );
  };

  const nextPaginationResolveFunc = (data) => {
    if (data.length < 100) {
      setPageEndReached(true);
    }
    if (data.length === 0) {
      setCustomAlert("You have reached end of page.");
    } else {
      const newPaginationStartIndex = Math.min(
        parseInt((feedbackData.length + data.length) / pageSize),
        paginationStartIndex + 5
      );
      setPaginationStartIndex(newPaginationStartIndex);
      setFeedbackData([...feedbackData, ...data]);
      setLastApiPageFetched(lastApiPageFetched + 1);
      setCurrentPage(newPaginationStartIndex);
    }
  };
  const formateDate = (dateFilter) => {
    if(dateFilter) {
    const dateVal = dateFilter.getDate() < 10 ? `0${dateFilter.getDate()}` : dateFilter.getDate()
    const monthVal = dateFilter.getMonth() < 9 ? `0${dateFilter.getMonth()+1}` : dateFilter.getMonth()+1
    return `${dateVal}${monthVal}${dateFilter.getFullYear()}`}

  }
  const updateFeedbackData = (page, resolveFunc, errFunc) => {
    setIsLoading(true);
    getFeedbackData({
      deviceCategory: categoryFilter,
      deviceBrand: brandFilter,
      type: typeFilter,
      status:statusFilter,
      mobile:mobileFilter,
      startDate:formateDate(startDateFilter),
      endDate:formateDate(endDateFilter),
      page: page || lastApiPageFetched,
      size: pageSize * 5, //API will only return max 100 results
    })
      .then((res) => {
        setIsLoading(false);
        if (!resolveFunc) {
          if (res.length < 100) {
            setPageEndReached(true);
          }
          setFeedbackData(res);
        } else {
          resolveFunc(res);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        if (!errFunc) {
          console.log(err);
          setCustomAlert("Error while fetching data.");
        } else {
          errFunc(err);
        }
      });
  };

  const changeFeedBackValueInPage = (feedbackId, key, value) => {
    let i,
      startingIndex = currentPage * pageSize;
    i = startIndex;
    while (i < startingIndex + pageSize) {
      if (feedbackData[i].feedbackId === feedbackId) {
        feedbackData[i][key] = value;
        setFeedbackData([...feedbackData]);
        return;
      }
      i += 1;
    }
  };

  const updateStatus = (feedbackId, status) => {
    updateFeedback(feedbackId, {
      status,
    })
      .then(() => {
        changeFeedBackValueInPage(feedbackId, "status", status);
      })
      .catch((err) =>
        setCustomAlert("There was an error while updating status.")
      );
  };

  const udpateComments = (feedbackId, comments, status) => {
    updateFeedback(feedbackId, {
      comments,
      status,
    })
      .then(() => {
        changeFeedBackValueInPage(feedbackId, "comments", comments);
      })
      .catch((err) => {
        setCustomAlert("There was an error while updating comments.");
      });
  };

  useEffect(() => {
    M.AutoInit();
  }, [updateFlag, isLoading, currentPage, feedbackData]);

  useEffect(() => {
    updateFeedbackData();
  }, [updateFlag]);

  const reset = () => {
    setTypeFilter(undefined);
    setCategoryFilter(undefined);
    setBrandFilter(undefined);
    setStatusFilter(undefined);
    setMobileFilter(undefined);
    setStartDateFilter(undefined)
    setEndDateFilter(undefined)
    filter();
  };

  const filter = () => {
    setCurrentPage(0);
    setPaginationStartIndex(0);
    setPageEndReached(false);
    setLastApiPageFetched(0);
    setUpdateFlag(Math.random());
  };

  if (isLoading) {
    return (
      <div
        style={{
          display: "grid",
          placeItems: "center",
          height: "80vh",
        }}
      >
        <CircularProgress size="5em" style={{ color: "#AFB42B" }} />
      </div>
    );
  }

  const handleStartDateChange = (date) => {
    setStartDateFilter(date)
  }
  const handleEndtDateChange = (date) => {
    setEndDateFilter(date)
  }
  const handleDownloadFeedback = () => {
    downloadFeedbackReport({
      deviceCategory: categoryFilter,
      deviceBrand: brandFilter,
      type: typeFilter,
      status:statusFilter,
      startDate:formateDate(startDateFilter),
      endDate:formateDate(endDateFilter),
    })
  }

  return (
    <div className="container">
      <div className="content-block bottom-shadow">
        <div className="py-15 ">
          <div className="row mb-0">
            <div className="col s3">
            <div className="model-srch reset width-md">
              <select
                className="custom-select select-reset radius-20"
                name="typeFilter"
                onChange={(e) => {
                  let value = e.target.value;
                  // if value is provided undefined, label comes as value when option is selected, which may cause error
                  if (value === typeFilters[0].label) {
                    value = undefined;
                  }
                  setTypeFilter(value);
                }}
                value={typeFilter}
              >
                {typeFilters.map((element) => (
                  <option value={element.value}>{element.label}</option>
                ))}
              </select>
              </div>
            </div>
            <div className="col s3">
            <div className="model-srch reset width-md">
              <select
                className="custom-select select-reset radius-20"
                name="categoryFilter"
                onChange={(e) => {
                  let value = e.target.value;
                  if (value === "Select Category") {
                    value = undefined;
                  }
                  setCategoryFilter(value);
                }}
                value={categoryFilter}
              >
                {["Select Category",...allowedDeviceCategories].map((element) => (
                  <option value={element}>{element}</option>
                ))}
              </select>
              </div>
            </div>
            <div className="col s3">
            <div className="model-srch reset width-md">
              <select 
                className="custom-select select-reset radius-20"
                name="brandFilter"
                onChange={(e) => {
                  let value = e.target.value;

                  if (value === "Select Brand") {
                    value = undefined;
                  }
                  setBrandFilter(value);
                }}
                value={brandFilter}
              >
                {["Select Brand",...allowCompanyBrand].map((element) => (
                  <option value={element}>{element}</option>
                ))}
              </select>
              </div>
            </div>
            <div className="col s3">
            <div className="model-srch reset width-md">
              <select
                className="custom-select select-reset radius-20"
                name="statusFilter"
                onChange={(e) => {
                  let value = e.target.value;
                  // if value is provided undefined, label comes as value when option is selected, which may cause error
                  if (value === statusFilters[0].label) {
                    value = undefined;
                  }
                  setStatusFilter(value);
                }}
                value={statusFilter}
              >
                {statusFilters.map((element) => (
                  <option value={element.value}>{element.label}</option>
                ))}
              </select>
              </div>
            </div>
            {/* <div className="col s3">
            <div className="model-srch reset width-md">
                <input
                  className="input_phone"
                  placeholder={ "Search mobile with country code" }
                  type="text"
                  name="mobileFilter"
                  value={mobileFilter}
                  onChange={(e) => {
                    let value = e.target.value;
                    setMobileFilter(value);
                  }}
                />
              </div>
            </div> */}
            <div className="col s3">
              <div className="model-srch reset width-md">
                <DatePicker
                  selected={startDateFilter}
                  maxDate={new Date()}
                  onChange={handleStartDateChange}
                  placeholderText="Start Date"
                  dateFormat = 'MM/dd/yyyy'
                  className="add-input calendar input_phone"
                />
              </div>
            </div>
            <div className="col s3">
              <div className="model-srch reset width-md">
                <DatePicker
                  selected={endDateFilter}
                  maxDate={new Date()}
                  onChange={handleEndtDateChange}
                  placeholderText="End Date"
                  dateFormat = 'MM/dd/yyyy'
                  className="add-input calendar input_phone"
                />
              </div>
            </div>
            <div className="col s3 pt-10">
              {/* <div className="d-flex align-items-center pt-25"> */}
              <button
                className="btn btn-style-3  sm  mr-10 font-400"
                onClick={filter}
              >
                Filter
              </button>
              <button
                className="btn btn-style-3 sm font-400"
                onClick={reset}
              >
                Reset
              </button>
              {/* </div> */}
            </div>
            <div className="col s3 pt-10">
              {/* <div className="d-flex align-items-center pt-25"> */}
              <button
                className="btn btn-style-3  sm  mr-10 font-400 float_right cursor-Hand"
                onClick={handleDownloadFeedback}
              >
                Download
              </button>
              {/* </div> */}
            </div>
          </div>
        </div>
      </div>
      <Alert />
      <div className="content-block bottom-shadow py-15 mb-30">
        <div className="row mb-0">
          <div className="col s12">
            <div
              className="table-wrapper mb-20"
              style={{
                overflowX: "scroll",
                scrollBehavior: "auto",
                // whiteSpace: "nowrap",
                maxHeight: "58vh",
                overflowY: "scroll",
              }}
            >
              <table
                width="100%"
                align="center"
                className="table style-4 mb-20 feedbacktable"
              >
                <thead
                  style={{ position: "sticky", top: 0, zIndex: 50, background: "white" }}
                >
                  <tr>
                    <th>Sl.No</th>
                    {Object.keys(headers).map((key) => (
                      <th>{headers[key]}</th>
                    ))}
                  </tr>
                </thead>

                <tbody>
                  {feedbackData.length === 0 ? (
                    <tr>
                      <td colSpan={Object.keys(headers).length}>
                        No Data found
                      </td>
                    </tr>
                  ) : (
                    feedbackData
                      .slice(startIndex, endIndex)
                      .map((element, index) => {
                        return (
                          <tr key={element.feedbackId} id={element.feedbackId}>
                            <td>{startIndex + index + 1}</td>
                            {Object.keys(headers).map((key) => {
                              if (key === "status") {
                                return (
                                  <td className = "select-status-box">
                                    <select
                                      disabled = {element[key] === "CLOSED" ? true : false}
                                      value={element[key]}
                                      onChange={(e) => {
                                        updateStatus(
                                          element.feedbackId,
                                          e.target.value
                                        );
                                      }}
                                    >
                                      <option value="NEW">New</option>
                                      <option value="IN-PROGRESS">In-Progress</option>
                                      <option value="CLOSED">Closed</option>
                                    </select>
                                  </td>
                                );
                              } else if (key === "comments") {
                                let commentInputId =
                                  element.feedbackId + "Comment";
                                let commentDivId = element.feedbackId + "Div";
                                if (element.showCommentEdit) {
                                  console.log(element);
                                }
                                return (
                                  <td
                                    onClick={() => {
                                      if (element.status === "NEW") {
                                        setCustomAlert(
                                          "Comments are editable if status is closed."
                                        );
                                        return;
                                      }
                                      changeFeedBackValueInPage(
                                        element.feedbackId,
                                        "showCommentEdit",
                                        true
                                      );
                                    }}
                                  >
                                    {element.showCommentEdit ? (
                                      <input
                                        value={element.comments}
                                        id={commentInputId}
                                        autoFocus
                                        onChange={(e) =>
                                          changeFeedBackValueInPage(
                                            element.feedbackId,
                                            "comments",
                                            e.target.value
                                          )
                                        }
                                        onBlur={() => {
                                          changeFeedBackValueInPage(
                                            element.feedbackId,
                                            "showCommentEdit",
                                            false
                                          );
                                          udpateComments(
                                            element.feedbackId,
                                            element.comments,
                                            element.status
                                          );
                                        }}
                                      />
                                    ) : (
                                      <div id={commentDivId}>
                                        {!element.comments ? (
                                          <i className={`material-icons icn ${element.status !== "NEW" ? "color-orange" : "color-gray"} cursor-Hand ml-20`} >
                                          mode_edit
                                        </i>
                                          ) : element.comments}
                                      </div>
                                    )}
                                  </td>
                                );
                              }
                              return (
                                <td
                                  className={
                                    String(element[key]).length > 200
                                      ? "breakWord"
                                      : ""
                                  }
                                >
                                  {element[key]}
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })
                  )}
                </tbody>
              </table>
            </div>
            <div className="d-flex justify-content-end ">
              {getPaginationData()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  allowedDeviceCategories:state.auth.allowedDeviceCategories,
  allowCompanyBrand:state.auth.allowCompanyBrand,

});

const mapDispatchToProps = (dispatch) => ({
  setCustomAlert: (message) => dispatch(setAlert(message, "warning")),
});

export default connect(mapStateToProps, mapDispatchToProps)(Feedback);
