import React, { useEffect, useState } from "react";
import M from "materialize-css/dist/js/materialize.min.js";
import CircularProgress from "@material-ui/core/CircularProgress";
import './deviceDealersList.css'
import { Fragment } from "react";
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from "@material-ui/icons/Delete";
import { getDeviceDealerData, deleteDealerInformation } from './../../action/deviceDealer'
import Popup from "../layout/Popup";
import DisplayDeviceDealerDeatils from "./deviceDealerDetailsPage";
import Grid from '@material-ui/core/Grid';
import customConfig from "../../config";

const DeviceDealerList = ({
    setAlert,
    countryCodeFilter,
    onEditDeviceDealerDetails,
    showUpdatedDealerInfo,
    userRoleInfo
}) => {

    const headers = {
        id: "ID",
        name: "Name",
        email: "Email",
        website: "Website",
        editDeviceDealerDetails: "Edit",
        deleteDeviceDealerDetails: "Delete"
    };

    const [deviceDealersData, setDeviceDealersData] = useState([])
    const [updateFlag, setUpdateFlag] = useState(undefined)
    const [isLoading, setIsLoading] = useState(false)
    const [currentPage, setCurrentPage] = useState(0)
    const [paginationStartIndex, setPaginationStartIndex] = useState(0)
    const [lastApiPageFetched, setLastApiPageFetched] = useState(0)
    const [pageEndReached, setPageEndReached] = useState(false)
    const [showDeviceDealerDetails, setShowDeviceDealerDetails] = useState(false)
    const [selectedDeviceDealerDetailsForDisplay, setSelectedDeviceDealerDetailsForDisplay] = useState({})
    const [showDealerDevicesList, setShowDealerDevicesList] = useState(false)
    const [deleteDeviceDealer, setDeleteDeviceDealer] = useState(false)
    const [selectedDealerDetailsForDelete, setSelectedDealerDetailsForDelete] = useState({})

    const pageSize = 20;
    const totalPageCount = Math.ceil(deviceDealersData?.length / pageSize);
    const startIndex = pageSize * currentPage;
    const endIndex = startIndex + pageSize;

    useEffect(() => {
        setCurrentPage(0);
        setPaginationStartIndex(0);
        setPageEndReached(false);
        setLastApiPageFetched(0);
        setUpdateFlag(Math.random());
        setShowDealerDevicesList(true);
        setSelectedDealerDetailsForDelete({})
        setSelectedDeviceDealerDetailsForDisplay({})
    }, [])

    useEffect(() => {
        M.AutoInit();
    }, [updateFlag, isLoading, currentPage, deviceDealersData, showDealerDevicesList, showDeviceDealerDetails]);

    useEffect(() => {
        if (updateFlag) {
            updateDevcieDealersData();
        }
    }, [updateFlag]);

    useEffect(() => {
        if (showUpdatedDealerInfo) {
            updateDevcieDealersData();
            setShowDeviceDealerDetails(false)
            setSelectedDeviceDealerDetailsForDisplay({})
        }
    }, [showUpdatedDealerInfo])

    const pageNumbersToShow = () => {
        if (totalPageCount === 0) {
            return [0];
        } else {
            let i = paginationStartIndex;
            let pagesToShow = [];
            while (i < totalPageCount && i < paginationStartIndex + 5) {
                pagesToShow.push(i);
                i += 1;
            }
            return pagesToShow;
        }
    };

    const getPaginationData = () => {
        const pageArr = pageNumbersToShow();
        return (
            <ul className="pagination">
                <li
                    className={paginationStartIndex === 0 ? "disabled" : "waves-effect"}
                    onClick={() => {
                        if (!(paginationStartIndex === 0)) {
                            setPaginationStartIndex(Math.max(0, paginationStartIndex - 5));
                        }
                    }}
                >
                    <a href="#!">
                        <i className="material-icons">chevron_left</i>
                    </a>
                </li>
                {pageArr.map((element) => {
                    return (
                        <li
                            className={
                                parseInt(element) === parseInt(currentPage) ? "active" : ""
                            }
                        >
                            <a href="#" onClick={() => setCurrentPage(element)}>
                                {element + 1}
                            </a>
                        </li>
                    );
                })}
                <li
                    className={
                        paginationStartIndex >= totalPageCount - 5
                            ? "disabled"
                            : "waves-effect"
                    }
                    onClick={() => {
                        if (!pageEndReached) {
                            const pageArr = pageNumbersToShow();
                            if (pageArr[-1] + 1 === totalPageCount) {
                                setPaginationStartIndex(
                                    Math.min(totalPageCount, paginationStartIndex + 5)
                                );
                            } else {
                                updateDevcieDealersData(
                                    lastApiPageFetched + 1,
                                    nextPaginationResolveFunc
                                );
                            }
                        } else {
                            setAlert("You have reached end of page.", "danger");
                        }
                    }}
                >
                    <a href="#!">
                        <i className="material-icons">chevron_right</i>
                    </a>
                </li>
            </ul>
        );
    };

    const nextPaginationResolveFunc = (data) => {
        if (data.length < 100) {
            setPageEndReached(true);
        }
        if (data.length === 0) {
            setAlert("You have reached end of page.", "danger");
        } else {
            const newPaginationStartIndex = Math.min(
                parseInt((deviceDealersData.length + data.length) / pageSize),
                paginationStartIndex + 5
            );
            setPaginationStartIndex(newPaginationStartIndex);
            setDeviceDealersData([...deviceDealersData, ...data]);
            setLastApiPageFetched(lastApiPageFetched + 1);
            setCurrentPage(newPaginationStartIndex);
        }
    };

    const updateDevcieDealersData = (page, resolveFunc, errFunc) => {
        setIsLoading(true);

        getDeviceDealerData({
            country: countryCodeFilter,
            page: page || lastApiPageFetched,
            size: pageSize * 5
        })
            .then((res) => {
                setIsLoading(false);
                if (!resolveFunc) {
                    if (!res || (res && res.length < 100)) {
                        setPageEndReached(true);
                    }

                    if (!res) {
                        setDeviceDealersData([])
                    }
                    else {
                        setDeviceDealersData(res);
                    }
                } else {
                    resolveFunc(res);
                }
            })
            .catch((err) => {
                setIsLoading(false);
                if (!errFunc) {
                    console.log(err);
                    setAlert("Error while fetching data.", "danger");
                } else {
                    errFunc(err);
                }
            });
    };

    function editDeviceDealer(deviceDealerDetails) {
        onEditDeviceDealerDetails(deviceDealerDetails)
    }

    function handleDeleteDeviceDealer(deviceDealerDetails) {
        setSelectedDealerDetailsForDelete({
            'dealerId': deviceDealerDetails["id"],
            'dealerName': deviceDealerDetails["name"]
        })
        setDeleteDeviceDealer(true)
    }

    function handleDeviceDealerClosePopup() {
        setSelectedDealerDetailsForDelete({})
        setDeleteDeviceDealer(false)
    }

    function handleDeleteDeviceDealerFromPopup() {
        deleteDealerInformation(selectedDealerDetailsForDelete["dealerId"])
            .then((res) => {
                setAlert("Dealer Deleted Sucessfully", "success")
                setSelectedDealerDetailsForDelete({})
                setDeleteDeviceDealer(false)
                updateDevcieDealersData()
            })
            .catch((err) => {
                setAlert(err?.response?.data?.message ?? "Failed to Delete Dealer", "danger")
                setSelectedDealerDetailsForDelete({})
                setDeleteDeviceDealer(false)
            })
    }

    function handleDeleteDeviceDealerDetails() {
        setShowDeviceDealerDetails(false)
        setSelectedDeviceDealerDetailsForDisplay({})
        setSelectedDealerDetailsForDelete({})
        setDeleteDeviceDealer(false)
        updateDevcieDealersData();
    }

    if (isLoading) {
        return (
            <div
                style={{
                    display: "grid",
                    placeItems: "center",
                    height: "80vh",
                }}
            >
                <CircularProgress size="5em" style={{ color: "#AFB42B" }} />
            </div>
        );
    }

    return (
        <Fragment>
            <div className="container">
                {
                    !showDeviceDealerDetails && <div className="content-block bottom-shadow py-15 mb-30">
                        <div className="row mb-0">
                            <div className="col s12">
                                <div
                                    className="table-wrapper mb-20"
                                    style={{
                                        overflowX: "scroll",
                                        scrollBehavior: "auto",
                                        maxHeight: "58vh",
                                        overflowY: "scroll",
                                    }}
                                >
                                    <table
                                        width="100%"
                                        align="center"
                                        className="table style-4 mb-20 deviceDealerTable"
                                    >
                                        <thead
                                            style={{ position: "sticky", top: 0, zIndex: 50, background: "white" }}
                                        >
                                            <tr>
                                                {Object.keys(headers).map((key) => (
                                                    (key == "editDeviceDealerDetails" || key == "deleteDeviceDealerDetails") ?
                                                        (userRoleInfo.level > 4 || userRoleInfo.roles.includes(customConfig.roleCloudAdmin)) ?
                                                            <th>{headers[key]}</th>
                                                            :
                                                            <Fragment></Fragment>
                                                        :
                                                        <th>{headers[key]}</th>
                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {deviceDealersData.length === 0 ? (
                                                <tr>
                                                    <td colSpan={Object.keys(headers).length}>
                                                        No Data found
                                                    </td>
                                                </tr>
                                            ) : (
                                                deviceDealersData
                                                    .slice(startIndex, endIndex)
                                                    .map((element, index) => {
                                                        return (
                                                            <tr key={index % pageSize} id={index % pageSize}>
                                                                {Object.keys(headers).map((key) => {
                                                                    return (
                                                                        (key == "editDeviceDealerDetails" && (userRoleInfo.level > 4 || userRoleInfo.roles.includes(customConfig.roleCloudAdmin))) ?
                                                                            <td>
                                                                                <a href="javascript:void(0)" onClick={() => {
                                                                                    editDeviceDealer(element)
                                                                                }}><EditIcon style={{ color: "black" }}></EditIcon></a>
                                                                            </td>
                                                                            :
                                                                            (key == "deleteDeviceDealerDetails" && (userRoleInfo.level > 4 || userRoleInfo.roles.includes(customConfig.roleCloudAdmin))) ?
                                                                                <td>
                                                                                    <a href="javascript:void(0)" onClick={() => {
                                                                                        handleDeleteDeviceDealer(element)
                                                                                    }}><DeleteIcon style={{ color: "black" }}></DeleteIcon></a>
                                                                                </td>
                                                                                :
                                                                                key == "id" ?
                                                                                    <td
                                                                                        className={
                                                                                            String(element[key]).length > 200
                                                                                                ? "breakWord"
                                                                                                : ""
                                                                                        }
                                                                                        onClick={() => {
                                                                                            setSelectedDeviceDealerDetailsForDisplay(element)
                                                                                            setShowDeviceDealerDetails(true)
                                                                                        }} style={{ "cursor": "pointer" }}
                                                                                    >
                                                                                        <u>{element[key] ? element[key] : ""}</u>
                                                                                    </td>
                                                                                    :
                                                                                    <td
                                                                                        className={
                                                                                            String(element[key]).length > 200
                                                                                                ? "breakWord"
                                                                                                : ""
                                                                                        }
                                                                                    >
                                                                                        {element[key] ? element[key] : ""}
                                                                                    </td>
                                                                    );
                                                                })}
                                                            </tr>
                                                        );
                                                    })
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="d-flex justify-content-end ">
                                    {getPaginationData()}
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
            {
                showDeviceDealerDetails &&
                <Fragment>
                    <div className="row mb-0" style={{ "paddingBottom": "10px" }}>
                        <button className="btn btn-style-2 orange-bg color-white pb-10" onClick={() => {
                            setShowDeviceDealerDetails(false)
                            setSelectedDeviceDealerDetailsForDisplay({})
                        }}
                        >
                            Back
                        </button>
                    </div>
                    {/* 
                    Define the component to Show Device Dealer Deatils based on dealer id
                    */}
                    <DisplayDeviceDealerDeatils
                        deviceDealerDetails={selectedDeviceDealerDetailsForDisplay}
                        displayEditDeleteButton={
                            (userRoleInfo.level > 4 || userRoleInfo.roles.includes(customConfig.roleCloudAdmin)) ? true : false
                        }
                        onEditDeviceDealerDetails={onEditDeviceDealerDetails}
                        setAlert={setAlert}
                        onDeleteDeviceDealerDetails={handleDeleteDeviceDealerDetails}
                    ></DisplayDeviceDealerDeatils>
                </Fragment>
            }
            {
                deleteDeviceDealer && selectedDealerDetailsForDelete && Object.keys(selectedDealerDetailsForDelete).length > 0 && <Fragment>
                    <Popup
                        visible={deleteDeviceDealer}
                        notifyPopupClose={handleDeviceDealerClosePopup}
                    >
                        <div className="popup-content blue-modal">
                            <div className="block-top">
                                <div className="block-title style-2 center-align  py-25">
                                    <h3 className="mb-0">Delete Device Dealer</h3>
                                </div>
                                <Grid container className="valign-wrapper info-reset justify-content-center ">
                                    <Grid item xs={2}>
                                        <label className="title" style={{ fontSize: "16px", fontWeight: "bold" }}>Dealer ID:</label>
                                    </Grid>
                                    <Grid item xs={4}>
                                        {selectedDealerDetailsForDelete?.["dealerId"] ?? ""}
                                    </Grid>
                                </Grid>
                                <Grid container className="valign-wrapper info-reset justify-content-center" style={{ marginTop: "20px" }}>
                                    <Grid item xs={2}>
                                        <label className="title" style={{ fontSize: "16px", fontWeight: "bold" }}>Name</label>
                                    </Grid>
                                    <Grid item xs={4}>
                                        {
                                            (selectedDealerDetailsForDelete?.["dealerName"] ?? "").length > 50 ?
                                                <p style={{ whiteSpace: "normal", wordBreak: "break-all" }}>{selectedDealerDetailsForDelete?.["dealerName"] ?? ""}</p>
                                                :
                                                <p>{selectedDealerDetailsForDelete?.["dealerName"] ?? ""}</p>
                                        }
                                    </Grid>
                                </Grid>
                            </div>
                            <div style={{ marginTop: "20px" }}>
                                <div className="px-25">
                                    <div className="row mb-0">
                                        <div className="s8 col">
                                        </div>
                                        <div className="s2 col">
                                            <button
                                                className="btn btn-style-2 orange-bg"
                                                onClick={handleDeleteDeviceDealerFromPopup}
                                                style={{ marginLeft: "10px" }}
                                            >
                                                Delete
                                            </button>
                                        </div>
                                        <div className="s2 col">
                                            <button
                                                className="btn btn-style-2 orange-bg"
                                                onClick={handleDeviceDealerClosePopup}
                                                style={{ marginLeft: "10px" }}
                                            >
                                                Cancel
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Popup>
                </Fragment>
            }
        </Fragment>
    );
};

export default DeviceDealerList
