import React, { Fragment, useState } from "react";
import Popup from "../layout/Popup";
import Grid from '@material-ui/core/Grid';
import { deleteDealerInformation } from './../../action/deviceDealer'

const DisplayDeviceDealerDeatils = ({
    deviceDealerDetails,
    displayEditDeleteButton,
    onEditDeviceDealerDetails,
    onDeleteDeviceDealerDetails,
    setAlert
}) => {

    const [deleteDeviceDealer, setDeleteDeviceDealer] = useState(false)

    function handleDeleteDeviceDealerFromPopup() {
        deleteDealerInformation(deviceDealerDetails["id"])
            .then((res) => {
                setAlert("Dealer Deleted Sucessfully", "success")
                setDeleteDeviceDealer(false)

                onDeleteDeviceDealerDetails()
            })
            .catch((err) => {
                setAlert(err?.response?.data?.message ?? "Failed to Delete Dealer", "danger")
                setDeleteDeviceDealer(false)
            })
    }

    return (
        <Fragment>
            <div>
                {deviceDealerDetails && Object.keys(deviceDealerDetails).length > 0 && (
                    <Fragment>
                        <div className=" device-info">
                            <div className="content-block py-15" style={{ paddingBottom: "30px" }}>
                                {
                                    displayEditDeleteButton && <div style={{ float: 'right', marginRight: '20px' }}>
                                        <button
                                            className="btn btn-style-2 orange-bg ml-15 mb-15"
                                            onClick={() => {
                                                onEditDeviceDealerDetails(deviceDealerDetails)
                                            }}
                                        >
                                            Edit
                                        </button>
                                        <button
                                            className="btn btn-style-2 orange-bg ml-15 mb-15"
                                            onClick={() => {
                                                setDeleteDeviceDealer(true)
                                            }}
                                        >
                                            Delete
                                        </button>
                                    </div>
                                }
                                <Grid container>
                                    <Grid item xs={2} style={{ marginLeft: "20px" }}>
                                        <label className="title">Dealer ID</label>
                                    </Grid>
                                    <Grid item xs={3}>
                                        {deviceDealerDetails["id"]}
                                    </Grid>
                                    <Grid item xs={2} style={{ marginLeft: "20px" }}>
                                        <label className="title">Name</label>
                                    </Grid>
                                    <Grid item xs={3}>
                                        {
                                            (deviceDealerDetails?.["name"] ?? "").length > 50 ?
                                                <p style={{ whiteSpace: "normal", wordBreak: "break-all" }}>{deviceDealerDetails?.["name"] ?? ""}</p>
                                                :
                                                <p>{deviceDealerDetails?.["name"] ?? ""}</p>
                                        }
                                    </Grid>
                                </Grid>
                                <Grid container style={{ marginTop: "30px" }}>
                                    <Grid item xs={2} style={{ marginLeft: "20px" }}>
                                        <label className="title">Website</label>
                                    </Grid>
                                    <Grid item xs={3}>
                                        {
                                            (deviceDealerDetails?.["website"] ?? "").length > 50 ?
                                                <p style={{ whiteSpace: "normal", wordBreak: "break-all" }}>{deviceDealerDetails?.["website"] ?? ""}</p>
                                                :
                                                <p>{deviceDealerDetails?.["website"] ?? ""}</p>
                                        }
                                    </Grid>
                                    <Grid item xs={2} style={{ marginLeft: "20px" }}>
                                        <label className="title">Email</label>
                                    </Grid>
                                    <Grid item xs={3}>
                                        {
                                            (deviceDealerDetails?.["email"] ?? "").length > 50 ?
                                                <p style={{ whiteSpace: "normal", wordBreak: "break-all" }}>{deviceDealerDetails?.["email"] ?? ""}</p>
                                                :
                                                <p>{deviceDealerDetails?.["email"] ?? ""}</p>
                                        }
                                    </Grid>
                                </Grid>
                                <Grid container style={{ marginTop: "30px" }}>
                                    <Grid item xs={2} style={{ marginLeft: "20px" }}>
                                        <label className="title">Phone</label>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Grid container>
                                            <Grid item xs={6}><label>Number</label></Grid>
                                            <Grid item xs={6}><label>Type</label></Grid>

                                            {deviceDealerDetails["phone"].map((phoneDetail) => {
                                                return <Fragment>
                                                    <Grid container style={{ marginTop: "20px" }}>
                                                        <Grid item xs={6}>{phoneDetail["number"]}</Grid>
                                                        <Grid item xs={6}>{phoneDetail["type"]}</Grid>
                                                    </Grid>
                                                </Fragment>
                                            })}
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={2} style={{ marginLeft: "20px" }}>
                                        <label className="title">Country</label>
                                    </Grid>
                                    <Grid item xs={3}>
                                        {deviceDealerDetails["country"]}
                                    </Grid>
                                </Grid>
                                <Grid container style={{ marginTop: "20px" }}>
                                    <Grid item xs={2} style={{ marginLeft: "20px" }}>
                                        <label className="title" style={{ fontSize: "16px", fontWeight: "bold" }}>Address</label>
                                    </Grid>
                                    <Grid item xs={3}>
                                    </Grid>
                                    <Grid item xs={2} style={{ marginLeft: "20px" }}>
                                        <label className="title" style={{ fontSize: "16px", fontWeight: "bold" }}>Social Links</label>
                                    </Grid>
                                    <Grid item xs={3}>
                                    </Grid>
                                </Grid>
                                <Grid container style={{ marginTop: "20px" }}>
                                    <Grid item xs={2} style={{ marginLeft: "20px" }}>
                                        <label className="title">Line 1</label>
                                    </Grid>
                                    <Grid item xs={3}>
                                        {
                                            (deviceDealerDetails?.["address"]?.["line1"] ?? "").length > 50 ?
                                                <p style={{ whiteSpace: "normal", wordBreak: "break-all" }}>{deviceDealerDetails?.["address"]?.["line1"] ?? ""}</p>
                                                :
                                                <p>{deviceDealerDetails?.["address"]?.["line1"] ?? ""}</p>
                                        }
                                    </Grid>
                                    <Grid item xs={2} style={{ marginLeft: "20px" }}>
                                        <label className="title">Facebook</label>
                                    </Grid>
                                    <Grid item xs={3}>
                                        {
                                            (deviceDealerDetails?.["socials"]?.["facebook"] ?? "").length > 50 ?
                                                <p style={{ whiteSpace: "normal", wordBreak: "break-all" }}>{deviceDealerDetails?.["socials"]?.["facebook"] ?? ""}</p>
                                                :
                                                <p>{deviceDealerDetails?.["socials"]?.["facebook"] ?? ""}</p>
                                        }
                                    </Grid>
                                </Grid>
                                <Grid container style={{ marginTop: "20px" }}>
                                    <Grid item xs={2} style={{ marginLeft: "20px" }}>
                                        <label className="title">Line 2</label>
                                    </Grid>
                                    <Grid item xs={3}>
                                        {
                                            (deviceDealerDetails?.["address"]?.["line2"] ?? "").length > 50 ?
                                                <p style={{ whiteSpace: "normal", wordBreak: "break-all" }}>{deviceDealerDetails?.["address"]?.["line2"] ?? ""}</p>
                                                :
                                                <p>{deviceDealerDetails?.["address"]?.["line2"] ?? ""}</p>
                                        }
                                    </Grid>
                                    <Grid item xs={2} style={{ marginLeft: "20px" }}>
                                        <label className="title">Instagram</label>
                                    </Grid>
                                    <Grid item xs={3}>
                                        {
                                            (deviceDealerDetails?.["socials"]?.["instagram"] ?? "").length > 50 ?
                                                <p style={{ whiteSpace: "normal", wordBreak: "break-all" }}>{deviceDealerDetails?.["socials"]?.["instagram"] ?? ""}</p>
                                                :
                                                <p>{deviceDealerDetails?.["socials"]?.["instagram"] ?? ""}</p>
                                        }
                                    </Grid>
                                </Grid>
                                <Grid container style={{ marginTop: "20px" }}>
                                    <Grid item xs={2} style={{ marginLeft: "20px" }}>
                                        <label className="title">City</label>
                                    </Grid>
                                    <Grid item xs={3}>
                                        {
                                            (deviceDealerDetails?.["address"]?.["city"] ?? "").length > 50 ?
                                                <p style={{ whiteSpace: "normal", wordBreak: "break-all" }}>{deviceDealerDetails?.["address"]?.["city"] ?? ""}</p>
                                                :
                                                <p>{deviceDealerDetails?.["address"]?.["city"] ?? ""}</p>
                                        }
                                    </Grid>
                                    <Grid item xs={2} style={{ marginLeft: "20px" }}>
                                        <label className="title">X</label>
                                    </Grid>
                                    <Grid item xs={3}>
                                        {
                                            (deviceDealerDetails?.["socials"]?.["x"] ?? "").length > 50 ?
                                                <p style={{ whiteSpace: "normal", wordBreak: "break-all" }}>{deviceDealerDetails?.["socials"]?.["x"] ?? ""}</p>
                                                :
                                                <p>{deviceDealerDetails?.["socials"]?.["x"] ?? ""}</p>
                                        }
                                    </Grid>
                                </Grid>
                                <Grid container style={{ marginTop: "20px" }}>
                                    <Grid item xs={2} style={{ marginLeft: "20px" }}>
                                        <label className="title">Pincode</label>
                                    </Grid>
                                    <Grid item xs={3}>
                                        {deviceDealerDetails?.["address"]?.["pincode"] ?? ""}
                                    </Grid>
                                </Grid>
                                {/* <div className="p-15">
                                        <div className="device-detail">
                                            <div className="row">
                                                <div className="s6 col">
                                                    <div className="unique-box mb-15">
                                                        <label className="title">Dealer ID</label>
                                                        <div className="device-info">
                                                            {deviceDealerDetails["id"]}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="s6 col">
                                                    <div className="unique-box mb-15">
                                                        <label className="title">Name</label>
                                                        <div className="device-info">
                                                            {deviceDealerDetails["name"]}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="device-detail">
                                            <div className="row">
                                                <div className="s6 col">
                                                    <div className="unique-box mb-15">
                                                        <label className="title">Website</label>
                                                        <div className="device-info">
                                                            {deviceDealerDetails["website"]}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="s6 col">
                                                    <div className="unique-box mb-15">
                                                        <label className="title">Email</label>
                                                        <div className="device-info">
                                                            {deviceDealerDetails["email"]}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="device-detail">
                                            <div className="row">
                                                <div className="s6 col">
                                                    <div className="unique-box mb-15">
                                                        <label className="title">Phone</label>
                                                        <div className="device-info">
                                                            {deviceDealerDetails["phone"].map((phoneDetail) => {
                                                                return <Fragment>
                                                                    <div className="row">
                                                                        <div className="s3 col"><label>Number:  </label>{phoneDetail["number"]}</div>
                                                                        <div className="s3 col"><label>Type:  </label>{phoneDetail["type"]}</div>
                                                                    </div>
                                                                </Fragment>
                                                            })}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="s6 col">
                                                    <div className="unique-box mb-15">
                                                        <label className="title">Country</label>
                                                        <div className="device-info">
                                                            {deviceDealerDetails["country"]}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="device-detail">
                                            <div className="row">
                                                <div className="s6 col">
                                                    <div className="unique-box mb-15">
                                                        <label className="title">Address</label>
                                                        <div className="device-info">
                                                            <div className="row">
                                                                <div className="s12 col"><label>Line 1:  </label>{deviceDealerDetails?.["address"]?.["line1"] ?? ""}</div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="s12 col"><label>Line 2:  </label>{deviceDealerDetails?.["address"]?.["line2"] ?? ""}</div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="s12 col"><label>City:  </label>{deviceDealerDetails?.["address"]?.["city"] ?? ""}</div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="s12 col"><label>Pincode:  </label>{deviceDealerDetails?.["address"]?.["pin"] ?? ""}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="s6 col">
                                                    <div className="unique-box mb-15">
                                                        <label className="title">Socials</label>
                                                        <div className="device-info">
                                                            <div className="device-info">
                                                                <div className="row">
                                                                    <div className="s12 col"><label>Facebook:  </label>{deviceDealerDetails?.["socials"]?.["facebook"] ?? ""}</div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="s12 col"><label>Instagram:  </label>{deviceDealerDetails?.["socials"]?.["instagram"] ?? ""}</div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="s12 col"><label>X:  </label>{deviceDealerDetails?.["socials"]?.["x"] ?? ""}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <span className=" top-right"></span>
                                    </div> */}
                            </div>
                        </div>
                        {
                            deleteDeviceDealer && <Popup
                                visible={deleteDeviceDealer}
                                notifyPopupClose={() => {
                                    setDeleteDeviceDealer(false)
                                }}
                            >
                                <div className="popup-content blue-modal">
                                    <div className="block-top">
                                        <div className="block-title style-2 center-align  py-25">
                                            <h3 className="mb-0">Delete Device Dealer</h3>
                                        </div>
                                        <Grid container className="valign-wrapper info-reset justify-content-center ">
                                            <Grid item xs={2}>
                                                <label className="title" style={{ fontSize: "16px", fontWeight: "bold" }}>Dealer ID:</label>
                                            </Grid>
                                            <Grid item xs={4}>
                                                {deviceDealerDetails?.["id"] ?? ""}
                                            </Grid>
                                        </Grid>
                                        <Grid container className="valign-wrapper info-reset justify-content-center" style={{ marginTop: "20px" }}>
                                            <Grid item xs={2}>
                                                <label className="title" style={{ fontSize: "16px", fontWeight: "bold" }}>Name</label>
                                            </Grid>
                                            <Grid item xs={4}>
                                                {
                                                    (deviceDealerDetails?.["name"] ?? "").length > 50 ?
                                                        <p style={{ whiteSpace: "normal", wordBreak: "break-all" }}>{deviceDealerDetails?.["name"] ?? ""}</p>
                                                        :
                                                        <p>{deviceDealerDetails?.["name"] ?? ""}</p>
                                                }
                                            </Grid>
                                        </Grid>
                                    </div>
                                    <div style={{ marginTop: "20px" }}>
                                        <div className="px-25">
                                            <div className="row mb-0">
                                                <div className="s8 col">
                                                </div>
                                                <div className="s2 col">
                                                    <button
                                                        className="btn btn-style-2 orange-bg"
                                                        onClick={handleDeleteDeviceDealerFromPopup}
                                                        style={{ marginLeft: "10px" }}
                                                    >
                                                        Delete
                                                    </button>
                                                </div>
                                                <div className="s2 col">
                                                    <button
                                                        className="btn btn-style-2 orange-bg"
                                                        onClick={() => {
                                                            setDeleteDeviceDealer(false)
                                                        }}
                                                        style={{ marginLeft: "10px" }}
                                                    >
                                                        Cancel
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Popup>
                        }
                    </Fragment>
                )
                }
            </div >
        </Fragment >
    );
};

export default DisplayDeviceDealerDeatils
