import { useState, useEffect } from "react";

const UseForm = (callback, validate, ModelData) => {
    
    const initialState={
          modelId: ModelData && Object.keys(ModelData).length > 0 ? ModelData.modelId : "",
          modelNumber: ModelData && Object.keys(ModelData).length > 0 ? ModelData.modelNumber : "",
          modelName: ModelData && Object.keys(ModelData).length > 0 ? ModelData.modelName : "",
          category: ModelData && Object.keys(ModelData).length > 0 ? ModelData.category : "",
          launchDate: ModelData && Object.keys(ModelData).length > 0 ? ModelData.launchDate['$date'] : '',
          isActive: ModelData && Object.keys(ModelData).length > 0 ? ModelData.isActive : "True",
          manufacturer: ModelData && Object.keys(ModelData).length > 0 ? ModelData.manufacturer : "",
          manufacturerAddress: ModelData && Object.keys(ModelData).length > 0 ? ModelData.manufacturerAddress : "",
          version: ModelData && Object.keys(ModelData).length > 0 ? ModelData.version : "",
          helpDocUrl: ModelData && Object.keys(ModelData).length > 0 ? ModelData.helpDocUrl : "",
          productManagerId: ModelData && Object.keys(ModelData).length > 0 ? ModelData.productManagerId : "",
          tonnage: ModelData && Object.keys(ModelData).length > 0 ? ModelData.tonnage : "",
          capability: "",
          key: "",
          values: "",
          minUnit: "",
          minValue: "",
          maxUnit: "",
          maxValue: "",
          incUnit: "",
          incValue: ""
        }
  const [values, setValues] = useState(initialState); 
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      callback();
    }
  }, [errors]);

  
  const handleSubmit = event => {
    if (event) event.preventDefault();
    setErrors(validate(values));
    setIsSubmitting(true);
  };

  const handleChange = event => {
    event.persist();
    setValues(values => ({
      ...values,
      [event.target.name]: event.target.value
    }));
  };
  const handleBlur = event => {
    if (event) event.preventDefault();
    let error=validate({[event.target.name]:event.target.value});
    setErrors({...errors,[event.target.name]:error[Object.keys(error)[0]]});
    setIsSubmitting(true);
  };
  return {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    errors
  };
};
export default UseForm;
