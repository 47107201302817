import axios from "axios";
import env from "../env";
import { setAlert } from "./alert";

export const generateQRCode = (data) => {

    const config = {
        headers: {
            "Content-Type": "application/json",
        }
    }

    return new Promise((resolve, reject) => {
        axios
            .post(
                `${env.GET_DEVICEMANAGEMENT_API_URL()}/simplifi/v1/certificateManager/qrcode`, data, config)
            .then((res) => resolve(res.data))
            .catch((err) => reject(err));
    });
};

export const fetchQRCode = (data) => {

    const config = {
        headers: {
            "Content-Type": "application/json",
        },
        params: data
    }

    return new Promise((resolve, reject) => {
        axios
            .get(
                `${env.GET_DEVICEMANAGEMENT_API_URL()}/simplifi/v1/certificateManager/qrcode`, config)
            .then((res) => resolve(res.data))
            .catch((err) => reject(err));
    });
};

export const getQRCodeCountFilter = (data) => {
    const config = {
        headers: {
            "Content-Type": "application/json",
        },
        params: data
    }

    return new Promise((resolve, reject) => {
        axios
            .get(
                `${env.GET_DEVICEMANAGEMENT_API_URL()}/simplifi/v1/certificateManager/qrcode/count`, config)
            .then((res) => resolve(res.data))
            .catch((err) => reject(err));
    });
}

export const getQRDetailsUsingScanner = (qrCodeScannedText) => {
    const config = {
        headers: {
            "Content-Type": "application/json",
        },
        params: {
            'qrcode': qrCodeScannedText
        }
    }

    return new Promise((resolve, reject) => {
        axios
            .get(
                `${env.GET_DEVICEMANAGEMENT_API_URL()}/simplifi/v1/certificateManager/qrcode`, config)
            .then((res) => {
                resolve(res.data)
            })
            .catch((err) => {
                reject(err?.response?.data?.message ?? 'Failed to Scan QR Code')
            });
    });
}