import React, { Fragment, useEffect, useState } from "react";
import M from "materialize-css/dist/js/materialize.min.js";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import { addFotaJob, updateFotaJob } from "../../action/fotaJobs";
import customConfig from "../../config";

const FotaJobForm = ({
  job,
  addFotaJob,
  updateFotaJob,
  fotaJobsData: { fotaJobAddSucces, fotaJobUpdateSucces },
  notifySuccess,
}) => {
  useEffect(() => {
    M.AutoInit();
  }, []);

  useEffect(() => {
    if (fotaJobAddSucces || fotaJobUpdateSucces) {
      notifySuccess();
    }
  }, [fotaJobAddSucces, fotaJobUpdateSucces]);

  const weekdays = ["mon", "tue", "wed", "thu", "fri", "sat", "sun"];

  const getInitialWeekDays = () => {
    let dayList = [];
    if (job && job.frequency.day_of_week) {
      let wDays = job.frequency.day_of_week;
      // check if "-" separated day list
      let splitDays = wDays.split("-");
      if (splitDays.length > 1) {
        let firstDay = splitDays[0];
        let lastDay = splitDays[1];
        let started = false;
        for (const d of weekdays) {
          if (d === firstDay) {
            started = true;
          }
          if (started) {
            dayList.push(d);
          }
          if (d === lastDay) {
            break;
          }
        }
      } else {
        // check if "," separated day list
        let splitDays = wDays.split(",");
        if (splitDays.length > 1) {
          for (const d of splitDays) {
            dayList.push(d);
          }
        } else {
          // Add single day
          dayList.push(wDays);
        }
      }
    }
    return dayList;
  };

  const getInitialEndDate = () => {
    if (job) {
      return new Date(job.endDate.$date);
    } else {
      var newEndDate = new Date();
      return new Date(
        newEndDate.getTime() +
          customConfig.fotaJobDefaultDurationInDays * 24 * 60 * 60 * 1000
      );
    }
  };

  const getInitialHour = () => {
    if (job) {
      let jobHour = Number(job.frequency.hour);
      if (jobHour === 0) {
        return "12";
      } else if (jobHour > 12) {
        return (jobHour - 12).toString();
      } else {
        return job.frequency.hour;
      }
    } else {
      return "12";
    }
  };

  const getWeekDays = () => {
    let sequence = true;
    let started = false;
    let finished = false;
    let startDay;
    let endDay;
    for (const d of weekdays) {
      if (daysOfWeek.includes(d)) {
        if (!started) {
          started = true;
          startDay = d;
        }
        if (finished) {
          sequence = false;
          break;
        }
        endDay = d;
      }
      if (started && !daysOfWeek.includes(d)) {
        finished = true;
      }
    }

    if (sequence) {
      return `${startDay}-${endDay}`;
    } else {
      return daysOfWeek.toString();
    }
  };

  const getHour = () => {
    if (hour === "12" && meridiem === "AM") {
      return "0";
    } else if (hour !== "12" && meridiem === "PM") {
      return (12 + Number(hour)).toString();
    } else {
      return hour;
    }
  };

  const [jobName, setJobName] = useState(job ? job.name : "");
  const [endDate, setEndDate] = useState(() => getInitialEndDate());
  const [daysOfWeek, setDaysOfWeek] = useState(() => getInitialWeekDays());
  const [hour, setHour] = useState(() => getInitialHour());
  const [minute, setMinute] = useState(job ? job.frequency.minute : "0");
  const [meridiem, setMeridiem] = useState(
    job && Number(job.frequency.hour) >= 12 ? "PM" : "AM"
  );

  const handleDayToggle = (day) => {
    if (daysOfWeek.includes(day)) {
      setDaysOfWeek(daysOfWeek.filter((d) => d !== day));
    } else {
      setDaysOfWeek([...daysOfWeek, day]);
    }
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const resetFotaJob = () => {
    setJobName(job.name);
    setEndDate(getInitialEndDate());
    setDaysOfWeek(getInitialWeekDays());
    setHour(getInitialHour());
    setMinute(job.frequency.minute);
    setMeridiem(Number(job.frequency.hour) >= 12 ? "PM" : "AM");
  };

  const validateFields = () => {
    const format = /[`!@#$%^&*()+\-=\[\]{};':"\\|<>\/?~]/;
    if (!(jobName && jobName.trim().length > 0)) {
      alert(customConfig.fotaJobNameNeededMsg);
      return false;
    }
    if (format.test(jobName)) {
      alert(customConfig.specialCharsNotAllowed);
      return false;
    }
    if (!endDate) {
      alert(customConfig.fotaJobEndDateNeededMsg);
      return false;
    }
    if (daysOfWeek.length === 0) {
      alert(customConfig.fotaJobWeekDayNeededMsg);
      return false;
    }

    return true;
  };

  const saveFotaJob = () => {
    if (validateFields()) {
      let endDateUnixTimeStamp = parseInt(endDate.getTime() / 1000);
      if (job) {
        updateFotaJob(
          job.jobId,
          jobName.trim(),
          endDateUnixTimeStamp,
          getWeekDays(),
          getHour(),
          minute
        );
      } else {
        addFotaJob(
          jobName.trim(),
          endDateUnixTimeStamp,
          getWeekDays(),
          getHour(),
          minute
        );
      }
    }
  };

  return (
    <Fragment>
      <div className="p-25 form-style-modal">
        <div className="block-title style-2 mb-20 center-align">
          <h3 className="mb-0">{job ? "Update Job" : "Add new Job"}</h3>
        </div>

        <div className="row">
          <div className="input-field p-0 m-0 col s12">
            <label className="active">Job Name</label>
            <input
              type="text"
              placeholder="Enter job title"
              className="add-input"
              value={jobName}
              onChange={(e) => setJobName(e.target.value)}
            />
          </div>
        </div>

        <div className="row">
          <div className="input-field p-0  m-0 col s12">
            <label className="active"> End Date</label>
            <DatePicker
              selected={endDate}
              minDate={new Date()}
              onChange={handleEndDateChange}
              placeholder="MM-DD-YYYY"
              className="add-input calendar"
            />
          </div>
        </div>

        <div className="row">
          <div className="p-0  m-0 input-field col s12">
            <label className="active">Days of Week</label>
            <div className="wweek-box  d-flex">
              <div
                className={
                  daysOfWeek && daysOfWeek.includes("mon")
                    ? "name active"
                    : "name"
                }
                onClick={() => handleDayToggle("mon")}
              >
                Mon
              </div>
              <div
                className={
                  daysOfWeek && daysOfWeek.includes("tue")
                    ? "name active"
                    : "name"
                }
                onClick={() => handleDayToggle("tue")}
              >
                Tue
              </div>
              <div
                className={
                  daysOfWeek && daysOfWeek.includes("wed")
                    ? "name active"
                    : "name"
                }
                onClick={() => handleDayToggle("wed")}
              >
                Wed
              </div>
              <div
                className={
                  daysOfWeek && daysOfWeek.includes("thu")
                    ? "name active"
                    : "name"
                }
                onClick={() => handleDayToggle("thu")}
              >
                Thu
              </div>
              <div
                className={
                  daysOfWeek && daysOfWeek.includes("fri")
                    ? "name active"
                    : "name"
                }
                onClick={() => handleDayToggle("fri")}
              >
                Fri
              </div>
              <div
                className={
                  daysOfWeek && daysOfWeek.includes("sat")
                    ? "name active"
                    : "name"
                }
                onClick={() => handleDayToggle("sat")}
              >
                Sat
              </div>
              <div
                className={
                  daysOfWeek && daysOfWeek.includes("sun")
                    ? "name active"
                    : "name"
                }
                onClick={() => handleDayToggle("sun")}
              >
                Sun
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="input-field p-0 m-0 col s5">
            <div className="control-capabilities select-height">
              <label className="active"> Hour</label>
              <div className="model-srch reset">
                <select
                  className="browser-default form-add-input"
                  value={hour}
                  onChange={(e) => setHour(e.target.value)}
                >
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                  <option value="11">11</option>
                  <option value="12">12</option>
                </select>
              </div>
            </div>
          </div>
          <div className="input-field p-0 m-0 col s5">
            <div className="control-capabilities select-height">
              <label className="active">Minute</label>
              <div className="model-srch reset">
                <select
                  className="browser-default form-add-input"
                  value={minute}
                  onChange={(e) => setMinute(e.target.value)}
                >
                  <option value="0">0</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                  <option value="11">11</option>
                  <option value="12">12</option>
                  <option value="13">13</option>
                  <option value="14">14</option>
                  <option value="15">15</option>
                  <option value="16">16</option>
                  <option value="17">17</option>
                  <option value="18">18</option>
                  <option value="19">19</option>
                  <option value="20">20</option>
                  <option value="21">21</option>
                  <option value="22">22</option>
                  <option value="23">23</option>
                  <option value="24">24</option>
                  <option value="25">25</option>
                  <option value="26">26</option>
                  <option value="27">27</option>
                  <option value="28">28</option>
                  <option value="29">29</option>
                  <option value="30">30</option>
                  <option value="31">31</option>
                  <option value="32">32</option>
                  <option value="33">33</option>
                  <option value="34">34</option>
                  <option value="35">35</option>
                  <option value="36">36</option>
                  <option value="37">37</option>
                  <option value="38">38</option>
                  <option value="39">39</option>
                  <option value="40">40</option>
                  <option value="41">41</option>
                  <option value="42">42</option>
                  <option value="43">43</option>
                  <option value="44">44</option>
                  <option value="45">45</option>
                  <option value="46">46</option>
                  <option value="47">47</option>
                  <option value="48">48</option>
                  <option value="49">49</option>
                  <option value="50">50</option>
                  <option value="51">51</option>
                  <option value="52">52</option>
                  <option value="53">53</option>
                  <option value="54">54</option>
                  <option value="55">55</option>
                  <option value="56">56</option>
                  <option value="57">57</option>
                  <option value="58">58</option>
                  <option value="59">59</option>
                </select>
              </div>
            </div>
          </div>
          <div className="input-field p-0 m-0 col s2">
            <div className="control-capabilities">
              <label className="active"></label>
              <div className="model-srch reset">
                <select
                  className="browser-default form-add-input"
                  value={meridiem}
                  onChange={(e) => setMeridiem(e.target.value)}
                >
                  <option value="AM">AM</option>
                  <option value="PM">PM</option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <div className="d-flex flex-end">
          {job && (
            <button
              className="btn btn-style-3 sm min-140  dark-text gray-bg mr-10"
              onClick={() => resetFotaJob()}
            >
              Reset
            </button>
          )}
          <button
            className="btn btn-style-3 orange-bg sm min-140 "
            onClick={() => saveFotaJob()}
          >
            {job ? "Update Job" : "Create Job"}
          </button>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  fotaJobsData: state.fotaJobs,
});

export default connect(mapStateToProps, { addFotaJob, updateFotaJob })(
  FotaJobForm
);
