import axios from "axios";
import { setAlert } from "./alert";
import customConfig from "../config";
import {
  GET_DEVICE,
  DEVICE_ERROR,
  GET_MODEL,
  MODEL_ERROR,
  CLEAR_DATA,
  UPDATE_DEVICE,
  GET_DEVICE_MODELS_METADATA
} from "./types";
import env from "../env";

// submit for data with post

export const addvcModel = (
  modelId,
  modelNumber,
  modelName,
  category,
  launchDate,
  isActive,
  manufacturer,
  manufacturerAddress,
  version,
  helpDocUrl,
  productManagerId,
  tonnage,
  controlCapabilities
) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = {
    modelId,
    modelNumber,
    modelName,
    category,
    launchDate,
    isActive,
    manufacturer,
    manufacturerAddress,
    version,
    helpDocUrl,
    productManagerId,
    tonnage,
    controlCapabilities,
  };
  dispatch({ type: CLEAR_DATA });
  try {
    const res = await axios.post(
      `${env.GET_DEVICEMANAGEMENT_API_URL()}/simplifi/v1/deviceManagement/deviceModels`,
      body,
      config
    );
    dispatch({
      type: GET_DEVICE,
      payload: res.data,
    });
    dispatch(setAlert(customConfig.deviceModelCreatedMsg, "success"));
    window.scrollTo(0, 0);
  } catch (err) {
    dispatch(setAlert(err.response.data.message, "danger"));
    dispatch({
      type: DEVICE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
  window.scrollTo(0, 0);
};

//Get Device by Model  Id

export const getModelId = (modelId) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json"
      },
    };
    if (modelId) {
      const res = await axios.get(
        `${env.GET_DEVICEMANAGEMENT_API_URL()}/simplifi/v1/deviceManagement/deviceModels/modelId/${modelId}`,
        config
      );
      dispatch({
        type: GET_MODEL,
        payload: res.data,
      });
    } else {
      dispatch(setAlert(customConfig.enterModelNumberMsg, "danger"));
      dispatch({
        type: GET_MODEL,
        payload: {},
      });
      window.scrollTo(0, 0);
    }
  } catch (err) {
    dispatch(setAlert(err.response.data.message, "danger"));
    dispatch({
      type: MODEL_ERROR,
      payload: err,
    });
    dispatch({
      type: GET_MODEL,
      payload: {},
    });
    window.scrollTo(0, 0);
  }
};

//Get Device by Model Number

export const getDeviceByModelNumber = (modelNumber) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json"
      },
    };
    if (modelNumber) {
      const res = await axios.get(
        `${env.GET_DEVICEMANAGEMENT_API_URL()}/simplifi/v1/deviceManagement/deviceModels/modelNumber/${modelNumber}`,
        config
      );
      dispatch({
        type: GET_MODEL,
        payload: res.data,
      });
    } else {
      dispatch(setAlert(customConfig.invalidModelNumberMsg, "danger"));
      dispatch({
        type: GET_MODEL,
        payload: {},
      });
      window.scrollTo(0, 0);
    }
  } catch (err) {
    dispatch(setAlert(err.response.data.message, "danger"));
    dispatch({
      type: MODEL_ERROR,
      payload: err,
    });
    dispatch({
      type: GET_MODEL,
      payload: {},
    });
    window.scrollTo(0, 0);
  }
};

// UPDATE

export const updvcModel = (
  modelId,
  modelNumber,
  modelName,
  category,
  launchDate,
  isActive,
  manufacturer,
  manufacturerAddress,
  version,
  helpDocUrl,
  productManagerId,
  tonnage,
  controlCapabilities
) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = {
    modelName,
    category,
    launchDate,
    isActive,
    manufacturer,
    manufacturerAddress,
    version,
    helpDocUrl,
    productManagerId,
    controlCapabilities,
  };
  dispatch({ type: CLEAR_DATA });
  try {
    const res = await axios.put(
      `${env.GET_DEVICEMANAGEMENT_API_URL()}/simplifi/v1/deviceManagement/deviceModels/${modelId}`,
      body,
      config
    );

    dispatch({
      type: UPDATE_DEVICE,
      payload: res.data,
    });
    dispatch(setAlert(customConfig.deviceModelUpdatedMsg, "success"));
    window.scrollTo(0, 0);
  } catch (err) {
    dispatch(setAlert(err.response.data.message, "danger"));
    dispatch({
      type: DEVICE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
    window.scrollTo(0, 0);
  }
};


export const addDeviceModel = (deviceModelData) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = { ...deviceModelData };
  dispatch({ type: CLEAR_DATA });
  try {
    const res = await axios.post(
      `${env.GET_DEVICEMANAGEMENT_API_URL()}/simplifi/v1/deviceManagement/deviceModels`,
      body,
      config
    );
    dispatch({
      type: GET_DEVICE,
      payload: res.data,
    });
    dispatch(setAlert(customConfig.deviceModelCreatedMsg, "success"));
    window.scrollTo(0, 0);
  } catch (err) {
    dispatch(setAlert(err.response.data.message, "danger"));
    dispatch({
      type: DEVICE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
  window.scrollTo(0, 0);
};

export const updateDeviceModel = (modelId, deviceModelData) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = { ...deviceModelData };
  dispatch({ type: CLEAR_DATA });
  try {
    const res = await axios.put(
      `${env.GET_DEVICEMANAGEMENT_API_URL()}/simplifi/v1/deviceManagement/deviceModels/${modelId}`,
      body,
      config
    );
    dispatch({
      type: UPDATE_DEVICE,
      payload: res.data,
    });
    dispatch(setAlert(customConfig.deviceModelUpdatedMsg, "success"));
    window.scrollTo(0, 0);
  } catch (err) {
    dispatch(setAlert(err.response.data.message, "danger"));
    dispatch({
      type: DEVICE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
  window.scrollTo(0, 0);
};

export const clearDeviceModelData = () => async (dispatch) => {
  dispatch({ type: CLEAR_DATA });
}

export const getDeviceModelsMetaData = (productCategory) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
      params: {
        deviceCategory: productCategory
      }
    };

    const res = await axios.get(
      `${env.GET_DEVICEMANAGEMENT_API_URL()}/simplifi/v1/deviceManagement/deviceModels/metadata`,
      config
    );

    dispatch({
      type: GET_DEVICE_MODELS_METADATA,
      payload: res.data,
    });

  } catch (err) {
    dispatch({
      type: GET_DEVICE_MODELS_METADATA,
      payload: {},
    });
  }
}

export const clearDeviceModelsMetadata = () => async (dispatch) => {
  dispatch({
    type: GET_DEVICE_MODELS_METADATA,
    payload: {}
  })
}

export const getDeviceModelNumbersList = (brandName, categoryName, isMatterEnabled) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
    params: {
      "brand": brandName
    }
  }

  if (isMatterEnabled) {
    config['params']["isMatterEnabled"] = isMatterEnabled
  }

  return new Promise((resolve, reject) => {
    axios
      .get(
        `${env.GET_DEVICEMANAGEMENT_API_URL()}/simplifi/v1/deviceManagement/deviceModels/category/${categoryName}/modelIds`,
        config
      )
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => {
        reject(err)
      });
  });
} 