import React, { Fragment } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import logo from "../../../src/assets/logo-inner.png";
import { logout } from "../../action/auth";
import { getAllDevice } from "../../action/report";
import customConfig from "../../config";
const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;

const Nav = ({
  auth: { isAuthenticated, loading },
  logout,
  getAllDevice,
  tenantLogoUrl,
}) => {
  const logout_click = () => {
    logout(CLIENT_ID);
    getAllDevice("");
  };

  if (!isAuthenticated) {
    return <Redirect to="/" />;
  }
  return (
    <Fragment>
      <nav className="top-bar fixed no-bg" id="header">
        <div className="mb-0 logo-wrapper">
          {" "}
          <div className="logo-inr-wrap bottom-shadow-1 radius-bottom-16">
            {" "}
            <img
              src={tenantLogoUrl}
              alt="Logo"
              className="logo logo-wrapper-inner"
              style={{ height: "100%" }}
            />
          </div>
          <div className="top-bar-right-style-1 radius-bottom-16 bottom-shadow-1 blu-bg">
            <div className="right-nav">
              <div className="user-name ">
                <span className="secondary-color right right-align">
                  <a
                    onClick={logout_click}
                    href="javascript:void(0)"
                    className="logns"
                  >
                    <i className="icn-logout"></i>
                    <span>Log out</span>
                  </a>
                </span>
              </div>
              <div className="user-name">
                <span className="secondary-color right right-align pr-20">
                  <a
                    href={customConfig.supportURL}
                    target="_blank"
                    className="support"
                  >
                    <i className="icn-support"></i>
                    <span>Support</span>
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </Fragment>
  );
};

Nav.propTypes = {
  auth: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  isAuthenticated: state.auth.isAuthenticated,
  tenantLogoUrl: state.tenant.tenantInfo.tenantLogoUrl,
});

export default connect(mapStateToProps, { logout, getAllDevice })(Nav);
