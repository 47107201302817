import React from "react";
import Feedback from "../../Common/feedback/Feedback";

const FeedbackPage = () => {
  return (
    <div className="right-content-wraper">
      <Feedback />
    </div>
  );
};

export default FeedbackPage;
