import React, {useEffect} from "react";
import { connect } from "react-redux";
import {getControllerDevices} from '../../../action/metrics';
import {controllerSeriesData, controllerX_Data, filterArr,totalFilterCount} from './GraphParameter';
import Graph from "./Graph";

function DevicesControlled({getControllerDevices, controllerDevicesFilter,deviceList,setDeviceCount}) {
  useEffect(()=>{
    const que= { 
        "dateType":'daily',
        "startDate":null,
      }
    getControllerDevices(que)
},[getControllerDevices])
    const intervalData = controllerX_Data(deviceList);
    let arr = deviceList.length> 0 ? controllerSeriesData(deviceList) : []
    const filterArray = filterArr(arr,controllerDevicesFilter)
    const totalCount = totalFilterCount(filterArray)
    useEffect(()=>{
      setDeviceCount(totalCount)
    },[totalCount])
  return (
    <div>
      <div className="chart_view">
        <Graph
          text={" Active Devices"}
          x_data={intervalData}
          title={"Number Of Active Device "}
          y_data={filterArray}
          stacked = {true}
          color = {["#a1469e", "#83c7d5", "#02a699","#9E7777","#fee251"]}
        />
      </div>
    </div>
  );
}
const mapStateToProps = (state) =>{
  return{
    deviceList:state.metrics.deviceList     
  }
}

export default connect(mapStateToProps, { getControllerDevices })(DevicesControlled);