import React, { Fragment } from 'react'
import Moment from "react-moment";

const DeviceUsage = ({ deviceId, modelNumber, deviceUsage }) => {

    return (
        <Fragment>
            {(deviceUsage && deviceUsage.onlineStatus) ?
                (<div className="popup-content blue-modal">
                    <div className="block-top">
                        <div className="block-title style-2 center-align  py-25">
                            <h3 className="mb-0">Device Usage Info Details</h3>
                        </div>
                        <div className="valign-wrapper info-reset justify-content-center lite-bg">
                            <div className="d-inline-flex pr-20">
                                <label className="title">Device ID:</label>
                                <div className="device-info">{deviceId}</div>
                            </div>

                            <div className="d-inline-flex pl-20">
                                <label className="title">Model Number:</label>
                                <div className="device-info">{modelNumber}</div>
                            </div>
                        </div></div>

                    <div className="block-bottom popup-2 p-25">
                        <div className="d-block pb-30"><div className="row mb-0">
                            <div className="s4 col">
                                <div className="boxes">
                                    <div className="title">Device Current State</div>
                                    {/* <div className="info">AC - <span className="orange-text">ON</span></div> */}
                                    <div className="info">{deviceUsage.ps && deviceUsage.ps}</div>
                                </div>
                            </div>

                            <div className="s5 col">
                                <div className="boxes">
                                    <div className="title">Device Status</div>
                                    {deviceUsage.onlineStatus === "true" ? (
                                        <div className="info pointGreen position-relative">Online</div>
                                    )
                                        : (
                                            <div className="info point position-relative">Offline <span className="font-12 gray-text">(Last Active: {deviceUsage.connectedTime && <Moment
                                                format="MMMM Do YYYY, h:mm:ss a"
                                                date={(deviceUsage.connectedTime.toString().length < 12) ? deviceUsage.connectedTime * 1000 : deviceUsage.connectedTime}
                                            />})</span></div>
                                        )
                                    }
                                </div>
                            </div>

                            <div className="s3 col">
                                <div className="boxes">
                                    <div className="title">Filter Dust Levels</div>
                                    <div className="info">{deviceUsage.acdl && deviceUsage.acdl}</div>
                                </div>
                            </div>

                        </div></div>

                        <div className="row mb-0"><div className="col s12">
                            <div className="block-title style-3">
                                <h3>Last Controlled Info</h3>
                            </div>

                        </div></div>

                        <div className="d-block pb-30"><div className="row mb-0">
                            <div className="s4 col">
                                <div className="boxes">
                                    <div className="title">Last Controlled By</div>
                                    <div className="info">{deviceUsage.cnt && deviceUsage.cnt}</div>
                                </div>
                            </div>

                            <div className="s5 col">
                                <div className="boxes">
                                    <div className="title">Channel</div>
                                    <div className="info">{deviceUsage.cnt && deviceUsage.cnt}</div>
                                </div>
                            </div>

                            <div className="s3 col">
                                <div className="boxes">
                                    <div className="title">Action</div>
                                    <div className="info">{deviceUsage.lcmd && deviceUsage.lcmd}</div>
                                </div>
                            </div>

                        </div></div>

                        <div className="row mb-0"><div className="s12 col"><div className="font-12 gray-text medium bottom-line"><label className="title">Date and Time:</label> {deviceUsage.ts && <Moment
                            format="MMMM Do YYYY, h:mm:ss a"
                            date={deviceUsage.ts * 1000}
                        />}</div></div></div>
                    </div>
                </div>)
                : (
                    <div className="popup-content  blue-modal blank">
                         <div className="block-title style-2 center-align  py-25">
                            <h3 className="mb-0">Device Usage Info Details</h3>
                        </div>
                        <div className="s12 col">
                            <div className="boxes">
                                <div className="info error content-reset">
                                <div className="text-box"><i className="no-data-icon"></i><label>{(deviceUsage && deviceUsage.msg) && deviceUsage.msg} </label></div>      
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        </Fragment>
    )
}

export default DeviceUsage;
