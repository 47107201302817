import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Spinner from "../layout/Spinner";
import { connect } from "react-redux";
import { getReportbyId, getAllDevice } from "../../action/report";
import ReportHome from "./ReportHome";
import ReportSpace from "./ReportSpace";
import ReportTab from "./ReportTab";
import Alert from "../layout/Alert";
import UserInfo from "../dashboard/UserInfo";

const Report = ({
  getReportbyId,
  getAllDevice,
  match,
  allDevice,
  report: { report, loading },
}) => {
  const [user, setUser] = useState({});
  useEffect(() => {
    getReportbyId(match.params.homeId);
    setUser(JSON.parse(localStorage.getItem("userInfo")));
  }, [getReportbyId, match.params.homeId]);

  const goBack = () => {
    getAllDevice("");
  };
  return (
    <Fragment>
      {report === null || loading ? (
        <div style={{ position: "absolute", top: "50%", left: "50%" }}>
          <Spinner />
        </div>
      ) : (
        <Fragment>
          <div className="main-wrapper">
            <div className="right-content-wraper">
              <Alert />
              <div className="row">
                <div className="s6 p-0 col">
                  <div className="left ">
                    <Link
                      to="/dashboardBack"
                      onClick={() => goBack()}
                      className="btn btn-style-2 orange-bg color-white"
                    >
                      Back
                    </Link>
                  </div>
                </div>
              </div>
              <UserInfo user={user}></UserInfo>
              <div className="user-report-wrap">
                <div className="s12 col">
                  <div className="content-block bottom-shadow position-relative">
                    <div className="row">
                      <div className="tabbing-section">
                        {report && !report.hasOwnProperty("msg") ? (
                          <div>
                            <div className="p-15">
                              <ReportHome report={report} />
                              <ReportTab report={report} />
                            </div>
                            <div className="tab-reset">
                              <ReportSpace
                                report={report}
                                getAllDevice={getAllDevice}
                                allDevice={allDevice}
                                homeId={match.params.homeId}
                              />
                            </div>
                          </div>
                        ) : (
                          <h4 className="App">Data not Found</h4>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};
const mapStateToProps = (state) => ({
  report: state.report,
  allDevice: state.report.allDevice,
});
export default connect(mapStateToProps, { getReportbyId, getAllDevice })(
  Report
);
