import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import UserSearchBar from "../components/user/UserSearchBar";
import Info from "../components/user/Info";
import HomeId from "../components/user/HomeId";
import { connect } from "react-redux";
import { getReportbyId, getAllDevice } from "../../../action/report";
import Alert from "../../layout/Alert";
import HomeSpaceDetails from "../components/devices/HomeSpaceDetails";
import Spinner from "../../layout/Spinner";
import { REPORT_ERROR } from "../../../action/types";

const UserInfoPage = ({ home, getReportbyId, getAllDevice, report }) => {
  const history = useHistory();
  const [showData, setShowData] = useState(false);
  const [loadHomeData, setLoadHomeData] = useState(false);
  const [primaryOrSecondaryUserHomeId, setPrimaryOrSecondaryUserHomeId] =
    useState("primaryUserHomeIds");
  const onSubmitSuccess = () => {
    setShowData(true);
    setLoadHomeData(true);
  };
  const onSubmitFailure = () => {
    setShowData(false);
    setLoadHomeData(false);
  };

  useEffect(() => {
    if (!showData && history.location.state) {
      setShowData(true);
    }
  }, []);

  useEffect(() => {
    if (loadHomeData === false || home.home === null || home.home.message) {
      return;
    } else {
      if (home.home[primaryOrSecondaryUserHomeId][0]) {
        getReportbyId(home.home[primaryOrSecondaryUserHomeId][0].homeId);
      } else {
        getReportbyId();
      }
      return;
    }
  }, [primaryOrSecondaryUserHomeId, loadHomeData, home.home]);

  useEffect(() => {
    if (report.report === null || !report.report.spaces) {
      return;
    } else {
      let searchText = "";
      report.report.spaces.map((element) => {
        element.devices.map((device) => {
          if (searchText.includes(device.deviceId)) {
            return;
          }
          searchText = searchText.concat(`${device.deviceId},`);
        });
      });
      getAllDevice(searchText);
      return;
    }
  }, [report.report]);
  return (
    <div className="content-wrapper">
      <UserSearchBar
        onSubmitSuccess={onSubmitSuccess}
        onSubmitFailure={onSubmitFailure}
      />
      <Alert />
      {showData && (
        <>
          <div className="block mb-30">
            <div className="row">
              <HomeId
                onPrimaryOrSecondaryClick={setPrimaryOrSecondaryUserHomeId}
                primaryOrSecondary={primaryOrSecondaryUserHomeId}
              />
              <Info />
            </div>
          </div>
          {report.loading === true ? (
            <div>
              <Spinner />
            </div>
          ) : (
            report.report && report.report.spaces && <HomeSpaceDetails />
          )}
        </>
      )}
    </div>
  );
};

UserInfoPage.propTypes = {};

export default connect(
  (state) => ({
    home: state.home,
    report: state.report,
  }),
  { getReportbyId, getAllDevice }
)(UserInfoPage);
