import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { getMqttUsers } from "../../../action/metrics";
import DatePicker from "react-datepicker";
import Graph from "./Graph";
import { mqttSeriesData, seriesXaxisData } from "./GraphParameter";

function MqttChart({
  selectedGraph,
  formateDate,
  getMqttUsers,
  mqttUsersDuration,
}) {
  const [startDate, setStartDate] = useState();
  const [disableDaily, setDisableDaily] = useState(true);

  useEffect(() => {
    getMqttUsers();
  }, [getMqttUsers]);

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const HandleDeviceControlFilter = (startDate) => {
    if (startDate) {
      setDisableDaily(false);
      if (selectedGraph === "13") {
        const que = {
          onDate: formateDate(startDate),
        };
        getMqttUsers(que);
      } else {
        resetDate();
      }
    }
  };
  const handleDeviceControl = () => {
    setDisableDaily(true);
    getMqttUsers();
    resetDate("");
  };

  const resetDate = () => {
    setStartDate("");
  };
  let seriesDataDevices = [];
  let intervalData = [];
  if (mqttUsersDuration.length) {
    intervalData = seriesXaxisData(mqttUsersDuration, selectedGraph);
    seriesDataDevices = mqttSeriesData(mqttUsersDuration, selectedGraph);
  }

  return (
    <div>
      <div className="control-capabilities">
        <div className="model-srch reset full-w justify_content_end date__picker">
          <DatePicker
            selected={startDate}
            maxDate={new Date()}
            onChange={handleStartDateChange}
            placeholderText="Select Date"
            dateFormat="dd/MM/yyyy"
            className="add-input calendar input_date"
          />
          <button
            className="date_button orange-bg filter_btn w-100"
            onClick={() => HandleDeviceControlFilter(startDate)}
          >
            Search
          </button>
          {!disableDaily && (
            <button
              className="date_button orange-bg filter_btn w-100 ml-20"
              onClick={handleDeviceControl}
            >
              Daily
            </button>
          )}
        </div>
      </div>
      <div>
        <div id="chart">
          <Graph
            text={"sec"}
            x_data={intervalData}
            title={"mqtt users time duration"}
            y_data={seriesDataDevices}
            x_title={"Date"}
          />
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    mqttUsersDuration: state.metrics.mqttUsersDuration,
  };
};

export default connect(mapStateToProps, { getMqttUsers })(MqttChart);
