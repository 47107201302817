import React, { Fragment, useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import M from "materialize-css/dist/js/materialize.min.js";
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import CircularProgress from "@material-ui/core/CircularProgress";
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { uploadDeviceFactoryFirmware, downloadDeviceFactoryFirmwareFile, getDeviceFactoryFirmwareData } from "../../../action/firmware";
import { setAlert } from "../../../action/alert";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import CloudDownload from '@material-ui/icons/CloudDownload';
import customConfig from "../../../config";
import { filterFirmwareData } from './../../Common/Metrics/GraphParameter';
import { getfirmwareInfo, resetFirmwareInfo } from "../../../action/report";
import Chart from "react-apexcharts";

const FirmwareDistribution = ({
    allowedDeviceCategories,
    allowedCompanyBrand,
    report: { firmwareInfo },
    getfirmwareInfo,
    resetFirmwareInfo,
    setAlert
}) => {
    const [categoryFilter, setCategoryFilter] = useState("Select Category");
    const [brandFilter, setBrandFilter] = useState("Select Brand");
    const [chipTypeFilter, setChipTypeFilter] = useState("Select Chip Type")
    const [isFirmwareDataLoading, setIsFirmwareDataLoading] = useState(false);
    const [updateFlag, setUpdateFlag] = useState(undefined);
    const [showFirmwareData, setShowFirmwareData] = useState(false)

    useEffect(() => {
        resetFirmwareInfo()
    }, [])

    useEffect(() => {
        M.AutoInit();
    }, [updateFlag, isFirmwareDataLoading, categoryFilter])

    useEffect(() => {
        if (!firmwareInfo || (firmwareInfo && Object.keys(firmwareInfo).length == 0)) {
            setIsFirmwareDataLoading(false)
            setShowFirmwareData(false)
        }
        else {
            setIsFirmwareDataLoading(false)
            setShowFirmwareData(true)
        }
    }, [firmwareInfo])

    useEffect(() => {
        setChipTypeFilter("Select Chip Type")
        setShowFirmwareData(false)
        setIsFirmwareDataLoading(false)

    }, [categoryFilter, brandFilter])

    useEffect(() => {
        setShowFirmwareData(false)
        setIsFirmwareDataLoading(false)
    }, [chipTypeFilter])

    const renderTable = () => {
        return (
            <div>
                <div className="content-block bottom-shadow">
                    <div className="row">
                        <div className="s12 p-0 col">
                            <div className="table-wrapper">
                                <table
                                    width="100%"
                                    align="left"
                                    className="table statistic style-4"
                                >
                                    <thead>
                                        <tr>
                                            <th>Sr</th>
                                            <th>Firmware Version</th>
                                            <th>Device Count</th>
                                            <th>Onboarded Device Count</th>
                                            <th>Percentage</th>
                                        </tr>
                                    </thead>
                                    <tbody>{renderTableData(firmwareInfo)}</tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    const renderTableData = (data) => {
        if (data.hasOwnProperty('firmwareDistribution'))
            return data['firmwareDistribution'].map((element, index) => {
                const { firmwareVersion, count, percentage, onboardedCount } = element;
                return (
                    <tr key={categoryFilter.toLowerCase() + firmwareVersion}>
                        <td width="2%">{index + 1}</td>
                        <td>{firmwareVersion}</td>
                        <td>{count}</td>
                        <td>{onboardedCount}</td>
                        <td>{percentage}</td>
                    </tr>
                );
            });
    };

    const renderSummary = () => {
        return <div className="table-wrapper">
            <table
                width="100%"
                align="left"
                className="table statistic style-4"
            >
                <tbody>
                    <tr>
                        <th>Total Devices</th>
                        <td>{firmwareInfo?.['totalDevices'] ?? 0}</td>
                    </tr>
                    <tr>
                        <th>Total Onboarded Devices</th>
                        <td>{firmwareInfo?.['totalOnboardedDevices'] ?? 0}</td>
                    </tr>
                    <tr>
                        <th>Total Firmware Versions</th>
                        <td>{firmwareInfo?.['totalFirmwareVersions'] ?? 0}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    }

    function displayPieChart() {

        let firmwareVersions = [], firmwareCount = []

        if (firmwareInfo && firmwareInfo.hasOwnProperty('firmwareDistribution')) {

            firmwareInfo['firmwareDistribution'].map((element, index) => {
                const { firmwareVersion, count } = element;
                firmwareCount.push(count)
                firmwareVersions.push(firmwareVersion)
            });

            var options = {
                chart: {
                    width: 380,
                    type: 'pie',
                },
                labels: firmwareVersions,
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 300
                        },
                        legend: {
                            position: 'bottom'
                        }
                    }
                }]
            };

            return <Chart options={options} series={firmwareCount} type="pie" width={"80%"} style={{ position: 'center' }} />
        }
    }

    function getFirmwareData() {
        setShowFirmwareData(false)

        if (categoryFilter.length == 0 || categoryFilter == "Select Category") {
            setAlert("Category Cannot be Empty", 'danger')
            return
        }

        if (!brandFilter || brandFilter.length == 0 || brandFilter == "Select Brand") {
            setAlert("Brand Cannot be Empty", 'danger')
            return
        }

        let chipTypeParam = ""
        if (!chipTypeFilter || chipTypeFilter.length == 0 || chipTypeFilter == "Select Chip Type") {
            chipTypeParam = ""
        }
        else {
            chipTypeParam = chipTypeFilter
        }

        setIsFirmwareDataLoading(true)
        getfirmwareInfo(categoryFilter, brandFilter, chipTypeParam)
        setUpdateFlag(Math.random());
    }

    function resetFilters() {
        setCategoryFilter("Select Category")
        setBrandFilter("Select Brand")
        setChipTypeFilter("Select Chip Type")
        setUpdateFlag(Math.random())
        setIsFirmwareDataLoading(false)
        setShowFirmwareData(false)
    };

    return (
        <Fragment>
            <div className="content-block bottom-shadow">
                <div className="py-15 ">
                    <div className="row mb-0">
                        <div className="col pt-10">
                            <div className="model-srch reset width-md">
                                <select
                                    className="custom-select select-reset radius-20"
                                    style={{ "display": "block !important" }}
                                    name="categoryFilter"
                                    onChange={(e) => {
                                        setCategoryFilter(e.target.value);
                                        setChipTypeFilter("Select Chip Type")
                                    }}
                                    value={categoryFilter}
                                >
                                    {["Select Category", ...allowedDeviceCategories].map((element) => (
                                        <option value={element}>{element}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="col pt-10">
                            <div className="model-srch reset width-md">
                                <select
                                    className="custom-select select-reset radius-20"
                                    style={{ "display": "block !important" }}
                                    name="brandFilter"
                                    onChange={(e) => {
                                        setBrandFilter(e.target.value);
                                    }}
                                    value={brandFilter}
                                >
                                    {["Select Brand", ...allowedCompanyBrand].map((element) => (
                                        <option value={element}>{element}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="col pt-10">
                            <div className="model-srch reset width-md">
                                <select
                                    className="custom-select select-reset radius-20"
                                    style={{ "display": "block !important" }}
                                    name="chipTypeFilter"
                                    onChange={(e) => {
                                        setChipTypeFilter(e.target.value);
                                    }}
                                    value={chipTypeFilter}
                                >
                                    {/* {
                                        categoryFilter == customConfig.deviceCategoryODMFRONTLOADWM ?
                                            ["Select Chip Type", ...customConfig.matterChipTypeFLWM].map((element) => (
                                                <option value={element}>{element}</option>
                                            ))
                                            :
                                            ["Select Chip Type", ...customConfig.matterChipType].map((element) => (
                                                <option value={element}>{element}</option>
                                            ))
                                    } */}

                                    {
                                        ["Select Chip Type", "ESPWIFI", "ESPWIFIBLE", "ASRWIFIBLE"].map((element) => (
                                            <option value={element}>{element}</option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="col pt-10">
                            <button
                                className="btn btn-style-2 orange-bg ml-15 mb-15"
                                onClick={getFirmwareData}
                            >
                                Filter
                            </button>
                            <button
                                className="btn btn-style-2 orange-bg ml-15 mb-15"
                                onClick={resetFilters}
                            >
                                Reset
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {
                isFirmwareDataLoading ?
                    <div style={{ display: "grid", placeItems: "center", height: "20vh" }}>
                        <CircularProgress size="15em" style={{ color: "#AFB42B" }} />
                    </div>
                    :
                    showFirmwareData && firmwareInfo && Object.keys(firmwareInfo).length > 0 &&
                    firmwareInfo.hasOwnProperty('firmwareDistribution')
                    && < Fragment >
                        <div className="container">
                            <div className="row mb-0">
                                <div className="col s6 pt-10">
                                    <div>{displayPieChart()}</div>
                                </div>
                                <div className="col s6 pt-10">
                                    <div>{renderSummary()}</div>
                                </div>
                            </div>
                            <div className="firmwareTableWrapper" style={{ paddingTop: '10px' }}>{renderTable()}</div>
                        </div>
                    </Fragment>
            }
        </Fragment >
    );
}

const mapStateToProps = (state) => ({
    allowedDeviceCategories: state.auth.allowedDeviceCategories,
    allowedCompanyBrand: state.auth.allowCompanyBrand,
    report: state.report,
});

const mapDispatchToProps = (dispatch) => ({
    setAlert: (message, status) => dispatch(setAlert(message, status)),
    getfirmwareInfo: (categoryFilter, brandFilter, chipTypeFilter) => dispatch(getfirmwareInfo(categoryFilter, brandFilter, chipTypeFilter)),
    resetFirmwareInfo: () => dispatch(resetFirmwareInfo()),
});

export default connect(mapStateToProps, mapDispatchToProps)(FirmwareDistribution);