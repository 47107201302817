import React, { useEffect } from "react";
import PropTypes from "prop-types";
import PhoneInput from "react-phone-input-2";
import UserSearchInputWrapper from "../../../Common/user/UserSearchInputWrapper";
import M from "materialize-css/dist/js/materialize.min.js";

const UserSearchBar = (props) => {
  useEffect(() => {
    M.AutoInit();
  }, []);
  return (
    <div>
      <UserSearchInputWrapper
        onSubmitSuccess={props.onSubmitSuccess}
        onSubmitFailure={props.onSubmitFailure}
      >
        {({
          searchValue,
          searchBy,
          countryCode,
          onSubmit,
          setCountryCode,
          onSearchByChange,
          onSearchValueChange,
          allowedCountries,
        }) => {
          return (
            <div className="block mb-30">
              <div className="row">
                <div className="col s12">
                  <div className="content-block mb-0 p-15 bottom-shadow-1 radius-6">
                    <div className="search-user-wraper d-flex justify-content-center align-items-center style-2">
                      <div className="d-flex justify-space-between wrap-all-search  align-items-center">
                        <label className="black-text font-600">
                          Search User
                        </label>

                        <div className="input-field mt-0 mb-0">
                          <select
                            value={searchBy}
                            onChange={(e) => onSearchByChange(e)}
                          >
                            <option value="mobile">Mobile No</option>
                            <option value="email">Email Id</option>
                          </select>
                        </div>
                        <div className="enter-wrapper d-flex">
                          {searchBy === "mobile" && (
                            <PhoneInput
                              //country={"in"}
                              enableSearch={false}
                              value={countryCode}
                              onChange={setCountryCode}
                              onlyCountries={allowedCountries}
                              containerClass="country-code-container"
                              searchClass="country-code-search"
                              dropdownClass="country-code-dropdown"
                            />
                          )}
                          {searchBy !== "mobile" && (
                            <i className="material-icons search-icon">
                              {" "}
                              search
                            </i>
                          )}

                          <input
                            className="add-input md mb-0"
                            placeholder={
                              searchBy === "mobile"
                                ? "Mobile No without country code"
                                : "Enter Email Id"
                            }
                            type="text"
                            name="text"
                            value={searchValue}
                            onChange={onSearchValueChange}
                          />
                          <input
                            type="submit"
                            className="btn orange-bg btn-block md no-shadow"
                            value="Search"
                            onClick={onSubmit}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mid-seprator"></div>
            </div>
          );
        }}
      </UserSearchInputWrapper>
    </div>
  );
};

UserSearchBar.propTypes = {};

export default UserSearchBar;
