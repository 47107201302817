import axios from "axios";
import customConfig from "../config";
const ResponseInterceptor = () => {
  axios.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      if (
        error.response &&
        401 === error.response.status &&
        window.location.pathname !== "/"
      ) {
        alert(customConfig.sessionExpireMsg);
        localStorage.removeItem("token");
        localStorage.removeItem("userInfo");
        localStorage.removeItem("userRoles");
        localStorage.removeItem("userLevel");
        localStorage.removeItem("loggedInUserName");
        window.location = "/";
      } else {
        return Promise.reject(error);
      }
    }
  );
};

export default ResponseInterceptor;
