import React, { useState } from "react";
import PropTypes from "prop-types";
import { getCurrentUser } from "../../../action/user";
import { connect } from "react-redux";
const UserSearchInputWrapper = (props) => {
  const [searchValue, setSearchValue] = useState("");
  const [searchBy, setSearchBy] = useState("mobile");
  const [countryCode, setCountryCode] = useState("91");
  const onSubmit = (e) => {
    e.preventDefault();
    if(countryCode || searchBy === "email"){
      let searchText = searchValue;
      if (searchBy === "mobile" && countryCode) {
        searchText = `+${countryCode}${searchValue}`;
      }
      props
        .getCurrentUser(searchText, searchBy, props.user.umV1AuthToken, props.tenantInfo.tenantId)
        .then((res) => props.onSubmitSuccess(e, res))
        .catch((err) => props.onSubmitFailure(e, err));
      props.onSubmit(e);
      if (props.resetTextOnSubmit) {
        setSearchValue("");
      }
    }
  };

  const onSearchByChange = (e) => {
    setSearchBy(e.target.value);
    setSearchValue("");
    props.onSearchByChange(e);
  };

  const onSearchValueChange = (e) => {
    setSearchValue(e.target.value);
    props.onSearchValueChange(e);
  };

  return (
    <>
      {props.children({
        searchValue,
        searchBy,
        countryCode,
        onSubmit,
        setCountryCode,
        onSearchByChange,
        onSearchValueChange,
        showText: props.showText,
        allowedCountries: props.allowedCountries,
      })}
    </>
  );
};

UserSearchInputWrapper.defaultProps = {
  onSubmit: () => {},
  resetTextOnSubmit: true,
  onSearchByChange: () => {},
  onSearchValueChange: () => {},
  onSubmitSuccess: () => {},
  onSubmitFailure: () => {},
};

UserSearchInputWrapper.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  children: PropTypes.func.isRequired,
  resetTextOnSubmit: PropTypes.bool,
  onSearchByChange: PropTypes.func,
  onSearchValueChange: PropTypes.func,
};

export default connect(
  (state) => ({
    user: state.user,
    tenantInfo: state.tenant.tenantInfo,
    allowedCountries: state.auth.allowedCountries,
  }),
  (dispatch) => ({ getCurrentUser: getCurrentUser(dispatch) })
)(UserSearchInputWrapper);
