import React, { Fragment, useState, useEffect, useRef } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import CircularProgress from "@material-ui/core/CircularProgress";
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { generateQRCode, fetchQRCode } from "../../action/qrcode";
import { getDeviceModelNumbersList } from './../../action/addvcModel'
import { useReactToPrint } from 'react-to-print';
import Alert from "../layout/Alert";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DatePicker from "react-datepicker";
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import notificationConstants, { notificationValidations } from "./notificationConstant";

const useStyles = makeStyles({
    selectInput: {
        height: "3rem",
        width: "100%"
    },
    selectValues: {
        width: "100%",
    },
    activeTab: {
        border: '1px solid #bdbdbd',
        backgroundColor: '#e0e0e0',
        textTransform: 'capitalize'
    },
    nonActiveTab: {
        border: 'none',
        backgroundColor: 'none',
        textTransform: 'capitalize'
    },
});

const headers = {
    productSerialNumber: "Product Serial Number",
    status: "QR Code Print Status",
    reasonOfFailure: "Reason of Failure"
};

const EmailNotification = ({
    messageData = { subject: "", html: "", text: "" },
    handleMessageData,
}) => {

    return (
        <Fragment>
            <label className="title" style={{ fontSize: "14px", fontWeight: "bold" }}>Message</label>
            <div className="content-block position-relative" style={{ marginTop: "10px" }}>
                <div className="py-15">
                    <Grid container style={{ marginBottom: "20px" }}>
                        <Grid item xs={2} style={{ marginLeft: "10px" }}>
                            <label className="title" style={{ color: 'black', fontSize: '14px' }}>Subject: </label>
                        </Grid>
                        <Grid item xs={4}>
                            <TextareaAutosize
                                aria-label="minimum height"
                                placeholder="Write a Message"
                                style={{ height: '50px', padding: "10px" }}
                                onChange={(e) => {
                                    handleMessageData("subject", e?.target?.value?.substring(0, notificationValidations?.email?.subject?.maxLength))
                                }}
                                value={messageData?.subject}
                            />
                            <p>{(messageData?.subject?.length > notificationValidations?.email?.subject?.maxLength ? notificationValidations?.email?.subject?.maxLength : messageData?.subject?.length) + "/" + notificationValidations?.email?.subject?.maxLength}</p>
                        </Grid>
                    </Grid>
                    <Grid container style={{ marginBottom: "20px" }}>
                        <Grid item xs={2} style={{ marginLeft: "10px" }}>
                            <label className="title" style={{ color: 'black', fontSize: '14px' }}>HTML: </label>
                        </Grid>
                        <Grid item xs={4}>
                            <TextareaAutosize
                                aria-label="minimum height"
                                placeholder="Write a Message"
                                style={{ height: '200px', padding: "10px" }}
                                onChange={(e) => {
                                    handleMessageData('html', e.target.value?.substring(0, notificationValidations?.email?.html?.maxLength))
                                }}
                                value={messageData?.html}
                            />
                            <p>{(messageData?.html?.length > notificationValidations?.email?.html?.maxLength ? notificationValidations?.email?.html?.maxLength : messageData?.html?.length) + "/" + notificationValidations?.email?.html?.maxLength}</p>
                        </Grid>
                    </Grid>
                    <Grid container style={{ marginBottom: "20px" }}>
                        <Grid item xs={2} style={{ marginLeft: "10px" }}>
                            <label className="title" style={{ color: 'black', fontSize: '14px' }}>Text: </label>
                        </Grid>
                        <Grid item xs={4}>
                            <TextareaAutosize
                                aria-label="minimum height"
                                placeholder="Write a Message"
                                style={{ height: '100px', padding: "10px" }}
                                onChange={(e) => {
                                    handleMessageData('text', e.target.value?.substring(0, notificationValidations?.email?.text?.maxLength))
                                }}
                                value={messageData?.text}
                            />
                            <p>{(messageData?.text?.length > notificationValidations?.email?.text?.maxLength ? notificationValidations?.email?.text?.maxLength : messageData?.text?.length) + "/" + notificationValidations?.email?.text?.maxLength}</p>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </Fragment>
    );
}


export default EmailNotification