import React, { Fragment, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import customConfig from "../../config";

const ServiceInfo = ({
  products,
  showProductInfo,
  WarrantyData: { warrantyDetails },
  serviceError,
}) => {
  const changeTab = (p) => {
    setSelectedProduct(p);
    setSelectedServices(warrantyDetails[p.id].services);
  };
  const [selectedProduct, setSelectedProduct] = useState(
    products && products.length > 0 ? products[0] : null
  );
  const [selectedServices, setSelectedServices] = useState(
    products &&
      products.length > 0 &&
      warrantyDetails &&
      warrantyDetails[products[0].id] &&
      warrantyDetails[products[0].id].services
      ? warrantyDetails[products[0].id].services
      : null
  );
  return (
    <Fragment>
      {!serviceError ? (
        <div className="popup-content  blue-modal blank">
          <div className="block-title style-2 center-align  py-25">
            <h3 className="mb-0">View Service Requests</h3>
          </div>
          <div className="list-modal px-15">
            {showProductInfo && (
              <div className="col s12">
                <ul className="tabs mb-10 tab-icon-style">
                  {products &&
                    products.length > 0 &&
                    products.map((product, i) => (
                      <li key={product.id} className="tab">
                        <Link
                          to={"#"}
                          onClick={() => changeTab(product)}
                          className={
                            selectedProduct.id === product.id ? "active" : ""
                          }
                        >
                          <img src={product.icon} />
                        </Link>
                      </li>
                    ))}
                </ul>
              </div>
            )}
            <div className="col s12">
              <div className="list-wrap pb-15">
                {showProductInfo && (
                  <div className="product-detail">
                    <div className="row valign-wrapper">
                      <div className="col s5">
                        <div className="unique-box mb-10">
                          <label className="title d-inline  min-140">
                            Product Category
                          </label>
                          <div className="device-info d-inline">
                            {selectedProduct &&
                              selectedProduct.product_name &&
                              selectedProduct.product_name}
                          </div>
                        </div>

                        <div className="unique-box mb-10">
                          <label className="title d-inline min-140">
                            Modal Name
                          </label>
                          <div className="device-info d-inline">
                            {selectedProduct &&
                              selectedProduct.model_name &&
                              selectedProduct.model_name}
                          </div>
                        </div>
                      </div>

                      <div className="col s5">
                        <div className="unique-box mb-10">
                          <label className="title d-inline  min-140">
                            Sub Category
                          </label>
                          <div className="device-info d-inline">
                            {selectedProduct &&
                              selectedProduct.sub_product_name &&
                              selectedProduct.sub_product_name}
                          </div>
                        </div>

                        <div className="unique-box mb-10">
                          <label className="title d-inline  min-140">
                            Serial Number
                          </label>
                          <div className="device-info d-inline">
                            {selectedProduct &&
                              selectedProduct.serial_no &&
                              selectedProduct.serial_no}
                          </div>
                        </div>
                      </div>

                      <div className="col s2">
                        <div className="product-img">
                          {selectedProduct && selectedProduct.image && (
                            <img src={selectedProduct.image} />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <div
                  className={
                    showProductInfo
                      ? "vertical-wrapper"
                      : "vertical-wrapper warrantySingle"
                  }
                >
                  {selectedServices && selectedServices.length > 0 ? (
                    selectedServices.map((service, i) => (
                      <div className="listing-modal mb-10">
                        <div className="title-wrap">
                          <label>ASC Job ID :</label>{" "}
                          {service.ASCJobId && service.ASCJobId}
                        </div>

                        <div className="row">
                          <div className="p-15">
                            <div className="col s6">
                              <div className="unique-box mb-10">
                                <label className="title d-inline  min-140">
                                  Job Classification
                                </label>
                                <div className="device-info d-inline">
                                  {service.JobClassification &&
                                    service.JobClassification}{" "}
                                </div>
                              </div>
                              <div className="unique-box mb-10">
                                <label className="title d-inline  min-140">
                                  Job Status
                                </label>
                                <div className="device-info d-inline">
                                  {service.JobStatusName &&
                                    service.JobStatusName}{" "}
                                </div>
                              </div>
                              <div className="unique-box mb-10">
                                <label className="title d-inline  min-140">
                                  Job Creation Date
                                </label>
                                <div className="device-info d-inline">
                                  {service.JobCreationDate &&
                                    service.JobCreationDate}{" "}
                                </div>
                              </div>
                              <div className="unique-box">
                                <label className="title d-inline  min-140">
                                  Job Remarks
                                </label>
                                <div className="device-info d-inline">
                                  {service.JobRemarks && service.JobRemarks}{" "}
                                </div>
                              </div>
                            </div>

                            <div className="col s6">
                              <div className="unique-box mb-10">
                                <label className="title d-inline  min-140">
                                  Job Number
                                </label>
                                <div className="device-info d-inline">
                                  {service.JobNumber && service.JobNumber}{" "}
                                </div>
                              </div>
                              <div className="unique-box mb-10">
                                <label className="title d-inline  min-140">
                                  Registered By
                                </label>
                                <div className="device-info d-inline">
                                  {service.RegisteredBy && service.RegisteredBy}
                                </div>
                              </div>
                              <div className="unique-box">
                                <label className="title d-inline  min-140">
                                  Job Closing Date
                                </label>
                                <div className="device-info d-inline">
                                  {service.JobClosingDate &&
                                    service.JobClosingDate}{" "}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="boxes">
                      <div className="info error content-reset">
                        <div className="text-box">
                          <i className="no-data-icon"></i>
                          <label>{customConfig.noServiceRequestMsg} </label>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="popup-content  blue-modal blank">
          <div className="block-title style-2 center-align  py-25">
            <h3 className="mb-0">Service Details</h3>
          </div>
          <div className="s12 col">
            <div className="boxes">
              <div className="info error content-reset">
                <div className="text-box">
                  <i className="no-data-icon"></i>
                  <label>{serviceError} </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};
const mapStateToProps = (state) => ({
  WarrantyData: state.warranty,
});

export default connect(mapStateToProps)(ServiceInfo);
