import React, { Fragment, useState } from "react";
import Spinner from "../layout/Spinner";
import { useEffect } from "react";
import WarrantyInfo from "../report/WarrantyInfo";
import ServiceInfo from "../report/ServiceInfo";
import { getWarrantyInfo, getWarrantyDetails } from "../../action/warranty";
import Popup from "../layout/Popup";
import { connect } from "react-redux";
import isObjectEmpty from "../../utils/checkEmptyObject";
import customConfig from "../../config";

const ReportHome = ({
  report,
  user,
  address,
  addressLine1,
  city,
  pincode,
  loading,
  getWarrantyInfo,
  getWarrantyDetails,
  warrantyData: {
    warrantyToken,
    applianceData,
    applianceError,
    warrantyDetails,
  },
}) => {
  const [showWarrantyInfo, setShowWarrantyInfo] = useState(false);
  const [showServiceInfo, setShowServiceInfo] = useState(false);
  const [showWarrantyService, setShowWarrantyService] = useState(true);

  useEffect(() => {
    if (user) {
      getWarrantyInfo(user.mobile);
    }
  }, []);

  useEffect(() => {
    if (applianceData && !applianceError) {
      applianceData.customer_products.map((product, i) => {
        getWarrantyDetails(warrantyToken, product.id);
      });
    }
    return;
  }, [applianceData]);

  useEffect(() => {
    if (report.spaces && report.spaces.length > 0) {
      report.spaces.forEach((space) => {
        if (space.devices.length > 0) {
          setShowWarrantyService(false);
        }
      });
    }
  }, [report.spaces]);

  const handleWarrantyPopup = (viewService = false) => {
    if (
      !(
        applianceData.customer_products &&
        applianceData.customer_products.length > 0
      )
    ) {
      let alertMsg = viewService
        ? customConfig.noServiceDataMsg
        : customConfig.noWarrantyDataMsg;
      alert(alertMsg);
      return;
    }
    if (viewService) {
      setShowServiceInfo(true);
    } else {
      setShowWarrantyInfo(true);
    }
  };

  const handleServiceInfoPopupClose = () => {
    setShowServiceInfo(false);
  };

  const handleWarrantyInfoPopupClose = () => {
    setShowWarrantyInfo(false);
  };

  if (!report) {
    return <h4>Data not Found</h4>;
  }

  return loading || report === null ? (
    <Spinner />
  ) : (
    <Fragment>
      <div className="col s12">
        <div className="p-0 col s6">
          <div className="hd-style-2 ">
            <h5>Home Details</h5>
          </div>
        </div>

        <div className="p-0 col s6">
          {showWarrantyService && (
            <div className="unique-box mb-15">
              <div className="buttonSection right-align">
                <button
                  className="btn btn-style-4 no-bg mr-10"
                  onClick={() => handleWarrantyPopup()}
                >
                  View warranty info
                </button>
                {showWarrantyInfo && (
                  <Popup
                    visible={showWarrantyInfo}
                    notifyPopupClose={handleWarrantyInfoPopupClose}
                    wide={true}
                  >
                    {" "}
                    <WarrantyInfo
                      products={
                        applianceData && applianceData.customer_products
                          ? applianceData.customer_products
                          : null
                      }
                      showProductInfo={true}
                    />
                  </Popup>
                )}

                <button
                  className="btn btn-style-4 no-bg"
                  onClick={() => handleWarrantyPopup(true)}
                >
                  View service requests
                </button>
                {showServiceInfo && (
                  <Popup
                    visible={showServiceInfo}
                    notifyPopupClose={handleServiceInfoPopupClose}
                    wide={true}
                  >
                    {" "}
                    <ServiceInfo
                      products={
                        applianceData && applianceData.customer_products
                          ? applianceData.customer_products
                          : null
                      }
                      showProductInfo={true}
                    />
                  </Popup>
                )}
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="s4 col">
        <div className="block mb-15">
          <label className="label">Home Address </label>
          <div className="value">
            {report && report.address && report.address.addressLine1},{" "}
            {report && report.address && report.address.addressLine2},{" "}
            {report && report.address && report.address.city},{" "}
            {report && report.address && report.address.pincode}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.user,
  warrantyData: state.warranty,
});
export default connect(mapStateToProps, {
  getWarrantyInfo,
  getWarrantyDetails,
})(ReportHome);
