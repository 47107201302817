import { useState, useEffect } from "react";
const UseForm = (callback, validate) => {
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      callback();
    }
  }, [errors]);
  const handleSubmit = event => {
    if (event) event.preventDefault();
    setErrors(validate(values));
    setIsSubmitting(true);
  };
  const InputOnFocus = event => {
    if (event) event.preventDefault();
    if (event.target.name === "mobile" && event.target.value.length >4) {
      setErrors({ mobile: "", password: errors.password });
    } else if (event.target.name === "password" && event.target.value.length >6) {
      setErrors({ mobile: errors.mobile, password: "" });
    }

    setIsSubmitting(false);
  };
  const handleChange = event => {
    event.persist();
    setValues(values => ({
      ...values,
      [event.target.name]: event.target.value
    }));
  };
  return {
    handleSubmit,
    InputOnFocus,
    handleChange,
    values,
    errors
  };
};
export default UseForm;
