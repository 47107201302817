import React, { Fragment, useState, useEffect, useRef } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import M from "materialize-css/dist/js/materialize.min.js";
import Alert from "../layout/Alert";
import CircularProgress from "@material-ui/core/CircularProgress";

const UploadPSNDealerMapping = ({
    setAlert,
    deviceDealer: { uploadPSNDealerFileInProgress,
        uploadPSNDealerFileSuccess,
        uploadPSNDealerFileFailure },
    uploadPSNDealerFile
}) => {

    const [uploadedPSNDealerFile, setUploadedPSNDealerFile] = useState("")
    const uploadedPSNDealerFileRef = useRef()

    useEffect(() => {
        M.AutoInit();
    }, [])

    function handleUploadPSNDealerFileMapping() {
        if (!uploadedPSNDealerFile || uploadedPSNDealerFile == "" || !uploadedPSNDealerFile.name.endsWith('.csv')) {
            setAlert('Invalid File', 'danger')
            return
        }

        let formData = new FormData()
        formData.append('csv_file', uploadedPSNDealerFile)

        uploadPSNDealerFile(formData)
    }

    function handleUploadPSNDealerFileReset() {
        setUploadedPSNDealerFile("")

        uploadedPSNDealerFileRef.current.value = ""
    }

    return (
        <Fragment>
            <div className="content-block bottom-shadow">
                <Alert />
                <div className="py-15 " style={{marginTop: "10px"}}>
                    <Grid container style={{ marginBottom: "20px" }}>
                        <Grid item xs={2} style={{ marginLeft: "10px" }}>
                            <label style={{ color: 'black', fontSize: '14px' }}>Upload CSV File: </label>
                        </Grid>
                        <Grid item xs={4} style={{ width: "100% important" }}>
                            <input
                                className="md border-1"
                                placeholder="Upload Firmware Image"
                                accept=".csv"
                                type="file"
                                style={{ width: "100% !important" }}
                                name={uploadedPSNDealerFile?.name ?? uploadedPSNDealerFile}
                                onChange={(e) => {
                                    setUploadedPSNDealerFile(e.target.files[0])
                                }}
                                ref={uploadedPSNDealerFileRef}
                            />
                        </Grid>
                    </Grid>
                    <div className="row" style={{marginTop: "50px"}}>
                        <button
                            className="btn btn-style-2 orange-bg"
                            onClick={handleUploadPSNDealerFileMapping}
                            disabled={uploadPSNDealerFileInProgress ? true : false}
                            style={{ marginLeft: "10px" }}
                        >
                            {
                                uploadPSNDealerFileInProgress ? <Fragment>
                                    <CircularProgress size="2em" style={{ color: "#AFB42B", marginTop: "5px" }} />
                                </Fragment>
                                    : "Upload"
                            }
                        </button>
                        <button
                            className="btn btn-style-2 orange-bg" onClick={handleUploadPSNDealerFileReset}
                            style={{ marginLeft: "20px" }}
                            disabled={uploadPSNDealerFileInProgress ? true : false}
                        >
                            Reset
                        </button>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default UploadPSNDealerMapping