import axios from "axios";
import {
  GET_WARRANTY_TOKEN,
  WARRANTY_TOKEN_ERROR,
  GET_WARRANTY_APPLIANCE,
  WARRANTY_APPLIANCE_ERROR,
  GET_WARRANTY_DETAIL,
  WARRANTY_DETAIL_ERROR,
  CLEAR_WARRANTY,
} from "./types";
import env from "../env";

export const clearWarrantyData = () => async (dispatch) => {
  dispatch({
    type: CLEAR_WARRANTY,
  });
};

//Get eWarranty auth token and Product List
export const getWarrantyInfo = (mobile) => async (dispatch) => {
  //Get eWarranty auth token
  try {
    const configToken = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const body = { mobile };
    const resToken = await axios.post(
      `${env.GET_DEVICEMANAGEMENT_API_URL()}/simplifi/v1/deviceManagement/ewarranty/token`,
      body,
      configToken
    );
    dispatch({
      type: GET_WARRANTY_TOKEN,
      payload: resToken.data.token,
    });
    //Get eWarranty Product List
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const token = resToken.data.token;
      const bodyWarranty = { token };
      const res = await axios.post(
        `${env.GET_DEVICEMANAGEMENT_API_URL()}/simplifi/v1/services/ewarranty/applianceData`,
        bodyWarranty,
        config
      );
      dispatch({
        type: GET_WARRANTY_APPLIANCE,
        payload: res.data,
      });
    } catch (err) {
      // Handle error in getting eWarranty Product List
      dispatch({
        type: WARRANTY_APPLIANCE_ERROR,
        payload: {
          msg: err.response
            ? err.response.data && err.response.data.message
              ? err.response.data.message
              : err.response.statusText
            : null,
          status: err.response ? err.response.status : null,
        },
      });
    }
  } catch (errToken) {
    // Handle error in getting eWarranty auth token
    dispatch({
      type: WARRANTY_TOKEN_ERROR,
      payload: null,
    });
  }
};

//GET Warranty Detail for a Product
export const getWarrantyDetails = (token, productId) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const body = { token };
    const res = await axios.post(
      `${env.GET_DEVICEMANAGEMENT_API_URL()}/simplifi/v1/services/ewarranty/productDetail?product_id=${productId}`,
      body,
      config
    );
    dispatch({
      type: GET_WARRANTY_DETAIL,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: WARRANTY_DETAIL_ERROR,
      payload: {
        msg: err.response
          ? err.response.data && err.response.data.message
            ? err.response.data.message
            : err.response.statusText
          : null,
        status: err.response ? err.response.status : null,
      },
    });
  }
};
