import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import { getModelId, getDeviceByModelNumber } from "../../action/addvcModel";
import { setAlert } from "../../action/alert";
import Alert from "../layout/Alert";
import { Link } from "react-router-dom";
import Spinner from "../layout/Spinner";
import Moment from "react-moment";
import M from "materialize-css/dist/js/materialize.min.js";
import { useHistory } from "react-router-dom";
import customConfig from "../../config";
import EditIcon from '@material-ui/icons/Edit';
import DownloadIcon from '@material-ui/icons/ArrowDownward'
import IconButton from '@material-ui/core/IconButton';

const SearchModel = ({
  getModelId,
  getDeviceByModelNumber,
  ModelData: { ModelData, loading },
  match,
  roles,
  wrapperClass = "right-content-wraper",
}) => {
  useEffect(() => {
    M.AutoInit();
  }, [getModelId]);
  const history = useHistory();
  const [text, setText] = useState("");
  const [showText, setShowText] = useState(false);
  const [searchBy, setSearchBy] = useState(
    match.params.modelNum ? "modelNumber" : "modelId"
  );

  useEffect(() => {
    if (match.params.modelNum) {
      ModelData = {};
      getDeviceByModelNumber(match.params.modelNum);
      setShowText(true);
      setText("");
    }
  }, []);

  const onSubmit = (e) => {
    ModelData = {};
    setShowText(true);
    e.preventDefault();
    if (searchBy === "modelId") {
      getModelId(text);
    } else {
      getDeviceByModelNumber(text);
    }
    setText("");
  };

  const onChange = (e) => setText(e.target.value);

  const changeSearchBy = (e) => {
    setSearchBy(e.target.value);
    ModelData = {};
    setShowText(false);
  };

  const downloadDeviceMetadata = () => {
    const blob = new Blob([JSON.stringify(ModelData)], { type: "text/json" });
    let url = window.URL.createObjectURL(blob);
    let a = document.createElement("a");
    a.href = url;
    a.download = `Device Model Data- ${ModelData.modelNumber}.json`;
    a.click();
  }

  if (loading) {
    return <Spinner />;
  }
  return (
    <Fragment>
      <div className={wrapperClass}>
        <div className="container">

          {
            roles.includes(customConfig.roleProductManager) && <Link to={{ pathname: `/addNewDeviceModel`, }}>
              <button
                className="btn btn-style-2 orange-bg ml-15 mb-15"
              >
                Add New Device Model
              </button>
            </Link>
          }


          <div className="row">
            <div className="s12  col">
              <div className="content-block bottom-shadow mb-0">
                <div className="p-15">
                  <div className="row reset-device-block mb-0">
                    <div className="s4 col ">
                      <div className="page-title">
                        <label className="black-text font-600">
                          Search Device Model
                        </label>
                        <div className="sub-title d-none">
                          Search by Model Id or Model Number
                        </div>
                      </div>
                    </div>

                    <div className="s4 col">
                      <form className="from">
                        <div className="control-capabilities">
                          <div className="model-srch reset">
                            <label className="field-name active">
                              Search By
                            </label>

                            <select
                              className="custom-select select-reset"
                              name="searchBy"
                              value={searchBy}
                              onChange={(e) => changeSearchBy(e)}
                            >
                              <option value="modelId">Model Id</option>
                              <option value="modelNumber">Model Number</option>
                            </select>
                          </div>
                        </div>
                      </form>
                    </div>

                    <div className="s4 col">
                      <form className="from" onSubmit={onSubmit}>
                        <div className="search-device not-found right">
                          <i className="material-icons search-icon"> search</i>
                          <input
                            className="add-input md border-0 "
                            placeholder={
                              searchBy === "modelId"
                                ? "Enter Model Id"
                                : "Enter Model Number"
                            }
                            type="text"
                            name="text"
                            value={text}
                            onChange={onChange}
                          />
                          <input
                            type="submit"
                            value="Search"
                            className="btn orange-bg btn-block md no-shadow"
                          />
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mid-seprator"></div>
          <Alert />
        </div>
      </div>
      <div>
        {showText && Object.keys(ModelData).length > 0 && (
          <div
            style={{ marginTop: "0px", paddingTop: "0px" }}
            className={wrapperClass}
          >
            <div className="container">
              <div className="device-detail-wrap device-info">
                <div className="row mb-0">
                  <div className="s12 p-0 col">
                    <div className="content-block position-relative">
                      {history.location.state &&
                        history.location.state.showBackButton && (
                          <div className="btnPosition">
                            <button
                              className="btn btn-style-3 orange-bg sm min-140 mr-10 font-400"
                              onClick={() =>
                                history.push(history.location.state.from, {
                                  from: "/deviceDetail/model",
                                })
                              }
                            >
                              Back
                            </button>
                          </div>
                        )}

                      <div className="p-15">
                        <div className="hd-style-2">
                          <h5>
                            Device Information
                            <div style={{ float: "right" }}>
                              {
                                roles.includes(customConfig.roleProductManager) &&
                                <Link to={{ pathname: `/addNewDeviceModel`, state: { editDeviceModel: true, deviceModelData: ModelData } }}>
                                  <button
                                    className="btn btn-style-2 orange-bg ml-15 mb-15"
                                  >
                                    Edit
                                    <IconButton size="small" style={{ marginLeft: "4px", marginTop: "-5px", color: '#fff' }}>
                                      <EditIcon fontSize="inherit" />
                                    </IconButton>
                                  </button>
                                </Link>
                              }
                              <button
                                className="btn btn-style-2 orange-bg ml-15 mb-15"
                                onClick={() => {
                                  downloadDeviceMetadata()
                                }}
                              >
                                Download
                                <IconButton size="small" style={{ marginLeft: "4px", marginTop: "-5px", color: '#fff' }}>
                                  <DownloadIcon fontSize="inherit" />
                                </IconButton>
                              </button>
                            </div>
                          </h5>
                        </div>
                        <div className="device-detail">
                          <div className="row">
                            <div className="s6 col">
                              <div className="unique-box mb-15">
                                <label className="title">Model ID</label>
                                <div className="device-info">
                                  {ModelData.modelId}
                                </div>
                              </div>
                            </div>

                            <div className="s6 col">
                              <div className="unique-box mb-15">
                                <label className="title">Model Number</label>
                                <div className="device-info">
                                  {ModelData.modelNumber}
                                </div>
                              </div>
                            </div>

                            <div className="s6 col">
                              <div className="unique-box mb-15">
                                <label className="title">Model Name</label>
                                <div className="device-info">
                                  {ModelData.modelName}
                                </div>
                              </div>
                            </div>

                            <div className="s6 col">
                              <div className="unique-box mb-15">
                                <label className="title">Category</label>
                                <div className="device-info">
                                  {ModelData.category}
                                </div>
                              </div>
                            </div>

                            {
                              ModelData.subCategory && ModelData.subCategory.length != 0 &&
                              <div className="s6 col">
                                <div className="unique-box mb-15">
                                  <label className="title">Sub Category</label>
                                  <div className="device-info">
                                    {ModelData.subCategory}
                                  </div>
                                </div>
                              </div>
                            }

                            <div className="s6 col">
                              <div className="unique-box mb-15">
                                <label className="title">Launch Date</label>
                                <div className="device-info">
                                  {/* {ModelData.launchDate["$date"]} */}
                                  {Number.isInteger(ModelData.launchDate) && (
                                    <Moment
                                      format="MMMM Do YYYY, h:mm:ss a"
                                      date={ModelData.launchDate * 1000}
                                    />
                                  )}
                                </div>
                              </div>
                            </div>

                            <div className="s6 col">
                              <div className="unique-box mb-15">
                                <label className="title">IsActive</label>
                                <div className="device-info">
                                  {ModelData.isActive}
                                </div>
                              </div>
                            </div>

                            <div className="s6 col">
                              <div className="unique-box mb-15">
                                <label className="title">Manufacturer</label>
                                <div className="device-info">
                                  {ModelData.manufacturer}
                                </div>
                              </div>
                            </div>

                            <div className="s6 col">
                              <div className="unique-box mb-15">
                                <label className="title">
                                  Manufacturer Address
                                </label>
                                <div className="device-info">
                                  {ModelData.manufacturerAddress}
                                </div>
                              </div>
                            </div>

                            <div className="s6 col">
                              <div className="unique-box mb-15">
                                <label className="title">
                                  Product Manager ID
                                </label>
                                <div className="device-info">
                                  {ModelData.productManagerId}
                                </div>
                              </div>
                            </div>

                            <div className="s6 col">
                              <div className="unique-box mb-15">
                                <label className="title">Version</label>
                                <div className="device-info">
                                  {ModelData.version}
                                </div>
                              </div>
                            </div>
                            <div className="s6 col">
                              <div className="unique-box mb-15">
                                <label className="title">Help Doc URL</label>
                                <a
                                  className="link-style"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  href={customConfig.supportURL}
                                >
                                  {ModelData.helpDocUrl}
                                </a>
                              </div>
                            </div>
                            {
                              (ModelData.category === customConfig.decviceCategoryAC || ModelData.category === customConfig.deviceCategoryCAC) && <div className="s6 col">
                                <div className="unique-box mb-15">
                                  <label className="title">Tonnage</label>
                                  <div className="device-info">
                                    {ModelData.tonnage}
                                  </div>
                                </div>
                              </div>
                            }
                          </div>
                        </div>
                        <div className="row">
                          <div className="s6 col">
                            <div className="unique-box mb-15">
                              <label className="title">Matter Enabled</label>
                              <div className="device-info">
                                {ModelData.isMatterEnabled == true ? "True" : "False"}
                              </div>
                            </div>
                          </div>
                          <div className="s6 col">
                            <div className="unique-box mb-15">
                              <label className="title">Chip Type</label>
                              <div className="device-info">
                                {ModelData.chipType}
                              </div>
                            </div>
                          </div>
                          {
                            ModelData.isMatterEnabled == true &&
                            <Fragment>
                              <div className="s6 col">
                                <div className="unique-box mb-15">
                                  <label className="title">Product ID</label>
                                  <div className="device-info">
                                    {ModelData.matterPID}
                                  </div>
                                </div>
                              </div>
                              <div className="s6 col">
                                <div className="unique-box mb-15">
                                  <label className="title">Vendor ID</label>
                                  <div className="device-info">
                                    {ModelData.vendorId}
                                  </div>
                                </div>
                              </div>
                            </Fragment>
                          }
                        </div>
                        <div className="reset-capabilities position-relative">
                          <div className="pb-10 pl-10 pt-10">
                            <label className="title" style={{ color: '#000' }}>Control Capability</label>
                          </div>

                          <div className="p-15">
                            <div className="device-detail">
                              <div className="row mb-0 gray-bdr">
                                <div className="s4 col">
                                  <div className="unique-box mb-15">
                                    <label className="title">Capability</label>
                                  </div>
                                </div>

                                <div className="s4 col">
                                  <div className="unique-box mb-15">
                                    <label className="title">
                                      Attribute Key
                                    </label>
                                  </div>
                                </div>

                                <div className="s4 col">
                                  <div className="unique-box mb-15">
                                    <label className="title">Values</label>
                                  </div>
                                </div>
                              </div>
                              {ModelData["controlCapabilities"] &&
                                ModelData["controlCapabilities"].map(
                                  (control, i) => (
                                    <div className="separator-light" key={i}>
                                      {control.attributes &&
                                        control.attributes.map(
                                          (attribute, j) => (
                                            <div className="row" key={j}>
                                              <div className="s4 col">
                                                <div className="unique-box">
                                                  <div className="device-info">
                                                    {j === 0 &&
                                                      control.capability}
                                                  </div>
                                                </div>
                                              </div>

                                              <div className="s4 col">
                                                <div className="unique-box">
                                                  <div className="device-info">
                                                    {attribute.key}
                                                  </div>
                                                </div>
                                              </div>

                                              {attribute.values ? (
                                                <div className="s4 col">
                                                  <div className="unique-box">
                                                    <div className="device-info">
                                                      {Array.isArray(
                                                        attribute.values
                                                      )
                                                        ? attribute.values
                                                          .map((elem) => {
                                                            if (
                                                              typeof elem ===
                                                              "string"
                                                            ) {
                                                              return elem;
                                                            } else if (
                                                              Array.isArray(
                                                                elem
                                                              )
                                                            ) {
                                                              return elem.join(
                                                                ", "
                                                              );
                                                            } else {
                                                              let keys =
                                                                Object.keys(
                                                                  elem
                                                                );
                                                              return `${keys[0]
                                                                } : ${elem[keys[0]]
                                                                } (${elem[keys[1]]
                                                                })`;
                                                            }
                                                          })
                                                          .join(" / ")
                                                        : attribute.values}
                                                    </div>
                                                  </div>
                                                </div>
                                              ) :
                                                attribute.intValues ? (
                                                  <div className="s4 col">
                                                    <div className="unique-box">
                                                      <div className="device-info">
                                                        {Array.isArray(
                                                          attribute.intValues
                                                        )
                                                          ? attribute.intValues
                                                            .map((elem) => {
                                                              if (
                                                                typeof elem ===
                                                                "string" || typeof elem === "number"
                                                              ) {
                                                                return elem;
                                                              } else if (
                                                                Array.isArray(
                                                                  elem
                                                                )
                                                              ) {
                                                                return elem.join(
                                                                  ", "
                                                                );
                                                              } else {
                                                                let keys =
                                                                  Object.keys(
                                                                    elem
                                                                  );
                                                                return `${keys[0]
                                                                  } : ${elem[keys[0]]
                                                                  } (${elem[keys[1]]
                                                                  })`;
                                                              }
                                                            })
                                                            .join(" / ")
                                                          : attribute.values}
                                                      </div>
                                                    </div>
                                                  </div>
                                                ) :
                                                  attribute.range ? (
                                                    <div className="s4 col">
                                                      <div className="unique-box">
                                                        <div className="device-info">
                                                          {Object.keys(
                                                            attribute.range
                                                          ).map((rangeKey, k) => (
                                                            <div key={k}>
                                                              <span className="gray-text">
                                                                {rangeKey}:
                                                              </span>
                                                              &nbsp;
                                                              {attribute.range[
                                                                rangeKey
                                                              ].map(
                                                                (rangeVal, l) =>
                                                                  `${l > 0
                                                                    ? " / "
                                                                    : ""
                                                                  }${rangeVal.value
                                                                  } ${rangeVal.unit
                                                                    ? rangeVal.unit
                                                                    : ""
                                                                  }`
                                                              )}
                                                              <br />
                                                            </div>
                                                          ))}
                                                        </div>
                                                      </div>
                                                    </div>
                                                  ) : (
                                                    <div className="s4 col">
                                                      <div className="unique-box">
                                                        <div className="device-info">
                                                          N/A
                                                        </div>
                                                      </div>
                                                    </div>
                                                  )}
                                            </div>
                                          )
                                        )}
                                    </div>
                                  )
                                )}
                            </div>
                          </div>
                        </div>

                        <div className="reset-capabilities position-relative" style={{ marginTop: "20px" }}>
                          <div className="pb-10 pl-10 pt-10">
                            <label className="title" style={{ color: '#000' }}>Status Capability</label>
                          </div>

                          <div className="p-15">
                            <div className="device-detail">
                              <div className="row mb-0 gray-bdr">
                                <div className="s4 col">
                                  <div className="unique-box mb-15">
                                    <label className="title">Capability</label>
                                  </div>
                                </div>
                              </div>
                              {ModelData["statusCapabilitiesV2"] &&
                                ModelData["statusCapabilitiesV2"].map(
                                  (status, i) => (
                                    <div className="separator-light" key={i}>
                                      <div className="row" key={i + 'row'}>
                                        <div className="s4 col">
                                          <div className="unique-box">
                                            <div className="device-info">
                                              {status.capability}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )
                                )}
                            </div>
                          </div>
                        </div>

                        <span className=" top-right"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  ModelData: state.getModel,
  roles: state.auth.roles
});

export default connect(mapStateToProps, {
  setAlert,
  getModelId,
  getDeviceByModelNumber,
})(SearchModel);
