import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

const useStyles = makeStyles({
    labelColor: {
        color: "#000"
    },
    addNewSwitchesButton: {
        marginTop: "10px"
    },
    selectInput: {
        height: "3rem",
        width: "100%"
    },
    deleteButton: {
        float: "left"
    },
    inputFields: {
        marginLeft: '5px'
    },
});

const ChannelAndSwitches = ({
    channelAndSwitchDevices,
    deviceTypes,
    addChannelAndSwitches
}) => {
    const classes = useStyles();
    const [channelCount, setChannelCount] = useState(0)

    function onAddNewDevice() {
        let updatedChannelsAndSwitches = [...channelAndSwitchDevices]
        updatedChannelsAndSwitches.push({
            name: "",
            channel: ('0' + (channelCount)).slice(-2),
            type: ""
        })

        addChannelAndSwitches(updatedChannelsAndSwitches)

        setChannelCount(channelCount + 1)
    }

    function onChangeDeviceName(index, name) {
        let elements = [...channelAndSwitchDevices]
        elements[index].name = name

        addChannelAndSwitches(elements)
    }

    function onChangeDeviceType(index, type) {
        let elements = [...channelAndSwitchDevices]
        elements[index].type = type

        addChannelAndSwitches(elements)
    }

    function onDeleteDevice(indexToDelete) {
        let updatedChannelsAndSwitches = channelAndSwitchDevices.filter((element, index) => {
            return index != indexToDelete
        })

        updatedChannelsAndSwitches = updatedChannelsAndSwitches.map((element, index) => {
            element.channel = ('0' + (index)).slice(-2)
            return element
        })

        addChannelAndSwitches(updatedChannelsAndSwitches)

        setChannelCount(channelCount - 1)
    }

    return (
        <Fragment>
            <div className="row">
                <div className={`s2 col`}>
                    <label className={`title d-inline min-180 ${classes.labelColor}`}>Devices and Switches</label>
                    {/* <br></br> */}
                    <button
                        className={`btn btn-style-2 orange-bg mr-5 ${classes.addNewSwitchesButton}`}
                        onClick={() => {
                            onAddNewDevice()
                        }}
                    >
                        Add New Device
                    </button>
                </div>
            </div>
            {
                channelAndSwitchDevices && channelAndSwitchDevices.map((switchElement, index) => {
                    return <div className="row">
                        <Grid key={index} container>
                            <Grid item xs={2}>
                                <input
                                    placeholder="Channel"
                                    className="add-input xl"
                                    value={channelAndSwitchDevices[index]['channel']}
                                    type="text"
                                    disabled={true}
                                />
                            </Grid>
                            <Grid item xs={2} className={classes.inputFields}>
                                <FormControl variant="outlined" className={classes.selectInput}>
                                    <InputLabel id="select-outlined-label-values">Select Device Type</InputLabel>
                                    <Select
                                        labelId="select-outlined-label-values"
                                        id="select-outlined-values-id"
                                        value={channelAndSwitchDevices[index]["type"]}
                                        onChange={(e) => {
                                            onChangeDeviceType(index, e.target.value)
                                        }}
                                        label="Select Device Type"
                                        inputProps={{ 'aria-label': 'Without label' }}
                                        className={classes.selectInput}
                                    >
                                        {
                                            Object.keys(deviceTypes).map(deviceType => {
                                                return <MenuItem key={deviceType} value={deviceType}>{deviceTypes[deviceType]}</MenuItem>
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={2} className={classes.inputFields}>
                                <input
                                    placeholder="Enter Device Name"
                                    className="add-input xl"
                                    value={channelAndSwitchDevices[index]["name"]}
                                    type="text"
                                    onChange={(e) => {
                                        onChangeDeviceName(index, e.target.value)
                                    }}
                                />
                            </Grid>
                            <Grid item xs={1}>
                                <IconButton aria-label="delete" onClick={(e) => { onDeleteDevice(index) }}
                                    className={`${classes.deleteButton}`}>
                                    <DeleteIcon fontSize="small" />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </div>
                })
            }
        </Fragment>
    )
}

const mapStateToProps = (state) => ({
    ModelData: state.getModel
});

export default connect(mapStateToProps)(ChannelAndSwitches);