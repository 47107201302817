import React, { useEffect, useState } from "react";
import M from "materialize-css/dist/js/materialize.min.js";
import {
    getManufacturedDevicesData, downloadManufacturedDevicesReport, updateProductSerialNumberbyID
} from "./../../action/report";
import CircularProgress from "@material-ui/core/CircularProgress";
import Alert from "./../layout/Alert";
import { setAlert } from "./../../action/alert";
import { connect } from "react-redux";
import DatePicker from "react-datepicker";
import './ManufacturedDevices.css'
import { Fragment } from "react";
import SearchDevice from "./SearchDevice";
import Moment from "react-moment";
import customConfig from "../../config";

const ManufacturedDevices = ({
    setCustomAlert,
    allowedDeviceCategories,
    updateProductSerialNumberbyID,
    updateSerialNumber,
    userRoleInfo
}) => {

    // add key value pair for any new column to be added to the table. 
    // Key should be same as the key in the response and value should be what you want the header for the column to be
    const headers = {
        deviceId: "Device Id",
        productSerialNumber: "Product Serial Number",
        manufacturedDate: "Manufactured Date",
        provisionedAt: "Provisioned At",
        onboardedAt: "OnBoarded At",
        modelNumber: "Model Number",
    };

    const [manufacturedDevicesData, setManufacturedDevicesData] = useState([]);
    const [categoryFilter, setCategoryFilter] = useState("Select Category");
    const [startDateFilter, setStartDateFilter] = useState(undefined);
    const [endDateFilter, setEndDateFilter] = useState(undefined);
    const [startSerialNumberFilter, setStartSerialNumberFilter] = useState(undefined);
    const [endSerialNumberFilter, setEndSerialNumberFilter] = useState(undefined);
    const [updateFlag, setUpdateFlag] = useState(undefined);
    const [isLoading, setIsLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [paginationStartIndex, setPaginationStartIndex] = useState(0);
    const [lastApiPageFetched, setLastApiPageFetched] = useState(0);
    const [pageEndReached, setPageEndReached] = useState(false);
    const [showDeviceDetails, setShowDeviceDetails] = useState(false);
    const [selectedDeviceId, setSelectedDeviceId] = useState(undefined);
    const [showManufacturedDevicesList, setShowManufacturedDevicesList] = useState(false);
    const [newDeviceSerialNumber, setNewDeviceSerialNumber] = useState(undefined);
    const [showUpdateDeviceSerialNoUI, setShowUpdateDeviceSerialNoUI] = useState(false);
    const [disabledDeviceSerialUpdateButton, setDisabledDeviceSerialUpdateButton] = useState(false);

    const pageSize = 20;
    const totalPageCount = Math.ceil(manufacturedDevicesData?.length / pageSize);
    const startIndex = pageSize * currentPage;
    const endIndex = startIndex + pageSize;

    const pageNumbersToShow = () => {
        if (totalPageCount === 0) {
            return [0];
        } else {
            let i = paginationStartIndex;
            let pagesToShow = [];
            while (i < totalPageCount && i < paginationStartIndex + 5) {
                pagesToShow.push(i);
                i += 1;
            }
            return pagesToShow;
        }
    };

    const getPaginationData = () => {
        const pageArr = pageNumbersToShow();
        return (
            <ul className="pagination">
                <li
                    className={paginationStartIndex === 0 ? "disabled" : "waves-effect"}
                    onClick={() => {
                        if (!(paginationStartIndex === 0)) {
                            setPaginationStartIndex(Math.max(0, paginationStartIndex - 5));
                        }
                    }}
                >
                    <a href="#!">
                        <i className="material-icons">chevron_left</i>
                    </a>
                </li>
                {pageArr.map((element) => {
                    return (
                        <li
                            className={
                                parseInt(element) === parseInt(currentPage) ? "active" : ""
                            }
                        >
                            <a href="#" onClick={() => setCurrentPage(element)}>
                                {element + 1}
                            </a>
                        </li>
                    );
                })}
                <li
                    className={
                        paginationStartIndex >= totalPageCount - 5
                            ? "disabled"
                            : "waves-effect"
                    }
                    onClick={() => {
                        if (!pageEndReached) {
                            const pageArr = pageNumbersToShow();
                            if (pageArr[-1] + 1 === totalPageCount) {
                                setPaginationStartIndex(
                                    Math.min(totalPageCount, paginationStartIndex + 5)
                                );
                            } else {
                                updateManufacturedDevciesData(
                                    lastApiPageFetched + 1,
                                    nextPaginationResolveFunc
                                );
                            }
                        } else {
                            setCustomAlert("You have reached end of page.", "danger");
                        }
                    }}
                >
                    <a href="#!">
                        <i className="material-icons">chevron_right</i>
                    </a>
                </li>
            </ul>
        );
    };

    const nextPaginationResolveFunc = (data) => {
        if (data.length < 100) {
            setPageEndReached(true);
        }
        if (data.length === 0) {
            setCustomAlert("You have reached end of page.", "danger");
        } else {
            const newPaginationStartIndex = Math.min(
                parseInt((manufacturedDevicesData.length + data.length) / pageSize),
                paginationStartIndex + 5
            );
            setPaginationStartIndex(newPaginationStartIndex);
            setManufacturedDevicesData([...manufacturedDevicesData, ...data]);
            setLastApiPageFetched(lastApiPageFetched + 1);
            setCurrentPage(newPaginationStartIndex);
        }
    };

    const formateDate = (dateFilter) => {
        // if (dateFilter) {
        //     const dateVal = dateFilter.getDate() < 10 ? `0${dateFilter.getDate()}` : dateFilter.getDate()
        //     const monthVal = dateFilter.getMonth() < 9 ? `0${dateFilter.getMonth() + 1}` : dateFilter.getMonth() + 1
        //     return `${dateVal}${monthVal}${dateFilter.getFullYear()}`
        // }
        if (dateFilter) {
            return dateFilter.getTime()
        }
    }

    const printableDate = (dateFilter) => {
        if (dateFilter) {
            const dateVal = dateFilter.getDate() < 10 ? `0${dateFilter.getDate()}` : dateFilter.getDate()
            const monthVal = dateFilter.getMonth() < 9 ? `0${dateFilter.getMonth() + 1}` : dateFilter.getMonth() + 1
            return `${dateVal}-${monthVal}-${dateFilter.getFullYear()}`
        }
    }

    const updateManufacturedDevciesData = (page, resolveFunc, errFunc) => {
        if (categoryFilter == "Select Category" || startDateFilter == undefined) {
            return;
        }

        setIsLoading(true);

        let endDate = null
        if (endDateFilter) {
            endDate = new Date(endDateFilter)
        }

        getManufacturedDevicesData({
            category: categoryFilter,
            startDate: formateDate(startDateFilter),
            endDate: formateDate(endDateFilter ? new Date(endDate.setDate(endDate.getDate() + 1)) : endDateFilter),
            page: page || lastApiPageFetched,
            size: pageSize * 5,
            startSerialNumber: startSerialNumberFilter,
            endSerialNumber: endSerialNumberFilter
        })
            .then((res) => {
                setIsLoading(false);
                if (!resolveFunc) {
                    if (res.length < 100) {
                        setPageEndReached(true);
                    }
                    setManufacturedDevicesData(res);
                } else {
                    resolveFunc(res);
                }
            })
            .catch((err) => {
                setIsLoading(false);
                if (!errFunc) {
                    console.log(err);
                    setCustomAlert("Error while fetching data.", "danger");
                } else {
                    errFunc(err);
                }
            });
    };

    useEffect(() => {
        M.AutoInit();
    }, [updateFlag, isLoading, currentPage, manufacturedDevicesData, showDeviceDetails]);

    useEffect(() => {
        updateManufacturedDevciesData();
    }, [updateFlag]);

    useEffect(() => {
        if (categoryFilter == "Select Category" || startDateFilter == undefined) {
            setShowManufacturedDevicesList(false)
        }
    }, [categoryFilter, startDateFilter])

    useEffect(() => {
        if (!updateSerialNumber || (updateSerialNumber instanceof Array && updateSerialNumber.length == 0)) {
            return
        }

        if (updateSerialNumber.hasOwnProperty('error')) {
            return;
        }

        setUpdateFlag(Math.random()); //Use this to Fetch Updated Manufactured Devices Data

    }, [updateSerialNumber])

    const reset = () => {
        setCategoryFilter("Select Category");
        setStartDateFilter(undefined)
        setEndDateFilter(undefined)
        setStartSerialNumberFilter("");
        setEndSerialNumberFilter("")
        setUpdateFlag(Math.random());
    };

    const filter = () => {
        if (categoryFilter == "Select Category") {
            setCustomAlert("Category Cannot be Empty", "danger")
            return;
        }

        if (startDateFilter == undefined) {
            setCustomAlert("Start Date Cannot be Empty", "danger")
            return;
        }

        setCurrentPage(0);
        setPaginationStartIndex(0);
        setPageEndReached(false);
        setLastApiPageFetched(0);
        setUpdateFlag(Math.random());
        setShowManufacturedDevicesList(true);
    };

    if (isLoading) {
        return (
            <div
                style={{
                    display: "grid",
                    placeItems: "center",
                    height: "80vh",
                }}
            >
                <CircularProgress size="5em" style={{ color: "#AFB42B" }} />
            </div>
        );
    }

    const handleStartDateChange = (date) => {
        setStartDateFilter(date)
    }

    const handleEndDateChange = (date) => {
        setEndDateFilter(date)
    }

    const handleDownloadManufacturedDevcies = () => {

        if (!categoryFilter || categoryFilter == "Select Category" || categoryFilter == "") {
            return;
        }

        if (!startDateFilter || startDateFilter == "") {
            return;
        }

        downloadManufacturedDevicesReport({
            category: categoryFilter,
            startDate: formateDate(startDateFilter),
            endDate: formateDate(endDateFilter),
            startSerialNumber: startSerialNumberFilter,
            endSerialNumber: endSerialNumberFilter,
            printableStartDate: printableDate(startDateFilter)
        })
    }

    const showUpdateDeviceSerialPopup = (deviceSerialNumber) => {
        let serialNo = deviceSerialNumber ? deviceSerialNumber.trim() : "";
        setNewDeviceSerialNumber(serialNo);
        setDisabledDeviceSerialUpdateButton(serialNo.length <= 0);
        showUpdateDeviceSerialNoUI ? setShowUpdateDeviceSerialNoUI(false) : setShowUpdateDeviceSerialNoUI(true);
    };

    const onChangeDeviceSerialNumber = (e) => {
        if (e.target.value.trim().length > 0)
            setDisabledDeviceSerialUpdateButton(false);
        else
            setDisabledDeviceSerialUpdateButton(true);

        setNewDeviceSerialNumber(e.target.value);
    };

    const updateDeviceSerialNumber = (deviceId) => {
        let deviceSerialNumber = newDeviceSerialNumber ? newDeviceSerialNumber : ""

        const updatedDeviceSerialNo = deviceSerialNumber.trim();
        if (updatedDeviceSerialNo) {
            updateProductSerialNumberbyID(deviceId, updatedDeviceSerialNo)
            setShowUpdateDeviceSerialNoUI(false);
        }
    };

    const cancelDeviceSerialPopup = () => {
        setShowUpdateDeviceSerialNoUI(false);
        setDisabledDeviceSerialUpdateButton(false);
    };

    return (
        <Fragment>
            {
                !showDeviceDetails &&
                <div className="container">
                    <div className="content-block bottom-shadow">
                        <div className="py-15 ">
                            <div className="row mb-0">
                                <div className="col pt-10">
                                    <div className="model-srch reset width-md">
                                        <select
                                            className="custom-select select-reset radius-20"
                                            style={{ "display": "block !important" }}
                                            name="categoryFilter"
                                            onChange={(e) => {
                                                setCategoryFilter(e.target.value);
                                            }}
                                            value={categoryFilter}
                                        >
                                            {["Select Category", ...allowedDeviceCategories].map((element) => (
                                                <option value={element}>{element}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="col">
                                    <DatePicker
                                        selected={startDateFilter}
                                        maxDate={new Date()}
                                        onChange={handleStartDateChange}
                                        placeholderText="Start Date"
                                        dateFormat='MM/dd/yyyy'
                                        className="add-input calendar input_phone"
                                    />
                                </div>
                                <div className="col">
                                    <DatePicker
                                        selected={endDateFilter}
                                        maxDate={new Date()}
                                        onChange={handleEndDateChange}
                                        placeholderText="End Date"
                                        dateFormat='MM/dd/yyyy'
                                        className="add-input calendar input_phone"
                                    />
                                </div>
                                <div className="col pt-10">
                                    <div className="model-srch reset width-md">
                                        <input
                                            className="add-input md border-1"
                                            placeholder="Start Serial Number"
                                            type="text"
                                            name="startSerialNumber"
                                            value={startSerialNumberFilter}
                                            onChange={(e) => {
                                                setStartSerialNumberFilter(e.target.value)
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="col pt-10">
                                    <div className="model-srch reset width-md">
                                        <input
                                            className="add-input md border-1"
                                            placeholder="End Serial Number"
                                            type="text"
                                            name="endSerialNumber"
                                            value={endSerialNumberFilter}
                                            onChange={(e) => {
                                                setEndSerialNumberFilter(e.target.value)
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col pt-10">
                                    <button
                                        className="btn btn-style-3  sm  mr-10 font-400"
                                        onClick={filter}
                                    >
                                        Filter
                                    </button>
                                    <button
                                        className="btn btn-style-3 sm font-400"
                                        onClick={reset}
                                    >
                                        Reset
                                    </button>
                                </div>
                                <div className="col pt-10">
                                    <button
                                        className="btn btn-style-3  sm font-400 float_right cursor-Hand"
                                        onClick={handleDownloadManufacturedDevcies}
                                    >
                                        Download
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Alert />

                    {
                        showManufacturedDevicesList &&
                        <div className="content-block bottom-shadow py-15 mb-30">
                            <div className="row mb-0">
                                <div className="col s12">
                                    <div
                                        className="table-wrapper mb-20"
                                        style={{
                                            overflowX: "scroll",
                                            scrollBehavior: "auto",
                                            maxHeight: "58vh",
                                            overflowY: "scroll",
                                        }}
                                    >
                                        <table
                                            width="100%"
                                            align="center"
                                            className="table style-4 mb-20 manufacturedDevicesTable"
                                        >
                                            <thead
                                                style={{ position: "sticky", top: 0, zIndex: 50, background: "white" }}
                                            >
                                                <tr>
                                                    {Object.keys(headers).map((key) => (
                                                        <th>{headers[key]}</th>
                                                    ))}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {manufacturedDevicesData.length === 0 ? (
                                                    <tr>
                                                        <td colSpan={Object.keys(headers).length}>
                                                            No Data found
                                                        </td>
                                                    </tr>
                                                ) : (
                                                    manufacturedDevicesData
                                                        .slice(startIndex, endIndex)
                                                        .map((element, index) => {
                                                            return (
                                                                <tr key={index % pageSize} id={index % pageSize}>
                                                                    {Object.keys(headers).map((key) => {
                                                                        return (
                                                                            key == "manufacturedDate" || key == "provisionedAt" || key == "onboardedAt" ?
                                                                                <td
                                                                                    className={
                                                                                        String(element[key]).length > 200
                                                                                            ? "breakWord"
                                                                                            : ""
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        String(element[key]) && String(element[key]).length > 0 ?
                                                                                            <Moment
                                                                                                format="DD-MM-YYYY HH:mm:ss"
                                                                                                date={element[key] * 1000}
                                                                                            />
                                                                                            :
                                                                                            ""
                                                                                    }
                                                                                </td>
                                                                                :
                                                                                key == "productSerialNumber" ?
                                                                                    <td
                                                                                        className={String(element[key]).length > 200
                                                                                            ? "breakWord"
                                                                                            : ""
                                                                                        }
                                                                                    >
                                                                                        {selectedDeviceId == element.deviceId && showUpdateDeviceSerialNoUI && (
                                                                                            <div>
                                                                                                <div className="row">
                                                                                                    <div className="col s5" style={{ width: "100%" }}>
                                                                                                        <div className="update-bx">
                                                                                                            <input
                                                                                                                placeholder="Enter Serial Number"
                                                                                                                className="font-16 update-inpt medium"
                                                                                                                value={newDeviceSerialNumber}
                                                                                                                type="text"
                                                                                                                onChange={(e) => {
                                                                                                                    onChangeDeviceSerialNumber(e)
                                                                                                                }}
                                                                                                            />
                                                                                                            <br></br>
                                                                                                            <button
                                                                                                                disabled={disabledDeviceSerialUpdateButton}
                                                                                                                className="btn btn-style-2 orange-bg mr-10"
                                                                                                                onClick={() => {
                                                                                                                    updateDeviceSerialNumber(element["deviceId"])
                                                                                                                }}
                                                                                                            >
                                                                                                                Update
                                                                                                            </button>
                                                                                                            <button
                                                                                                                className="btn btn-style-2 dark-text gray-bg"
                                                                                                                onClick={() => cancelDeviceSerialPopup()}
                                                                                                            >
                                                                                                                Cancel
                                                                                                            </button>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        )}
                                                                                        {
                                                                                            (!showUpdateDeviceSerialNoUI || selectedDeviceId != element.deviceId) &&
                                                                                            <>
                                                                                                {element[key] ? element[key] : ""}
                                                                                                {
                                                                                                    (userRoleInfo.level > 1 || (userRoleInfo.level > 1 && userRoleInfo.roles.includes(customConfig.roleFactoryAdmin))) &&
                                                                                                    <button
                                                                                                        onClick={() => {
                                                                                                            setSelectedDeviceId(element.deviceId)
                                                                                                            showUpdateDeviceSerialPopup(element[key])
                                                                                                        }}
                                                                                                        className="edit-link"
                                                                                                    >
                                                                                                        <i className="material-icons icn">mode_edit</i>
                                                                                                    </button>
                                                                                                }

                                                                                            </>
                                                                                        }
                                                                                    </td>
                                                                                    :
                                                                                    key == "deviceId" ?
                                                                                        <td
                                                                                            className={
                                                                                                String(element[key]).length > 200
                                                                                                    ? "breakWord"
                                                                                                    : ""
                                                                                            }
                                                                                            onClick={() => {
                                                                                                setSelectedDeviceId(element.deviceId)
                                                                                                setShowDeviceDetails(true)
                                                                                            }} style={{ "cursor": "pointer" }}
                                                                                        >
                                                                                            <u>{element[key] ? element[key] : ""}</u>
                                                                                        </td>
                                                                                        :
                                                                                        <td
                                                                                            className={
                                                                                                String(element[key]).length > 200
                                                                                                    ? "breakWord"
                                                                                                    : ""
                                                                                            }
                                                                                        >
                                                                                            {element[key] ? element[key] : ""}
                                                                                        </td>
                                                                        );
                                                                    })}
                                                                </tr>
                                                            );
                                                        })
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="d-flex justify-content-end ">
                                        {getPaginationData()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                </div>
            }
            {
                showDeviceDetails &&
                <Fragment>
                    <div className="row mb-0" style={{ "paddingBottom": "10px" }}>
                        <button className="btn btn-style-2 orange-bg color-white pb-10" onClick={() => {
                            setShowDeviceDetails(false)
                            setSelectedDeviceId(null)
                        }}
                        >
                            Back
                        </button>
                    </div>
                    <SearchDevice deviceId={selectedDeviceId} />
                </Fragment>
            }
        </Fragment>
    );
};
const mapStateToProps = (state) => ({
    allowedDeviceCategories: state.auth.allowedDeviceCategories,
    updateSerialNumber: state.report.updateSerialNumber,
    userRoleInfo: {
        level: state.auth.userLevel,
        roles: state.auth.userRoles,
    },
});

const mapDispatchToProps = (dispatch) => ({
    setCustomAlert: (message, alertType) => dispatch(setAlert(message, alertType)),
    updateProductSerialNumberbyID: (deviceId, productSerialNumber) => dispatch(updateProductSerialNumberbyID(deviceId, productSerialNumber))
});

export default connect(mapStateToProps, mapDispatchToProps)(ManufacturedDevices);
