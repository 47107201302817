import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getOnBoardAcUser } from "../../../action/metrics";
import { seriesData, seriesXaxisData, totalFilterCount } from "./GraphParameter";
import Graph from "./Graph";

function OnboardAcUsers({getOnBoardAcUser, graphType, onBordedAcUsers, type,setDeviceCount }) {
  useEffect(() => {
    if (type !== "4" ) {
      const que = {
        dateType: type === "1" ? "daily" : type === "2" ? "weekly" : "monthly",
        // "X-Tenant-ID": "panasonic",
      };
      getOnBoardAcUser(que);
    }
  }, [getOnBoardAcUser,type,graphType]);
//   let d = new Date();
  let seriesDataDevices = [];
  let intervalData = [];
  if (onBordedAcUsers?.data?.length) {
    seriesDataDevices = seriesData(onBordedAcUsers?.data,graphType);
    intervalData = seriesXaxisData(onBordedAcUsers?.data, graphType);
  }
  const totalCount = totalFilterCount(seriesDataDevices)
  useEffect(()=>{
    setDeviceCount(totalCount)
  },[totalCount])


  return (
    <div>
      <div id="chart">
        <Graph
          text={"Users"}
          x_data={intervalData}
          title={"Number Of Onboarded User"}
          y_data={seriesDataDevices}
          x_title = {"Onboarded Date"}
        />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    onBordedAcUsers: state.metrics.onBordedAcUsers,
  };
};
export default connect(mapStateToProps, {getOnBoardAcUser})(OnboardAcUsers);
