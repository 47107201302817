import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logout } from "../../../action/auth";
import { getAllDevice } from "../../../action/report";
import { Redirect } from "react-router-dom";

const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;

const NavWrapper = ({
  auth,
  isAuthenticated,
  tenantLogoUrl,
  children,
  logout,
  getAllDevice,
  tenantInfo
}) => {
  const onLogout = () => {
    logout(CLIENT_ID);
    getAllDevice("");
  };
  if (!isAuthenticated) {
    return <Redirect to="/" />;
  }
  return <>{children({ auth, isAuthenticated, tenantLogoUrl, tenantInfo, onLogout })}</>;
};

NavWrapper.propTypes = {
  auth: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
  children: PropTypes.func.isRequired,
  
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  isAuthenticated: state.auth.isAuthenticated,
  tenantLogoUrl: state.tenant.tenantInfo.tenantLogoUrl,
  tenantInfo: state.tenant.tenantInfo,
});

export default connect(mapStateToProps, { logout, getAllDevice })(NavWrapper);
