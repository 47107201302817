import React, {useState ,useEffect} from "react";
import { connect } from "react-redux";
import {getGeoLocationDevices} from '../../../action/metrics';
import GoogleMapReact from 'google-map-react';
import MyMarker from "./MyMarker";
import DatePicker from "react-datepicker";


function DeviceGeoLocation({getGeoLocationDevices, geoLocationDevices , selectedGraph , ActiveGeolocation,formateDate}) {
  const [startDate, setStartDate] = useState(new Date());
  useEffect(()=>{
    const que= {
        "onDate":formateDate(new Date())
      }
      getGeoLocationDevices(que)
},[getGeoLocationDevices])

const handleStartDateChange = (date) => {
  setStartDate(date);
};

const HandleDeviceControlFilter  = (startDate) => {
  if(selectedGraph === "5") {
    ActiveGeolocation(startDate)
    
  }
  
  
  else {
    resetDate();
  }

}

const resetDate = () =>{
  setStartDate("");
}
const host = window.location.host;
const showKey = host.includes("dev1") || host.includes("preprod") || host.includes("miraie") || host.includes("localhost")

    return (
      <div>
        <div className="control-capabilities">
        <div className="model-srch reset full-w justify_content_end date__picker">
          <DatePicker
              selected={startDate}
              maxDate={new Date()}
              onChange={handleStartDateChange}
              placeholderText="Start Date"
              dateFormat="dd/MM/yyyy"
              className="add-input calendar input_date"
            />
            <button
          className="date_button orange-bg filter_btn w-100"
          onClick={() => HandleDeviceControlFilter (startDate)}
        >
          Search
        </button>
        </div>
      </div>
        {showKey && <div style={{ height: '100vh', width: '100%' }}>
        <GoogleMapReact
          bootstrapURLKeys= {{ key: host.includes("localhost") ? 'AIzaSyDN2by5PQgBQaT9Eu1ojxFwOTiNhLbYVNM' : process.env.REACT_APP_GOOGLE_MAP_API_KEY ,language:"en", region: "IN" }}
          defaultCenter={{
            lat: 12.972442,
            lng: 77.580643
          }}
          defaultZoom={5}
        //   distanceToMouse={distanceToMouse}
          // yesIWantToUseGoogleMapApiInternals
        //   onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
        >
          {geoLocationDevices.length && geoLocationDevices.map((place,id) => {
          return (
            <MyMarker key={id} lat={place["location"][1]} lng={place["location"][0]}  device_count = {place["deviceCount"]} user_count = {place["userCount"]} nearBy = {place} />
          );
        })}
        </GoogleMapReact>
      </div>}
      </div>
    )
}
const mapStateToProps = (state) =>{
  return{
    geoLocationDevices:state.metrics.geoLocationDevices     

  }
}

export default connect(mapStateToProps, { getGeoLocationDevices  })(DeviceGeoLocation);
