import React, { Fragment, useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
//import DatePicker from "react-datepicker";
import { notificationConstants } from './notificationConstant'
import DatePicker from "react-multi-date-picker"
import DatePanel from "react-multi-date-picker/plugins/date_panel"
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import { Checkbox } from "@material-ui/core";

const useStyles = makeStyles({
    selectInput: {
        height: "3rem",
        width: "100%"
    },
    selectValues: {
        width: "100%",
    },
    activeTab: {
        border: '1px solid #bdbdbd',
        backgroundColor: '#e0e0e0',
        textTransform: 'capitalize'
    },
    nonActiveTab: {
        border: 'none',
        backgroundColor: 'none',
        textTransform: 'capitalize'
    },
    customText: {
        height: '2rem !important',
        paddingLeft: '10px!important',
        paddingRight: '30px !important',
        backgroundColor: 'none',
        border: '1px solid #9e9e9e !important',
    }
});

const headers = {
    productSerialNumber: "Product Serial Number",
    status: "QR Code Print Status",
    reasonOfFailure: "Reason of Failure"
};

const ScheduleNotification = ({
    setAlert,
    scheduleNotification,
    handleScheduleNotification,
    scheduleType,
    handleScheduleTypeChange,
    multiScheduleDateTime,
    handleMultiScheduleDateTime,
    scheduleDayOfWeek,
    handleScheduleDayOfWeekChange,
    multiScheduleTime,
    handleMultiScheduleTimeChange,
    handleDeleteMultiScheduleTime
}) => {

    const classes = useStyles()

    // const [daysOfWeek, setDaysOfWeek] = useState([]);

    const [selectedTimeForRepeatScheduling, setSelectedTimeForRepeatScheduling] = useState(undefined)

    // Adjust the height of menu items in select. Otherwise get hidden behind headers
    const menuProps = {
        style: {
            marginTop: '52px'
        },
    };



    function CustomMultipleInput({ onFocus, value, onChange }) {
        return (
            <Fragment>
                <input
                    onFocus={onFocus}
                    value={value}
                    readOnly
                    className={classes.customText}
                    onChange={onChange}
                />
            </Fragment>
        )
    }

    const handleDayToggle = (day) => {
        if (scheduleDayOfWeek.includes(day)) {
            handleScheduleDayOfWeekChange(scheduleDayOfWeek.filter((d) => d !== day));
        } else {
            handleScheduleDayOfWeekChange([...scheduleDayOfWeek, day]);
        }
    };

    function handleAddSelectedTimeForRepeatScheduling() {
        handleMultiScheduleTimeChange(selectedTimeForRepeatScheduling)
    }

    function onDeleteSelectedTimeForRepeatScheduling(index) {
        handleDeleteMultiScheduleTime(index)
    }

    return (
        <Fragment>
            <label className="title" style={{ fontSize: "14px", fontWeight: "bold" }}>Schedule Notification</label>

            <div className="content-block position-relative" style={{ marginTop: "10px" }}>
                <div className="py-15">
                    <Grid container style={{ marginBottom: "20px" }}>
                        <Grid item xs={2} style={{ marginLeft: "10px" }}>
                            <label className="title" style={{ color: 'black', fontSize: '14px' }}>Schedule Notification </label>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={scheduleNotification}
                                        onChange={(e) => {
                                            handleScheduleNotification(e.target.checked)
                                        }}
                                        color="primary"
                                    />
                                }
                                label={"Schedule Notification"}
                            />
                        </Grid>
                    </Grid>
                    {
                        scheduleNotification && <Fragment>
                            <Grid container style={{ marginBottom: "20px" }}>
                                <Grid item xs={2} style={{ marginLeft: "10px" }}>
                                    <label className="title" style={{ color: 'black', fontSize: '14px' }}>Select Schedule Type: </label>
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControl component="fieldset">
                                        <RadioGroup aria-label="scheduleType" name="scheduleType" row value={scheduleType} onChange={(e) => {
                                            handleScheduleTypeChange(e.target.value)
                                        }}>
                                            <FormControlLabel value={notificationConstants.oneTimeScheduleType} control={<Radio color="default" />} label="One Time" />
                                            <FormControlLabel value={notificationConstants.repeatScheduleType} control={<Radio color="default" />} label="Repeat" />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            {
                                scheduleType == notificationConstants.oneTimeScheduleType && <Fragment>
                                    <Grid container style={{ marginBottom: "20px" }}>
                                        <Grid item xs={2} style={{ marginLeft: "10px" }}>
                                            <label className="title" style={{ color: 'black', fontSize: '14px' }}>Select Scheduling Date and Time: </label>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <DatePicker
                                                minDate={new Date()}
                                                render={<CustomMultipleInput />}
                                                multiple
                                                placeholder="Select Scheduling Dates"
                                                plugins={[
                                                    <DatePanel markFocused style={{ width: '100%' }} />,
                                                    <TimePicker position="bottom" />,
                                                ]}
                                                format="MM/DD/YYYY HH:mm:ss"
                                                value={multiScheduleDateTime}
                                                onChange={(selectedDateTime) => {
                                                    handleMultiScheduleDateTime(selectedDateTime)
                                                }}
                                            />
                                        </Grid>
                                        <Grid item>
                                        <i class="fa fa-calendar" style={{ padding: '10px', minWidth: '40px', }}></i>
                                        </Grid>
                                    </Grid>
                                </Fragment>
                            }
                            {
                                scheduleType == notificationConstants.repeatScheduleType && <Fragment>
                                    <Grid container style={{ marginBottom: "20px" }}>
                                        <Grid item xs={2} style={{ marginLeft: "10px" }}>
                                            <label className="title" style={{ color: 'black', fontSize: '14px' }}>Select Scheduling Time: </label>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <DatePicker
                                                render={<CustomMultipleInput />}
                                                disableDayPicker
                                                format="hh:mm:ss A"
                                                plugins={[
                                                    <TimePicker />
                                                ]}
                                                values={selectedTimeForRepeatScheduling}
                                                onChange={(e) => {
                                                    if (e) {
                                                        setSelectedTimeForRepeatScheduling(new Date(e).getTime())
                                                    }
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={2}
                                        >
                                            <button
                                                className="btn btn-style-2 orange-bg"
                                                onClick={() => {
                                                    if (selectedTimeForRepeatScheduling) {
                                                        handleAddSelectedTimeForRepeatScheduling()
                                                    }
                                                }}
                                            >
                                                Add Time
                                            </button>
                                            <i class="fa fa-calendar" style={{ padding: '10px', minWidth: '40px', }}></i>
                                        </Grid>
                                    </Grid>
                                    <Grid container style={{ marginBottom: "20px" }}>
                                        <Grid item xs={2} style={{ marginLeft: "10px" }}>
                                        </Grid>
                                        <Grid item xs={6} style={{ whiteSpace: 'nowrap', overflowX: 'auto' }}>
                                            <div className="row">
                                                <div className="p-0  m-0 input-field col s12">
                                                    <div className="wweek-box  d-flex">
                                                        {
                                                            multiScheduleTime && multiScheduleTime.length > 0 && multiScheduleTime.map((scheduledTime, index) => {
                                                                return <Fragment>
                                                                    <div
                                                                        className={"name"}
                                                                    >
                                                                        {new Date(scheduledTime).getHours() + ":" + new Date(scheduledTime).getMinutes() + ":" +
                                                                            new Date(scheduledTime).getSeconds()}
                                                                        <span><i class="fa fa-close" style={{ marginLeft: "20px", cursor: "pointer" }} onClick={() => {
                                                                            onDeleteSelectedTimeForRepeatScheduling(index)
                                                                        }}></i></span>
                                                                    </div>
                                                                </Fragment>
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </Grid>
                                    </Grid>
                                    <Grid container style={{ marginBottom: "20px" }}>
                                        <Grid item xs={2} style={{ marginLeft: "10px" }}>
                                            <label className="title" style={{ color: 'black', fontSize: '14px' }}>Select Days of Week: </label>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <div className="row">
                                                <div className="p-0  m-0 input-field col s12">
                                                    <div className="wweek-box  d-flex">
                                                        <div
                                                            className={
                                                                scheduleDayOfWeek && scheduleDayOfWeek.includes("mon")
                                                                    ? "name active"
                                                                    : "name"
                                                            }
                                                            onClick={() => handleDayToggle("mon")}
                                                        >
                                                            Mon
                                                        </div>
                                                        <div
                                                            className={
                                                                scheduleDayOfWeek && scheduleDayOfWeek.includes("tue")
                                                                    ? "name active"
                                                                    : "name"
                                                            }
                                                            onClick={() => handleDayToggle("tue")}
                                                        >
                                                            Tue
                                                        </div>
                                                        <div
                                                            className={
                                                                scheduleDayOfWeek && scheduleDayOfWeek.includes("wed")
                                                                    ? "name active"
                                                                    : "name"
                                                            }
                                                            onClick={() => handleDayToggle("wed")}
                                                        >
                                                            Wed
                                                        </div>
                                                        <div
                                                            className={
                                                                scheduleDayOfWeek && scheduleDayOfWeek.includes("thu")
                                                                    ? "name active"
                                                                    : "name"
                                                            }
                                                            onClick={() => handleDayToggle("thu")}
                                                        >
                                                            Thu
                                                        </div>
                                                        <div
                                                            className={
                                                                scheduleDayOfWeek && scheduleDayOfWeek.includes("fri")
                                                                    ? "name active"
                                                                    : "name"
                                                            }
                                                            onClick={() => handleDayToggle("fri")}
                                                        >
                                                            Fri
                                                        </div>
                                                        <div
                                                            className={
                                                                scheduleDayOfWeek && scheduleDayOfWeek.includes("sat")
                                                                    ? "name active"
                                                                    : "name"
                                                            }
                                                            onClick={() => handleDayToggle("sat")}
                                                        >
                                                            Sat
                                                        </div>
                                                        <div
                                                            className={
                                                                scheduleDayOfWeek && scheduleDayOfWeek.includes("sun")
                                                                    ? "name active"
                                                                    : "name"
                                                            }
                                                            onClick={() => handleDayToggle("sun")}
                                                        >
                                                            Sun
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Fragment>
                            }
                        </Fragment>
                    }
                </div>
            </div>
        </Fragment >
    );
}


export default ScheduleNotification