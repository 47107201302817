import axios from "axios";
import env from "../env";
import { setAlert } from "./alert";
import {
  GET_GROUP_USERS_SUCCESS,
  GET_GROUP_USERS_ERROR,
  GET_ONBOARDED_DEVICE_SUCCESS,
  GET_ONBOARDED_DEVICE_ERROR,
  GET_OFFBOARDED_DEVICE_SUCCESS,
  GET_OFFBOARDED_DEVICE_ERROR,
  GET_ONBOARDED_AC_USER_SUCCESS,
  GET_ONBOARDED_AC_USER_ERROR,
  GET_CONTROLLED_DEVICES_SUCCESS,
  GET_CONTROLLED_DEVICES_ERROR,
  GET_LOCATION_DEVICES_SUCCESS,
  GET_LOCATION_DEVICES_ERROR,
  GET_MODEL_WISE_ONBOARDED_DEVICE_SUCCESS,
  GET_MODEL_WISE_ONBOARDED_DEVICE_ERROR,
  GET_HOURLY_ACTIVE_DEVICE_SUCCESS,
  GET_HOURLY_ACTIVE_DEVICE_ERROR,
  GET_ONBOARDED_AC_DEVICE_SUCCESS,
  GET_ONBOARDED_AC_DEVICE_ERROR,
  GET_OFFBOARDED_AC_DEVICE_SUCCESS,
  GET_OFFBOARDED_AC_DEVICE_ERROR,
  GET_MQTT_USERS_SUCCESS,
  GET_MQTT_USERS_ERROR,
  GET_MQTT_USED_KEYS_SUCCESS,
  GET_MQTT_USED_KEYS_ERROR,
  RESET_GRAPH_DATA
} from "./types";


//GET Group user present
export const getGroupUser = (params) => async (dispatch) => {
  try {
        const res = await axios.get(
      `${env.GET_DEVICEMANAGEMENT_API_URL()}/simplifi/v1/metricsportal/groupUsers`
    )
    dispatch({
      type: GET_GROUP_USERS_SUCCESS,
      payload: res.data.data,
      // payload:response
    });
  } catch (err) {
    dispatch(setAlert(err?.response?.data?.status ? err?.response?.data?.status : "Something went wrong.", "danger"));
    dispatch({
      type: GET_GROUP_USERS_ERROR,
      payload:
      {
        msg: err.response
          ? err.response.data && err.response.data.message
            ? err.response.data.message
            : err.response.statusText
          : null,
        status: err.response ? err.response.status : null,
      },
    });
  }
};

//GET onboarddevice present
export const getOnBoardDevices = (params) => async (dispatch) => {
  try {
    const res = await axios.get(
      `${env.GET_DEVICEMANAGEMENT_API_URL()}/simplifi/v1/metricsportal/onboardDevicesCount/onboard`,
      {
        params: params,
      }
    )
    dispatch({
      type: GET_ONBOARDED_DEVICE_SUCCESS,
       payload: res.data.data,

    });
  } catch (err) {
    dispatch(setAlert(err?.response?.data?.status ? err?.response?.data?.status : "Something went wrong.", "danger"));

    dispatch({
      type: GET_ONBOARDED_DEVICE_ERROR,
      payload: {
        msg: err.response
          ? err.response.data && err.response.data.message
            ? err.response.data.message
            : err.response.statusText
          : null,
        status: err.response ? err.response.status : null,
      },
    });
  }
};

//GET oFFboarddevice present
export const getOffBoardDevices = (params) => async (dispatch) => {

  try {
    const res = await axios.get(
      `${env.GET_DEVICEMANAGEMENT_API_URL()}/simplifi/v1/metricsportal/onboardDevicesCount/offboard`,
      {
        params: params,
      }
    )
    dispatch({
      type: GET_OFFBOARDED_DEVICE_SUCCESS,
      payload: res.data.data,

    });
  } catch (err) {
    dispatch(setAlert(err?.response?.data?.status ? err?.response?.data?.status : "Something went wrong.", "danger"));

    dispatch({
      type: GET_OFFBOARDED_DEVICE_ERROR,
      payload: {
        msg: err.response
          ? err.response.data && err.response.data.message
            ? err.response.data.message
            : err.response.statusText
          : null,
        status: err.response ? err.response.status : null,
      },
    });
  }
};

//GET onboarddevice Ac'c user present
export const getOnBoardAcUser = (params) => async (dispatch) => {

  try {
    const res = await axios.get(
      `${env.GET_DEVICEMANAGEMENT_API_URL()}/simplifi/v1/metricsportal/onboardUsersCountByCategory/AC`,
      {
        params: params,
      }
    )
    dispatch({
      type: GET_ONBOARDED_AC_USER_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch(setAlert(err?.response?.data?.status ? err?.response?.data?.status : "Something went wrong.", "danger"));

    dispatch({
      type: GET_ONBOARDED_AC_USER_ERROR,
      payload: {
        msg: err.response
          ? err.response.data && err.response.data.message
            ? err.response.data.message
            : err.response.statusText
          : null,
        status: err.response ? err.response.status : null,
      },
    });
  }
};

//GET offboarddevice Ac'c user present
export const getOffBoardAcDevices = (params) => async (dispatch) => {

  try {
    const res = await axios.get(
      `${env.GET_DEVICEMANAGEMENT_API_URL()}/simplifi/v1/metricsportal/offBoardDeviceCountAc`,
      {
        params: params,
      }
    )

    dispatch({
      type: GET_OFFBOARDED_AC_DEVICE_SUCCESS,
      payload: res.data.data,
    });
  } catch (err) {
    dispatch(setAlert(err?.response?.data?.status ? err?.response?.data?.status : "Something went wrong.", "danger"));

    dispatch({
      type: GET_OFFBOARDED_AC_DEVICE_ERROR,
      payload: {
        msg: err.response
          ? err.response.data && err.response.data.message
            ? err.response.data.message
            : err.response.statusText
          : null,
        status: err.response ? err.response.status : null,
      },
    });
  }
};

//GET  MQTT USERS COUNT
export const getMqttUsers = (params) => async (dispatch) => {
try {
      const res = await axios.get(
    `${env.GET_DEVICEMANAGEMENT_API_URL()}/simplifi/v1/metricsportal/usersDuration`,
    {
      params: params,
    }
  )
  dispatch({
    type: GET_MQTT_USERS_SUCCESS,
    payload: res?.data?.data,
  });
} catch (err) {
  dispatch(setAlert(err?.response?.data?.status ? err?.response?.data?.status : "Something went wrong.", "danger"));
  dispatch({
    type: GET_MQTT_USERS_ERROR,
    payload:
    {
      msg: err.response
        ? err.response.data && err.response.data.message
          ? err.response.data.message
          : err.response.statusText
        : null,
      status: err.response ? err.response.status : null,
    },
  });
}
};
//GET  MQTT KEYS USED...
export const getMqttUsedKeys = (params) => async (dispatch) => {
try {
      const res = await axios.get(
    `${env.GET_DEVICEMANAGEMENT_API_URL()}/simplifi/v1/metricsportal/mqttKeysFrequency`,
    {
      params: params,
    }
  )
  dispatch({
    type: GET_MQTT_USED_KEYS_SUCCESS,
    payload: res?.data?.data,
  });
} catch (err) {
  dispatch(setAlert(err?.response?.data?.status ? err?.response?.data?.status : "Something went wrong.", "danger"));
  dispatch({
    type: GET_MQTT_USED_KEYS_ERROR,
    payload:
    {
      msg: err.response
        ? err.response.data && err.response.data.message
          ? err.response.data.message
          : err.response.statusText
        : null,
      status: err.response ? err.response.status : null,
    },
  });
}
};
//GET CONTROLLED  DEVICES present
export const getControllerDevices = (params) => async (dispatch) => {
    //   let date = moment(new Date(today)).format("DD/MM/YYYY");
  try {
        const res = await axios.get(
      `${env.GET_DEVICEMANAGEMENT_API_URL()}/simplifi/v1/metricsportal/usersCount`,
      {
        params: params,
      }
    )
    dispatch({
      type: GET_CONTROLLED_DEVICES_SUCCESS,
      payload: res.data.data,
      // payload: response
    });
  } catch (err) {
    dispatch(setAlert(err?.response?.data?.status ? err?.response?.data?.status : "Something went wrong.", "danger"));
    dispatch({
      type: GET_CONTROLLED_DEVICES_ERROR,
      payload:
      {
        msg: err.response
          ? err.response.data && err.response.data.message
            ? err.response.data.message
            : err.response.statusText
          : null,
        status: err.response ? err.response.status : null,
      },
    });
  }
};


//GET Geo-Location device present
export const getGeoLocationDevices = (params) => async (dispatch) => {

  try {
    const res = await axios.get(
      `${env.GET_DEVICEMANAGEMENT_API_URL()}/simplifi/v1/metricsportal/geographicalDist`,
      {
        params: params,
      }
    )
    dispatch({
      type: GET_LOCATION_DEVICES_SUCCESS,
      payload: res.data.data,

    });

    // points.map((location,idx)=>{
    //   fetch(`http://www.mapquestapi.com/geocoding/v1/reverse?key=GUBjwEHACSqf75m0NgK12ACLzD7g6eaT&location=${location.lat},${location.lng}&includeRoadMetadata=true&includeNearestIntersection=true`)
    //   .then(res => res.json())
    //   .then(
    //     (result) => {
    //       points[idx]["nearBy"] = result?.results[0]?.locations[0]?.street
    //     },
    //     // Note: it's important to handle errors here
    //     // instead of a catch() block so that we don't swallow
    //     // exceptions from actual bugs in components.
    //     (error) => {
    //     }
    //   ).then(()=>{
    //     dispatch({
    //       type: GET_LOCATION_DEVICES_SUCCESS,
    //       // payload: res.data,
    //       payload: points,

    //     });
    //   })
    // })
  } catch (err) {
    dispatch(setAlert(err?.response?.data?.status ? err?.response?.data?.status : "Something went wrong.", "danger"));

    dispatch({
      type: GET_LOCATION_DEVICES_ERROR,
      payload: {
        msg: err.response
          ? err.response.data && err.response.data.message
            ? err.response.data.message
            : err.response.statusText
          : null,
        status: err.response ? err.response.status : null,
      },
    });
  }
};

//get modelwise onboard device  present
export const getModelwiseOnBoardDevices = (params) => async (dispatch) => {
  try {
    let res;
    if(params.devicesCatogary === "AC") {
      let req;
      if(params.type === "4") {
        req = {
          "startDate":params.startDate,
          "endDate":params.endDate
        }
      }
      else{
        req = {
          dateType:params.dataType
        }
      }
      res = await axios.get(
      `${env.GET_DEVICEMANAGEMENT_API_URL()}/simplifi/v1/metricsportal/onboardDevicesCountAc`,
      {
        params: req,
      }
    )
    }else {
      let req;
      if(params.type === "4") {
        req = {
          "category":params.devicesCatogary,
          "startDate":params.startDate,
          "endDate":params.endDate,
          
        }
      }
      else{
        req = {
          "category":params.devicesCatogary,
          "dateType":params.dataType
        }
      }
      res = await axios.get(
        `${env.GET_DEVICEMANAGEMENT_API_URL()}/simplifi/v1/metricsportal/onboardDevicesCountByModal`,
        {
          params: req,
        }
      )
    }
    
    dispatch({
      type: GET_MODEL_WISE_ONBOARDED_DEVICE_SUCCESS,
      payload:params.devicesCatogary === "AC"? res.data.data:res.data.data,

    });
  } catch (err) {
    dispatch(setAlert(err?.response?.data?.status ? err?.response?.data?.status : "Something went wrong.", "danger"));

    dispatch({
      type: GET_MODEL_WISE_ONBOARDED_DEVICE_ERROR,
      payload: {
        msg: err.response
          ? err.response.data && err.response.data.message
            ? err.response.data.message
            : err.response.statusText
          : null,
        status: err.response ? err.response.status : null,
      },
    });
  }
};

//GET hourly active devices.........
export const getHourlyDevices = (params) => async (dispatch) => {
  
  
  try {
    const res = await axios.get(
      `${env.GET_DEVICEMANAGEMENT_API_URL()}/simplifi/v1/metricsportal/activeDevicesCount`,
      {
        params: params,
      }
    )
    dispatch({
      type: GET_HOURLY_ACTIVE_DEVICE_SUCCESS,
      payload: res.data.data,

    });
  } catch (err) {
    dispatch(setAlert(err?.response?.data?.status ? err?.response?.data?.status : "Something went wrong.", "danger"));

    dispatch({
      type: GET_HOURLY_ACTIVE_DEVICE_ERROR,
      payload: {
        msg: err.response
          ? err.response.data && err.response.data.message
            ? err.response.data.message
            : err.response.statusText
          : null,
        status: err.response ? err.response.status : null,
      },
    });
  }
};

//GET onboard Ac's device present
export const getOnBoardAcDevices = (params) => async (dispatch) => {
  try {
    const res = await axios.get(
      `${env.GET_DEVICEMANAGEMENT_API_URL()}/simplifi/v1/metricsportal/onboardDevicesCountAc`,
      {
        params: params,
      }
    )
    dispatch({
      type: GET_ONBOARDED_AC_DEVICE_SUCCESS,
      payload: res.data.data,
      // payload: response,

    });
  } catch (err) {
    dispatch(setAlert(err?.response?.data?.status ? err?.response?.data?.status : "Something went wrong.", "danger"));

    dispatch({
      type: GET_ONBOARDED_AC_DEVICE_ERROR,
      payload: {
        msg: err.response
          ? err.response.data && err.response.data.message
            ? err.response.data.message
            : err.response.statusText
          : null,
        status: err.response ? err.response.status : null,
      },
    });
  }
};






//Reset graph data...............
export const ResetGraphData = () => async (dispatch) => {
  dispatch({
    type: RESET_GRAPH_DATA,
    payload: [],
  });
}

