import axios from "axios";
import { setAlert } from "./alert";
import {
    SEND_NOTIFICATION,
    SEND_NOTIFICATION_SUCCESS,
    SEND_NOTIFICATION_ERROR,
    GET_NOTIFICATION_TASK_REQUEST,
    GET_NOTIFICATION_TASK_SUCCESS,
    GET_NOTIFICATION_TASK_FAILURE,
    CANCEL_NOTIFICATION_TASK_SUCCESS,
    CANCEL_NOTIFICATION_TASK_REQUEST,
    CANCEL_NOTIFICATION_TASK_FAILURE,
    SEARCH_NOTIFICATION_REQUEST,
    SEARCH_NOTIFICATION_SUCCESS,
    SEARCH_NOTIFICATION_FAILURE,
    GET_USER,
    USER_ERROR
} from "./types";
import env from "../env";
import { notificationService } from "../services";
import { notificationListPageSize } from "../components/notificationManagement/notificationConstant";
import customConfig from "../config";

const config = {
    headers: {
        "Content-Type": "application/json",
    }
};

const getConfig = (umV1AuthToken, tenantId) => {
    if (env.GET_UM_MOBILE_USERS_VERSION() == customConfig.umV2Version) {
        return {
            userConfig: {
                headers: {
                    "Content-Type": "application/json"
                }
            },
            url: env.GET_USERMANAGEMENT_API_URL_V2(),
            version: "v2"
        };
    }
    else {
        return {
            userConfig: {
                headers: {
                    "Content-Type": "application/json",
                    "X-Tenant-ID": tenantId,
                    "x-auth-token": umV1AuthToken,
                    "authorization": `Bearer ${umV1AuthToken}`
                }
            },
            version: "v1",
            url: env.GET_USERMANAGEMENT_API_URL()
        };
    }
}

export const createNotificationTask = (requestData) => (dispatch) => {
    dispatch({
        type: SEND_NOTIFICATION,
    });
    axios.post(
        `${env.GET_DEVICEMANAGEMENT_API_URL()}/simplifi/v1/notificationmanager/send`, requestData, config).then((res) => {
            dispatch({
                type: SEND_NOTIFICATION_SUCCESS,
                payload: res.data,
            });
            dispatch(setAlert("Sucessfully Created Notification Task " + res.data.taskId, "success"));
        }).catch((err) => {
            dispatch(setAlert(err?.response?.data?.message ?? "Failed to Create Notification", "danger"));
            dispatch({
                type: SEND_NOTIFICATION_ERROR,
                payload: {},
            });
        });
}

export const getNotificationTask = (taskId, action = "updateGetNotification") => (dispatch) => {
    dispatch({
        type: GET_NOTIFICATION_TASK_REQUEST,
    });
    // notificationService?.getNotificationTask(taskId).

    axios.get(
        `${env.GET_DEVICEMANAGEMENT_API_URL()}/simplifi/v1/notificationmanager/task/${taskId}`, config).then(({ data }) => {
            // console.log("here", res)
            dispatch({
                type: GET_NOTIFICATION_TASK_SUCCESS,
                payload: { data, action, taskId },
            });
        }).catch(
            (err) => {
                console.error(err)
                dispatch(setAlert((err?.response?.data?.message ?? "Failed to fetch Notification Task details") + "- Task ID: " + taskId, "danger"));
                dispatch({
                    type: GET_NOTIFICATION_TASK_FAILURE,
                    payload: { taskId },
                });
            })
}

export const searchNotificationsByUserId = (userId, pgIndex = 1, severity = null) => (dispatch) => {
    dispatch({
        type: SEARCH_NOTIFICATION_REQUEST,
    });
    axios.get(
        `${env.GET_DEVICEMANAGEMENT_API_URL()}/simplifi/v1/notificationmanager/log?userId=${userId}&pgIndex=${pgIndex}&pgSize=${notificationListPageSize}${severity === null ? "" : `&severity=${severity}`}`, config).then(({ data }) => {
            // console.log("here", res)
            dispatch({
                type: SEARCH_NOTIFICATION_SUCCESS,
                payload: data,
            });
        }).catch(
            (err) => {
                console.error(err)
                dispatch(setAlert((err?.response?.data?.message ?? "Failed to fetch Notification Data") + "- User ID: " + userId, "danger"));
                dispatch({
                    type: SEARCH_NOTIFICATION_FAILURE,
                    payload: {},
                });
            })
}

export const searchNotifications = (searchBy, searchStr, pgIndex = 1, tenantId, umV1AuthToken) => (dispatch) => {
    dispatch({
        type: SEARCH_NOTIFICATION_REQUEST,
    });
    if (!/\S+@\S+\.\S+/.test(searchStr) && searchBy === "email") {
        dispatch(setAlert(("Please enter a valid E-mail ID") + ` - User ${searchBy}: ` + searchStr, "danger"));
        dispatch({
            type: SEARCH_NOTIFICATION_FAILURE,
            payload: {},
        });
        return
    }
    else if (!/^\+[1-9]{1}[0-9]{3,14}$/.test(searchStr) && searchBy === "mobile") {
        dispatch(setAlert(("Please enter a valid mobile no") + ` - User ${searchBy}: ` + searchStr, "danger"));
        dispatch({
            type: SEARCH_NOTIFICATION_FAILURE,
            payload: {},
        });
        return
    }
    const { userConfig, url, version } = getConfig(umV1AuthToken, tenantId)
    axios.get(`${url}/simplifi/${version}/userManagement/users/search?${searchBy}=${searchBy === "mobile" ? encodeURIComponent(searchStr) : searchStr}`, userConfig).then(({ data }) => {
        dispatch({
            type: GET_USER,
            payload: data,
        });
        axios.get(
            `${env.GET_DEVICEMANAGEMENT_API_URL()}/simplifi/v1/notificationmanager/log?userId=${data?.userId}&pgIndex=${pgIndex}&pgSize=${notificationListPageSize}`, config).then(({ data }) => {
                dispatch({
                    type: SEARCH_NOTIFICATION_SUCCESS,
                    payload: data,
                });
            }).catch(
                (err) => {
                    dispatch(setAlert((err?.response?.data?.message ?? "Failed to fetch Notification Data") + `- User ${searchBy}: ` + searchStr, "danger"));
                    dispatch({
                        type: SEARCH_NOTIFICATION_FAILURE,
                        payload: {},
                    });
                })
    }).catch(
        (err) => {
            dispatch(setAlert((err?.response?.data?.message ?? "Unable to find user details") + `- User ${searchBy}: ` + searchStr, "danger"));
            dispatch({
                type: USER_ERROR,
                payload: err,
            });
            dispatch({
                type: SEARCH_NOTIFICATION_FAILURE,
                payload: {},
            });
        })
}


export const cancelNotificationTask = (taskId, cb = () => { }) => (dispatch) => {
    dispatch({
        type: CANCEL_NOTIFICATION_TASK_REQUEST,
    });

    axios.delete(
        `${env.GET_DEVICEMANAGEMENT_API_URL()}/simplifi/v1/notificationmanager/task/${taskId}`, config).then((res) => {
            dispatch({
                type: CANCEL_NOTIFICATION_TASK_SUCCESS,
                payload: res.data,
            });
            cb();
        }).catch((err) => {
            dispatch(setAlert((err?.response?.data?.message ?? "Failed to cancel Notification Task details") + "- Task ID: " + taskId, "danger"));
            dispatch({
                type: CANCEL_NOTIFICATION_TASK_FAILURE,
                payload: {},
            });
        });
}


