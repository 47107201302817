import React, { Fragment } from "react";
import {useHistory ,NavLink} from "react-router-dom";
import Spinner from "../layout/Spinner";

const HomeItem = ({
  home: { home, homeId, primaryUserHomeIds, loading },
  homeData
}) => {
  let history = useHistory();
  if (!home) {
    return <Spinner />;
  }

  return loading || home === null ? (
    <Spinner />
  ) : (
    <Fragment>
      <div className="mb-0">
     
        <div className="col s12">
          {homeData && homeData.length > 0 ? (
            <ul className="tabs nested">
              {homeData.map(home => (
                <li key={home.homeId} className="tab">
                  <NavLink to={`/report/${home.homeId}`} activeClassName="selectedLink"  onClick={()=>history.push(`/report/${home.homeId}`)}>
                    {" "}
                    {home.homeName || home.homeId}{" "}
                  </NavLink>
                </li>
              ))}
            </ul>
          ) : (
            <p className="mtopBot10 is-danger">
              You have not yet setup a home, please add some information
            </p>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default HomeItem;
