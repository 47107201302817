import { GET_HOME, HOME_ERROR } from "../action/types";

const initialState = {
  home: null,
  loading: true,
  error: {}
};


export default function(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_HOME:
      return {
        ...state,
        home: payload,
        loading: false
      };
    case HOME_ERROR:
      return {
        ...state,
        home: payload,
        loading: false
      };

    default:
      return state;
  }
}
