import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import M from "materialize-css/dist/js/materialize.min.js";
import FotaJobForm from "./FotaJobForm";
import Popup from "../layout/Popup";
import customConfig from "../../config";
import { getAllFotaJobs, deleteFotaJob } from "../../action/fotaJobs";
import Spinner from "../layout/Spinner";
import Moment from "react-moment";
import Alert from "../layout/Alert";

const FotaJobs = ({
  getAllFotaJobs,
  deleteFotaJob,
  wrapperClass = "right-content-wraper",
  fotaJobsData: {
    fotaJobs,
    fotaJobsError,
    fotaJobsLoading,
    fotaJobDeleteSucces,
  },
}) => {
  const [showSelected, setShowSelected] = useState(false);
  const [selectedJob, setSelectedJob] = useState("");

  useEffect(() => {
    M.AutoInit();
    getAllFotaJobs();
  }, []);

  useEffect(() => {
    if (fotaJobDeleteSucces) {
      getAllFotaJobs();
    }
  }, [fotaJobDeleteSucces]);

  const handleFotaJobForm = (job) => {
    setSelectedJob(job);
    setShowSelected(true);
  };

  const handleJobDelete = (job) => {
    if (window.confirm(customConfig.confirmFotaJobDelete)) {
      deleteFotaJob(job.jobId);
      getAllFotaJobs();
    }
  };

  const handleSelectedPopupClose = () => {
    setShowSelected(false);
    getAllFotaJobs();
  };

  const getHour = (job) => {
    let hour = job.frequency.hour;
    if (parseInt(hour) === 0) {
      hour = "12";
    } else if (parseInt(hour) > 12) {
      hour = (parseInt(hour) - 12).toString();
    }
    return hour.length < 2 ? "0" + hour : hour;
  };

  if (fotaJobsLoading) {
    return (
      <div style={{ position: "absolute", top: "50%", left: "50%" }}>
        {" "}
        <Spinner />{" "}
      </div>
    );
  }

  return (
    <Fragment>
      <div className={wrapperClass}>
        <div className="container">
          <Alert />
          <div className="page-title d-flex justify-content-between">
            <h3>Manage MQTT FOTA Jobs</h3>
            <button
              className="btn btn-style-3 orange-bg sm  font-400 min-140 d-flex"
              onClick={() => handleFotaJobForm()}
            >
              <i className="material-icons">add</i> <span></span>Add new Job
            </button>
          </div>
          {showSelected && (
            <Popup
              visible={showSelected}
              notifyPopupClose={handleSelectedPopupClose}
            >
              <FotaJobForm
                job={selectedJob}
                notifySuccess={handleSelectedPopupClose}
              ></FotaJobForm>
            </Popup>
          )}
          <div className="content-block bottom-shadow">
            <div className="row">
              <div className="s12 p-0 col">
                {fotaJobs && fotaJobs.length > 0 ? (
                  <div className="table-wrapper">
                    <table width="100%" align="left" className="table style-4">
                      <thead>
                        <tr>
                          <th>Job Name</th>
                          <th>End Date</th>
                          <th>Days of Week</th>
                          <th>Time</th>
                          <th>Action</th>
                        </tr>
                      </thead>

                      <tbody>
                        {fotaJobs.map((job, i) => (
                          <tr key={`job_${i}`}>
                            <td>{job.name}</td>
                            <td>
                              <Moment format="ll" date={job.endDate.$date} />
                            </td>
                            <td>{job.frequency.day_of_week}</td>
                            <td>{`${getHour(job)}:${
                              job.frequency.minute.length < 2
                                ? "0" + job.frequency.minute
                                : job.frequency.minute
                            } ${
                              parseInt(job.frequency.hour) > 11 ? "PM" : "AM"
                            }`}</td>
                            <td>
                              <button
                                className="lnkBtn edit-icn"
                                onClick={() => handleFotaJobForm(job)}
                              >
                                <i className="material-icons">create</i>
                              </button>{" "}
                              <button
                                className="lnkBtn del-icn"
                                onClick={() => handleJobDelete(job)}
                              >
                                <i className="material-icons">delete</i>
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <div className="boxes">
                    <div className="info error content-reset">
                      <div className="text-box">
                        <i className="no-data-icon"></i>
                        <label>{customConfig.noFotaJobMsg} </label>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  fotaJobsData: state.fotaJobs,
});

export default connect(mapStateToProps, { getAllFotaJobs, deleteFotaJob })(
  FotaJobs
);
