import React, {useEffect} from "react";
import { connect } from "react-redux";
import {getOnBoardDevices} from '../../../action/metrics';
import {catogariesSeriesData, seriesXaxisData, filterArr, totalFilterCount} from './GraphParameter';
import Graph from "./Graph";

function OnboardOffboardAGM({getOnBoardDevices, devicesCatogaryFilter,onBordedDevices,graphType , type, setDeviceCount}) {
  
    useEffect(()=>{
    if(type !== "4") {
    const que= {
      "dateType":type === "1" ? "daily" : type === "2" ? "weekly" : "monthly",
      // "X-Tenant-ID":'panasonic'
    }
    getOnBoardDevices(que)}
},[getOnBoardDevices,type])
    const intervalData =  onBordedDevices.length> 0 ? seriesXaxisData(onBordedDevices, graphType) : []
    let arr = onBordedDevices.length> 0 ? catogariesSeriesData(onBordedDevices) : []
    const filterArray = filterArr(arr,devicesCatogaryFilter)
    const totalCount = totalFilterCount(filterArray)
    useEffect(()=>{
      setDeviceCount(totalCount)
    },[totalCount])
  return (
    <div>
      <div className="chart_view">
        <Graph
          text={" Devices"}
          x_data={intervalData}
          title={"Number Of Onboarded Devices "}
          y_data={filterArray}
          stacked = {true}
          color = {["#a1469e", "#83c7d5", "#02a699","#9E7777","#fee251"]}
          x_title = {"Onboarded Date"}
        />
      </div>
    </div>
  );
}
const mapStateToProps = (state) =>{
  return{
    onBordedDevices:state.metrics.onBordedDevices     
  }
}

export default connect(mapStateToProps, { getOnBoardDevices })(OnboardOffboardAGM);

