import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Dashboard from "../../components/dashboard/DashBoard";
import PrivateRoute from "../../components/routing/PrivateRoute";
import UserInfoPage from "./pages/UserInfoPage";
import NavBar from "./components/NavBar";
import { connect } from "react-redux";
import "../../round.css";
import "../../color.css";
import "./layout2.css";
// import DeviceInfo from "./pages/DeviceInfo";
import SearchDevice from "../../components/device/SearchDevice";
// import DeviceModel from "./pages/DeviceModel";
import SearchModel from "../../components/device/SearchModel";
import FotaJobs from "../../components/dashboard/FotaJobs";
import StatisticsPage from "./pages/StatisticsPage";
import ProductMetricsPage from "./pages/ProductMetricsPage";
import Statistics from "../../components/dashboard/Statistics";
import ProductMetrics from "../../components/dashboard/ProductMetrics";
import ManageMqttFotaPage from "./pages/ManageMqttFotaPage";
import FirmwareInfo from "../report/FirmwareInfo";
import FeedbackPage from "./pages/FeedbackPage";
import MetricsCharts from "./pages/MetricsCharts";
import ManufacturedDevicesPage from "./pages/ManufacturedDevicesPage";
import AddNewDeviceModel from "../../components/device/AddNewDeviceModel"
import IDUEEPROMConfig from "../../components/device/IDUEEPROMConfig/IDUConfig"
import customConfig from "../../config";
import DeviceDealerMgmt from "../../components/deviceDealerMgmt/deviceDealerMgmt"


import QRCode from "../qrcode/qrcode";
import DeviceFirmware from "./../device/DeviceFirmware/Firmware"
import LineTestUserSearchDevice from './../LineTestUser/SearchDevice'
import NotificationManagement from "../notificationManagement/index";

const Routes = ({ isAuthenticated, userLevel, userRoles }) => {
  return (
    <div className="new-layout-wraper">
      {isAuthenticated ? (
        <div>
          <NavBar />
        </div>
      ) : null}

      <Switch>
        {
          (userRoles && userRoles.includes(customConfig.roleLineTestUser)) ?
            <PrivateRoute path="/dashboard" component={LineTestUserSearchDevice} />
            :
            (userLevel == 0 && (userRoles && userRoles.includes(customConfig.roleFactoryAdmin))) ||
              userRoles && userRoles.includes(customConfig.roleFieldOperator) ?
              <PrivateRoute
                exact
                path="/dashboard"
                component={({ ...rest }) => (
                  <SearchModel wrapperClass="content-wrapper " {...rest} />
                )}
              />
              :
              <PrivateRoute path="/dashboard" component={UserInfoPage} />
        }

        <PrivateRoute
          exact
          path="/searchDevice"
          component={() => <SearchDevice wrapperClass="content-wrapper" />}
        />
        <PrivateRoute
          exact
          path="/deviceDetail"
          component={({ ...rest }) => (
            <SearchModel wrapperClass="content-wrapper " {...rest} />
          )}
        />
        <PrivateRoute
          exact
          path="/deviceDetail/:modelNum"
          component={({ ...rest }) => (
            <SearchModel wrapperClass="content-wrapper " {...rest} />
          )}
        />
        <PrivateRoute
          exact
          path="/statistics"
          component={({ ...rest }) => (
            <Statistics wrapperClass="content-wrapper " {...rest} />
          )}
        />
        <PrivateRoute
          exact
          path="/productMetrics"
          component={({ ...rest }) => (
            <ProductMetrics wrapperClass="content-wrapper " {...rest} />
          )}
        />
        <PrivateRoute
          exact
          path="/fotaJobs"
          component={({ ...rest }) => (
            <FotaJobs wrapperClass="content-wrapper " {...rest} />
          )}
        />
        <PrivateRoute
          exact
          path="/manufacturedDevices"
          component={({ ...rest }) => (
            <ManufacturedDevicesPage wrapperClass="content-wrapper " {...rest} />
          )}
        />
        <PrivateRoute
          exact
          path="/firmwareInfo"
          component={({ ...rest }) => (
            <FirmwareInfo wrapperClass="content-wrapper " {...rest} />
          )}
        />
        <PrivateRoute
          exact
          path="/feedback"
          component={({ ...rest }) => (
            <FeedbackPage wrapperClass="content-wrapper " {...rest} />
          )}
        />
        <PrivateRoute
          exact
          path="/metricsChart"
          component={({ ...rest }) => (
            <MetricsCharts wrapperClass="content-wrapper " {...rest} />
          )}
        />
        <PrivateRoute
          exact
          path="/addNewDeviceModel"
          component={({ ...rest }) => (
            <AddNewDeviceModel wrapperClass="content-wrapper " {...rest} />
          )}
        />
        <PrivateRoute
          exact
          path="/idueepromConfig"
          component={({ ...rest }) => (
            <IDUEEPROMConfig wrapperClass="content-wrapper " {...rest} />
          )} />


        <PrivateRoute
          exact
          path="/qrcode"
          component={({ ...rest }) => (
            <QRCode wrapperClass="content-wrapper " {...rest} />
          )}
        />
        <PrivateRoute
          exact
          path="/deviceFirmware"
          component={({ ...rest }) => (
            <DeviceFirmware wrapperClass="content-wrapper " {...rest} />
          )}
        />

        <PrivateRoute
          exact
          path="/deviceDealer"
          component={({ ...rest }) => (
            <DeviceDealerMgmt wrapperClass="content-wrapper " {...rest} />
          )}
        />

        <PrivateRoute
          exact
          path="/notification"
          component={({ ...rest }) => (
            <NotificationManagement wrapperClass="content-wrapper " {...rest} />
          )}
        />
      </Switch>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  userRoles: state.auth.userRoles,
  userLevel: state.auth.userLevel,
});
export default connect(mapStateToProps, {})(Routes);
