import React from "react";

function MyMarker({ text, tooltip, $hover, device_count, user_count, nearBy }) {
  const handleClick = () => {
    console.log(`You clicked on ${tooltip}`);
  };
  return (
    <div onClick={handleClick}>
      <i class="fa fa-map-marker marker_detail"></i>
      {$hover && (
      <div className="detail_value">
        <div className = "d-flex">
        <div className = "detail_location"> {user_count} User </div>
        <div className = "detail_location">{device_count} Devices </div>{" "}
        </div>
        
        {/* <div className = "detail_location w-150">{nearBy.nearBy} </div>{" "} */}
      </div>
           )}
    </div>
  );
}

export default MyMarker;
