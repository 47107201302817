import React from "react";
import { connect } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import { setAlert } from "../../action/alert";
import Alert from "../layout/Alert";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import CreateQRCode from "./createQRCode";
import FetchQRCode from "./fetchQRCode";
import QRCodeFilter from "./qrCodeCountFilter";

const useStyles = makeStyles({
    selectInput: {
        height: "3rem",
        width: "100%"
    },
    selectValues: {
        width: "100%",
    },
    activeTab: {
        border: '1px solid #bdbdbd',
        backgroundColor: '#e0e0e0',
        textTransform: 'capitalize'
    },
    nonActiveTab: {
        border: 'none',
        backgroundColor: 'none',
        textTransform: 'capitalize'
    },
});

const headers = {
    productSerialNumber: "Product Serial Number",
    status: "QR Code Print Status",
    reasonOfFailure: "Reason of Failure"
};

const QRCode = ({
    wrapperClass = "right-content-wraper",
    allowedDeviceCategories,
    allowedCompanyBrand,
    setAlert
}) => {
    const classes = useStyles()
    const [activeTab, setActiveTab] = React.useState(0)

    return (
        <div className={wrapperClass}>
            <div className="row">
                <Tabs
                    value={activeTab}
                    onChange={(e, newValue) => {
                        setActiveTab(newValue)
                    }}
                    TabIndicatorProps={{
                        style: {
                            backgroundColor: "#bdbdbd"
                        }
                    }}
                    style={{ marginTop: '20px' }}
                >
                    <Tab label="Create and Print QR Code" className={activeTab == 0 ? classes.activeTab : classes.nonActiveTab} />
                    <Tab label="Fetch and Print QR Code" className={activeTab == 1 ? classes.activeTab : classes.nonActiveTab} />
                    <Tab label="QR Code Count" className={activeTab == 2 ? classes.activeTab : classes.nonActiveTab} />
                </Tabs>
                <div hidden={activeTab != 0}>
                    <CreateQRCode
                        allowedDeviceCategories={allowedDeviceCategories}
                        allowedCompanyBrand={allowedCompanyBrand}
                        setAlert={setAlert}
                    ></CreateQRCode>
                </div>
                <div hidden={activeTab != 1}>
                    <FetchQRCode
                        allowedDeviceCategories={allowedDeviceCategories}
                        allowedCompanyBrand={allowedCompanyBrand}
                        setAlert={setAlert}
                    ></FetchQRCode>
                </div>
                <div hidden={activeTab != 2}>
                    <QRCodeFilter
                        setAlert={setAlert}
                    ></QRCodeFilter>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    allowedDeviceCategories: state.auth.allowedDeviceCategories,
    allowedCompanyBrand: state.auth.allowCompanyBrand,
});

const mapDispatchToProps = (dispatch) => ({
    setAlert: (message, status) => dispatch(setAlert(message, status))
});

export default connect(mapStateToProps, mapDispatchToProps)(QRCode);