import React, { useState, Fragment, useEffect } from "react";
import { connect } from "react-redux";
import { addvcModel, updvcModel } from "../../action/addvcModel";
import { setAlert } from "../../action/alert";
import Alert from '../layout/Alert';
import { Link } from "react-router-dom";
import M from "materialize-css/dist/js/materialize.min.js";

import UseForm from "./useDeviceForm";
import validate from "./DeviceFormValidationRules";

const GetdModel = ({ setAlert, addvcModel, updvcModel, ModelData }) => {
  
  useEffect(() => {
    M.AutoInit();
  },[]);

  const { handleSubmit, handleChange, handleBlur, values, errors } = UseForm(submitForm,validate,ModelData);
 
 function submitForm() {
    onSubmit();
  }

  const onSubmit = ()=> {
    let attributeValue = [{
      key: values.key,
      values: [values.values]
    }];
    let attributeRange = [{
      key: values.key,
      range: {
        min: [
          {
            unit: values.minUnit,
            value: Number(values.minValue)
          }
        ],
        max: [
          {
            unit: values.maxUnit,
            value: Number(values.maxValue)
          }
        ],
        increment: [
          {
            unit: values.incUnit,
            value: Number(values.incValue)
          }
        ]
      }
    }];

    let obj = [{
      capability: values.capability,
      attributes: (values.capability === 'WMTemperature' && values.key === 'temperature') || (values.capability === 'SleepProfile' && values.key === 'duration') || (values.capability === 'SleepProfile' && values.key === 'temperature') ? attributeRange : attributeValue
    }];


    let setControlCapabilities = obj;

    if (ModelData && Object.keys(ModelData).length > 0) {
      // console.log('-----update')
      updvcModel(
        values.modelId,
        values.modelNumber,
        values.modelName,
        values.category,
        Number(values.launchDate),
        values.isActive,
        values.manufacturer,
        values.manufacturerAddress,
        Number(values.version),
        values.helpDocUrl,
        values.productManagerId,
        values.tonnage,
        setControlCapabilities);
    } else {
      console.log('----add')
      addvcModel(
        values.modelId,
        values.modelNumber,
        values.modelName,
        values.category,
        Number(values.launchDate),
        values.isActive,
        values.manufacturer,
        values.manufacturerAddress,
        Number(values.version),
        values.helpDocUrl,
        values.productManagerId,
        values.tonnage,
        setControlCapabilities
      );
    }
  };

  return (
    <Fragment>
      <div className="right-content-wraper">
        <div className="container">
          <div className="device-detail-wrap device-model">
            <Alert />
            <div className="row">
              <div className="s6 p-0 col">
                <div className="page-title pt-10">
                  <h3>Device Model</h3>
                </div>
              </div>

              <div className="s6 p-0 col">
                <div className="right">
                  <Link to="/deviceDetail" className="waves-effect waves-light btn btn-style-2 reset lg"> Get Data </Link>
                </div>
              </div>
            </div>

            <form className="form" onSubmit={handleSubmit} noValidate>
              <div className="row mb-0">
                <div className="s12 p-0 col">
                  <div className="content-block position-relative">
                    <div className="p-15">
                      <div className="block-title style-2">
                        <h3>Device Information</h3>
                      </div>
                      <div className="form-upload reset-form-field">

                        <div className="row mb-0">
                          <div className="s6 col">
                            <div className="row">
                              <div className="input-field col s12">
                                <span className="require">*</span>
                                <label className="field-name active">Model Id</label>
                                <input
                                  placeholder="Enter Model Id"
                                  type="text"
                                  name="modelId"
                                  className="validate style-fix"
                                  value={values.modelId || ""}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                {errors.modelId && (<p className="error-mes">{errors.modelId}</p>)}
                               
                              </div>
                            </div>
                          </div>
                          <div className="s6 col">
                            <div className="row">
                              <div className="input-field col s12">
                              <span className="require">*</span>
                                <label className="field-name active">Model No</label>
                                <input
                                  placeholder="Enter Model Number"
                                  type="text"
                                  name="modelNumber"
                                  className="validate style-fix"
                                  value={values.modelNumber || ""}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                {errors.modelNumber && (<p className="error-mes">{errors.modelNumber}</p>)}
                              </div>
                            </div>
                          </div>
                           <div className="s6 col">
                            <div className="row">
                              <div className="input-field col s12">
                              <span className="require">*</span>
                                <label className="field-name active">Model Name</label>
                                <input
                                  placeholder="Enter Model Name"
                                  type="text"
                                  name="modelName"
                                  className="validate style-fix"
                                  value={values.modelName || ""}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                  {errors.modelName && (<p className="error-mes">{errors.modelName}</p>)}
                              </div>
                            </div>
                          </div>
                         
                          <div className="s6 col">
                            <div className="row">
                              <div className="input-field col s12">
                              <span className="require">*</span>
                                <label className="field-name active">Category</label>
                                <input
                                  placeholder="Enter Category"
                                  type="text"
                                  name="category"
                                  className="validate style-fix"
                                  value={values.category || ""}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                {errors.category && (<p className="error-mes">{errors.category}</p>)}
                              </div>
                            </div>
                          </div>
                           
                          <div className="s6 col">
                            <div className="row">
                              <div className="input-field col s12">
                              <span className="require">*</span>
                                <label className="field-name active">Launch Date</label>
                                <input
                                  placeholder="Enter Launch Date"
                                  type="text"
                                  name="launchDate"
                                  className="validate style-fix"
                                  value={values.launchDate || ""}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                 {errors.launchDate && (<p className="error-mes">{errors.launchDate}</p>)}
                              </div>
                            </div>
                          </div>
                          
                          <div className="s6 col">
                            <div className="row">
                              <div className="input-field control-capabilities col s12">
                                <label className="field-name active">Is Active</label>
                                <select className="custom-select select-reset" name='isActive' value={values.isActive || "True"} onChange={handleChange}>
                                  {/* <option value="">Select Option</option> */}
                                  <option value="True">Yes</option>
                                  <option value="False">No</option>

                                </select>
                              </div>
                            </div>
                          </div> 


                          <div className="s6 col">
                            <div className="row">
                              <div className="input-field col s12">
                              <span className="require">*</span>
                                <label className="field-name active">Manufacturer Name</label>
                                <input
                                  placeholder="Enter Manufacturer Name"
                                  type="text"
                                  name="manufacturer"
                                  className="validate style-fix"
                                  value={values.manufacturer || ""}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                 {errors.manufacturer && (<p className="error-mes">{errors.manufacturer}</p>)}
                              </div>
                            </div>
                          </div>
                           
                          <div className="s6 col">
                            <div className="row">
                              <div className="input-field col s12">
                              <span className="require">*</span>
                                <label className="field-name active">Manufacturer Address</label>
                                <input
                                  placeholder="Enter Manufacturer Address"
                                  type="text"
                                  name="manufacturerAddress"
                                  className="validate style-fix"
                                  value={values.manufacturerAddress || ""}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                {errors.manufacturerAddress && (<p className="error-mes">{errors.manufacturerAddress}</p>)}
                              </div>
                            </div>
                          </div>
                        
                          <div className="s6 col">
                            <div className="row">
                              <div className="input-field col s12">
                              <span className="require">*</span>
                                <label className="field-name active">Product Manager Id</label>
                                <input
                                  placeholder="Enter Manager Id"
                                  type="text"
                                  name="productManagerId"
                                  className="validate style-fix"
                                  value={values.productManagerId || ""}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                  {errors.productManagerId && (<p className="error-mes">{errors.productManagerId}</p>)}
                              </div>
                            </div>
                          </div>
 
                          <div className="s6 col">
                            <div className="row">
                              <div className="input-field col s12">
                              <span className="require">*</span>
                                <label className="field-name active">Tonnage</label>
                                <input
                                  placeholder="Enter Tonnage Number"
                                  type="text"
                                  name="tonnage"
                                  className="validate style-fix"
                                  value={values.tonnage || ""}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                {errors.tonnage && (<p className="error-mes">{errors.tonnage}</p>)}
                              </div>
                            </div>
                          </div>

                          <div className="s6 col">
                            <div className="row mb-0">
                              <div className="input-field col s12">
                              <span className="require">*</span>
                                <label className="field-name active">Version</label>
                                <input
                                  placeholder="Enter Version"
                                  type="text"
                                  name="version"
                                  className="validate style-fix"
                                  value={values.version || ""}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                {errors.version && (<p className="error-mes">{errors.version}</p>)}
                              </div>
                            </div>
                          </div>
                           
                          <div className="s6 col">
                            <div className="row mb-0">
                              <div className="input-field col s12">
                              <span className="require">*</span>
                                <label className="field-name active">Help Doc URL</label>
                                <input
                                  placeholder="Enter URL"
                                  type="text"
                                  name="helpDocUrl"
                                  className="validate style-fix"
                                  value={values.helpDocUrl || ""}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                {errors.helpDocUrl && (<p className="error-mes">{errors.helpDocUrl}</p>)}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="content-block control-capabilities position-relative">
                    <div className="p-15">
                      <div className="block-title style-2 mbot10"><h3>Control Capabilities</h3></div>
                      <div className="form reset-form-field">
                        <div className="row mb-0">
                          <div className="col s12 p-0">
                            <div className="row mb-0">
                              <div className="add-section d-flex">
                                <div className="s4 col">
                                  <div className="row mb-0">
                                    <div className="input-field col s12 mb-0">
                                      <label className="field-name active">Capability :</label>

                                      <select className="custom-select select-reset" name='capability'
                                       value={values.capability || ""}
                                       onChange={handleChange}
                                        >
                                        <option value="">Select Capability Mode</option>
                                        <option value="ACMode">ACMode</option>
                                        <option value="WMMode">WMMode</option>
                                        <option value="WMPowerStatus">WMPowerStatus</option>
                                        <option value="WMTemperature">WMTemperature</option>
                                        <option value="SleepProfile">SleepProfile</option>
                                      </select>


                                    </div>
                                  </div>
                                </div>
                                <div className="s4 col">
                                  <div className="row mb-0">
                                    <div className="input-field col s12">
                                      <label className="field-name active"> Attributes :</label>
                                      <select className="custom-select" name='key' 
                                      value={values.key || ""}
                                      onChange={handleChange}
                                      >
                                        <option value="">Select Key</option>
                                        <option value="ac_mode">ac_mode</option>
                                        <option value="mode">mode</option>
                                        <option value="power_status">power_status</option>
                                        <option value="temperature">temperature</option>
                                        <option value="duration">duration</option>
                                      </select>

                                    </div>
                                  </div>
                                </div>
                                <div className="s4 col">
                                  <div className="row mb-0">
                                    <div className={"input-field" + ((values.capability === 'WMTemperature' && values.key === 'temperature') || (values.capability === 'SleepProfile' && values.key === 'duration') || (values.capability === 'SleepProfile' && values.key === 'temperature') ? ' hidden' : ' show')}>
                                      <input placeholder="Values"
                                        type="text"
                                        name="values"
                                        className="validate style-fix"
                                        value={values.values || ""}
                                        onChange={handleChange}
                                        />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                          </div>

                        </div>
                        <div className="range">
                          <div className="row mb-0">
                            <div className={"s12 col" + ((values.capability === 'WMTemperature' && values.key === 'temperature') || (values.capability === 'SleepProfile' && values.key === 'duration') || (values.capability === 'SleepProfile' && values.key === 'temperature') ? ' show' : ' hidden')}>
                              <div className="head-style-2">
                                <h4>Range :</h4>
                              </div>
                            </div>

                            <div className={"row" + ((values.capability === 'WMTemperature' && values.key === 'temperature') || (values.capability === 'SleepProfile' && values.key === 'duration') || (values.capability === 'SleepProfile' && values.key === 'temperature') ? ' show' : ' hidden')}>
                              <div className="col s12">

                                <div className="s6 col">
                                  <div className="row mb-0">
                                    <div className="input-field col s12">
                                      <label className="field-name active"> Min Unit :</label>
                                      <select className="custom-select" name='minUnit'
                                       value={values.minUnit || ""}
                                       onChange={handleChange}
                                       >
                                        <option value="">Select unit</option>
                                        <option value="celcius">celcius</option>
                                        <option value="fahrenheit">fahrenheit</option>
                                      </select>


                                    </div>
                                  </div>
                                </div>
                                <div className="s6 col">
                                  <div className="row">
                                    <div className="input-field col s12">
                                      <input
                                        placeholder="Value"
                                        type="text"
                                        name="minValue"
                                        className="validate style-fix"
                                        value={values.minValue || ""}
                                        onChange={handleChange}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col s12">

                                <div className="s6 col">
                                  <div className="row">
                                    <div className="input-field col s12">
                                      <label className="field-name active">Max Unit :</label>
                                      <select className="custom-select" name='maxUnit' 
                                       value={values.maxUnit || ""}
                                       onChange={handleChange}
                                      >
                                        <option value="">Select unit</option>
                                        <option value="celcius">celcius</option>
                                        <option value="fahrenheit">fahrenheit</option>
                                      </select>

                                    </div>
                                  </div>
                                </div>
                                <div className="s6 col">
                                  <div className="row">
                                    <div className="input-field col s12">
                                      <input
                                        placeholder="Value"
                                        type="text"
                                        name="maxValue"
                                        className="validate style-fix"
                                        value={values.maxValue || ""}
                                        onChange={handleChange}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col s12">
                                <div className="s6 col">
                                  <div className="row">
                                    <div className="input-field col s12">
                                      <label className="field-name active">Increment Unit :</label>
                                      <select className="custom-select" name='incUnit'
                                        value={values.incUnit || ""}
                                        onChange={handleChange}
                                       >
                                        <option value="">Select Unit</option>
                                        <option value="celcius">celcius</option>
                                        <option value="fahrenheit">fahrenheit</option>
                                      </select>


                                    </div>
                                  </div>
                                </div>
                                <div className="s6 col">
                                  <div className="row">
                                    <div className="input-field col s12">
                                      <input
                                        placeholder="Value"
                                        type="text"
                                        name="incValue"
                                        className="validate style-fix"
                                        value={values.incValue || ""}
                                        onChange={handleChange}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Temporarily hiding button.
                                              TODO: To be enabled after new role is supported */}
                  <div className="s12 center-align col">
                    <button className="waves-effect waves-light btn-style-2 min-180 orange-bg btn theme-bg white-text reset lg">
                                {ModelData &&   Object.keys(ModelData).length > 0 ? 'Update':'Submit'}
                            </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = state => ({
  ModelData: state.getModel.ModelDatas
});

export default connect(mapStateToProps,{ setAlert, addvcModel, updvcModel })(GetdModel);