export const notificationConstants = {
    oneTimeScheduleType: "scheduleOneTime",
    repeatScheduleType: "scheduleTypeRepeat",

    iosPlatform: "iosPlatform",
    androidPlatform: "androidPlatform",
    notifySeverityInfo: "info",
    notifySeverityAlert: "alert",
    notifySeverityRecommendation: "recommendation",
    notifySeverityPromotion: "promotion",

}
export const notificationChannelTypes = {
    pushNotification: { value: "push", label: "Push Notification" },
    // smsNotification: { value: "sms", label: "SMS Notification" },
    // emailNotification: { value: "email", label: "E-mail Notification" },
}

export const notificationValidations = {
    email: {
        subject: { maxLength: 200 },
        html: { maxLength: 200 },
        text: { maxLength: 200 },
    },
    push: {
        body: { maxLength: 500 }
    },
    sms: {
        body: { maxLength: 200 }
    }
}

export const notificationDetailsRefreshRate = 10000;

export const notificationStatusHeaders = {
    taskId: { label: "Task Id" },
    expectedUserCount: { label: "Total User Count" },
    userCount: { label: "Success User Count" },
    // successCount: "Success Count",
    failureCount: { label: "Failure Count" },
    taskStatus: { label: "Task Status" },
    action: { label: "Actions", style: { display: 'flex', justifyContent: "flex-end" } }
};

export const notificationStatusMoreHeaders = {
    notificationType: {  label: "Type" },
    severity: { label: "Severity" },
    createdAt: {  label: "Created At" },
    expiryDate: { label: "Expiry" },
};

export const notificationListPageSize = 20;

export const notificationCountryMapping = {
    IN: "ind",
    BD: "BGD",
    LK: "LKA",
    SG: "SGP",
    TH: "THA",
    JP: "JP",
    NP: "NP"
}