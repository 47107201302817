import store from "../store";

export const API_URL = {
    // To be used when tenant management API returns true values
    USER_MANAGEMENT: store.getState().tenant.tenantInfo.auth.baseUrl,
    DEVICE_MANAGEMENT: store.getState().tenant.tenantInfo.app.baseUrl,
    FOTA: store.getState().tenant.tenantInfo.app.baseUrl,
    CLIENT_ID: store.getState().tenant.tenantInfo.auth.webClientId,
};


export const STATUS_CODES = {
    NO_CONTENT: 204,
    UNAUTHORIZED: 401
}