import {
  GET_DEVICE,
  DEVICE_ERROR,
  GET_MODEL,
  MODEL_ERROR,
  CLEAR_DATA,
  UPDATE_DEVICE,
  GET_DEVICE_MODELS_METADATA
} from "../action/types";

const initialState = {
  loading: false,
  error: null,
  getModel: {},
  ModelData: {},
  updvcModel: {},
  deviceModelsMetadata: {}
};

export default function(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_DEVICE:
      return {
        ...state,
        getModel: payload,
        loading: false
      };
    case DEVICE_ERROR:
      return {
        ...state,
        error: payload,
        loading: false
      };
    case CLEAR_DATA:
      return {
        ...state,
        getModel: null,
        updvcModel: null,
        loading: false
      };
    case GET_MODEL:
      return {
        ...state,
        ModelData: payload,
        loading: false
      };
    case UPDATE_DEVICE:
      return {
        ...state,
        updvcModel: payload,
        loading: false
      };
    case MODEL_ERROR:
      return {
        ...state,
        error: payload,
        loading: false
      };
    case GET_DEVICE_MODELS_METADATA:{
      return {
        ...state,
        deviceModelsMetadata: payload
      }
    }
    default:
      return state;
  }
}
