import axios from "axios";
import env from "../env";

const static_feedback_data = [
  {
    type: "ONBOARDING_FAILURE",

    deviceBrand: "SANYO",

    deviceCategory: "AC",

    deviceId: "ae124fd45312-19",

    errorCode: "193",

    appVersion: "0.1.1",

    mobileOSVersion: "10",

    mobileName: "RealMePro",

    mobileModel: "GM101",

    mobileOSBuildNumber: "1.2.324",

    description:
      "Description Description  Description Description Description Description Description Description Description Description Description Description",

    feedbackId: "1f95d918-ae26-485b-b02d-6159cc0cb5a8",

    userId: "8502d8fe-85f6-461c-bba0-2fe2f9310f5e",

    tenant: "panasonic",

    mobile: "+919821761932",

    status: "NEW",

    createdAt: "2021-06-15T13:20:19.823+0000",

    updatedAt: "2021-06-15T13:20:19.823+0000",
  },

  {
    type: "ONBOARDING_FAILURE",

    deviceBrand: "PANASONIC",

    deviceCategory: "REFRIGERATOR",

    deviceId: "ae124fd45312-19",

    errorCode: "193",

    appVersion: "0.1.1",

    mobileOSVersion: "10",

    mobileName: "RealMePro",

    mobileModel: "GM101",

    mobileOSBuildNumber: "1.2.324",

    description: "Description",

    feedbackId: "434ff406-9605-4b3d-879e-a1160063258b",

    userId: "8502d8fe-85f6-461c-bba0-2fe2f9310f5e",

    tenant: "panasonic",

    mobile: "+919821761932",

    status: "NEW",

    createdAt: "2021-06-16T05:58:27.390+0000",

    updatedAt: "2021-06-16T05:58:27.390+0000",
  },
];

export const getFeedbackData = (params) => {
  let filteredParams = {};
  if (params) {
    Object.keys(params).forEach((key) => {
      if (params[key] || params[key] === 0) {
        filteredParams[key] = params[key];
      }
    });
  }
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${env.GET_DEVICEMANAGEMENT_API_URL()}/simplifi/v1/appsupport/userFeedback/search`,
        {
          params: filteredParams,
        }
      )
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

export const updateFeedback = (id, data) => {
  return new Promise((resolve, reject) => {
    axios
      .put(
        `${env.GET_DEVICEMANAGEMENT_API_URL()}/simplifi/v1/appsupport/userFeedback/id/${id}`,
        data
      )
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const downloadFeedbackReport = (filteredParams) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${env.GET_DEVICEMANAGEMENT_API_URL()}/simplifi/v1/appsupport/userFeedback/download`,
        {
          params: filteredParams,
        }
      )
      .then((res) => {
        const blob = new Blob([res.data], { type: "text/csv" });
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = 'user_feedback';
        a.click();
        resolve(res.data)
      } )
      .catch((err) => reject(err));
  });
};

