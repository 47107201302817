import React, { useState,useEffect } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import {deviceCategory,initialCatFilterVal} from '../../Common/Metrics/GraphParameter';
import { connect } from "react-redux";


function DeviceCatogary({ handleDeviceCatogary, allowedDeviceCategories }) {
    const [checkedFilterValue,setCheckedFilterValue] = useState(initialCatFilterVal(allowedDeviceCategories))

  useEffect(()=>{
    setCheckedFilterValue(checkedFilterValue);
    handleDeviceCatogary(checkedFilterValue);
  },[checkedFilterValue])

  const handleControllerChange = (event) => {
    handleFilterChecked(event)
  };
  const handleFilterChecked = (event) => {
    setCheckedFilterValue({...checkedFilterValue , [event.target.name]:event.target.checked})
  }

  const categoryList = allowedDeviceCategories.filter(item => item !== "AC")

  return (
    <div>
      <div className="control-capabilities">
        <div className="model-srch reset w-auto date_range_position">
          <Accordion className="over_float_second">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography> Device Category </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {categoryList.map((category)=>(
                <FormControlLabel
                control={
                  <Checkbox
                    checked={checkedFilterValue[category]}
                    onChange={handleControllerChange}
                    name={category}
                    color="primary"
                  />
                }
                label={category}
              />
              ))}
            </AccordionDetails>
          </Accordion>
        </div>
      </div>{" "}
    </div>
  );
}

const mapStateToProps = (state) =>{
  return{
    allowedDeviceCategories:state.auth.allowedDeviceCategories,
  }
}
export default connect(mapStateToProps, null)(DeviceCatogary);
