import {
    UPLOAD_PSN_DELAER_MAPPING_FILE,
    UPLOAD_PSN_DELAER_MAPPING_FILE_SUCCESS,
    UPLOAD_PSN_DELAER_MAPPING_FILE_ERROR
} from "../action/types";

const initialState = {
    uploadPSNDealerFileInProgress: false,
    uploadPSNDealerFileSuccess: false,
    uploadPSNDealerFileFailure: false
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case UPLOAD_PSN_DELAER_MAPPING_FILE:
            return {
                ...state,
                uploadPSNDealerFileInProgress: true,
                uploadPSNDealerFileSuccess: false,
                uploadPSNDealerFileFailure: false
            }
        case UPLOAD_PSN_DELAER_MAPPING_FILE_SUCCESS:
            return {
                ...state,
                uploadPSNDealerFileInProgress: false,
                uploadPSNDealerFileSuccess: true,
                uploadPSNDealerFileFailure: false, 
            };
        case UPLOAD_PSN_DELAER_MAPPING_FILE_ERROR:
            return {
                ...state,
                uploadPSNDealerFileInProgress: false,
                uploadPSNDealerFileSuccess: false,
                uploadPSNDealerFileFailure: true
            };
        default:
            return state;
    }
}
