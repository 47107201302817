import {
    UPLOAD_IDU_CONFIG,
    UPLOAD_IDU_CONFIG_SUCCESS,
    UPLOAD_IDU_CONFIG_ERROR,
    IDU_CONFIG_MQTT_TRIGGER,
    IDU_CONFIG_MQTT_TRIGGER_SUCCESS,
    IDU_CONFIG_MQTT_TRIGGER_ERROR
} from "../action/types";

const initialState = {
    uploadIDUConfigInProgress: false,
    uploadIDUConfigSuccess: false,
    uploadIDUConfigFailure: false,
    iduConfigMQTTTriggerInProgress: false,
    iduConfigMQTTTriggerSuccess: false,
    iduConfigMQTTTriggerFailure: false
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case UPLOAD_IDU_CONFIG:
            return {
                ...state,
                uploadIDUConfigInProgress: true,
                uploadIDUConfigSuccess: false,
                uploadIDUConfigFailure: false
            }
        case UPLOAD_IDU_CONFIG_SUCCESS:
            return {
                ...state,
                uploadIDUConfigInProgress: false,
                uploadIDUConfigSuccess: true,
                uploadIDUConfigFailure: false
            };
        case UPLOAD_IDU_CONFIG_ERROR:
            return {
                ...state,
                uploadIDUConfigInProgress: false,
                uploadIDUConfigSuccess: false,
                uploadIDUConfigFailure: true
            };
        case IDU_CONFIG_MQTT_TRIGGER:
            return {
                ...state,
                iduConfigMQTTTriggerInProgress: true,
                iduConfigMQTTTriggerSuccess: false,
                iduConfigMQTTTriggerFailure: false
            }
        case IDU_CONFIG_MQTT_TRIGGER_SUCCESS:
            return {
                ...state,
                iduConfigMQTTTriggerInProgress: false,
                iduConfigMQTTTriggerSuccess: true,
                iduConfigMQTTTriggerFailure: false
            };
        case IDU_CONFIG_MQTT_TRIGGER_ERROR:
            return {
                ...state,
                iduConfigMQTTTriggerInProgress: false,
                iduConfigMQTTTriggerSuccess: false,
                iduConfigMQTTTriggerFailure: true
            };
        default:
            return state;
    }
}
