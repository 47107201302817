import axios from "axios";
import customConfig from "../config";
import env from "../env";
import iduConfig from "../reducers/iduConfig";

const setTenantId = (tenantId) => {
  if (env.GET_USERMANAGEMENT_VERSION() == customConfig.umV1Version) {
    if (tenantId) {
      axios.defaults.headers.common["X-Tenant-ID"] = tenantId;
    } else {
      delete axios.defaults.headers.common["X-Tenant-ID"];
    }
  }
  else {
    if (tenantId) {
      axios.defaults.headers.common["tenantId"] = tenantId;
    } else {
      delete axios.defaults.headers.common["tenantId"];
    }
  }

};

export default setTenantId;
