import axios from "axios";
import { setAlert } from "./alert";
import {
    UPLOAD_IDU_CONFIG,
    UPLOAD_IDU_CONFIG_SUCCESS,
    UPLOAD_IDU_CONFIG_ERROR,
    IDU_CONFIG_MQTT_TRIGGER,
    IDU_CONFIG_MQTT_TRIGGER_SUCCESS,
    IDU_CONFIG_MQTT_TRIGGER_ERROR
} from "./types";
import env from "../env";

export const uploadIDUConfig = (formData) => async (dispatch) => {
    try {
        dispatch({
            type: UPLOAD_IDU_CONFIG,
        });

        const config = {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        };

        const res = await axios.post(
            `${env.GET_DEVICEMANAGEMENT_API_URL()}/simplifi/v1/deviceManagement/iduconfig`, formData, config);

        dispatch({
            type: UPLOAD_IDU_CONFIG_SUCCESS,
            payload: {},
        });

        dispatch(setAlert(res?.data?.message ?? "Sucessfully Uploaded the IDU EEPROM Config", "success"));

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message ?? "Failed to Upload the IDU EEPROM Cofnig", "danger"));
        dispatch({
            type: UPLOAD_IDU_CONFIG_ERROR,
            payload: {},
        });
    }
};

export const updateIDUConfigByModelId = (modelId, formData) => async (dispatch) => {
    try {
        dispatch({
            type: UPLOAD_IDU_CONFIG,
        });

        const config = {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        };

        const res = await axios.put(
            `${env.GET_DEVICEMANAGEMENT_API_URL()}/simplifi/v1/deviceManagement/iduconfig?modelId=${modelId}`, formData,
            config);

        dispatch({
            type: UPLOAD_IDU_CONFIG_SUCCESS,
            payload: {},
        });

        dispatch(setAlert(res?.data?.message ?? "Sucessfully Uploaded the IDU EEPROM Config", "success"));

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message ?? "Failed to Upload the IDU EEPROM Cofnig", "danger"));
        dispatch({
            type: UPLOAD_IDU_CONFIG_ERROR,
            payload: {},
        });
    }
};

export const updateIDUConfigByConfigId = (configId, formData) => async (dispatch) => {
    try {
        dispatch({
            type: UPLOAD_IDU_CONFIG,
        });

        const config = {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        };

        const res = await axios.put(
            `${env.GET_DEVICEMANAGEMENT_API_URL()}/simplifi/v1/deviceManagement/iduconfig?configId=${configId}`, formData,
            config);

        dispatch({
            type: UPLOAD_IDU_CONFIG_SUCCESS,
            payload: {},
        });

        dispatch(setAlert(res?.data?.message ?? "Sucessfully Uploaded the IDU EEPROM Config", "success"));

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message ?? "Failed to Upload the IDU EEPROM Cofnig", "danger"));
        dispatch({
            type: UPLOAD_IDU_CONFIG_ERROR,
            payload: {},
        });
    }
};

export const getIDUConfigData = (params) => {

    const config = {
        headers: {
            "Content-Type": "application/json",
        },
        params: params
    }

    return new Promise((resolve, reject) => {
        axios
            .get(
                `${env.GET_DEVICEMANAGEMENT_API_URL()}/simplifi/v1/deviceManagement/iduconfig/config`,
                config
            )
            .then((res) => resolve(res.data))
            .catch((err) => reject(err));
    });
};

export const downloadIDUConfigFile = (params) => async (dispatch) => {
    try {
        let { fileName, ...args } = params
        const config = {
            params: args
        };

        const url = window.URL.createObjectURL(new Blob([params.fileData]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();

        dispatch(setAlert("Successfully Downloaded the Device IDU Config File", "success"))

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message ?? "Failed to Download Device IDU Config File", "danger"))
    }
};

export const getDeviceModelNumbersList = (brandName, categoryName) => {
    const config = {
        headers: {
            "Content-Type": "application/json",
        },
        params: {
            "brand": brandName
        }
    }

    return new Promise((resolve, reject) => {
        axios
            .get(
                `${env.GET_DEVICEMANAGEMENT_API_URL()}/simplifi/v1/deviceManagement/deviceModels/category/${categoryName}/modelIds`,
                config
            )
            .then((res) => resolve(res.data))
            .catch((err) => reject(err));
    });
}

export const getDeviceModelIdsUsingConfigId = (configId) => {
    const config = {
        headers: {
            "Content-Type": "application/json",
        }
    }

    return new Promise((resolve, reject) => {
        axios
            .get(
                `${env.GET_DEVICEMANAGEMENT_API_URL()}/simplifi/v1/deviceManagement/iduconfig/${configId}/modelIds`,
                config
            )
            .then((res) => resolve(res.data))
            .catch((err) => reject(err));
    });
}

export const sendIDUConfigMQTTTrigger = (deviceId) => async (dispatch) => {
    try {
        dispatch({
            type: IDU_CONFIG_MQTT_TRIGGER,
        });

        const config = {
            headers: {
                "Content-Type": "application/json",
            }
        };

        const res = await axios.post(
            `${env.GET_DEVICEMANAGEMENT_API_URL()}/simplifi/v1/deviceManagement/iduconfig/trigger`, {
                'deviceId': deviceId 
            }, config);

        dispatch({
            type: IDU_CONFIG_MQTT_TRIGGER_SUCCESS,
            payload: {},
        });

        dispatch(setAlert(res?.data?.message ?? "Sucessfully Uploaded the IDU EEPROM Config", "success"));

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message ?? "Failed to Upload the IDU EEPROM Cofnig", "danger"));
        dispatch({
            type: IDU_CONFIG_MQTT_TRIGGER_ERROR,
            payload: {},
        });
    }
}