import React from "react";
import CKEditor from "ckeditor4-react";
import { useState } from "react";
import { sendEmail } from "../../action/report";
import { connect } from "react-redux";

const SendingEmail = ({
  deviceInfo,
  emailSendingResponse,
  sendEmail,
  loggedInUserName,
}) => {
  const [emailContent, setEmailContent] = useState("");
  const [showSentEmailMessage, setShowSentEmailMessage] = useState(false);

  const userName = loggedInUserName;

  const onEditorChange = (evt) => {
    setEmailContent(evt.editor.getData());
  };

  const sendIncidentEmail = () => {
    const emailBody = `</br><b>Device Id:</b>${deviceInfo.deviceId},</br><b>Category:</b>${deviceInfo.category},</br><b>Model Number:</b>${deviceInfo.modelNumber},</br><b>Firmware Version:</b>${deviceInfo.firmwareVersion},</br><b>Brand:</b>${deviceInfo.brand},</br><b>Onboarded By:</b>: ${deviceInfo.onboardedBy},${emailContent}`;

    let receiptList = process.env.REACT_APP_EMAIL_SENDING_RECEIPT_LIST;
    receiptList = receiptList.replace(/'/g, '"');
    receiptList = JSON.parse(receiptList);
    sendEmail(emailBody, "Miraie support incident", receiptList, userName);
    setShowSentEmailMessage(true);
  };

  return (
    <div className="email-sending-popup">
      {
        // deviceInfo &&

        <div>
          <div className="block-title style-2 center-align  py-25">
            <h3 className="mb-0">Report Incidence</h3>
          </div>
          <div className="gray-section col">
            <div className="s4 col">
              <div className="unique-box d-inline">
                <label className="title">Device ID:</label>
                <div className="device-info">{deviceInfo.deviceId}</div>
              </div>

              <div className="unique-box d-inline">
                <label className="title">Category:</label>
                <div className="device-info">{deviceInfo.category}</div>
              </div>
            </div>

            <div className="s4 col">
              <div className="unique-box d-inline">
                <label className="title">Model Number:</label>
                <div className="device-info">{deviceInfo.modelNumber}</div>
              </div>

              <div className="unique-box d-inline">
                <label className="title">Current Firmware:</label>
                <div className="device-info">{deviceInfo.firmwareVersion}</div>
              </div>
            </div>

            <div className="s4 col">
              <div className="unique-box d-inline">
                <label className="title">Brand Name:</label>
                <div className="device-info">{deviceInfo.brand}</div>
              </div>

              <div className="unique-box d-inline">
                <label className="title">Onboarded By:</label>
                <div className="device-info">{deviceInfo.onboardedBy}</div>
              </div>
            </div>
          </div>
        </div>
      }
      <div className="editor px-20">
        <h3 className="gray title-3">Incidence Details</h3>

        <CKEditor
          onChange={onEditorChange}
          config={{
            toolbar: [
              {
                name: "clipboard",
                items: ["Cut", "Copy", "-", "Undo", "Redo"],
              },
              {
                name: "editing",
                groups: [
                  "Heading",
                  "Find",
                  "Selection",
                  "SpellChecker",
                  "Editing",
                ],
              },
              {
                name: "basicstyles",
                items: [
                  "Bold",
                  "Italic",
                  "Underline",
                  "Subscript",
                  "Superscript",
                  "Strike",
                  "-",
                  "RemoveFormate",
                ],
              },
              {
                name: "paragraph",
                items: [
                  "NumberedList",
                  "BulletedList",
                  "-",
                  "Outdent",
                  "Indent",
                  "Blockquote",
                  "List",
                  "Blocks",
                  "Align",
                  "Bidi",
                  "Paragraph",
                ],
              },
              { name: "styles", items: ["Styles", "Format"] },
              "/",
              { name: "links", items: ["Link", "Unlink", "Anchor"] },
              {
                name: "insert",
                items: [
                  "Table",
                  "mergeTableCells",
                  "MergeTableCells",
                  "HorizontalRule",
                  "SpecialChar",
                ],
              }, //Image
              {
                name: "document",
                items: ["Source", "-", "NewPage", "Preview", "-", "Templates"],
              },
              { name: "colors", groups: ["Colors"] },
              { name: "tools", groups: ["Tools"] },
              { name: "others", groups: ["Others"] },
              "/",
              { name: "forms", groups: ["Forms"] },
              { name: "document", groups: ["Mode", "Document", "Doctools"] },
            ],
          }}
        />
      </div>
      <div className="d-flex bottom">
        <div className="unique-box d-inline self">
          <label className="title">Sent By: </label>
          <div className="device-info"> {userName}</div>
        </div>

        {showSentEmailMessage && (
          <div className="unique-box d-inline self">
            <label className="email_response_message">
              {emailSendingResponse.message}
            </label>
          </div>
        )}
        <button
          className="btn btn-style-3 orange-bg lg"
          onClick={sendIncidentEmail}
        >
          Send E-mail
        </button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loggedInUserName: state.auth.username,
    emailSendingResponse: state.report.emailSendingResponse,
  };
};

export default connect(mapStateToProps, { sendEmail })(SendingEmail);
