import axios from "axios";
import { setAlert } from "./alert";
import {
  GET_REPORT,
  REPORT_ERROR,
  GET_DEVICE,
  GET_ALL_DEVICE,
  DEVICE_ERROR,
  REQUEST_DEVICE_USAGE,
  GET_DEVICE_USAGE,
  DEVICE_USAGE_ERROR,
  GET_FIRMWARE_DETAILS,
  DEVICE_FIRMWARE_ERROR,
  OFFBOARD_DEVICE,
  GET_LATEST_FIRMWARE,
  GET_LATEST_FIRMWARE_ERROR,
  GET_ONBOARDED_HISTORY_DETAILS,
  GET_ONBOARDED_HISTORY_DETAILS_ERROR,
  UPDATE_SERIAL_NUMBER,
  UPDATE_SERIAL_NUMBER_ERROR,
  SEND_EMAIL_RESPONSE_SUCCESS,
  SEND_EMAIL_RESPONSE_FAILED,
  GET_DIAGNOSTIC_DATA,
  GET_DIAGNOSTIC_DATA_ERROR,
  SEND_MISMATCH_DATA_RESPONSE_SUCCESS,
  SEND_MISMATCH_DATA_RESPONSE_FAILED,
  GET_METRICS_DATA,
  GET_METRICS_DATA_ERROR,
  FIRMWARE_INFO_ERROR,
  GET_FIRMWARE_INFO,
  UPDATE_FIRMWARE_VERSION_SUCCESS,
  UPDATE_FIRMWARE_VERSION_ERROR
} from "./types";
import customConfig from "../config";
import env from "../env";

//Get profile by ID
export const getReportbyId = (homeId) => async (dispatch) => {
  dispatch({
    type: GET_REPORT,
    payload: null,
  });
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const res = await axios.get(
      `${env.GET_DEVICEMANAGEMENT_API_URL()}/simplifi/v1/homeManagement/homes/${homeId}`,
      config
    );
    dispatch({
      type: GET_REPORT,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: REPORT_ERROR,
      payload: { msg: console.log("err") },
    });
  }
};

//Get all devices by deviceId
export const getAllDevice =
  (
    searchText,
    searchBy = customConfig.deviceSearchByDeviceId,
    resolve = () => { },
    reject = () => { }
  ) =>
    async (dispatch) => {
      try {
        if (searchText) {
          const config = {
            headers: {
              "Content-Type": "application/json",
            },
          };
          const res = await axios.get(
            `${env.GET_DEVICEMANAGEMENT_API_URL()}/simplifi/v1/deviceManagement/devices/${searchBy}/${searchText}`,
            config
          );
          const onbrdBy = res.data[0].onboardedBy;
          res.data[0].onboardedById = onbrdBy; // Custom element added
          res.data[0].onboardedBy = "";
          dispatch({
            type: GET_ALL_DEVICE,
            payload: res.data,
          });
        } else {
          dispatch({
            type: GET_ALL_DEVICE,
            payload: [],
          });
        }
      } catch (err) {
        dispatch(setAlert(err.response.data.message, "danger"));
        dispatch({
          type: DEVICE_ERROR,
          payload: {
            msg: err.response
              ? err.response.data && err.response.data.message
                ? err.response.data.message
                : err.response.statusText
              : null,
            status: err.response ? err.response.status : null,
          },
        });
      }
    };
//Get device by ID - Get full name on the bases of onboarded by id - latest Firmware version by modelNumber
export const getDevice =
  (searchText, searchBy = customConfig.deviceSearchByDeviceId, userRoleInfo = null, umV1AuthToken, tenantId, showAlertOnDeviceError = true) =>
    async (dispatch) => {
      try {
        if (!(searchText && searchText.length > 0)) {
          let blankWarnMsg;
          switch (searchBy) {
            case customConfig.deviceSearchByMacAddress:
              blankWarnMsg = customConfig.enterMacAddressMsg;
              break;

            case customConfig.deviceSearchByProductSerialNumber:
              blankWarnMsg = customConfig.enterProductSerialNumMsg;
              break;

            default:
              blankWarnMsg = customConfig.enterDeviceIdMsg;
              break;
          }
          dispatch(setAlert(blankWarnMsg, "danger"));
          return;
        }

        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };
        const res = await axios.get(
          `${env.GET_DEVICEMANAGEMENT_API_URL()}/simplifi/v1/deviceManagement/devices/${searchBy}/${searchText}`,
          config
        );
        const onbrdBy = res.data[0].onboardedBy;
        res.data[0].onboardedById = onbrdBy; // Custom element added
        res.data[0].onboardedBy = "";
        dispatch({
          type: GET_DEVICE,
          payload: res.data,
        });

        if (!(userRoleInfo.level == 0 && userRoleInfo.roles.includes(customConfig.roleFactoryAdmin))) {
          //Get full name on the bases of onboarded by id with in api
          if (onbrdBy) {
            try {
              let userDetail = {}
              if (env.GET_UM_MOBILE_USERS_VERSION() == customConfig.umV2Version) {
                let umConfig = {
                  headers: {
                    "Content-Type": "application/json"
                  }
                }
                userDetail = await axios.get(
                  `${env.GET_USERMANAGEMENT_API_URL_V2()}/simplifi/v2/userManagement/users/userId?userId=${onbrdBy}`,
                  umConfig
                );
              }
              else {
                let umConfig = {
                  headers: {
                    "Content-Type": "application/json",
                    "X-Tenant-ID": tenantId,
                    "x-auth-token": umV1AuthToken,
                    "authorization": `Bearer ${umV1AuthToken}`
                  }
                }
                userDetail = await axios.get(
                  `${env.GET_USERMANAGEMENT_API_URL()}/simplifi/v1/userManagement/users/userId?userId=${onbrdBy}`,
                  umConfig
                );
              }

              res.data[0].onboardedBy = userDetail.data
                ? (userDetail.data.firstName ? userDetail.data.firstName : "") +
                " " +
                (userDetail.data.lastName ? userDetail.data.lastName : "")
                : " ";
              dispatch({
                type: GET_DEVICE,
                payload: res.data,
              });
            } catch (err) { }
          }

          //Get latest Firmware version by modelNumber with in api
          try {
            const lstFrmDt = await axios.get(
              `${env.GET_FOTA_API_URL()}/simplifi/v1/fota/firmware?modelNumber=${res.data[0].modelNumber
              }`,
              config
            );
            dispatch({
              type: GET_LATEST_FIRMWARE,
              payload: lstFrmDt.data,
            });
          } catch (err) {
            dispatch({
              type: GET_LATEST_FIRMWARE_ERROR,
              payload: {
                msg: err.response
                  ? err.response.data && err.response.data.message
                    ? err.response.data.message
                    : err.response.statusText
                  : null,
                status: err.response ? err.response.status : null,
              },
            });
          }
        }
      } catch (err) {
        if (showAlertOnDeviceError) {
          dispatch(setAlert(err?.response?.data?.message ?? "Failed to Get Device Details", "danger"));
        }
        dispatch({
          type: DEVICE_ERROR,
          payload: {
            msg: err.response
              ? err.response.data && err.response.data.message
                ? err.response.data.message
                : err.response.statusText
              : null,
            status: err.response ? err.response.status : null,
          },
        });
      }
    };

//Get device usage by ID
export const getDeviceUsagebyId = (deviceId) => async (dispatch) => {
  try {
    dispatch({
      type: REQUEST_DEVICE_USAGE,
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.get(
      `${env.GET_DEVICEMANAGEMENT_API_URL()}/simplifi/v1/deviceManagement/devices/${deviceId}/web/status`,
      config
    );
    dispatch({
      type: GET_DEVICE_USAGE,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: DEVICE_USAGE_ERROR,
      payload: {
        msg:
          err.response.data && err.response.data.message
            ? err.response.data.message
            : err.response.statusText,
        status: err.response.status,
      },
    });
  }
};

//Get Firmware History by ID
export const getFirmwareHistory = (deviceId) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.get(
      `${env.GET_FOTA_API_URL()}/simplifi/v1/fota/firmware/history?deviceId=${deviceId}`,
      config
    );
    dispatch({
      type: GET_FIRMWARE_DETAILS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: DEVICE_FIRMWARE_ERROR,
      payload: {
        msg: err.response
          ? err.response.data && err.response.data.message
            ? err.response.data.message
            : err.response.statusText
          : null,
        status: err.response ? err.response.status : null,
      },
    });
  }
};

//Get offboard Device by Id by ID
export const offboardDevicebyId = (deviceId) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.post(
      `${env.GET_DEVICEMANAGEMENT_API_URL()}/simplifi/v1/deviceManagement/devices/${deviceId}/deviceOffboard`,
      {},
      config
    );
    dispatch({
      type: OFFBOARD_DEVICE,
      payload: res.data,
    });
  } catch (err) {
    dispatch(setAlert(err.response.data.message, "danger"));
    dispatch({
      type: DEVICE_ERROR,
      payload: {
        msg: err.response
          ? err.response.data && err.response.data.message
            ? err.response.data.message
            : err.response.statusText
          : null,
        status: err.response ? err.response.status : null,
      },
    });
  }
};

// reset firmware info data
export const resetFirmwareInfo = () => (dispatch) => {
  try {
    dispatch({
      type: GET_FIRMWARE_INFO,
      payload: [],
    });
  } catch (err) {
    // do nothing
  }
}

//Get Firmware Info
export const getfirmwareInfo = (categoryFilter, brandFilter, chipTypeFilter) => async (dispatch) => {
  try {
    dispatch({
      type: GET_FIRMWARE_INFO,
      payload: {},
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
      params: {
        brand: brandFilter,
        category: categoryFilter
      }
    };

    if (chipTypeFilter != "") {
      config['params']['chipType'] = chipTypeFilter
    }
    const res = await axios.get(
      `${env.GET_FOTA_API_URL()}/simplifi/v1/deviceManagement/devices/firmwareVersion/percentage`,
      config
    );
    dispatch({
      type: GET_FIRMWARE_INFO,
      payload: res.data,
    });
  } catch (err) {
    dispatch(setAlert(err?.response?.data?.message ?? "Failed to get Firmware Details", "danger"))
    dispatch({
      type: FIRMWARE_INFO_ERROR,
      payload: {
        msg: err.response
          ? err.response.data && err.response.data.message
            ? err.response.data.message
            : err.response.statusText
          : null,
        status: err.response ? err.response.status : null,
      },
    });
  }
};
//Update serial number by deviceId  [NOT IN USE]
export const updateSerialNumberbyID =
  (deviceId, serialNumber) => async (dispatch) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const body = {
        serialNumber,
      };
      const res = await axios.put(
        `${env.GET_DEVICEMANAGEMENT_API_URL()}/simplifi/v1/deviceManagement/device/${deviceId}`,
        body,
        config
      );
      dispatch({
        type: "",
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: "",
        payload: {
          msg: err.response
            ? err.response.data && err.response.data.message
              ? err.response.data.message
              : err.response.statusText
            : null,
          status: err.response ? err.response.status : null,
        },
      });
    }
  };

//Update product serial number by deviceId
export const updateProductSerialNumberbyID =
  (deviceId, productSerialNumber) => async (dispatch) => {
    try {
      dispatch({
        type: UPDATE_SERIAL_NUMBER,
        payload: null
      })

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const body = {
        productSerialNumber,
      };
      const res = await axios.put(
        `${env.GET_DEVICEMANAGEMENT_API_URL()}/simplifi/v1/deviceManagement/device/${deviceId}`,
        body,
        config
      );
      dispatch({
        type: UPDATE_SERIAL_NUMBER,
        payload: res.data,
      });

      dispatch(setAlert("Product Serial Number Updated Successfully", "success"))

    } catch (err) {
      dispatch({
        type: UPDATE_SERIAL_NUMBER_ERROR,
        payload: {
          error: err?.response?.data?.message ?? "Failed to Update Product Serial Number",
          status: err.response ? err.response.status : null,
        },
      });

      dispatch(setAlert(err?.response?.data?.message ?? "Failed to Update Product Serial Number", "danger"))
    }
  };

export const updateProductModelNumberbyID =
  (deviceId, modelNumber) => (dispatch) => {
    return new Promise((resolve, reject) => {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const body = {
        modelNumber,
      };
      axios
        .put(
          `${env.GET_DEVICEMANAGEMENT_API_URL()}/simplifi/v1/deviceManagement/device/${deviceId}`,
          body,
          config
        )
        .then((res) => {
          dispatch({
            type: "",
            payload: res.data,
          });
          resolve(res);
        })
        .catch((err) => {
          //alert(err.response.data.message);
          dispatch({
            type: "MODEL_ERROR",
            payload: {
              msg: err.response
                ? err.response.data && err.response.data.message
                  ? err.response.data.message
                  : err.response.statusText
                : null,
              status: err.response ? err.response.status : null,
            },
          });
          reject(err);
        });
    });
  };

//Get Onboarded/ Offboarded History by ID
export const getOnboardedHistory = (deviceId) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.get(
      `${env.GET_DEVICEMANAGEMENT_API_URL()}/simplifi/v1/deviceManagement/devices/${deviceId}/history`,
      config
    );
    dispatch({
      type: GET_ONBOARDED_HISTORY_DETAILS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_ONBOARDED_HISTORY_DETAILS_ERROR,
      payload: {
        msg: err.response
          ? err.response.data && err.response.data.message
            ? err.response.data.message
            : err.response.statusText
          : null,
        status: err.response ? err.response.status : null,
      },
    });
  }
};

//Send email
export const sendEmail = (body, subject, to, sendBy) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const bodyObj = { body, subject, to, sendBy };
    let bodyJSON = JSON.parse(JSON.stringify(bodyObj).replace(/'/g, '"'));
    const res = await axios.post(
      `${env.GET_DEVICEMANAGEMENT_API_URL()}/simplifi/v1/email/sendEmail`,
      bodyJSON,
      config
    );
    dispatch({
      type: SEND_EMAIL_RESPONSE_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: SEND_EMAIL_RESPONSE_FAILED,
      payload: {
        message: err.response
          ? err.response.data && err.response.data.message
            ? err.response.data.message
            : err.response.statusText
          : "Failed to send email.",
        status: err.response ? err.response.status : null,
      },
    });
  }
};

//Send warranty mismatch data
export const sendMismatchData = (body) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.post(
      `${env.GET_DEVICEMANAGEMENT_API_URL()}/simplifi/v1/upload`,
      body,
      config
    );
    dispatch({
      type: SEND_MISMATCH_DATA_RESPONSE_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: SEND_MISMATCH_DATA_RESPONSE_FAILED,
      payload: {
        msg: err.response
          ? err.response.data && err.response.data.message
            ? err.response.data.message
            : err.response.statusText
          : null,
        status: err.response ? err.response.status : null,
      },
    });
  }
};

//Get Metrics data
export const getMetricsData = () => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.get(
      `${env.GET_DEVICEMANAGEMENT_API_URL()}/simplifi/v1/appsupport/metricsimages/download`,
      config
    );
    dispatch({
      type: GET_METRICS_DATA,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_METRICS_DATA_ERROR,
      payload: {
        msg: err.response
          ? err.response.data && err.response.data.message
            ? err.response.data.message
            : err.response.statusText
          : null,
        status: err.response ? err.response.status : null,
      },
    });
  }
};

//Get Diagnostic data
export const getDiagnosticsData = (deviceId) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.get(
      `${env.GET_DEVICEMANAGEMENT_API_URL()}/simplifi/v1/diagnostics/devices/${deviceId}`,
      config
    );
    dispatch({
      type: GET_DIAGNOSTIC_DATA,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_DIAGNOSTIC_DATA_ERROR,
      payload: {
        msg: err.response
          ? err.response.data && err.response.data.message
            ? err.response.data.message
            : err.response.statusText
          : null,
        status: err.response ? err.response.status : null,
      },
    });
  }
};

export const updateFirmwareVersion = (body) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.post(
      `${env.GET_DEVICEMANAGEMENT_API_URL()}/simplifi/v1/firmwareupgrade/upgradeBydeviceId`,
      body,
      config
    );
    dispatch({
      type: UPDATE_FIRMWARE_VERSION_SUCCESS,
      payload: res.data,
    });
    dispatch(setAlert(res?.data?.message, "show"));
  } catch (err) {
    dispatch({
      type: UPDATE_FIRMWARE_VERSION_ERROR,
      payload: {
        msg: err.response
          ? err.response.data && err.response.data.message
            ? err.response.data.message
            : err.response.statusText
          : null,
        status: err.response ? err.response.status : null,
      },
    });
    dispatch(setAlert(err.response.data.message, "danger"));

  }
};

//get manufactured devices data
export const getManufacturedDevicesData = (params) => {
  let filteredParams = {};
  if (params) {
    Object.keys(params).forEach((key) => {
      if (params[key] || params[key] === 0) {
        filteredParams[key] = params[key];
      }
    });
  }
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${env.GET_DEVICEMANAGEMENT_API_URL()}/simplifi/v1/deviceManagement/devices/manufacturedDevices`,
        {
          params: filteredParams,
        }
      )
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

//download manufactured devices report
export const downloadManufacturedDevicesReport = (filteredParams) => {
  return new Promise((resolve, reject) => {

    let { printableStartDate, ...params } = filteredParams
    axios
      .get(
        `${env.GET_DEVICEMANAGEMENT_API_URL()}/simplifi/v1/deviceManagement/devices/manufacturedDevices/download`,
        {
          params: params,
        }
      )
      .then((res) => {
        const blob = new Blob([res.data], { type: "text/csv" });
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = `Manufactured_Devices_${filteredParams.category}_${printableStartDate}`;
        a.click();
        resolve(res.data)
      })
      .catch((err) => reject(err));
  });
};
