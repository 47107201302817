import React, { Fragment } from "react";
import { connect } from "react-redux";
import Moment from "react-moment";

const LineTestUserDeviceDetails = ({
  showSeparator = false,
  device
}) => {
  return (
    <Fragment>
      {device && device.length > 0 ? (
        <div className="position-relative">
          <div className="reset-device-detail">
            <div className="row">
              <div className="s3 col">
                <div
                  className={`block-title ${showSeparator ? "style-3" : "style-4"
                    }`}
                >
                  <h3>Device Details</h3>
                </div>
              </div>
            </div>
            <div className="device-detail">
              <div className="row">
                <div className="s6 col">
                  <div className="unique-box mb-15">
                    <label className="title d-inline  min-180">Device ID</label>
                    <div className="device-info d-inline">
                      {device[0].deviceId}
                    </div>
                  </div>
                  <div className="unique-box mb-15">
                    <label className="title d-inline  min-180">
                      Model Number
                    </label>
                    <div className="device-info d-inline">
                      {device[0].modelNumber}
                    </div>
                  </div>
                  <div className="unique-box mb-15">
                    <label className="title d-inline  min-180">
                      Mac Address
                    </label>
                    <div className="device-info d-inline">
                      {device[0].macAddress}
                    </div>
                  </div>
                  <div className="unique-box mb-15">
                    <label className="title d-inline  min-180">
                      Serial Number
                    </label>
                    <div className="device-info d-inline">
                      {device[0].serialNumber}{" "}
                    </div>
                  </div>
                  <div className="unique-box mb-15">
                    <label className="title d-inline  min-180">
                      Product Serial Number
                    </label>
                    <div className="device-info d-inline">
                      {device[0].productSerialNumber}
                    </div>
                  </div>
                  <div className="unique-box mb-15">
                    <label className="title d-inline  min-180">
                      Model Name
                    </label>
                    <div className="device-info d-inline">
                      {device[0].modelName}
                    </div>
                  </div>
                  <div className="unique-box mb-15">
                    <label className="title d-inline  min-180">
                      Manufactured Date
                    </label>
                    <div className="device-info d-inline">
                      {device[0].manufacturedDate &&
                        <Moment
                          format="MMMM Do YYYY, h:mm:ss a"
                          date={device[0].manufacturedDate * 1000}
                        />}
                    </div>
                  </div>
                  <div className="unique-box mb-15">
                    <label className="title d-inline  min-180">
                      Firmware Current Version
                    </label>
                    <div className="device-info d-inline">
                      {device[0].firmwareVersion}
                    </div>
                  </div>
                </div>
                <div className="s6 col">
                  <div className="unique-box mb-15">
                    <label className="title d-inline  min-180">
                      Brand Name
                    </label>
                    <div className="device-info d-inline">
                      {device[0].brand}
                    </div>
                  </div>
                  <div className="unique-box mb-15">
                    <label className="title d-inline  min-180">
                      Created At
                    </label>
                    <div className="device-info d-inline">
                      {device[0].createdAt &&
                        <Moment
                          format="MMMM Do YYYY, h:mm:ss a"
                          date={device[0].createdAt * 1000}
                        />}
                    </div>
                  </div>
                  <div className="unique-box mb-15">
                    <label className="title d-inline  min-180">
                      Created By
                    </label>
                    <div className="device-info d-inline">
                      {device[0].createdBy}
                    </div>
                  </div>
                  <div className="unique-box mb-15">
                    <label className="title d-inline  min-180">Category</label>
                    <div className="device-info d-inline">
                      {device[0].category}
                    </div>
                  </div>
                  <div className="unique-box mb-15">
                    <label className="title d-inline  min-180">
                      efuseMacAddress
                    </label>
                    <div className="device-info d-inline">
                      {device[0].efuseMacAddress}
                    </div>
                  </div>
                  <div className="unique-box mb-15">
                    <label className="title d-inline  min-180">
                      Provisioned At
                    </label>
                    <div className="device-info d-inline">
                      {device[0].provisionedAt &&
                        <Moment
                          format="MMMM Do YYYY, h:mm:ss a"
                          date={device[0].provisionedAt * 1000}
                        />}
                    </div>
                  </div>
                  <div className="unique-box mb-15">
                    <label className="title d-inline  min-180">
                      Onboarded At
                    </label>
                    <div className="device-info d-inline">
                      {device[0].onboardedAt && (
                        <Moment
                          format="MMMM Do YYYY, h:mm:ss a"
                          date={device[0].onboardedAt * 1000}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  userRoleInfo: {
    level: state.auth.userLevel,
    roles: state.auth.userRoles,
  },
});

export default connect(mapStateToProps, {})(LineTestUserDeviceDetails);
