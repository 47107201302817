import React, { Fragment, useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import M from "materialize-css/dist/js/materialize.min.js";
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import CircularProgress from "@material-ui/core/CircularProgress";
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import {
    getDeviceModelNumbersList, sendFOTAMQTTTrigger, resetFOTAMQTTTriggerStatus, downloadFirmwareUpgradeReportFile
} from "../../../action/firmware";
import { setAlert } from "../../../action/alert";
import DeleteIcon from '@material-ui/icons/Delete';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import Popup from "../../layout/Popup";
import { getFOTABatchList, deleteFOTABatchById, getUpgradableDeviceCountInfo } from './../../../action/firmware'
import { initFOTAJobs } from './../../../action/fotaJobs'

const useStyles = makeStyles({
    selectInput: {
        height: "3rem",
        width: "100%"
    },
    selectValues: {
        width: "100%"
    },
    activeTab: {
        border: '1px solid #bdbdbd',
        backgroundColor: '#e0e0e0',
        textTransform: 'capitalize'
    },
    nonActiveTab: {
        border: 'none',
        backgroundColor: 'none',
        textTransform: 'capitalize'
    }
});

const MQTTTrigger = ({
    allowedDeviceCategories,
    allowedCompanyBrand,
    firmwareData: { sendFOTAMQTTTriggerInProgress, sendFOTAMQTTTriggerSuccess, sendFOTAMQTTTriggerFailure },
    displaySendMQTTTriggerUI,
    sendFOTAMQTTTrigger,
    resetFOTAMQTTTriggerStatus,
    setAlert }) => {

    const classes = useStyles()
    const [categoryName, setCategoryName] = useState("")
    const [brandName, setBrandName] = useState("")
    const [modelNumbersList, setModelumbersList] = useState([])
    const [modelNumber, setModelNumber] = useState("")
    const [percentage, setPercentage] = useState("")
    const [priority, setPriority] = useState("high")
    const [firmwareVersion, setFirmwareVersion] = useState("")
    const [sendMQTTTriggerButtonClick, setSendMQTTTriggerButtonClick] = useState(undefined)

    const [displayUpgradeDeviceCountInfo, setDisplayUpgradeDeviceCountInfo] = useState(false)
    const [upgradeDeviceCount, setUpgradeDeviceCount] = useState("")
    const [upgradeDeviceFirmwareVersion, setUpgradeDeviceFirmwareVersion] = useState("")
    const [errorDisplayUpgradeDeviceCountInfo, setErrorDisplayUpgradeCountInfo] = useState("")

    useEffect(() => {
        M.AutoInit()
        resetFOTAMQTTTriggerStatus()
    }, [])

    useEffect(() => {
        if (!sendFOTAMQTTTriggerInProgress && sendFOTAMQTTTriggerSuccess) {
            setCategoryName("")
            setBrandName("")
            setModelNumber("")
            setPercentage("")
            setPriority("high")
            setFirmwareVersion("")
            setSendMQTTTriggerButtonClick(false)
            displaySendMQTTTriggerUI(false)
            resetFOTAMQTTTriggerStatus()
            setErrorDisplayUpgradeCountInfo("")
        }
    }, [sendFOTAMQTTTriggerInProgress, sendFOTAMQTTTriggerSuccess])

    useEffect(() => {
        if (sendFOTAMQTTTriggerFailure) {
            setSendMQTTTriggerButtonClick(false)
            resetFOTAMQTTTriggerStatus()
        }
    }, [sendFOTAMQTTTriggerFailure])

    useEffect(() => {
        if (brandName && brandName.length != 0 && categoryName && categoryName.length != 0) {
            getDeviceModelNumbersList(brandName, categoryName)
                .then((data) => {
                    setModelumbersList(data)
                })
                .catch((err) => {
                    setModelumbersList([])
                })
        }
    }, [brandName, categoryName])

    useEffect(() => {
        if (sendMQTTTriggerButtonClick) {
            handleMQTTTrigger();
        }
    }, [sendMQTTTriggerButtonClick])

    useEffect(() => {
        if (!modelNumber || modelNumber.length == 0) {
            setDisplayUpgradeDeviceCountInfo(false)
        }
        else {
            getUpgradableDeviceCountInfo({ 'modelNumber': modelNumber })
                .then((result) => {
                    setDisplayUpgradeDeviceCountInfo(true)
                    setUpgradeDeviceCount(result["deviceCount"])
                    setUpgradeDeviceFirmwareVersion(result["firmwareVersion"])
                    setErrorDisplayUpgradeCountInfo("")
                })
                .catch((err) => {
                    console.log(err)
                    setDisplayUpgradeDeviceCountInfo(true)
                    setAlert(err?.response?.data?.message ?? "Failed to Get the Latest Firmware Version and Device Count", "danger")
                })
        }
    }, [modelNumber])

    function handleMQTTTrigger() {
        if (!categoryName || categoryName.length == 0) {
            setAlert('Category Name Cannot be Empty', 'danger')
            window.scrollTo(0, 0)
            return
        }
        if (!brandName || brandName.length == 0) {
            setAlert('Brand Name Cannot be Empty', 'danger')
            window.scrollTo(0, 0)
            return
        }
        if (!percentage || percentage == "" || !(parseInt(percentage) >= 0 && parseInt(percentage) <= 100)) {
            setAlert('Invalid Percentage Value', 'danger')
            window.scrollTo(0, 0)
            return
        }
        if (!priority || priority.length == 0) {
            setAlert('Invalid Priority Value', 'danger')
            window.scrollTo(0, 0)
            return
        }
        if (!modelNumber || modelNumber.length == 0) {
            setAlert('Model Number Cannot be Empty', 'danger')
            window.scrollTo(0, 0)
            return
        }
        if (firmwareVersion && firmwareVersion.length != 0 && firmwareVersion.match('^[0-9]{1,2}\\.[0-9]{1,2}$') == null) {
            setAlert('Invalid Firmware Version', 'danger')
            return
        }
        let mqttPayload = {
            percentage: parseInt(percentage),
            priority: priority,
            modelNumber: modelNumber
        }

        sendFOTAMQTTTrigger(mqttPayload)
    };

    return (
        < Fragment >
            <div className="content-block bottom-shadow">
                <div className="py-15 ">
                    <Grid container style={{ marginBottom: "20px" }}>
                        <Grid item xs={2} style={{ marginLeft: "10px" }}>
                            <label className="title">Category: </label>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl variant="outlined" className={classes.selectValues}>
                                <InputLabel id="select-outlined-label-category-name" className="title">Select Category</InputLabel>
                                <Select
                                    labelId="select-outlined-label-category-name"
                                    id="select-outlined-ctageory-name-id"
                                    value={categoryName}
                                    onChange={(e) => {
                                        setCategoryName(e.target.value)
                                    }}
                                    label="Select Category"
                                    className={classes.selectInput}
                                >
                                    {
                                        allowedDeviceCategories.map(categoryName => {
                                            return <MenuItem key={categoryName} value={categoryName}>{categoryName}</MenuItem>
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container style={{ marginBottom: "20px" }}>
                        <Grid item xs={2} style={{ marginLeft: "10px" }}>
                            <label className="title">Brand: </label>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl variant="outlined" className={classes.selectValues}>
                                <InputLabel id="select-outlined-label-brand-name">Select Brand</InputLabel>
                                <Select
                                    labelId="select-outlined-label-brand-name"
                                    id="select-outlined-brand-name-id"
                                    value={brandName}
                                    onChange={(e) => {
                                        setBrandName(e.target.value)
                                    }}
                                    label="Select Brand"
                                    className={classes.selectInput}
                                >
                                    {
                                        allowedCompanyBrand.map(brandName => {
                                            return <MenuItem key={brandName} value={brandName}>{brandName}</MenuItem>
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container style={{ marginBottom: "20px" }}>
                        <Grid item xs={2} style={{ marginLeft: "10px" }}>
                            <label className="title">Model Number: </label>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl variant="outlined" className={classes.selectValues}>
                                <InputLabel id="select-outlined-label-brand-name">Select Model Number</InputLabel>
                                <Select
                                    labelId="select-outlined-label-brand-name"
                                    id="select-outlined-brand-name-id"
                                    value={modelNumber}
                                    onChange={(e) => {
                                        setModelNumber(e.target.value)
                                    }}
                                    label="Select Model Number"
                                    className={classes.selectInput}
                                >
                                    {
                                        modelNumbersList.map(model => {
                                            return <MenuItem key={model} value={model}>{model}</MenuItem>
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    {
                        displayUpgradeDeviceCountInfo && <Fragment>
                            <br></br>
                            <Grid container style={{ marginBottom: "20px" }}>
                                <Grid item xs={2} style={{ marginLeft: "10px" }}>
                                    <h1>Total Upgradable Device Count: </h1>
                                </Grid>
                                <Grid item xs={2} style={{ marginLeft: "10px" }}>
                                    <h1>{upgradeDeviceCount}</h1>
                                </Grid>
                                <Grid item xs={2} style={{ marginLeft: "10px" }}>
                                    <h1>Latest Firmware Version: </h1>
                                </Grid>
                                <Grid item xs={2} style={{ marginLeft: "10px" }}>
                                    <h1>{upgradeDeviceFirmwareVersion} </h1>
                                </Grid>
                            </Grid>
                            <br></br>
                        </Fragment>
                    }
                    <Grid container style={{ marginBottom: "20px" }}>
                        <Grid item xs={2} style={{ marginLeft: "10px" }}>
                            <label>Firmware Version (Optional): </label>
                        </Grid>
                        <Grid item xs={6} style={{ width: "100% important" }}>
                            <input
                                className="add-input xl"
                                placeholder="Enter Firmware Version"
                                type="text"
                                style={{ width: "100% !important" }}
                                value={firmwareVersion}
                                onChange={(e) => {
                                    setFirmwareVersion(e.target.value)
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container style={{ marginBottom: "20px" }}>
                        <Grid item xs={2} style={{ marginLeft: "10px" }}>
                            <label className="title">Percentage (0% to 100%): </label>
                        </Grid>
                        <Grid item xs={6} style={{ width: "100% important" }}>
                            <input
                                className="add-input xl"
                                placeholder="Enter Percentage of Devices For Upgrade"
                                type="number"
                                style={{ width: "100% !important" }}
                                value={percentage}
                                onChange={(e) => {
                                    setPercentage(e.target.value)
                                }}
                            />
                        </Grid>
                    </Grid>
                    <div className="row">
                        <button
                            className="btn btn-style-2 orange-bg"
                            onClick={() => {
                                setSendMQTTTriggerButtonClick(Math.random())
                            }}
                            disabled={sendFOTAMQTTTriggerInProgress ? true : false}
                            style={{ marginLeft: "10px" }}
                        >
                            {
                                sendFOTAMQTTTriggerInProgress ? <Fragment>
                                    <CircularProgress size="2em" style={{ color: "#AFB42B", marginTop: "5px" }} />
                                </Fragment>
                                    : "Send Trigger"
                            }
                        </button>
                        <button
                            className="btn btn-style-2 orange-bg"
                            onClick={() => {
                                displaySendMQTTTriggerUI(false)
                            }}
                            disabled={sendFOTAMQTTTriggerInProgress ? true : false}
                            style={{ marginLeft: "10px" }}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

const ManageMQTTFOTA = ({
    allowedDeviceCategories,
    allowedCompanyBrand,
    setAlert,
    firmwareData,
    sendFOTAMQTTTrigger,
    resetFOTAMQTTTriggerStatus,
    fotaJobs: { fotaBatchData, fotaBatchDataLoading, fotaBatchError, fotaBatchDeleteSuccess},
    getFOTABatchList,
    deleteFOTABatchById,
    downloadFirmwareUpgradeReportFile,
    initFOTAJobs
}) => {
    const tableHeaders = {
        batchId: "Batch ID",
        firmwareVersion: "Frimware Version",
        totalDevices: "Total Devices",
        successfyllyUpgraded: "Successfully Upgraded",
        mqttCommandFailed: "MQTT Command Failed",
        mqttCommandSent: "MQTT Command Sent",
        downloadReport: "Download Report",
        deleteBatch: "Delete Batch",
    }
    const [categoryFilter, setCategoryFilter] = useState("Select Category")
    const [brandFilter, setBrandFilter] = useState("Select Brand")
    const [modelNumberFilterList, setModelNumberFilterList] = useState([])
    const [modelNumberFilter, setModelNumberFilter] = useState("Select Model Number")
    const [showMQTTBatchDataList, setShowMQTTBatchDataList] = useState(false)
    const [updateFlag, setUpdateFlag] = useState(undefined);
    const [showDeleteBatchPopup, setShowDeleteBatchPupup] = useState(false)
    const [selectedBatchId, setSelectedBatchId] = useState(undefined)
    const [displaySendMQTTTrigger, setDisplaySendMQTTTrigger] = useState(false)

    useEffect(() => {
        M.AutoInit();
        initFOTAJobs()
    }, [updateFlag, modelNumberFilterList, displaySendMQTTTrigger, fotaBatchData, fotaBatchDataLoading, fotaBatchError])

    useEffect(() => {
        if (!categoryFilter || categoryFilter.length == 0 || categoryFilter == "Select Category") {
            return
        }
        if (!brandFilter || brandFilter.length == 0 || brandFilter == "Select Brand") {
            return
        }
        if (categoryFilter && categoryFilter.length != 0 && brandFilter && brandFilter.length != 0) {
            getDeviceModelNumbersList(brandFilter, categoryFilter)
                .then((data) => {
                    setModelNumberFilterList(data)
                })
                .catch((err) => {
                    setModelNumberFilterList([])
                })
        }
    }, [categoryFilter, brandFilter])

    useEffect(() => {
        setShowMQTTBatchDataList(false)
    }, [categoryFilter, brandFilter, modelNumberFilter])

    useEffect(() => {
        if (fotaBatchDeleteSuccess) {
            setShowDeleteBatchPupup(false)
            filterMQTTBatchData()
        }
    }, [fotaBatchDeleteSuccess])

    function filterMQTTBatchData() {
        if (!categoryFilter || categoryFilter.length == 0 || categoryFilter == "Select Category") {
            setAlert("Category Cannot be Empty", 'danger')
            return
        }
        if (!brandFilter || brandFilter.length == 0 || brandFilter == "Select Brand") {
            setAlert("Brand Cannot be Empty", 'danger')
            return
        }
        if (!modelNumberFilter || modelNumberFilter.length == 0 || modelNumberFilter == "Select Model Number") {
            setAlert("Model Number Cannot be Empty", 'danger')
            return
        }
        setShowMQTTBatchDataList(true)
        getMQTTBatchData()
    };

    function resetFilters() {
        setCategoryFilter("Select Category")
        setBrandFilter("Select Brand")
        setModelNumberFilter("Select Model Number")
        setUpdateFlag(Math.random())
    };

    function getMQTTBatchData() {
        let filteredParams = {}
        if (categoryFilter && categoryFilter.length != 0 && categoryFilter != "Select Category") {
            filteredParams['categoryName'] = categoryFilter
        }
        if (brandFilter && brandFilter.length != 0 && brandFilter != "Select Brand") {
            filteredParams['brandName'] = brandFilter
        }
        if (modelNumberFilter && modelNumberFilter.length != 0 && modelNumberFilter != "Select Model Number") {
            filteredParams['modelNumber'] = modelNumberFilter
        }
        getFOTABatchList(filteredParams)
    };

    function displayDeleteBatchPopup(batchId) {
        setShowDeleteBatchPupup(true)
        setSelectedBatchId(batchId)
    }

    function closeDeleteBatchPopup() {
        setShowDeleteBatchPupup(false)
        setSelectedBatchId(undefined)
    }

    function handleBatchDelete() {
        if (!selectedBatchId) {
            setAlert("Invalid Batch Id", "dnager")
        }
        deleteFOTABatchById({ "batchId": selectedBatchId })
    }

    function displaySendMQTTTriggerUI(visible) {
        setDisplaySendMQTTTrigger(visible)
    }

    function downloadFirmwareUpgradeReport(batchId) {
        downloadFirmwareUpgradeReportFile(batchId)
    }

    if (fotaBatchDataLoading) {
        return (<div style={{ display: "grid", placeItems: "center", height: "20vh" }}>
            <CircularProgress size="5em" style={{ color: "#AFB42B" }} />
        </div>)
    }

    if (displaySendMQTTTrigger) {
        return (<MQTTTrigger
            allowedDeviceCategories={allowedDeviceCategories}
            allowedCompanyBrand={allowedCompanyBrand}
            firmwareData={firmwareData}
            setAlert={setAlert}
            sendFOTAMQTTTrigger={sendFOTAMQTTTrigger}
            displaySendMQTTTriggerUI={displaySendMQTTTriggerUI}
            resetFOTAMQTTTriggerStatus={resetFOTAMQTTTriggerStatus}
        >
        </MQTTTrigger>)
    }
    return (
        <Fragment>
            <div className="content-block bottom-shadow">
                <button
                    className="btn btn-style-2 orange-bg ml-15" style={{ marginTop: "10px" }}
                    onClick={() => {
                        setDisplaySendMQTTTrigger(true)
                    }}
                >
                    Notify Device
                </button>
                <div className="py-15 ">
                    <div className="row mb-0">
                        <div className="col s2 pt-10">
                            <div className="model-srch reset width-md">
                                <select
                                    className="custom-select select-reset radius-20"
                                    style={{ "display": "block !important" }}
                                    name="categoryFilter"
                                    onChange={(e) => {
                                        setCategoryFilter(e.target.value);
                                    }}
                                    value={categoryFilter}
                                >
                                    {["Select Category", ...allowedDeviceCategories].map((element, index) => (
                                        <option value={element} key={element + '_' + index}>{element}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="col s2 pt-10">
                            <div className="model-srch reset width-md">
                                <select
                                    className="custom-select select-reset radius-20"
                                    style={{ "display": "block !important" }}
                                    name="brandFilter"
                                    onChange={(e) => {
                                        setBrandFilter(e.target.value);
                                    }}
                                    value={brandFilter}
                                >
                                    {["Select Brand", ...allowedCompanyBrand].map((element, index) => (
                                        <option value={element} key={element + '_' + index}>{element}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="col s2 pt-10">
                            <div className="model-srch reset width-md">
                                <select
                                    className="custom-select select-reset radius-20"
                                    style={{ "display": "block !important" }}
                                    name="modelNumberFilter"
                                    onChange={(e) => {
                                        setModelNumberFilter(e.target.value);
                                    }}
                                    value={modelNumberFilter}
                                >
                                    {["Select Model Number", ...modelNumberFilterList].map((element, index) => {
                                        return <option value={element} key={element + '_' + index}>{element}</option>
                                    })}
                                </select>
                            </div>
                        </div>
                        <div className="col s2 pt-10">
                            <button
                                className="btn btn-style-2 orange-bg ml-15 mb-15"
                                onClick={filterMQTTBatchData}
                            >
                                Filter
                            </button>
                            <button
                                className="btn btn-style-2 orange-bg ml-15 mb-15"
                                onClick={resetFilters}
                            >
                                Reset
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {
                showMQTTBatchDataList &&
                <div className="content-block bottom-shadow py-15 mb-30">
                    <div className="row mb-0">
                        <div className="col s12">
                            <div
                                className="table-wrapper mb-20"
                                style={{
                                    overflowX: "scroll",
                                    scrollBehavior: "auto",
                                    maxHeight: "58vh",
                                    overflowY: "scroll",
                                }}
                            >
                                <table
                                    width="100%"
                                    align="center"
                                    className="table style-4 mb-20 manufacturedDevicesTable"
                                >
                                    <thead
                                        style={{ position: "sticky", top: 0, zIndex: 50, background: "white" }}
                                    >
                                        <tr>
                                            {Object.keys(tableHeaders).map((key) => (
                                                <th key={key}>{tableHeaders[key]}</th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {fotaBatchData == undefined || fotaBatchData.length === 0 ? (
                                            <tr>
                                                <td colSpan={Object.keys(tableHeaders).length}>
                                                    No Data found
                                                </td>
                                            </tr>
                                        ) : (
                                            fotaBatchData.map((element, index) => {
                                                return (
                                                    <tr key={index} id={index}>
                                                        {Object.keys(tableHeaders).map((key) => {
                                                            return (
                                                                key == "deleteBatch" ?
                                                                    <td
                                                                        className={
                                                                            String(element[key]).length > 200
                                                                                ? "breakWord"
                                                                                : ""
                                                                        }
                                                                    >
                                                                        <a href="javascript:void(0)" onClick={() => {
                                                                            displayDeleteBatchPopup(element["batchId"])
                                                                        }}><DeleteIcon style={{ color: "black" }}></DeleteIcon></a>
                                                                    </td>
                                                                    :
                                                                    key == 'mqttCommandFailed' || key == 'mqttCommandSent' || key == 'successfyllyUpgraded' ?
                                                                        <td
                                                                            className={
                                                                                String(element[key]).length > 200
                                                                                    ? "breakWord"
                                                                                    : ""
                                                                            }
                                                                        >
                                                                            {element[key]}
                                                                        </td> :
                                                                        key == 'downloadReport' ?
                                                                            <td>
                                                                                <a href="javascript:void(0)" onClick={() => {
                                                                                    downloadFirmwareUpgradeReport(element["batchId"])
                                                                                }}><DownloadIcon style={{ color: "black" }}></DownloadIcon></a>
                                                                            </td>
                                                                            :
                                                                            <td
                                                                                className={
                                                                                    String(element[key]).length > 200
                                                                                        ? "breakWord"
                                                                                        : ""
                                                                                }
                                                                            >
                                                                                {element[key] ? element[key] : ""}
                                                                            </td>
                                                            );
                                                        })}
                                                    </tr>
                                                );
                                            })
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {
                showDeleteBatchPopup && <Popup
                    visible={showDeleteBatchPopup}
                    notifyPopupClose={closeDeleteBatchPopup}
                >
                    <Fragment>
                        <div className="p-25 form-style-modal">
                            <div className="row">
                                <p style={{ fontSize: '16px' }}>Are you sure want to delete a batch?</p>
                                <br />
                            </div>
                            <div className="d-flex flex-end">
                                <button
                                    className="btn btn-style-3 sm min-140  dark-text gray-bg mr-10"
                                    onClick={handleBatchDelete}
                                >
                                    Delete Batch
                                </button>
                                <button
                                    className="btn btn-style-3 orange-bg sm min-140 mr-10"
                                    onClick={closeDeleteBatchPopup}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </Fragment>
                </Popup>
            }
        </Fragment>
    );
}

const mapStateToProps = (state) => ({
    allowedDeviceCategories: state.auth.allowedDeviceCategories,
    allowedCompanyBrand: state.auth.allowCompanyBrand,
    firmwareData: state.firmware,
    fotaJobs: state.fotaJobs
});
const mapDispatchToProps = (dispatch) => ({
    setAlert: (message, status) => dispatch(setAlert(message, status)),
    sendFOTAMQTTTrigger: (body) => dispatch(sendFOTAMQTTTrigger(body)),
    resetFOTAMQTTTriggerStatus: () => dispatch(resetFOTAMQTTTriggerStatus()),
    getFOTABatchList: (params) => dispatch(getFOTABatchList(params)),
    deleteFOTABatchById: (params) => dispatch(deleteFOTABatchById(params)),
    downloadFirmwareUpgradeReportFile: (batchId) => dispatch(downloadFirmwareUpgradeReportFile(batchId)),
    initFOTAJobs: () => dispatch(initFOTAJobs())
});

export default connect(mapStateToProps, mapDispatchToProps)(ManageMQTTFOTA);