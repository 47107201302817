export default function validate(values) {
    let errors = {};
        if (values.modelId!=null && values.modelId.trim().length===0) {
            errors.modelId = 'Model Id is required';
        }
        if (values.modelNumber!=null && values.modelNumber.trim().length===0) {
            errors.modelNumber = 'Model Number is required';
        }
        if (values.modelName!=null && values.modelName.trim().length===0) {
            errors.modelName = 'Model Name is required';
        }
        if (values.category!=null && values.category.trim().length===0) {
            errors.category = 'Category is required';
        }
        if (values.launchDate!=null && values.launchDate.trim().length===0) {
            errors.launchDate = 'Launch Date is required';
        }
        if (values.manufacturer!=null && values.manufacturer.trim().length===0) {
            errors.manufacturer = 'Manufacturer Name is required';
        }
        if (values.manufacturerAddress!=null && values.manufacturerAddress.trim().length===0) {
            errors.manufacturerAddress = 'Manufacturer Address is required';
        }
        if (values.productManagerId!=null && values.productManagerId.trim().length===0) {
            errors.productManagerId = 'Product Manager Id is required';
        }
        if (values.tonnage!=null && values.tonnage.trim().length===0) {
            errors.tonnage = 'Tonnage is required';
        }
        if (values.version!=null && values.version.trim().length===0) {
            errors.version = 'Version is required';
        }
        if (values.helpDocUrl!=null && values.helpDocUrl.trim().length===0) {
            errors.helpDocUrl = 'URL is required';
        }
    return errors;
  };
  