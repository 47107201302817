import React from "react";
import { connect } from "react-redux";
import Layout1 from "../layout1";
import Layout2 from "../layout2";

export const UserContext = React.createContext();
const Routes = ({ isAuthenticated, userLevel, userRoles, tenantInfo }) => {
  if (
    tenantInfo.selectedTheme &&
    ["newlayout"].includes(tenantInfo.selectedTheme.toLowerCase())
  ) {
    return (
      <UserContext.Provider
        value={{
          level: userLevel,
          roles: userRoles,
        }}
      >
        <Layout2 />
      </UserContext.Provider>
    );
  } else if (
    tenantInfo.selectedTheme &&
    ["round", "rectangular"].includes(tenantInfo.selectedTheme.toLowerCase())
  ) {
    require(`../../${tenantInfo.selectedTheme}.css`);

    return (
      <UserContext.Provider
        value={{
          level: userLevel,
          roles: userRoles,
        }}
      >
        <Layout1 />
      </UserContext.Provider>
    );
  } else {
    return (
      <UserContext.Provider
        value={{
          level: userLevel,
          roles: userRoles,
        }}
      >
        <Layout1 />
      </UserContext.Provider>
    );
  }
  // return (
  //   <UserContext.Provider
  //     value={{
  //       level: userLevel,
  //       roles: userRoles,
  //     }}
  //   >
  //     <section className="container">
  //       {isAuthenticated ? (
  //         <div>
  //           <Nav />
  //           <LeftNav />
  //         </div>
  //       ) : null}

  //       <Switch>
  //         <PrivateRoute path="/dashboard" component={UserSearchBar} />
  //         <PrivateRoute
  //           exact
  //           path="/dashboardBack"
  //           component={() => <Dashboard showDetail={true} />}
  //         />
  //         <PrivateRoute exact path="/searchDevice" component={SearchDevice} />
  //         <PrivateRoute exact path="/device" component={GetdModel} />
  //         <PrivateRoute exact path="/deviceDetail" component={SearchModel} />
  //         <PrivateRoute
  //           exact
  //           path="/deviceDetail/:modelNum"
  //           component={SearchModel}
  //         />
  //         <PrivateRoute exact path="/report/:homeId" component={Report} />
  //         <PrivateRoute exact path="/statistics" component={Statistics} />
  //         <PrivateRoute
  //           exact
  //           path="/productMetrics"
  //           component={ProductMetrics}
  //         />
  //         <PrivateRoute exact path="/fotaJobs" component={FotaJobs} />
  //         <PrivateRoute exact path="/firmwareInfo" component={FirmwareInfo} />
  //         <Route path="/pageNotFound" component={NotFound} />
  //         <Route component={NotFound} />
  //         <Redirect from="*" to="/pageNotFound" />
  //       </Switch>
  //     </section>
  //   </UserContext.Provider>
  // );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  userRoles: state.auth.userRoles,
  userLevel: state.auth.userLevel,
  tenantInfo: state.tenant.tenantInfo,
});
export default connect(mapStateToProps, {})(Routes);
