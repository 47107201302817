import React, { useState } from "react";
import PropTypes from "prop-types";
import UserSearchBar from "../components/UserSearchBar";

const UserInfoPage = (props) => {
  const [showText, setShowText] = useState(false);

  return (
    <UserSearchBar
      showText={showText}
      onSubmitSuccess={() => setShowText(true)}
      onSearchByChange={() => {
        setShowText(false);
      }}
    />
  );
};

UserInfoPage.propTypes = {};

export default UserInfoPage;
