import React, { Fragment, useState, useEffect } from "react";
import Alert from "../../layout/Alert";
import DeviceFactoryFirmware from "./FactoryFirmware";
import DeviceFotaFirmware from "./FotaFirmware";
import FirmwareDistribution from "./FirmwareDistribution"
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

const DeviceFirmware = ({
    wrapperClass = "right-content-wraper",
}) => {

    const [activeTab, setActiveTab] = React.useState(0)

    return (<Fragment>
        <div className={wrapperClass}>
            <Alert />

            <div className="row">
                <Tabs
                    value={activeTab}
                    onChange={(e, newValue) => {
                        setActiveTab(newValue)
                    }}
                    TabIndicatorProps={{
                        style: {
                            backgroundColor: "#000"
                        }
                    }}
                >
                    <Tab label="Firmware Distribution" />
                    <Tab label="Factory Firmware" />
                    <Tab label="FOTA Firmware" />
                </Tabs>
                <div hidden={activeTab != 0}>
                    <FirmwareDistribution></FirmwareDistribution>
                </div>
                <div hidden={activeTab != 1}>
                    <DeviceFactoryFirmware></DeviceFactoryFirmware>
                </div>
                <div hidden={activeTab != 2}>
                    <DeviceFotaFirmware></DeviceFotaFirmware>
                </div>
            </div>
        </div>

    </Fragment>
    );
}

export default DeviceFirmware

