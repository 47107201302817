import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getOnBoardAcDevices } from "../../../action/metrics";
import { seriesData, seriesXaxisData,totalFilterCount } from "./GraphParameter";
import Graph from "./Graph";

function OnboardAcDevice({onBordedAcDevice, graphType, getOnBoardAcDevices, type, setDeviceCount }) {
  useEffect(() => {
    if (type !== "4" ) {
      const que = {
        dateType: type === "1" ? "daily" : type === "2" ? "weekly" : "monthly",
        // "X-Tenant-ID": "panasonic",
      };
      getOnBoardAcDevices(que);
    }
  }, [getOnBoardAcDevices,type,graphType]);
//   let d = new Date();
  let seriesDataDevices = [];
  let intervalData = [];
  if (onBordedAcDevice.length) {
    seriesDataDevices = seriesData(onBordedAcDevice,graphType);
    intervalData = seriesXaxisData(onBordedAcDevice, graphType);
  }
  const totalCount = totalFilterCount(seriesDataDevices)
    useEffect(()=>{
      setDeviceCount(totalCount)
    },[totalCount])

  return (
    <div>
      <div id="chart">
        <Graph
          text={"Ac"}
          x_data={intervalData}
          title={"Number Of Ac's device"}
          y_data={seriesDataDevices}
          x_title = {"Onboarded Date"}
        />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    onBordedAcDevice: state.metrics.onBordedAcDevice,
  };
};
export default connect(mapStateToProps, {getOnBoardAcDevices})(OnboardAcDevice);
