import React, { Fragment, useState, useEffect, useRef } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import CircularProgress from "@material-ui/core/CircularProgress";
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { generateQRCode, fetchQRCode } from "../../action/qrcode";
import { getDeviceModelNumbersList } from './../../action/addvcModel'
import { useReactToPrint } from 'react-to-print';
import Alert from "../layout/Alert";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DatePicker from "react-datepicker";
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import { notificationConstants, notificationValidations } from "./notificationConstant";

const useStyles = makeStyles({
    selectInput: {
        height: "3rem",
        width: "100%"
    },
    selectValues: {
        width: "100%",
    },
    activeTab: {
        border: '1px solid #bdbdbd',
        backgroundColor: '#e0e0e0',
        textTransform: 'capitalize'
    },
    nonActiveTab: {
        border: 'none',
        backgroundColor: 'none',
        textTransform: 'capitalize'
    },
});

const headers = {
    productSerialNumber: "Product Serial Number",
    status: "QR Code Print Status",
    reasonOfFailure: "Reason of Failure"
};

const PushNotification = ({
    allowedDeviceCategories,
    allowedCompanyBrand,
    setAlert,
    pnMessage,
    handleMessageData,
    pnPlatforms,
    handlePlatformChange
}) => {


    return (
        <Fragment>
            <label className="title" style={{ fontSize: "14px", fontWeight: "bold" }}>Message</label>
            <div className="content-block position-relative" style={{ marginTop: "10px" }}>
                <div className="py-15">
                    <Grid container style={{ marginBottom: "20px" }}>
                        <Grid item xs={2} style={{ marginLeft: "10px" }}>
                            <label className="title" style={{ color: 'black', fontSize: '14px' }}>Select Platforms: </label>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl component="fieldset">
                                <FormGroup>
                                    <FormControlLabel
                                        control={<Checkbox checked={pnPlatforms['iosPlatform']} onChange={(e) => {
                                            handlePlatformChange(e.target.name, e.target.checked)
                                        }} name={notificationConstants.iosPlatform} />}
                                        label="iOS"
                                    />
                                    <FormControlLabel
                                        control={<Checkbox checked={pnPlatforms['androidPlatform']} onChange={(e) => {
                                            handlePlatformChange(e.target.name, e.target.checked)
                                        }} name={notificationConstants.androidPlatform} />}
                                        label="Android"
                                    />
                                </FormGroup>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container style={{ marginBottom: "20px" }}>
                        <Grid item xs={2} style={{ marginLeft: "10px" }}>
                            <label className="title" style={{ color: 'black', fontSize: '14px' }}>Message: </label>
                        </Grid>
                        <Grid item xs={4}>
                            <TextareaAutosize
                                aria-label="minimum height"
                                placeholder="Write a Message"
                                style={{ height: '100px', padding: "10px" }}
                                onChange={(e) => {
                                    handleMessageData((e.target.value).substring(0, notificationValidations?.push?.body?.maxLength))
                                }}
                                value={pnMessage}
                            />
                            <p>{(pnMessage?.length > notificationValidations?.push?.body?.maxLength ? notificationValidations?.push?.body?.maxLength : pnMessage?.length) + "/" + notificationValidations?.push?.body?.maxLength}</p>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </Fragment>
    );
}


export default PushNotification