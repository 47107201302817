import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import M from "materialize-css/dist/js/materialize.min.js";
import DeviceCatogary from './DeviceCatogary';
import Device_model_filter from './Device_model_filter';

function Filter({ handleDataFilter, selectedFilter, setSelectedFilter, deviceCount, title, selectedGraph,totalUserCount, handleDeviceCatogary, setModalFilterType, setDevicesCatogary }) {
  const [showDateCalendar, setShowDateCalendar] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  useEffect(() => {
    M.AutoInit();
  }, []);

  useEffect(() => {
    resetDate();
  }, [selectedGraph]);

  const handleClickfilter = (type) => {
    setSelectedFilter(type);
    if (type === "4") setShowDateCalendar(true);
    else {
      resetDate();
    } 
  };

  const resetDate = () =>{
    setEndDate("");
    setStartDate("");
    setShowDateCalendar(false);
  }

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };
  const handleEndDateChange = (date) => {
    setEndDate(date);
  };
  const handleClickDateRange = (startDate, endDate) => {
    // if(selectedGraph === "2") {
      handleDataFilter(startDate, endDate)
      
    // }
    // else {
    //   resetDate();
    // }
  }
  return (
    <div>
      <div>
      {(selectedGraph === "8") && <Device_model_filter resetDate = {resetDate} setSelectedFilter = {setSelectedFilter} setModalFilterType = {setModalFilterType} setDevicesCatogary = {setDevicesCatogary}/>}

        <div className="row reset-device-block mb-0 pb-15">
          <div className="s3 col m-0">
            <div className="hd-style-5 device_main_div">
            {selectedGraph === "3"  && <div className = "black-text font-600 title_flex title_border">Registered User Count
                      <div className = "gray-text-light device_no">{totalUserCount}</div>
                    </div>}
              <div class="black-text font-600 title_flex ">
                {title}
                <div className="gray-text-light device_no"> {deviceCount?deviceCount:0} </div>
              </div>
            </div>
          </div>



          {showDateCalendar && (
            <div className="s6 col d-flex justify-content-between">
              <div className="control-capabilities">
                <div className="model-srch reset w-auto">
                  <DatePicker
                    selected={startDate}
                    maxDate={new Date()}
                    onChange={handleStartDateChange}
                    placeholderText="Start Date"
                    dateFormat="dd/MM/yyyy"
                    className="add-input calendar input_date"
                  />
                </div>
              </div>
              <div className="control-capabilities">
                <div className="model-srch reset w-auto">
                  <DatePicker
                    selected={endDate}
                    maxDate={new Date()}
                    onChange={handleEndDateChange}
                    placeholderText="End Date"
                    dateFormat="dd/MM/yyyy"
                    className="add-input calendar input_date "
                  />
                </div>
              </div>
              <button
                className="date_button orange-bg filter_btn"
                onClick={() => handleClickDateRange(startDate, endDate)}
              >
                Search
              </button>
            </div>
          )}
                    <div className="s3 col">
            <div className="control-capabilities">
              <div className="model-srch reset w-auto">
                {(selectedGraph === "2" || selectedGraph === "1") && <DeviceCatogary handleDeviceCatogary= {handleDeviceCatogary} />}
              </div>
            </div>
          </div>
        </div>
        <div className="right">
          <div className="filter-value">
            <div
              className={`${selectedFilter === "1" ? "selectedFilter" : ""}`}
              onClick={() => handleClickfilter("1")}
            >
              Daily
            </div>
            <div
              className={`${selectedFilter === "2" ? "selectedFilter" : ""}`}
              onClick={() => handleClickfilter("2")}
            >
              Weekly
            </div>
            <div
              className={`${selectedFilter === "3" ? "selectedFilter" : ""}`}
              onClick={() => handleClickfilter("3")}
            >
              Monthly
            </div>
            <div
              className={`${selectedFilter === "4" ? "selectedFilter" : ""}`}
              onClick={() => handleClickfilter("4")}
            >
              Date Range
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Filter;
