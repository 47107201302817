import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import M from "materialize-css/dist/js/materialize.min.js";
import ProductMetricsSingle from "./ProductMetricsSingle";
import Popup from "../layout/Popup";
import customConfig from "../../config";
import { getMetricsData } from "../../action/report";
import Spinner from "../layout/Spinner"; 
import missing_logo from '../../assets/no-found.png'

const ProductMetrics = ({
  getMetricsData,
  wrapperClass = "right-content-wraper",
  reportData: { metricsData, metricsError, metricsLoading }
}) => {
  const [showSelected, setShowSelected] = useState(false);
  const [title, setTitle] = useState("");
  const [imgSrc, setImgSrc] = useState("");
  

  useEffect(() => {
    M.AutoInit();
    getMetricsData();
    const timer = setInterval(
      () => getMetricsData(),
      1000 * 60 * 60 * customConfig.productMetricsRefreshHour
    );
    return () => clearInterval(timer);
  }, []);

  const getImageSource = (imgName) => {
    let item = metricsData.find((m) => m.fileName === imgName);
    return item ? `data:image/jpeg;base64,${item.base64}` : missing_logo;
  };

  const handleReportSelection = (reportTitle, imgName) => {
    setTitle(reportTitle);
    setImgSrc(getImageSource(imgName));
    setShowSelected(true);
  };

  const handleSelectedPopupClose = () => {
    setShowSelected(false);
  };

  if (metricsLoading) {
    return (
      <div style={{ position: "absolute", top: "50%", left: "50%" }}>
        {" "}
        <Spinner />{" "}
      </div>
    );
  }

  return (
    <Fragment>
      <div className={wrapperClass}>
        <div className="container">
          <div className="page-title">
            <h3>Product Metrics</h3>
          </div>
          {showSelected && (
            <Popup
              visible={showSelected}
              notifyPopupClose={handleSelectedPopupClose}
            >
              <ProductMetricsSingle
                reportTitle={title}
                imageURL={imgSrc}
              ></ProductMetricsSingle>
            </Popup>
          )}
          <div className="px-70">
            {metricsData && metricsData.length > 0 ? (
              <div className="row">
                <div className="s4 col auto-height">
                  <div className="content-block bottom-shadow  m-10">
                    <div
                      className="p-5 cursor-Hand"
                      onClick={() =>
                        handleReportSelection(
                          customConfig.productMetricsTitle1,
                          customConfig.productMetricsFileName1
                        )
                      }
                    >
                      <div className="metrix-box">
                        <img className = "metric-image"
                          src={getImageSource(
                            customConfig.productMetricsFileName1
                          )}
                        />
                      </div>
                      <div className="font-12 center-align medium-font">
                        {customConfig.productMetricsTitle1}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="s4 col auto-height">
                  <div className="content-block bottom-shadow  m-10">
                    <div
                      className="p-5 cursor-Hand"
                      onClick={() =>
                        handleReportSelection(
                          customConfig.productMetricsTitle2,
                          customConfig.productMetricsFileName2
                        )
                      }
                    >
                      <div className="metrix-box">
                        <img className = "metric-image"
                          src={getImageSource(
                            customConfig.productMetricsFileName2
                          )}
                        />
                      </div>
                      <div className="font-12 center-align medium-font">
                        {customConfig.productMetricsTitle2}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="s4 col auto-height">
                  <div className="content-block bottom-shadow  m-10">
                    <div
                      className="p-5 cursor-Hand"
                      onClick={() =>
                        handleReportSelection(
                          customConfig.productMetricsTitle3,
                          customConfig.productMetricsFileName3
                        )
                      }
                    >
                      <div className="metrix-box">
                        <img className = "metric-image"
                          id="img3"
                          src={getImageSource(
                            customConfig.productMetricsFileName3
                          )}
                        />
                      </div>
                      <div className="font-12 center-align medium-font">
                        {customConfig.productMetricsTitle3}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="s4 col auto-height">
                  <div className="content-block bottom-shadow  m-10">
                    <div
                      className="p-5 cursor-Hand"
                      onClick={() =>
                        handleReportSelection(
                          customConfig.productMetricsTitle4,
                          customConfig.productMetricsFileName4
                        )
                      }
                    >
                      <div className="metrix-box">
                        <img className = "metric-image"
                          src={getImageSource(
                            customConfig.productMetricsFileName4
                          )}
                        />
                      </div>
                      <div className="font-12 center-align medium-font">
                        {customConfig.productMetricsTitle4}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="s4 col auto-height">
                  <div className="content-block bottom-shadow  m-10">
                    <div
                      className="p-5 cursor-Hand"
                      onClick={() =>
                        handleReportSelection(
                          customConfig.productMetricsTitle5,
                          customConfig.productMetricsFileName5
                        )
                      }
                    >
                      <div className="metrix-box">
                        <img className = "metric-image"
                          src={getImageSource(
                            customConfig.productMetricsFileName5
                          )}
                        />
                      </div>
                      <div className="font-12 center-align medium-font">
                        {customConfig.productMetricsTitle5}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="s4 col auto-height">
                  <div className="content-block bottom-shadow  m-10">
                    <div
                      className="p-5 cursor-Hand"
                      onClick={() =>
                        handleReportSelection(
                          customConfig.productMetricsTitle6,
                          customConfig.productMetricsFileName6
                        )
                      }
                    >
                      <div className="metrix-box">
                        <img className = "metric-image"
                          src={getImageSource(
                            customConfig.productMetricsFileName6
                          )}
                        />
                      </div>
                      <div className="font-12 center-align medium-font">
                        {customConfig.productMetricsTitle6}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="s4 col auto-height">
                  <div className="content-block bottom-shadow  m-10">
                    <div
                      className="p-5 cursor-Hand"
                      onClick={() =>
                        handleReportSelection(
                          customConfig.productMetricsTitle7,
                          customConfig.productMetricsFileName7
                        )
                      }
                    >
                      <div className="metrix-box">
                        <img className = "metric-image"
                          src={getImageSource(
                            customConfig.productMetricsFileName7
                          )}
                        />
                      </div>
                      <div className="font-12 center-align medium-font">
                        {customConfig.productMetricsTitle7}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="s4 col auto-height">
                  <div className="content-block bottom-shadow  m-10">
                    <div
                      className="p-5 cursor-Hand"
                      onClick={() =>
                        handleReportSelection(
                          customConfig.productMetricsTitle8,
                          customConfig.productMetricsFileName8
                        )
                      }
                    >
                      <div className="metrix-box">
                        <img className = "metric-image"
                          src={getImageSource(
                            customConfig.productMetricsFileName8
                          )}
                        />
                      </div>
                      <div className="font-12 center-align medium-font">
                        {customConfig.productMetricsTitle8}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="s4 col auto-height">
                  <div className="content-block bottom-shadow  m-10">
                    <div
                      className="p-5 cursor-Hand"
                      onClick={() =>
                        handleReportSelection(
                          customConfig.productMetricsTitle9,
                          customConfig.productMetricsFileName9
                        )
                      }
                    >
                      <div className="metrix-box">
                        <img className = "metric-image"
                          src={getImageSource(
                            customConfig.productMetricsFileName9
                          )}
                        />
                      </div>
                      <div className="font-12 center-align medium-font">
                        {customConfig.productMetricsTitle9}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="s4 col auto-height">
                  <div className="content-block bottom-shadow  m-10">
                    <div
                      className="p-5 cursor-Hand"
                      onClick={() =>
                        handleReportSelection(
                          customConfig.productMetricsTitle10,
                          customConfig.productMetricsFileName10
                        )
                      }
                    >
                      <div className="metrix-box">
                        <img className = "metric-image"
                          src={getImageSource(
                            customConfig.productMetricsFileName10
                          )}
                        />
                      </div>
                      <div className="font-12 center-align medium-font">
                        {customConfig.productMetricsTitle10}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="s4 col auto-height">
                  <div className="content-block bottom-shadow  m-10">
                    <div
                      className="p-5 cursor-Hand"
                      onClick={() =>
                        handleReportSelection(
                          customConfig.productMetricsTitle11,
                          customConfig.productMetricsFileName11
                        )
                      }
                    >
                      <div className="metrix-box">
                        <img className = "metric-image"
                          src={getImageSource(
                            customConfig.productMetricsFileName11
                          )}
                        />
                      </div>
                      <div className="font-12 center-align medium-font">
                        {customConfig.productMetricsTitle11}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="s4 col auto-height">
                  <div className="content-block bottom-shadow  m-10">
                    <div
                      className="p-5 cursor-Hand"
                      onClick={() =>
                        handleReportSelection(
                          customConfig.productMetricsTitle12,
                          customConfig.productMetricsFileName12
                        )
                      }
                    >
                      <div className="metrix-box">
                        <img className = "metric-image"
                          src={getImageSource(
                            customConfig.productMetricsFileName12
                          )}
                        />
                      </div>
                      <div className="font-12 center-align medium-font">
                        {customConfig.productMetricsTitle12}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="s4 col auto-height">
                  <div className="content-block bottom-shadow  m-10">
                    <div
                      className="p-5 cursor-Hand"
                      onClick={() =>
                        handleReportSelection(
                          customConfig.productMetricsTitle13,
                          customConfig.productMetricsFileName13
                        )
                      }
                    >
                      <div className="metrix-box">
                        <img className = "metric-image"
                          src={getImageSource(
                            customConfig.productMetricsFileName13
                          )}
                        />
                      </div>
                      <div className="font-12 center-align medium-font">
                        {customConfig.productMetricsTitle13}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="s12 col">
                <div className="boxes">
                  <div className="info error content-reset">
                    <div className="text-box">
                      <i className="no-data-icon"></i>
                      <label>
                        {metricsError && metricsError.msg
                          ? metricsError.msg
                          : customConfig.noProductMetricsMsg}{" "}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  reportData: state.report,
});

export default connect(mapStateToProps, { getMetricsData })(ProductMetrics);
