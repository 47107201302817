import React, { Fragment } from 'react'

const ProductMetricsSingle = ({ reportTitle, imageURL }) => {

    return (
        <Fragment>
           <div className="p-15">
                <div className="metrics-box large mb-10">
                <img src={imageURL} />

                </div>

                 <div className="font-22 center-align medium-font">{reportTitle}</div>
           </div>



        </Fragment>
    )
}

export default ProductMetricsSingle;