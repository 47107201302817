import {
    UPLOAD_DEVICE_FACTORY_FIRMWARE,
    UPLOAD_DEVICE_FACTORY_FIRMWARE_SUCCESS,
    UPLOAD_DEVICE_FACTORY_FIRMWARE_ERROR,
    UPLOAD_DEVICE_FOTA_FIRMWARE,
    UPLOAD_DEVICE_FOTA_FIRMWARE_SUCCESS,
    UPLOAD_DEVICE_FOTA_FIRMWARE_ERROR,
    UPLOAD_DEVICE_FOTA_FIRMWARE_INIT,
    SEND_FOTA_MQTT_TRIGGER,
    SEND_FOTA_MQTT_TRIGGER_SUCCESS,
    SEND_FOTA_MQTT_TRIGGER_FAILURE,
    RESET_FOTA_MQTT_TRIGGER_STATUS
} from "../action/types";

const initialState = {
    uploadDeviceFactoryFirmwareInProgress: false,
    uploadDeviceFactoryFirmwareSuccess: false,
    uploadDeviceFactoryFirmwareFailure: false,

    uploadDeviceFOTAFirmwareInProgress: false,
    uploadDeviceFOTAFirmwareSuccess: false,
    uploadDeviceFOTAFirmwareFailure: false,

    uploadedDeviceFotaFirmwareResult: [],

    sendFOTAMQTTTriggerInProgress: false,
    sendFOTAMQTTTriggerSuccess: false,
    sendFOTAMQTTTriggerFailure: false
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case UPLOAD_DEVICE_FACTORY_FIRMWARE:
            return {
                ...state,
                uploadDeviceFactoryFirmwareInProgress: true,
                uploadDeviceFactoryFirmwareSuccess: false,
                uploadDeviceFactoryFirmwareFailure: false
            }
        case UPLOAD_DEVICE_FACTORY_FIRMWARE_SUCCESS:
            return {
                ...state,
                uploadDeviceFactoryFirmwareInProgress: false,
                uploadDeviceFactoryFirmwareSuccess: true,
                uploadDeviceFactoryFirmwareFailure: false, 
            };
        case UPLOAD_DEVICE_FACTORY_FIRMWARE_ERROR:
            return {
                ...state,
                uploadDeviceFactoryFirmwareInProgress: false,
                uploadDeviceFactoryFirmwareSuccess: false,
                uploadDeviceFactoryFirmwareFailure: true
            };

        case UPLOAD_DEVICE_FOTA_FIRMWARE_INIT: 
            return {
                ...state,
                uploadDeviceFOTAFirmwareInProgress: false,
                uploadDeviceFOTAFirmwareSuccess: false,
                uploadDeviceFOTAFirmwareFailure: false,
                uploadedDeviceFotaFirmwareResult: []
            }
        case UPLOAD_DEVICE_FOTA_FIRMWARE:
            return {
                ...state,
                uploadDeviceFOTAFirmwareInProgress: true,
                uploadDeviceFOTAFirmwareSuccess: false,
                uploadDeviceFOTAFirmwareFailure: false,
                uploadedDeviceFotaFirmwareResult: []
            }
        case UPLOAD_DEVICE_FOTA_FIRMWARE_SUCCESS:
            return {
                ...state,
                uploadDeviceFOTAFirmwareInProgress: false,
                uploadDeviceFOTAFirmwareSuccess: true,
                uploadDeviceFOTAFirmwareFailure: false,
                uploadedDeviceFotaFirmwareResult: payload
            };
        case UPLOAD_DEVICE_FOTA_FIRMWARE_ERROR:
            return {
                ...state,
                uploadDeviceFOTAFirmwareInProgress: false,
                uploadDeviceFOTAFirmwareSuccess: false,
                uploadDeviceFOTAFirmwareFailure: true,
                uploadedDeviceFotaFirmwareResult: []
            };

        case SEND_FOTA_MQTT_TRIGGER: 
            return {
                ...state,
                sendFOTAMQTTTriggerInProgress: true,
                sendFOTAMQTTTriggerFailure: false,
                sendFOTAMQTTTriggerSuccess: false
            };
        case SEND_FOTA_MQTT_TRIGGER_SUCCESS:
            return {
                ...state,
                sendFOTAMQTTTriggerInProgress: false,
                sendFOTAMQTTTriggerFailure: false,
                sendFOTAMQTTTriggerSuccess: true
            }
        case SEND_FOTA_MQTT_TRIGGER_FAILURE:
            return {
                ...state,
                sendFOTAMQTTTriggerInProgress: false,
                sendFOTAMQTTTriggerFailure: true,
                sendFOTAMQTTTriggerSuccess: false
            }
        case RESET_FOTA_MQTT_TRIGGER_STATUS:
            return {
                ...state,
                sendFOTAMQTTTriggerInProgress: false,
                sendFOTAMQTTTriggerFailure: false,
                sendFOTAMQTTTriggerSuccess: false
            }
        default:
            return state;
    }
}
