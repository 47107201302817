import React from "react";

const ReportTab = ({ report: { report, homeName } }) => {
  return (
 
      <div className="col s4">
          <div className="block mb-15">
            <label className="label">Home Name</label>

        {/* <span className="d-inline hd-style-2"> Home Name - </span>{" "} */}
        <div className="value">{homeName}</div>
      </div> </div>

     
   
  );
};

ReportTab.propTypes = {};

export default ReportTab;
