import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles({
    labelColor: {
        color: "#000"
    },
    selectInput: {
        height: "3rem",
        width: "100%"
    },
    capabilityListDropdown: {
        marginTop: "20px",
        marginBottom: "20px"
    },
    addCapabilityButton: {
        marginTop: "45px",
        marginBottom: "20px",
    },
    capabilityCard: {
        marginBottom: "15px",
        backgroundColor: "#f2f2f2"
    },
    deleteCapabilityCardButton: {
        float: "right"
    }
});

const DeviceModelStatusCapability = ({
    productCategory,
    isDeviceModelAddedUsingFileUpload,
    triggerAddNewDeviceModel,
    addDeviceStatusCapabilties,
    editDeviceModel,
    deviceModelData,
    ModelData: { deviceModelsMetadata },
}) => {
    const classes = useStyles();
    const [statusCapabiltiesList, setStatusCapabiltiesList] = useState(Object.keys(deviceModelsMetadata?.categories?.[productCategory]?.statusCapabilities ?? {}).sort())
    const [selectedStatusCapability, setSelectedStatusCapability] = useState(Object.keys(deviceModelsMetadata?.categories?.[productCategory]?.statusCapabilities ?? {}).sort()?.[0] ?? "")
    const [addedStatusCapabilities, setAddedStatusCapabilities] = useState([])
    const [statusCapabilitiesAttributes, setStatusCapabilitiesAttributes] = useState({})

    useEffect(() => {
        let nextSelectedCapability = ""
        if (statusCapabiltiesList && statusCapabiltiesList.length > 0) {
            nextSelectedCapability = statusCapabiltiesList[0]
        }
        setSelectedStatusCapability(nextSelectedCapability)

    }, [statusCapabiltiesList])

    useEffect(() => {
        let stsCapabilitiesAttributes = {}

        if (editDeviceModel || isDeviceModelAddedUsingFileUpload) {
            let statusCapabilities = deviceModelData.statusCapabilitiesV2
            for (let capability of statusCapabilities) {

                let capabilityName = capability['capability']
                stsCapabilitiesAttributes[capabilityName] = {}
                let attributes = capability['attributes'];

                if (attributes) {
                    stsCapabilitiesAttributes[capabilityName]["attributes"] = {}

                    for (let index in attributes) {
                        stsCapabilitiesAttributes[capabilityName]["attributes"][index] = {}
                        stsCapabilitiesAttributes[capabilityName]["attributes"][index]['key'] = attributes[index]['key']
                    }
                }
            }
        }

        let statusCapabilities = deviceModelsMetadata?.categories?.[productCategory]?.statusCapabilities;
        if (statusCapabilities) {
            for (let capabilityName in statusCapabilities) {

                if (stsCapabilitiesAttributes.hasOwnProperty(capabilityName)) {
                    continue;
                }

                stsCapabilitiesAttributes[capabilityName] = {}
                let attributes = statusCapabilities[capabilityName]['attributes'];
                if (attributes) {
                    stsCapabilitiesAttributes[capabilityName]["attributes"] = {}

                    for (let index in attributes) {
                        stsCapabilitiesAttributes[capabilityName]["attributes"][index] = {}
                        stsCapabilitiesAttributes[capabilityName]["attributes"][index]['key'] = attributes[index]['key'] ? attributes[index]['key'] : ""
                    }
                }
            }
        }
        setStatusCapabilitiesAttributes(stsCapabilitiesAttributes)

    }, [productCategory, editDeviceModel, isDeviceModelAddedUsingFileUpload])

    useEffect(() => {
        if (editDeviceModel || isDeviceModelAddedUsingFileUpload) {
            let addedStatusCapabilitiesList = []
            for (let statusCapability of deviceModelData['statusCapabilitiesV2']) {
                addedStatusCapabilitiesList.push(statusCapability['capability'])
            }

            setAddedStatusCapabilities(addedStatusCapabilitiesList)
            setStatusCapabiltiesList((prevState) => {
                return prevState.filter((capabilityName) => !(addedStatusCapabilitiesList.includes(capabilityName))).sort()
            })
        }
        else {
            setStatusCapabiltiesList(deviceModelsMetadata["categories"][productCategory] ? Object.keys(deviceModelsMetadata["categories"][productCategory]["statusCapabilities"]) : [])
            setAddedStatusCapabilities([])
        }
    }, [productCategory, editDeviceModel, isDeviceModelAddedUsingFileUpload])

    useEffect(() => {
        if (triggerAddNewDeviceModel) {
            let statusCapabilitiesData = getStatusCapabilityData();
            addDeviceStatusCapabilties(statusCapabilitiesData)
        }
    }, [triggerAddNewDeviceModel])

    function handleSelectStatusCapability(selectedCapability) {
        setSelectedStatusCapability(selectedCapability)
    }

    function handleAddStatusCapability(addedCapabilityName) {
        setAddedStatusCapabilities((prevState) => {
            return [...prevState, addedCapabilityName]
        })

        setStatusCapabiltiesList((prevState) => {
            return prevState.filter((capabilityName) => capabilityName != selectedStatusCapability).sort()
        })
    }

    function handleRemoveStatusCapability(removedCapabilityName) {
        setAddedStatusCapabilities((prevState) => {
            return prevState.filter((capabilityName) => capabilityName != removedCapabilityName).sort()
        })

        setStatusCapabiltiesList((prevState) => {
            return [...prevState, removedCapabilityName]
        })
    }

    function onChangeAttributeKeyName(capabilityName, attributeIndex, keyName) {
        let capabilityAttributes = { ...statusCapabilitiesAttributes }
        capabilityAttributes[capabilityName]["attributes"][attributeIndex]["key"] = keyName

        handleStatusCapabilitiesAttributes(capabilityAttributes)
    }

    function handleStatusCapabilitiesAttributes(capabilityAttributes) {
        setStatusCapabilitiesAttributes(capabilityAttributes)
    }

    function getStatusCapabilityData() {
        let statusCapabilities = []

        for (let capabilityName of addedStatusCapabilities) {
            let capabilityData = {}
            capabilityData['capability'] = capabilityName

            let attributes = statusCapabilitiesAttributes[capabilityName]['attributes'];
            if(attributes){
                capabilityData['attributes'] = []
            }
            for (let index in attributes) {
                let attributeData = {}
                attributeData['key'] = attributes[index]['key']
                capabilityData['attributes'].push(attributeData)
            }

            statusCapabilities.push(capabilityData)
        }
        return statusCapabilities;
    }

    return (
        <Fragment>
            <div className="row">
                <div className={`s4 col ${classes.capabilityListDropdown}`}>
                    <label className="title d-inline min-180 mb-5 mt-20">Select Capability</label>
                    <Select
                        value={selectedStatusCapability}
                        onChange={(e) => {
                            handleSelectStatusCapability(e.target.value)
                        }}
                        displayEmpty
                        input={<OutlinedInput />}
                        inputProps={{ 'aria-label': 'Without label' }}
                        className={classes.selectInput}
                    >
                        {
                            statusCapabiltiesList?.sort().map((capabilityName) => {
                                return <MenuItem key={capabilityName} value={capabilityName}>{capabilityName}</MenuItem>
                            })
                        }
                    </Select>
                </div>
                <div className={`s4 col`}>
                    <button
                        className={`btn btn-style-2 orange-bg mr-5 ${classes.addCapabilityButton}`}
                        onClick={() => {
                            handleAddStatusCapability(selectedStatusCapability)
                        }}
                        disabled={statusCapabiltiesList && statusCapabiltiesList.length == 0}
                    >
                        Add Capability
                    </button>
                </div>
            </div>
            {
                addedStatusCapabilities.map((capabilityName) => {
                    return <Fragment key={productCategory + '_' + capabilityName}>
                        <Card variant="outlined" className={`${classes.capabilityCard}`} key={capabilityName}>
                            <CardContent>
                                <Grid container xs={12}>
                                    <Grid item xs={11}>
                                        <Typography variant="button">{capabilityName}</Typography>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <IconButton aria-label="delete" onClick={(e) => {
                                            handleRemoveStatusCapability(capabilityName)
                                        }} className={`${classes.deleteCapabilityCardButton}`}>
                                            <DeleteIcon fontSize="small" />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                                {deviceModelsMetadata?.categories?.[productCategory]?.statusCapabilities?.[capabilityName]?.attributes?.map((element, index) => {
                                        return <Grid key={capabilityName + '_' + index} container>
                                            <Grid item xs={1}>
                                                <Typography>Attribute</Typography>
                                            </Grid>
                                            <Grid item xs={4} className={classes.attributeKey}>
                                                <input
                                                    placeholder="Enter Key Name"
                                                    className="add-input xl"
                                                    value={statusCapabilitiesAttributes[capabilityName]["attributes"][index]["key"]}
                                                    type="text"
                                                    disabled={true}
                                                    onChange={(e) => {
                                                        onChangeAttributeKeyName(capabilityName, index, e.target.value)
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    })}
                            </CardContent>
                        </Card>
                    </Fragment>
                })
            }
        </Fragment>
    )
}

const mapStateToProps = (state) => ({
    ModelData: state.getModel
});

export default connect(mapStateToProps)(DeviceModelStatusCapability);