import axios from "axios";
import { setAlert } from "./alert";
import { LOGIN_SUCCESS, LOGIN_FAIL, LOGOUT, CLEAR_USER, UM_V1_AUTH_TOKEN } from "./types";
import setAuthToken from "../utils/setAuthToken";
import customConfig from "../config";
import env from "../env";
import { OutlinedFlag } from "@material-ui/icons";

// Login User
export const login =
  (dispatch) => async (userName, password, clientId, scope, recaptchaValue) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        "captcha-response": recaptchaValue,
      },
    };
    const body = { userName, password, clientId };
    try {
      let umURL = env.GET_USERMANAGEMENT_VERSION() == customConfig.umV1Version ? env.GET_USERMANAGEMENT_API_URL() : env.GET_USERMANAGEMENT_API_URL_V2()
      let authURL = env.GET_USERMANAGEMENT_VERSION() == customConfig.umV1Version
        ? `${umURL}/simplifi/v1/userManagement/webLogin`
        : `${umURL}/simplifi/v2/userManagement/login`

      const res = await axios.post(
        authURL,
        body,
        config
      );

      // Check role of logged in user
      const configVal = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${res.data.accessToken}`,
        },
      };
      const resVal =
      env.GET_USERMANAGEMENT_VERSION() == customConfig.umV1Version ? await axios.get(
        `${umURL}/simplifi/v1/userManagement/tokenDetails`,
        configVal
      ) : await axios.get(
        `${umURL}/simplifi/v2/userManagement/tokenDetails`,
        configVal
      )

      // Check for valid roles
      let userLevel = 0;
      let roleList = resVal.data.roles;
      let loggedInUserRoles = ""

      // localStorage.setItem("loggedInUserName", resVal.data.username);
      if (roleList.indexOf(customConfig.roleCustomerSupportL4) > -1) {
        userLevel = 5;
      } else if (
        roleList.indexOf(customConfig.roleCustomerSupportL3Special) > -1
      ) {
        userLevel = 4;
      } else if (roleList.indexOf(customConfig.roleCustomerSupportL3) > -1) {
        userLevel = 3;
      } else if (roleList.indexOf(customConfig.roleCustomerSupportL2) > -1) {
        userLevel = 2;
      } else if (roleList.indexOf(customConfig.roleCustomerSupportL1) > -1) {
        userLevel = 1;
      } else if (roleList.indexOf(customConfig.roleFactoryAdmin) > -1) {
        loggedInUserRoles = customConfig.roleFactoryAdmin
      }


      let allowCompanyBrand = [];
      let allowedDeviceCategories = []
      let allowedCountries = [];
      let allowedCountryCodes = [];
      resVal.data.permissions.forEach((element) => {
        if (element.country === "BGD") {
          allowedCountries.push("BD".toLowerCase());

        }
        else if (element.country.substring(0, 2) === "SL") {
          allowedCountries.push("LK".toLowerCase()); //LK as country code for srilanka
        }
        else {
          allowedCountries.push(element.country.toLowerCase());

        }
        allowedDeviceCategories.push(...element.categories);
        allowCompanyBrand.push(element.brand);

        if (allowedCountryCodes.indexOf(element.country) == -1) {
          allowedCountryCodes.push(element.country)
        }
      });

      // resVal.data.roles.forEach();
      //for removing duplicate value in list
      allowCompanyBrand = allowCompanyBrand.filter(
        (value, index, self) => self.indexOf(value) === index
      );
      //for removing duplicate category in list
      allowedDeviceCategories = allowedDeviceCategories.filter(
        (value, index, self) => self.indexOf(value) === index
      );

      //for removing duplicate countries in list
      allowedCountries = allowedCountries.filter(
        (value, index, self) => self.indexOf(value) === index
      );

      // Modified the login.. Now Factory Admin Users can also log in to webapp
      if (userLevel > 0 || [customConfig.roleFactoryAdmin].includes(loggedInUserRoles)) {
        // localStorage.setItem("userRoles", JSON.stringify(roleList));
        // localStorage.setItem("userLevel", userLevel);
        dispatch({
          type: LOGIN_SUCCESS,
          payload: {
            ...res.data,
            ...resVal.data,
            allowedCountries,
            userRoles: roleList,
            userLevel: userLevel,
            allowedDeviceCategories,
            allowCompanyBrand,
            allowedCountryCodes
          },
        });
        setAuthToken(res.data.accessToken);

        // Additonal Requirement in case of v2 UM Version
        if (env.GET_UM_MOBILE_USERS_VERSION() == customConfig.umV1Version) {

          // in case if the mobile users are not migrated.. We have to use UM V1 token for mobile users search
          // in future if mobile users migrated to UM2, no point of using UM V1 token
          if (env.GET_USERMANAGEMENT_VERSION() == customConfig.umV2Version) {
            let umV1AuthToken = await getUMV1AuthToken(userName, password, env.GET_CLIENT_ID(), res.data.accessToken);

            dispatch({
              type: UM_V1_AUTH_TOKEN,
              payload: umV1AuthToken
            })
          }
          else {
            dispatch({
              type: UM_V1_AUTH_TOKEN,
              payload: res.data.accessToken
            })
          }
        }

      } else {
        dispatch(setAlert(customConfig.customerSupportRoleErrorMsg, "danger"));
        dispatch({
          type: LOGIN_FAIL,
        });
      }
    } catch (err) {
      console.log("This is error : ", err);
      if (!err.response) {
        dispatch(setAlert(customConfig.serverDownMsg, "danger"));
      } else if (err.response.status === 429) {
        dispatch(setAlert(err.response.data.message, "danger"));
      } else if (err.response.status === 500) {
        //added for invalid captcha
        dispatch(setAlert(err.response.data.message, "danger"));
      } else {
        dispatch(setAlert(customConfig.authMsg, "danger"));
      }
      dispatch({
        type: LOGIN_FAIL,
      });
    }
  };

//LOGOUT
export const logout = () => async (dispatch) => {
  let umURL = env.GET_USERMANAGEMENT_VERSION() == customConfig.umV1Version ? env.GET_USERMANAGEMENT_API_URL() : env.GET_USERMANAGEMENT_API_URL_V2()
  const clientId = env.GET_USERMANAGEMENT_VERSION() == customConfig.umV1Version ? env.GET_CLIENT_ID() : env.GET_CLIENT_ID_V2()

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const body = { clientId };
  dispatch({ type: CLEAR_USER });
  dispatch({ type: LOGOUT });
  localStorage.removeItem("token");
  localStorage.removeItem("userInfo");
  localStorage.removeItem("userRoles");
  localStorage.removeItem("userLevel");
  localStorage.removeItem("loggedInUserName");

  try {
    const res = env.GET_USERMANAGEMENT_VERSION() == customConfig.umV1Version ? await axios.post(
      `${umURL}/simplifi/v1/userManagement/logout`,
      body,
      config
    ) :  await axios.post(
      `${umURL}/simplifi/v2/userManagement/logout`,
      body,
      config
    )
  } catch (err) { }
  setAuthToken();
  if (window && window.location && window.location.reload) {
    window.location.reload();
  } else if (document && document.location && document.location.reload) {
    document.location.reload();
  }
};


export const getUMV1AuthToken = async (userName, password, clientId, umV2AuthToken) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    // first delete the existing auth headers
    delete axios.defaults.headers.common["x-auth-token"]
    delete axios.defaults.headers.common["authorization"]

    const body = { userName, password, clientId };

    let authURL = `${env.GET_USERMANAGEMENT_API_URL()}/simplifi/v1/userManagement/login`

    const res = await axios.post(
      authURL,
      body,
      config
    );

    // add again the existing auth headers
    setAuthToken(umV2AuthToken)

    //set auth token only
    return res.data.accessToken;

  } catch (error) {
    console.log("This is error while getting UM v1 Access Token: ", error);
    console.log("Failed to get access token of v1 user management")
  }

  return ""
}