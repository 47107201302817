import axios from "axios";
import { STATUS_CODES } from "../constants";

const _pendingRequests = {};
let CancelToken = axios.CancelToken;

const abortPendingRequests = (key) => {
    if (_pendingRequests[key]) {
        _pendingRequests[key]('REQUEST_CANCELLED');
        _pendingRequests[key] = null;
    }
};

const isInvalidToken = (response) => {
    if (response.status !== STATUS_CODES?.UNAUTHORIZED) {
        return false;
    }

    const authHeader = response.headers.get('WWW-Authenticate') || '';

    return authHeader.includes('invalid_token');
};

const processResponse = (res) => {
    if (isInvalidToken(res)) {
        return { data: {} };
    }

    if (res.status === STATUS_CODES?.NO_CONTENT) {
        const response = Object.assign({}, res, { data: {} });
        return response;
    }
    return res;
};

const handleResponse = (key, options, response, jsonResponse) => {
    const jsonRes = JSON.stringify(jsonResponse) === "{}" ? {} : jsonResponse;
    const { status } = response;
    const { errors } = Object.assign({}, jsonRes);
    const resp = {
        status,
        body: jsonResponse,
        errors,
        headers: response.headers,
    };
    return resp;
    // use any dispatchers/method to communicate this data to the store/view
    // dispatch(key, resp)
};

const getHeaders = (accessToken) => {
    return {
        'Content-Type': 'application/json',
        // Authorization: `Bearer ${accessToken}`,
    };
}

const getDefaultOptions = (options = {}) => {
    return options;
}

const makeRequest = async (url, key, reqInit, options = {}) => {
    abortPendingRequests(key);
    const headers = getHeaders("");
    const option = getDefaultOptions(options);
    const init = Object.assign({}, reqInit, { headers });
    await axios({
        url,
        ...init,
        timeout: 30000,
        // withCredentials: process.env.NODE_ENV === 'production',
        cancelToken: new CancelToken(function executor(c) {
            _pendingRequests[key] = c;
        }),
    })
        .then(res => processResponse(res))
        .then(res => {
            return res
            // return handleResponse(key, option, res, res.data);
        })
        .catch((err) => {
            // error handling logic
            //write logic to logout if 401 is returned
        });
}

const getParams = (queryParams = {}) => {
    return queryParams;
}

const get = async (path, key, queryParams, options = {}) => {
    const getData = {
        method: 'GET',
        params: getParams(queryParams),
        //can be used if query params are passed as arrays
        // paramsSerializer: (params) => {
        //     return qs.stringify(sanitizeParams(params), { arrayFormat: 'brackets' });
        // },
    };
    return await makeRequest(path, key, getData, options);
}

const post = async (path, key, body, options = {}) => {
    const postData = {
        method: 'POST',
        data: body,
        params: getParams(),
        //can be used if query params are passed as arrays
        // paramsSerializer: (params) => {
        //     return qs.stringify(sanitizeParams(params), { arrayFormat: 'brackets' });
        // },
    };
    return await makeRequest(path, key, postData, options);
}

const put = async (path, key, body, options = {}) => {
    const putData = {
        method: 'PUT',
        data: body,
        params: getParams(),
        //can be used if query params are passed as arrays
        // paramsSerializer: (params) => {
        //     return qs.stringify(sanitizeParams(params), { arrayFormat: 'brackets' });
        // },
    };
    return await makeRequest(path, key, putData, options);
}

export const API = {
    get,
    post,
    put
}