import {
  REQUEST_USER,
  GET_USER,
  USER_ERROR,
  CLEAR_USER,
  GET_USER_BY_ID,
  USER_ERROR_BY_ID,
  UPDATE_EMAIL_CLEAR,
  UPDATE_EMAIL_ERROR,
  UPDATE_EMAIL_SUCCESS,
  UM_V1_AUTH_TOKEN
} from "../action/types";
import customConfig from "../config";

const initialState = {
  user: null,
  userById: null,
  loading: true,
  emailUpdateError: false,
  emailUpdateSuccess: false,
  error: {},
  umVersion: process.env?.REACT_APP_UM_VERSION ?? customConfig.umV1Version,
  umMobileUsersVersion: process.env?.REACT_APP_MOBILE_USERS_VERSION ?? customConfig.umV1Version,
  umV1AuthToken: ''
};

export default function User(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case REQUEST_USER:
      return {
        ...state,
        loading: true,
      };
    case GET_USER:
      return {
        ...state,
        user: payload,
        loading: false,
      };
    case USER_ERROR:
      return {
        ...state,
        user: payload,
        loading: false,
      };
    case CLEAR_USER:
      return {
        ...state,
        user: null,
        loading: false,
      };
    case GET_USER_BY_ID:
      return {
        ...state,
        userById: payload,
        loading: false,
      };
    case USER_ERROR_BY_ID:
      return {
        ...state,
        userById: payload,
        loading: false,
      };
    case UPDATE_EMAIL_CLEAR:
      return {
        ...state,
        emailUpdateError: false,
        emailUpdateSuccess: false,
      };
    case UPDATE_EMAIL_ERROR:
      return {
        ...state,
        emailUpdateError: true,
        emailUpdateSuccess: false,
      };
    case UPDATE_EMAIL_SUCCESS:
      return {
        ...state,
        emailUpdateError: false,
        emailUpdateSuccess: true,
      };
    case UM_V1_AUTH_TOKEN: 
      return {
        ...state,
        umV1AuthToken: payload
      }
    default:
      return state;
  }
}
