import React, { Fragment, useState, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import Moment from "react-moment";
import DeviceUsage from "../report/DeviceUsage";
import FirmwareDetails from "../report/FirmwareDetails";
import OnboardedHistory from "../report/OnboardedHistory";
import DiagnosticData from "../report/DiagnosticData";
import WarrantyInfo from "../report/WarrantyInfo";
import ServiceInfo from "../report/ServiceInfo";
import Popup from "../layout/Popup";
import {
  offboardDevicebyId,
  updateProductSerialNumberbyID,
  sendMismatchData,
  updateProductModelNumberbyID,
  updateFirmwareVersion
} from "../../action/report";
import { UserContext } from "../../components/routing/Routes";
import SendingEmail from "../report/SendingEmail";
import isValid from "../../utils/checkProdSerial";
import { useEffect } from "react";
import { getWarrantyDetails } from "../../action/warranty";
import customConfig from "../../config";
import Alert from "../layout/Alert";

import DisplayDeviceDealerDeatils from "../deviceDealerMgmt/deviceDealerDetailsPage";
import { getDeviceDealerInfoByPSN } from "../../action/deviceDealer";
import { setAlert } from "../../action/alert";

const DeviceDetails = ({
  setAlert,
  showSeparator = false,
  device,
  deviceUsage,
  latestFirmwareData,
  firmwareData,
  onBoardedHistoryData,
  notifyOffboard,
  offboardDevicebyId,
  updateProductSerialNumberbyID,
  updateProductModelNumberbyID,
  sendMismatchData,
  getWarrantyDetails,
  diagnosticData,
  updateFirmwareVersion,
  // updateFirmwareRes,
  homeId,
  setIsEdit,
  tenantInfo,
  // updateFirmwareVersion,
  updateFirmwareRes,
  // homeId,
  WarrantyData: { warrantyToken, applianceData, applianceError },
  userRoleInfo
}) => {
  const userInfo = useContext(UserContext);
  const history = useHistory();
  const [showUsage, setShowUsage] = useState(false);
  const [showFirmwareHistory, setShowFirmwareHistory] = useState(false);
  const [showUpdateProductSerialNo, setShowUpdateProductSerialNo] =
    useState(false);
  const [
    disabledProductSerialUpdateButton,
    setDisabledProductSerialUpdateButton,
  ] = useState(false);
  const [newProductSerialNumber, setNewProductSerialNumber] = useState(
    device.length > 0 ? device[0].productSerialNumber : ""
  );

  const [showUpdateProductModelNo, setShowUpdateProductModelNo] =
    useState(false);
  const [
    disabledProductModelUpdateButton,
    setDisabledProductModelUpdateButton,
  ] = useState(false);
  const [newProductModelNumber, setNewProductModelNumber] = useState(
    device.length > 0 ? device[0].modelNumber : ""
  );
  const [showOnboardedHistory, setShowOnboardedHistory] = useState(false);
  const [showDiagnosticData, setShowDiagnosticData] = useState(false);
  const [showSendingEmailEditor, setShowSendingEmailEditor] = useState(false);
  const [showWarrantyInfo, setShowWarrantyInfo] = useState(false);
  const [showServiceInfo, setShowServiceInfo] = useState(false);
  const [showLinkRedStyle, setShowLinkRedStyle] = useState(
    "lnkBtn link-red-style"
  );
  const [warrantyProducts, setWarrantyProducts] = useState(null);
  const [warrantyError, setWarrantyError] = useState("");
  const [serviceError, setServiceError] = useState("");

  const [deviceDealerInfo, setDeviceDealerInfo] = useState({})
  const [showDeviceDealerInfo, setShowDeviceDealerInfo] = useState(false)

  useEffect(() => {
    if (device && device.length > 0) {
      setWarrantyLinkStyle();
    }
  }, [device]);

  useEffect(() => {
    if ((showUpdateProductModelNo || showUpdateProductSerialNo) && setIsEdit) {
      setIsEdit(true)
    }
    else {
      if (setIsEdit) setIsEdit(false)
    }
  }, [setIsEdit, showUpdateProductModelNo, showUpdateProductSerialNo]);

  useEffect(() => {

    if (!(userRoleInfo.level == 0 && userRoleInfo.roles.includes(customConfig.roleFactoryAdmin))) {
      if (
        device &&
        device.length > 0 &&
        warrantyToken &&
        applianceData &&
        applianceData.customer_products &&
        applianceData.customer_products.length > 0
      ) {
        let prodSerial = device[0].productSerialNumber;
        let validSerial = isValid(prodSerial);
        let products;
        if (validSerial) {
          products = applianceData.customer_products.filter(
            (p) => p.serial_no === prodSerial
          );
        } else {
          let modelNumFragments = device[0].modelNumber.split("-");
          let modelNum = modelNumFragments[modelNumFragments.length - 1];
          products = applianceData.customer_products.filter(
            (p) => p.model_name.indexOf(modelNum) !== -1
          );
        }

        setWarrantyProducts(products);
        //Get Product Details
        for (let i = 0; i < products.length; i++) {
          const prodId = products[i].id;
          getWarrantyDetails(warrantyToken, prodId);
        }

        if (!(products && products.length > 0)) {
          sendWarrantyMismatchData();
        }
      }
    }
  }, [device, warrantyToken, applianceData, showLinkRedStyle]);
  function isValidProdSerialNum() {
    return (
      device && device.length > 0 && isValid(device[0].productSerialNumber)
    );
  }

  function setWarrantyLinkStyle() {
    if (isValidProdSerialNum()) {
      setShowLinkRedStyle("lnkBtn link-style");
    } else {
      setShowLinkRedStyle("lnkBtn link-red-style");
    }
  }

  const offboard = (dd) => {
    offboardDevicebyId(dd.deviceId);
    notifyOffboard();
  };

  const handleUsagePopupClose = () => {
    setShowUsage(false);
  };

  const handleHistoryPopupClose = () => {
    setShowFirmwareHistory(false);
  };

  const handleEmailSendingPopupClose = () => {
    setShowSendingEmailEditor(false);
  };

  const handleOnBoardedHistoryPopupClose = () => {
    setShowOnboardedHistory(false);
  };

  const handleDiagnosticDataPopupClose = () => {
    setShowDiagnosticData(false);
  };

  const handleServiceInfoPopupClose = () => {
    setShowServiceInfo(false);
  };

  const handleWarrantyInfoPopupClose = () => {
    setShowWarrantyInfo(false);
  };

  // Visible serial number update form
  const showUpdateProductSerialPopup = () => {
    let prodSerialNo = device[0].productSerialNumber
      ? device[0].productSerialNumber.trim()
      : "";
    setNewProductSerialNumber(prodSerialNo);
    //SIFI-4968 - empty product serial number can also be updated
    //setDisabledProductSerialUpdateButton(prodSerialNo.length <= 0);
    showUpdateProductSerialNo
      ? setShowUpdateProductSerialNo(false)
      : setShowUpdateProductSerialNo(true);
  };

  const cancelSerialPopup = () => {
    setShowUpdateProductSerialNo(false);
    setDisabledProductSerialUpdateButton(false);
  };

  const showUpdateProductModelPopup = () => {
    let prodModelNo = device[0].modelNumber ? device[0].modelNumber.trim() : "";
    setNewProductModelNumber(prodModelNo);
    setDisabledProductModelUpdateButton(prodModelNo.length <= 0);
    showUpdateProductModelNo
      ? setShowUpdateProductModelNo(false)
      : setShowUpdateProductModelNo(true);
  };

  const cancelModelPopup = () => {
    setShowUpdateProductModelNo(false);
    setDisabledProductModelUpdateButton(false);
  };

  // Hit api to update serial number
  const updateProductSerialNumber = () => {
    const updatedProductSerialNo = newProductSerialNumber.trim();
    if (updatedProductSerialNo) {
      updateProductSerialNumberbyID(device[0].deviceId, updatedProductSerialNo);
      setShowUpdateProductSerialNo(false);
      device[0].productSerialNumber = updatedProductSerialNo;
      setWarrantyLinkStyle();
    }
    else {
      //SIFI-4968 - Update empty product serial number
      updateProductSerialNumberbyID(device[0].deviceId, "");
      setShowUpdateProductSerialNo(false);
      device[0].productSerialNumber = "";
      setWarrantyLinkStyle();
    }
  };
  // Hit api to update model number
  const updateProductModelNumber = () => {
    const updatedProductModelNo = newProductModelNumber.trim();
    if (updatedProductModelNo) {
      updateProductModelNumberbyID(device[0].deviceId, updatedProductModelNo)
        .then(() => {
          device[0].modelNumber = updatedProductModelNo;
          setShowUpdateProductModelNo(false);
          setAlert("Model Number Updated Succesfully", "success")
        })
        .catch((err) => {
          setShowUpdateProductModelNo(false)
          setAlert(err?.response?.data?.message ?? "Failed to Update Model Number", "danger")
        });
    }
  };

  const handleWarrantyPopup = (viewService = false) => {
    if (!(warrantyProducts && warrantyProducts.length > 0)) {
      if (viewService) {
        setServiceError(customConfig.noServiceDataMsg);
        setShowServiceInfo(true);
      } else {
        setWarrantyError(customConfig.noWarrantyDataMsg);
        setShowWarrantyInfo(true);
      }
      return;
    }
    setWarrantyError("");
    setServiceError("");

    let proceed = true;
    if (!isValidProdSerialNum()) {
      proceed = window.confirm(customConfig.confirmProccedWithoutProdSerial);
    }

    if (proceed) {
      if (viewService) {
        setShowServiceInfo(true);
      } else {
        setShowWarrantyInfo(true);
      }
    }
  };

  const sendWarrantyMismatchData = () => {
    let reqBody = {
      deviceId: device[0].deviceId,
      modelNumber: device[0].modelNumber,
      category: device[0].category,
      productSerialNumber: device[0].productSerialNumber,
      onboardedBy: device[0].onboardedBy,
    };
    sendMismatchData(reqBody);
  };

  const onChange = (e) => {
    //SIFI-4968 - empty product serial number can also be updated
    // if (e.target.value.trim().length > 0)
    //   setDisabledProductSerialUpdateButton(false);
    // else setDisabledProductSerialUpdateButton(true);

    setNewProductSerialNumber(e.target.value);
  };
  const onChangeModel = (e) => {
    if (e.target.value.trim().length > 0)
      setDisabledProductModelUpdateButton(false);
    else setDisabledProductModelUpdateButton(true);

    setNewProductModelNumber(e.target.value);
  };

  const handleVersionUpdate = () => {
    let reqBody =
    {
      "currentFirmwareVersion": device[0]?.firmwareVersion,
      "latestFirmwareVersion": latestFirmwareData?.firmwareVersion,
      "deviceId": device[0]?.deviceId,
    }
    updateFirmwareVersion(reqBody)
  }

  function handleViewDealerInfo() {
    if (device && device[0].productSerialNumber && device[0].productSerialNumber.length > 0) {

      getDeviceDealerInfoByPSN(device[0].productSerialNumber)
        .then((data) => {
          setDeviceDealerInfo(data)
          setShowDeviceDealerInfo(true)
        })
        .catch((err) => {
          setDeviceDealerInfo({})
          setShowDeviceDealerInfo(true)
        })
    }
  }

  return (
    <Fragment>
      {device && device.length > 0 ? (
        <div className="position-relative">
          {/* <span className=" bottom-right"></span> */}
          {/* <span className="watermark panasonic bottom-right"></span> */}
          <div className="reset-device-detail">
            {homeId && <Alert />}
            <div className="row">
              <div className="s3 col">
                <div
                  className={`block-title ${showSeparator ? "style-3" : "style-4"
                    }`}
                >
                  <h3>Device Details</h3>
                </div>
              </div>
              <div className="s9 col">
                <div className="right-align">
                  <button
                    className="btn btn-style-3 orange-bg sm min-140 mr-10 font-400 mqtt_btn"
                    onClick={() => setShowSendingEmailEditor(true)}
                  >
                    Send Email
                  </button>

                  {/* CustomerSupportL2 or higher role is allowed to Offboard */}
                  {userInfo.level > 1 && (
                    <button
                      className="btn btn-style-3 orange-bg sm  font-400 min-140 mqtt_btn"
                      onClick={() => {
                        if (window.confirm(customConfig.confirmOffboard))
                          offboard(device[0]);
                      }}
                    >
                      Offboard device
                    </button>
                  )}
                  {userInfo.level > 1 && latestFirmwareData.firmwareVersion > device[0].firmwareVersion && (
                    <button
                      className="btn btn-style-3 orange-bg sm min-140 ml_10 font-400 mqtt_btn"
                      onClick={() => {
                        handleVersionUpdate()
                      }}
                    >
                      Send FOTA Trigger
                    </button>
                  )}
                </div>
              </div>
            </div>

            <div className="device-detail">
              <div className="row">
                <div className="s6 col">
                  <div className="unique-box mb-15">
                    <label className="title d-inline  min-180">Device ID</label>
                    <div className="device-info d-inline">
                      {device[0].deviceId}
                    </div>
                  </div>

                  <div className="unique-box mb-15">
                    <label className="title d-inline  min-180">
                      Model Number
                      {(userInfo.level > 1 || (userInfo.level == 0 && userRoleInfo.roles.includes(customConfig.roleFactoryAdmin))) && !showUpdateProductModelNo && (
                        <button
                          onClick={showUpdateProductModelPopup}
                          className="edit-link"
                        >
                          <i className="material-icons icn">mode_edit</i>
                        </button>
                      )}
                      {/* {userInfo.level > 3 && !showUpdateProductModelNo && (
                        <button
                          onClick={showUpdateProductModelPopup}
                          className="edit-link"
                        >
                          <i className="material-icons icn">mode_edit</i>
                        </button>
                      )} */}
                    </label>
                    {!showUpdateProductModelNo && (
                      <Link
                        to={{
                          pathname: `/deviceDetail/${device[0].modelNumber}`,
                          state: {
                            from: history.location.pathname,
                            showBackButton: true,
                          },
                        }}
                        className="link-style"
                      >
                        {device[0].modelNumber}
                      </Link>
                    )}
                  </div>

                  {showUpdateProductModelNo && (
                    <div>
                      <div className="row">
                        <div className="col s5">
                          <div className="update-bx">
                            <input
                              placeholder="Enter Model Number"
                              className="font-16 update-inpt medium"
                              value={newProductModelNumber}
                              type="text"
                              onChange={onChangeModel}
                            />
                            <button
                              disabled={disabledProductModelUpdateButton}
                              className="btn btn-style-2 orange-bg mr-10"
                              onClick={() => updateProductModelNumber()}
                            >
                              Update
                            </button>
                            <button
                              className="btn btn-style-2 dark-text gray-bg"
                              onClick={() => cancelModelPopup()}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="unique-box mb-15">
                    <label className="title d-inline  min-180">
                      Mac Address
                    </label>
                    <div className="device-info d-inline">
                      {device[0].macAddress}
                    </div>
                  </div>

                  <div className="unique-box mb-15">
                    <label className="title d-inline  min-180">
                      Serial Number
                    </label>
                    <div className="device-info d-inline">
                      {device[0].serialNumber}{" "}
                    </div>
                  </div>

                  <div className="unique-box mb-15">
                    <label className="title d-inline  min-180">
                      Product Serial Number
                      {
                        device[0].onboardedAt && device[0].onboardedAt != ""
                          ?
                          <Fragment></Fragment>
                          :
                          (userInfo.level > 1 || (userInfo.level == 0 && userRoleInfo.roles.includes(customConfig.roleFactoryAdmin))) && !showUpdateProductSerialNo && (
                            <button
                              onClick={showUpdateProductSerialPopup}
                              className="edit-link"
                            >
                              <i className="material-icons icn">mode_edit</i>
                            </button>
                          )
                      }
                    </label>
                    {!showUpdateProductSerialNo && (
                      <div className="device-info d-inline">
                        {device[0].productSerialNumber}
                      </div>
                    )}
                  </div>
                  {showUpdateProductSerialNo && (
                    <div>
                      <div className="row">
                        <div className="col s5">
                          <div className="update-bx">
                            <input
                              placeholder="Enter Serial Number"
                              className="font-16 update-inpt medium"
                              value={newProductSerialNumber}
                              type="text"
                              onChange={onChange}
                            />
                            <button
                              disabled={disabledProductSerialUpdateButton}
                              className="btn btn-style-2 orange-bg mr-10"
                              onClick={() => updateProductSerialNumber()}
                            >
                              Update
                            </button>
                            <button
                              className="btn btn-style-2 dark-text gray-bg"
                              onClick={() => cancelSerialPopup()}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="unique-box mb-15">
                    <label className="title d-inline  min-180">
                      Model Name
                    </label>
                    <div className="device-info d-inline">
                      {device[0].modelName}
                    </div>
                  </div>

                  <div className="unique-box mb-15">
                    <label className="title d-inline  min-180">
                      Manufactured Date
                    </label>
                    <div className="device-info d-inline">
                      {device[0].manufacturedDate &&
                        <Moment
                          format="MMMM Do YYYY, h:mm:ss a"
                          date={device[0].manufacturedDate * 1000}
                        />}
                    </div>
                  </div>

                  <div className="unique-box mb-15">
                    <label className="title d-inline  min-180">
                      Firmware Current Version
                    </label>
                    <div className="device-info d-inline">
                      {device[0].firmwareVersion}
                    </div>
                  </div>
                  {
                    !(userRoleInfo.level == 0 && userRoleInfo.roles.includes(customConfig.roleFactoryAdmin)) && <div className="unique-box mb-15">
                      <label className="title d-inline  min-180">
                        Onboarded By
                      </label>
                      <div className="device-info d-inline">
                        {device[0].onboardedBy}
                      </div>
                    </div>
                  }
                  {
                    !(userRoleInfo.level == 0 && userRoleInfo.roles.includes(customConfig.roleFactoryAdmin)) &&
                    <div className="unique-box mb-15">
                      <label className="title d-inline  min-180">
                        Onboard/Offboard History
                      </label>
                      <div className="device-info d-inline">
                        <button
                          className="lnkBtn link-style"
                          onClick={() => setShowOnboardedHistory(true)}
                        >
                          View History
                        </button>
                        {showOnboardedHistory && (
                          <Popup
                            visible={showOnboardedHistory}
                            notifyPopupClose={handleOnBoardedHistoryPopupClose}
                          >
                            {" "}
                            <OnboardedHistory
                              onBoardedHistoryData={onBoardedHistoryData}
                            />
                          </Popup>
                        )}
                      </div>
                    </div>
                  }
                  {
                    !(userRoleInfo.level == 0 && userRoleInfo.roles.includes(customConfig.roleFactoryAdmin)) &&
                    (device[0].category == customConfig.decviceCategoryAC || device[0].category == customConfig.deviceCategoryCAC) &&
                    <div className="unique-box mb-15">
                      <label className="title d-inline  min-180">
                        Diagnostics Data
                      </label>
                      <div className="device-info d-inline">
                        <button
                          className="lnkBtn link-style"
                          onClick={() => setShowDiagnosticData(true)}
                        >
                          View Data
                        </button>
                        {showDiagnosticData && (
                          <Popup
                            visible={showDiagnosticData}
                            notifyPopupClose={handleDiagnosticDataPopupClose}
                          >
                            {" "}
                            <DiagnosticData
                              diagnosticData={diagnosticData}
                              deviceID={device[0].deviceId}
                              modelNumber={device[0].modelNumber}
                            />
                          </Popup>
                        )}
                      </div>
                    </div>
                  }

                  <div className="unique-box mb-15">
                    <label className="title d-inline  min-180">
                      Alexa Connected Date
                    </label>
                    <div className="device-info d-inline">
                      {device[0].alexaConnectDate ?
                        <Moment
                          format="MMMM Do YYYY, h:mm:ss a"
                          date={device[0].alexaConnectDate.$date}
                        />
                        :
                        "-"}
                    </div>
                  </div>

                  <div className="unique-box mb-15">
                    <label className="title d-inline  min-180">
                      Google Home Connected Date
                    </label>
                    <div className="device-info d-inline">
                      {device[0].googleHomeConnectDate ?
                        <Moment
                          format="MMMM Do YYYY, h:mm:ss a"
                          date={device[0].googleHomeConnectDate.$date}
                        />
                        :
                        "-"}
                    </div>
                  </div>
                </div>

                <div className="s6 col">
                  <div className="unique-box mb-15">
                    <label className="title d-inline  min-180">
                      Brand Name
                    </label>
                    <div className="device-info d-inline">
                      {device[0].brand}
                    </div>
                  </div>

                  <div className="unique-box mb-15">
                    <label className="title d-inline  min-180">
                      Created At
                    </label>
                    <div className="device-info d-inline">
                      {device[0].createdAt &&
                        <Moment
                          format="MMMM Do YYYY, h:mm:ss a"
                          date={device[0].createdAt * 1000}
                        />}
                    </div>
                  </div>
                  <div className="unique-box mb-15">
                    <label className="title d-inline  min-180">
                      Created By
                    </label>
                    <div className="device-info d-inline">
                      {device[0].createdBy}
                    </div>
                  </div>
                  <div className="unique-box mb-15">
                    <label className="title d-inline  min-180">Category</label>
                    <div className="device-info d-inline">
                      {device[0].category}
                    </div>
                  </div>
                  <div className="unique-box mb-15">
                    <label className="title d-inline  min-180">
                      efuseMacAddress
                    </label>
                    <div className="device-info d-inline">
                      {device[0].efuseMacAddress}
                    </div>
                  </div>
                  <div className="unique-box mb-15">
                    <label className="title d-inline  min-180">
                      Provisioned At
                    </label>
                    <div className="device-info d-inline">
                      {device[0].provisionedAt &&
                        <Moment
                          format="MMMM Do YYYY, h:mm:ss a"
                          date={device[0].provisionedAt * 1000}
                        />}
                    </div>
                  </div>
                  <div className="unique-box mb-15">
                    <label className="title d-inline  min-180">
                      Onboarded At
                    </label>
                    <div className="device-info d-inline">
                      {device[0].onboardedAt && (
                        <Moment
                          format="MMMM Do YYYY, h:mm:ss a"
                          date={device[0].onboardedAt * 1000}
                        />
                      )}
                    </div>
                  </div>
                  {
                    !(userRoleInfo.level == 0 && userRoleInfo.roles.includes(customConfig.roleFactoryAdmin)) && <div className="unique-box mb-15">
                      <label className="title d-inline  min-180">
                        Latest Firmware Version
                      </label>
                      <div className="device-info d-inline">
                        {latestFirmwareData.firmwareVersion}
                      </div>
                    </div>
                  }
                  {
                    !(userRoleInfo.level == 0 && userRoleInfo.roles.includes(customConfig.roleFactoryAdmin)) && <div className="unique-box mb-15">
                      <label className="title d-inline  min-180">
                        Firmware History
                      </label>
                      <div className="device-info d-inline">
                        <button
                          className="lnkBtn link-style"
                          onClick={() => setShowFirmwareHistory(true)}
                        >
                          View History
                        </button>
                        {showFirmwareHistory && (
                          <Popup
                            visible={showFirmwareHistory}
                            notifyPopupClose={handleHistoryPopupClose}
                          >
                            {" "}
                            <FirmwareDetails
                              firmwareData={firmwareData}
                              deviceID={device[0].deviceId}
                            />
                          </Popup>
                        )}
                      </div>
                    </div>
                  }

                  {
                    !(userRoleInfo.level == 0 && userRoleInfo.roles.includes(customConfig.roleFactoryAdmin)) && <div className="unique-box mb-15">
                      <label className="title d-inline  min-180">
                        Usage Information
                      </label>
                      <div className="device-info d-inline">
                        <button
                          className="lnkBtn link-style"
                          onClick={() => setShowUsage(true)}
                        >
                          View Usage
                        </button>
                        {showUsage && (
                          <Popup
                            visible={showUsage}
                            notifyPopupClose={handleUsagePopupClose}
                          >
                            <DeviceUsage
                              deviceId={device[0].deviceId}
                              modelNumber={device[0].modelNumber}
                              deviceUsage={deviceUsage}
                            ></DeviceUsage>
                          </Popup>
                        )}
                      </div>
                    </div>
                  }

                  {
                    !(userRoleInfo.level == 0 && userRoleInfo.roles.includes(customConfig.roleFactoryAdmin)) && tenantInfo.tenantId === "panasonic" && <div className="unique-box mb-15">
                      <label className="title d-inline  min-180">
                        Warranty Info
                      </label>
                      <div className="device-info d-inline">
                        <button
                          id="btnWarranty"
                          className={showLinkRedStyle}
                          onClick={() => handleWarrantyPopup()}
                        >
                          View warranty info
                        </button>
                        {showWarrantyInfo && (
                          <Popup
                            visible={showWarrantyInfo}
                            notifyPopupClose={handleWarrantyInfoPopupClose}
                            wide={true}
                          >
                            {" "}
                            <WarrantyInfo
                              products={warrantyProducts}
                              showProductInfo={!isValidProdSerialNum()}
                              warrantyError={warrantyError}
                            />
                          </Popup>
                        )}
                      </div>
                    </div>
                  }

                  {
                    !(userRoleInfo.level == 0 && userRoleInfo.roles.includes(customConfig.roleFactoryAdmin)) && <div className="unique-box mb-15">
                      <label className="title d-inline  min-180">
                        Service Requests
                      </label>
                      <div className="device-info d-inline">
                        <button
                          className={showLinkRedStyle}
                          onClick={() => handleWarrantyPopup(true)}
                        >
                          View service requests
                        </button>
                        {showServiceInfo && (
                          <Popup
                            visible={showServiceInfo}
                            notifyPopupClose={handleServiceInfoPopupClose}
                            wide={true}
                          >
                            {" "}
                            <ServiceInfo
                              products={warrantyProducts}
                              showProductInfo={!isValidProdSerialNum()}
                              serviceError={serviceError}
                            />
                          </Popup>
                        )}
                      </div>
                    </div>
                  }

                  {
                    (device[0].category == customConfig.decviceCategoryAC || device[0].category == customConfig.deviceCategoryCAC) &&
                    <div className="unique-box">
                      <label className="title d-inline  min-180">
                        Errors
                      </label>
                      <div className="device-info d-inline">
                        {deviceUsage && deviceUsage.errors ? deviceUsage.errors : "-"}
                      </div>
                    </div>
                  }

                  <div className="unique-box">
                    <label className="title d-inline  min-180">
                      Dealer Information
                    </label>
                    <div className="device-info d-inline">
                      {device[0].productSerialNumber && device[0].productSerialNumber.length > 0 ?
                        <Fragment>
                          {
                            !showDeviceDealerInfo ? <button
                              id="btnViewDealerInfo"
                              className={showLinkRedStyle}
                              onClick={() => handleViewDealerInfo()}
                            >
                              View Dealer Information
                            </button> :
                              <button
                                id="btnViewDealerInfo"
                                className={showLinkRedStyle}
                                onClick={() =>
                                  setShowDeviceDealerInfo(false)
                                }
                              >
                                Hide Dealer Information
                              </button>
                          }

                        </Fragment>
                        : "-"}
                    </div>
                  </div>


                  <div className="unique-box mb-15">
                    {showSendingEmailEditor && (
                      <Popup
                        visible={showSendingEmailEditor}
                        wide={true}
                        notifyPopupClose={handleEmailSendingPopupClose}
                      >
                        {" "}
                        <SendingEmail
                          deviceInfo={device[0]}
                          userDetail={userInfo}
                        />
                      </Popup>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {
            showDeviceDealerInfo && <Popup
              visible={showDeviceDealerInfo}
              notifyPopupClose={() => {
                setShowDeviceDealerInfo(false)
              }}
              wide={true}
            >
              <div className="popup-content  blue-modal blank">
                <div className="block-title style-2 center-align  py-25">
                  <h3 className="mb-0">Dealer Information</h3>
                </div>
                {
                  deviceDealerInfo && Object.keys(deviceDealerInfo).length > 0 ?
                    <DisplayDeviceDealerDeatils deviceDealerDetails={deviceDealerInfo}></DisplayDeviceDealerDeatils>
                    :
                    <div className="s12 col">
                      <div className="boxes">
                        <div className="info error content-reset">
                          <div className="text-box">
                            <i className="no-data-icon"></i>

                          </div>
                        </div>
                      </div>
                    </div>
                }

              </div>
            </Popup>
          }
        </div>
      ) : null}
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  WarrantyData: state.warranty,
  // updateFirmwareRes:state.report.updateFirmwareRes,
  tenantInfo: state.tenant.tenantInfo,
  updateFirmwareRes: state.report.updateFirmwareRes,
  userRoleInfo: {
    level: state.auth.userLevel,
    roles: state.auth.userRoles,
  },
});

export default connect(mapStateToProps, (dispatch) => {
  return {
    setAlert: (message, status) => dispatch(setAlert(message, status)),
    offboardDevicebyId: (deviceId) => dispatch(offboardDevicebyId(deviceId)),
    updateProductSerialNumberbyID: (deviceId, psn) => dispatch(updateProductSerialNumberbyID(deviceId, psn)),
    updateProductModelNumberbyID: (deviceId, modelNumber) => dispatch(updateProductModelNumberbyID(deviceId, modelNumber)),
    sendMismatchData: (data) => dispatch(sendMismatchData(data)),
    getWarrantyDetails: (token, productId) => dispatch(getWarrantyDetails(token, productId)),
    updateFirmwareVersion: (body) => dispatch(updateFirmwareVersion(body)),
  }

})(DeviceDetails);
