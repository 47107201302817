import { combineReducers } from "redux";

import alert from "./alert";
import auth from "./auth";
import user from "./user";
import home from "./home";
import report from "./report";
import getModel from "./addvcModel";
import statistics from "./statistics";
import warranty from "./warranty";
import fotaJobs from "./fotaJobs";
import tenant from "./tenant";
import metrics from "./metrics";
import iduConfig from "./iduConfig"
import firmware from "./firmware";
import deviceDealer from "./deviceDealer";
import notification from "./notification"
export default combineReducers({
  alert,
  auth,
  user,
  home,
  report,
  getModel,
  statistics,
  warranty,
  fotaJobs,
  tenant,
  metrics,
  iduConfig,
  firmware,
  deviceDealer,
  notification
});
