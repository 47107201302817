import React,{useEffect} from "react";
import { connect } from "react-redux";
import {groupUserSeries,totalFilterCount,groupUserX_data} from './GraphParameter';
import {getGroupUser} from '../../../action/metrics';
import Graph from './Graph';

function GroupingUser({ activeFilter, groupUsers,getGroupUser, setDeviceCount }) {
    useEffect(()=>{
        const que= {
            // "X-Tenant-ID":'panasonic'
          }
        getGroupUser(que)
    },[getGroupUser])
    
    const y_data = groupUserSeries(groupUsers);
    const x_data = groupUserX_data(groupUsers);
  let date = new Date();
  date.setDate(date.getDate() - 7);
  const x_title = `${date.getDate()}-${date.getMonth()+1}-${date.getFullYear()} to ${new Date().getDate()}-${new Date().getMonth()+1}-${date.getFullYear()} `
  
  // let m = d.getMonth();
  const totalCount = totalFilterCount(y_data)
    useEffect(()=>{
      setDeviceCount(totalCount)
    },[totalCount])
  return (
    <div>
      <div id="chart">
        <Graph text = {'Users'} x_data = {x_data} title = {"Number Of Active User"} y_data = {y_data} x_title = {`Frequency of active users across a week( ${x_title})`} />
      </div>
    </div>
  );
}

const mapStateToProps = (state) =>{
    return{
        groupUsers:state.metrics.groupUsers     
    }
}
  
  export default connect(mapStateToProps, { getGroupUser
  })(GroupingUser);

