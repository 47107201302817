import axios from "axios";
import { setAlert } from "./alert";
import { DOWNLOAD_DEVICE_STATISTICS_FAIL } from "../action/types";
import customConfig from "../config";
import env from "../env";

function DownloadFile(response, fileName, fileType) {
  const blob = new Blob([response.data], { type: fileType });
  let url = window.URL.createObjectURL(blob);
  let a = document.createElement("a");
  a.href = url;
  a.download = fileName;
  a.click();
}

// Download file with device onboard statistics
export const downloadDeviceStatistics = (category) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
      responseType: "blob",
    };
    const res = await axios.post(
      `${env.GET_DEVICEMANAGEMENT_API_URL()}/simplifi/v1/deviceManagement/devices/download/deviceOnboardStat/${category}`,
      null,
      config
    );
    DownloadFile(res, `${category}_Onboard.csv`, "text/csv");
  } catch (err) {
    if (!err.response) {
      dispatch(setAlert(customConfig.serverDownMsg, "danger"));
    } else if (err.response.data && err.response.data.message) {
      dispatch(setAlert(err.response.data.message, "danger"));
    } else {
      dispatch(setAlert(customConfig.downloadErrorMsg, "danger"));
    }
    dispatch({
      type: DOWNLOAD_DEVICE_STATISTICS_FAIL,
    });
  }
};

// Download file with device offboard statistics
export const downloadDeviceOffboardStatistics = () => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
      responseType: "blob",
    };
    const res = await axios.post(
      `${env.GET_DEVICEMANAGEMENT_API_URL()}/simplifi/v1/deviceManagement/devices/download/deviceOffboardStat`,
      null,
      config
    );
    DownloadFile(res, "Offboard.csv", "text/csv");
  } catch (err) {
    if (!err.response) {
      dispatch(setAlert(customConfig.serverDownMsg, "danger"));
    } else if (err.response.data && err.response.data.message) {
      dispatch(setAlert(err.response.data.message, "danger"));
    } else {
      dispatch(setAlert(customConfig.downloadErrorMsg, "danger"));
    }
    dispatch({
      type: DOWNLOAD_DEVICE_STATISTICS_FAIL,
    });
  }
};
