import React, { useEffect, useState } from "react";
import M from "materialize-css/dist/js/materialize.min.js";
import CircularProgress from "@material-ui/core/CircularProgress";
import { setAlert } from "./../../action/alert";
import { connect } from "react-redux";
import './DeviceUpdateLogs.css'
import { Fragment } from "react";
import { getDeviceLogsData } from './../../action/devicelogs'
import Moment from "react-moment";
import Alert from "../layout/Alert";

const DeviceUpdateLogs = ({
    deviceDetails,
    setCustomAlert
}) => {
    const headers = {
        serialNumber: "S.No",
        activity: "Actions",
        timestamp: "Timestamp",
        productSerialNumber: "Product Serial Number",
        macAddress: "MAC Address",
        status: "Status",
    };

    const [deviceLogsData, setDeviceLogsData] = useState([]);
    const [updateFlag, setUpdateFlag] = useState(undefined);
    const [isLoading, setIsLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [paginationStartIndex, setPaginationStartIndex] = useState(0);
    const [lastApiPageFetched, setLastApiPageFetched] = useState(0);
    const [pageEndReached, setPageEndReached] = useState(false);

    const pageSize = 20;
    const totalPageCount = Math.ceil(deviceLogsData?.length / pageSize);
    const startIndex = pageSize * currentPage;
    const endIndex = startIndex + pageSize;

    const pageNumbersToShow = () => {
        if (totalPageCount === 0) {
            return [0];
        } else {
            let i = paginationStartIndex;
            let pagesToShow = [];
            while (i < totalPageCount && i < paginationStartIndex + 5) {
                pagesToShow.push(i);
                i += 1;
            }
            return pagesToShow;
        }
    };

    const getPaginationData = () => {
        const pageArr = pageNumbersToShow();
        return (
            <ul className="pagination">
                <li
                    className={paginationStartIndex === 0 ? "disabled" : "waves-effect"}
                    onClick={() => {
                        if (!(paginationStartIndex === 0)) {
                            setPaginationStartIndex(Math.max(0, paginationStartIndex - 5));
                        }
                    }}
                >
                    <a href="#!">
                        <i className="material-icons">chevron_left</i>
                    </a>
                </li>
                {pageArr.map((element) => {
                    return (
                        <li
                            className={
                                parseInt(element) === parseInt(currentPage) ? "active" : ""
                            }
                        >
                            <a href="#" onClick={() => setCurrentPage(element)}>
                                {element + 1}
                            </a>
                        </li>
                    );
                })}
                <li
                    className={
                        paginationStartIndex >= totalPageCount - 5
                            ? "disabled"
                            : "waves-effect"
                    }
                    onClick={() => {
                        if (!pageEndReached) {
                            const pageArr = pageNumbersToShow();
                            if (pageArr[-1] + 1 === totalPageCount) {
                                setPaginationStartIndex(
                                    Math.min(totalPageCount, paginationStartIndex + 5)
                                );
                            } else {
                                updateDeviceLogsData(
                                    lastApiPageFetched + 1,
                                    nextPaginationResolveFunc
                                );
                            }
                        } else {
                            setCustomAlert("You have reached end of page.", "danger");
                        }
                    }}
                >
                    <a href="#!">
                        <i className="material-icons">chevron_right</i>
                    </a>
                </li>
            </ul>
        );
    };

    const nextPaginationResolveFunc = (data) => {
        if (data.length < 100) {
            setPageEndReached(true);
        }
        if (data.length === 0) {
            setCustomAlert("You have reached end of page.", "danger");
        } else {
            const newPaginationStartIndex = Math.min(
                parseInt((deviceLogsData.length + data.length) / pageSize),
                paginationStartIndex + 5
            );
            setPaginationStartIndex(newPaginationStartIndex);
            setDeviceLogsData([...deviceLogsData, ...data]);
            setLastApiPageFetched(lastApiPageFetched + 1);
            setCurrentPage(newPaginationStartIndex);
        }
    };

    const updateDeviceLogsData = (page, resolveFunc, errFunc) => {
        setIsLoading(true)
        getDeviceLogsData(deviceDetails?.deviceId ?? "")
            .then((res) => {
                setIsLoading(false);
                if (!resolveFunc) {
                    setDeviceLogsData(res);
                } else {
                    resolveFunc(res);
                }
            })
            .catch((err) => {
                setIsLoading(false);
                if (!errFunc) {
                    console.log(err);
                    setCustomAlert("Error while fetching device logs data.", "danger");
                    setDeviceLogsData([])
                } else {
                    errFunc(err);
                }
            });
    };

    // useEffect(() => {
    //     filter()
    // }, [])

    useEffect(() => {
        if (deviceDetails && deviceDetails instanceof Object && deviceDetails.hasOwnProperty('deviceId')) {
            updateDeviceLogsData()
        }
    }, [deviceDetails])

    useEffect(() => {
        M.AutoInit();
    }, [updateFlag, isLoading, currentPage, deviceLogsData]);

    useEffect(() => {
        if (updateFlag) {
            updateDeviceLogsData();
        }
    }, [updateFlag]);

    const filter = () => {
        setCurrentPage(0);
        setPaginationStartIndex(0);
        setPageEndReached(false);
        setLastApiPageFetched(0);
        setUpdateFlag(Math.random());
    };

    if (isLoading) {
        return (
            <div
                style={{
                    display: "grid",
                    placeItems: "center",
                    height: "80vh",
                }}
            >
                <CircularProgress size="5em" style={{ color: "#AFB42B" }} />
            </div>
        );
    }

    return (
        <Fragment>
            <Alert/>
            <div className="container">
                <div className="content-block bottom-shadow py-15 mb-30">
                    <div className="row mb-0">
                        <div className="col s12">
                            <div
                                className="table-wrapper mb-20"
                                style={{
                                    //overflowX: "scroll",
                                    scrollBehavior: "auto",
                                    //maxHeight: "58vh",
                                    //overflowY: "scroll",
                                }}
                            >
                                <table
                                    width="100%"
                                    align="center"
                                    className="table style-4 mb-20 deviceUpdateLogsTable"
                                >
                                    <thead
                                        style={{ position: "sticky", top: 0, zIndex: 50, background: "white" }}
                                    >
                                        <tr>
                                            {Object.keys(headers).map((key) => (
                                                <th>{headers[key]}</th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {deviceLogsData.length === 0 ? (
                                            <tr>
                                                <td colSpan={Object.keys(headers).length}>
                                                    No Data found
                                                </td>
                                            </tr>
                                        ) : (
                                            deviceLogsData
                                                .map((element, index) => {
                                                    return (
                                                        <tr key={index % pageSize} id={index % pageSize}>
                                                            {Object.keys(headers).map((key) => {
                                                                return (
                                                                    key == 'serialNumber' ?
                                                                        <td className={String(element[key]).length > 60 ? "breakWord" : ""}
                                                                        >
                                                                            {
                                                                                index + 1
                                                                            }
                                                                        </td>
                                                                        :
                                                                        key == 'timestamp' ?
                                                                            <td className={String(element[key]).length > 60 ? "breakWord" : ""}
                                                                            >
                                                                                {
                                                                                    String(element[key]) && String(element[key]).length > 0 ?
                                                                                        <Moment
                                                                                            format="DD-MM-YYYY HH:mm:ss"
                                                                                            date={element[key]}
                                                                                        />
                                                                                        :
                                                                                        ""
                                                                                }
                                                                            </td>
                                                                            :
                                                                            <td
                                                                                className={
                                                                                    String(element[key]).length > 200
                                                                                        ? "breakWord"
                                                                                        : ""
                                                                                }
                                                                            >
                                                                                {element[key] ? element[key] : ""}
                                                                            </td>
                                                                );
                                                            })}
                                                        </tr>
                                                    );
                                                })
                                        )}
                                    </tbody>
                                </table>
                            </div>
                            {/* <div className="d-flex justify-content-end ">
                                {getPaginationData()}
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};
const mapStateToProps = (state) => ({
});

const mapDispatchToProps = (dispatch) => ({
    setCustomAlert: (message, alertType) => dispatch(setAlert(message, alertType))
});

export default connect(mapStateToProps, mapDispatchToProps)(DeviceUpdateLogs);
