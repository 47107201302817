import store from "./store";

export default {
  // To be used when tenant management API returns true values
  GET_USERMANAGEMENT_API_URL: () => store.getState().tenant.tenantInfo.auth.baseUrl,
  GET_DEVICEMANAGEMENT_API_URL: () => store.getState().tenant.tenantInfo.app.baseUrl,
  GET_FOTA_API_URL: () => store.getState().tenant.tenantInfo.app.baseUrl,
  GET_CLIENT_ID: () => store.getState().tenant.tenantInfo.auth.webClientId,
  GET_USERMANAGEMENT_VERSION: () => store.getState().user.umVersion,
  GET_CLIENT_ID_V2: () => {
    if (window.location.host.includes("smarthomeplus")) {
      return store.getState().tenant.tenantInfo.auth.webClientIdFlipkartV2;
    }
    else if (window.location.host.includes("appliancehub")) {
      return store.getState().tenant.tenantInfo.auth.webClientIdAmazonV2;
    }
    else {
      return store.getState().tenant.tenantInfo.auth.webClientIdV2;
    }
  },
  GET_USERMANAGEMENT_API_URL_V2: () => store.getState().tenant.tenantInfo.auth.baseUrlV2,
  GET_UM_MOBILE_USERS_VERSION: () => store.getState().user.umMobileUsersVersion,
};