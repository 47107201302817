import React, {useEffect} from "react";
import { connect } from "react-redux";
import {getOffBoardDevices} from '../../../action/metrics';
import {catogariesSeriesData, seriesXaxisData, filterArr,totalFilterCount,offBoardData} from './GraphParameter';
import Graph from "./Graph";

function OffboardDevice({getOffBoardDevices, devicesCatogaryFilter,offBordedDevices,graphType,setDeviceCount,type}) {
  useEffect(()=>{
    if(type !== "4") {
      const que= {
        "dateType":type === "1" ? "daily" : type === "2" ? "weekly" : "monthly",
      }
      getOffBoardDevices(que)
    }
},[getOffBoardDevices,type,graphType])
    let data_res = []
    data_res = offBoardData(offBordedDevices)
    const intervalData =  data_res.length> 0 ? seriesXaxisData(data_res, graphType) : []

    let arr = data_res.length> 0 ? catogariesSeriesData(data_res) : []
    const filterArray = filterArr(arr,devicesCatogaryFilter)
    const totalCount = totalFilterCount(filterArray)
    useEffect(()=>{
      setDeviceCount(totalCount)
    },[totalCount,type])

  return (
    <div>
      <div className="chart_view">
        <Graph
          text={" Devices"}
          x_data={intervalData}
          title={"Number Of Offboard Devices "}
          x_title = {"Offboard Date"}
          y_data={filterArray}
          stacked = {true}
          color = {["#a1469e", "#83c7d5", "#02a699","#9E7777","#fee251"]}
        />
      </div>
    </div>
  );
}
const mapStateToProps = (state) =>{
  return{
    offBordedDevices:state.metrics.offBordedDevices     
  }
}

export default connect(mapStateToProps, { getOffBoardDevices })(OffboardDevice);
