import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import { getfirmwareInfo } from "../../action/report";
import Alert from "../layout/Alert";
import Spinner from "../layout/Spinner";
import {filterFirmwareData} from '../Common/Metrics/GraphParameter';
const FirmwareInfo = ({
  getfirmwareInfo,
  allowedDeviceCategories,
  wrapperClass = "right-content-wraper",
  report: { firmwareInfo, loading },
}) => {
  useEffect(() => {
    getfirmwareInfo();
  }, [getfirmwareInfo]);

  const renderTable = () => {
    let firmwareInfoDirt = filterFirmwareData(firmwareInfo,allowedDeviceCategories);
    let mappedInfo = {};
    firmwareInfoDirt.forEach((element) => {
      let cat = element.category.toLowerCase();
      if (!mappedInfo[cat]) {
        mappedInfo[cat] = [];
      }
      mappedInfo[cat].push(element);
    });

    return Object.keys(mappedInfo).map((key, index) => {
      return (
        <div>
          <h4>Device Type :{key.toUpperCase()}</h4>
          <div className="content-block bottom-shadow" key={key}>
            <div className="row">
              <div className="s12 p-0 col">
                <div className="table-wrapper">
                  <table
                    width="100%"
                    align="left"
                    className="table statistic style-4"
                  >
                    <thead>
                      <tr>
                        <th>Sr</th>
                        <th>Firmware Version</th>
                        <th>Count</th>
                        <th>Percentage</th>
                      </tr>
                    </thead>
                    <tbody>{renderTableData(mappedInfo[key])}</tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    });
  };

  const renderTableData = (data) => {
    return data.map((element, index) => {
      const { firmwareVersion, category, count, percentage } = element;
      return (
        <tr key={category.toLowerCase() + firmwareVersion}>
          <td width="2%">{index + 1}</td>
          <td>{firmwareVersion}</td>
          <td>{count}</td>
          <td>{percentage}</td>
        </tr>
      );
    });
  };

  return loading ? (
    <div style={{ position: "absolute", top: "50%", left: "50%" }}>
      {" "}
      <Spinner />{" "}
    </div>
  ) : (
    <Fragment>
      <div className={wrapperClass}>
        <Alert />
        <div className="container">
          <div className="page-title">
            <h3>Firmware Info</h3>
          </div>
          <div className="firmwareTableWrapper">{renderTable()}</div>
        </div>
      </div>
    </Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    report: state.report,
    allowedDeviceCategories:state.auth.allowedDeviceCategories,
  };
};
export default connect(mapStateToProps, { getfirmwareInfo })(FirmwareInfo);
