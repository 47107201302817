import React, {useEffect} from "react";
import { connect } from "react-redux";
import {getHourlyDevices} from '../../../action/metrics';
import {time_filter_data,hours} from './GraphParameter';
import Graph from "./Graph";

function HourlyActiveDevice({TimeFilterType, getHourlyDevices, hourlyActiveDevices,formateDate, setDeviceCount}) {
  useEffect(()=>{
    const que= {
        "startDate":formateDate(new Date())
      }
      getHourlyDevices(que)
},[getHourlyDevices])
    const y_series_data = time_filter_data(hourlyActiveDevices,TimeFilterType)
    useEffect(()=>{
      setDeviceCount(y_series_data[0]?.data?.reduce((a,c)=>a+c))
  },[y_series_data])

  return (
    <div>
      <div className="chart_view">
        <Graph
          text={" Devices"}
          x_data={hourlyActiveDevices.length > 0 ? hours:[]}
          title={"Number Of Active Devices "}
          y_data={y_series_data.length > 0 ? y_series_data : []}
        //   stacked = {true}
          color = {["#a1469e", "#83c7d5", "#02a699","#9E7777","#fee251","#aee251"]}
          x_title = {"Active devices usage every hour of the day"}
        />
      </div>
    </div>
  );
}
const mapStateToProps = (state) =>{
  return{
    hourlyActiveDevices:state.metrics.hourlyActiveDevices     
  }
}

export default connect(mapStateToProps, { getHourlyDevices })(HourlyActiveDevice);

