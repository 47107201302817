import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import { setAlert } from "../../action/alert";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import CreateDealer from "./createDealer";
import GetDealerInfo from "./getDealerInfo";
import UploadPSNDealerMapping from './uploadPSNDealerMapping'
import { uploadPSNDealerFile } from "../../action/deviceDealer";
import customConfig from "../../config";

const useStyles = makeStyles({
    selectInput: {
        height: "3rem",
        width: "100%"
    },
    selectValues: {
        width: "100%",
    },
    activeTab: {
        border: '1px solid #bdbdbd',
        backgroundColor: '#e0e0e0',
        textTransform: 'capitalize'
    },
    nonActiveTab: {
        border: 'none',
        backgroundColor: 'none',
        textTransform: 'capitalize'
    },
});

const DeviceDealerMgmt = ({
    wrapperClass = "right-content-wraper",
    allowedDeviceCategories,
    allowedCompanyBrand,
    allowedCountries,
    setAlert,
    deviceDealer,
    uploadPSNDealerFile,
    userRoleInfo,
}) => {
    const classes = useStyles()
    const [activeTab, setActiveTab] = React.useState(0)
    const [deviceDealerDetails, setDeviceDealerDetails] = useState({})
    const [editDeviceDealerDetails, setEditDeviceDealerDetails] = useState(false)

    const [showUpdatedDealerInfo, setShowUpdatedDealerInfo] = useState(false)

    useEffect(() => {
        if (activeTab != 0) {
            // active tab 0 is create/edit
            // whenever the tab is changed/switched then reset the tab configs

            setEditDeviceDealerDetails(false)
            setDeviceDealerDetails({})
        }
    }, [activeTab])

    function handleEditDeviceDealerDetails(deviceDealerDetails) {
        setDeviceDealerDetails(deviceDealerDetails)
        setEditDeviceDealerDetails(true)
        setActiveTab(0)
        setShowUpdatedDealerInfo(false)
    }

    function handleCancelEditDeviceDealerDetails() {
        setActiveTab(1) // get back to details page
        setEditDeviceDealerDetails(false)
        setDeviceDealerDetails({})
        setShowUpdatedDealerInfo(true)
    }

    return (
        <div className={wrapperClass}>
            <div className="row">
                <Tabs
                    value={activeTab}
                    onChange={(e, newValue) => {
                        setActiveTab(newValue)
                    }}
                    TabIndicatorProps={{
                        style: {
                            backgroundColor: "#bdbdbd"
                        }
                    }}
                    style={{ marginTop: '20px' }}
                >
                    {
                        (userRoleInfo.level > 4 || userRoleInfo.roles.includes(customConfig.roleCloudAdmin)) &&
                        <Tab label="Create Dealer" className={activeTab == 0 ? classes.activeTab : classes.nonActiveTab} />
                    }
                    <Tab label="Get Dealer Information" className={activeTab == 1 ? classes.activeTab : classes.nonActiveTab} />
                    {
                        (userRoleInfo.level > 4 || userRoleInfo.roles.includes(customConfig.roleCloudAdmin)) &&
                        <Tab label="Upload PSN Dealer Mapping" className={activeTab == 2 ? classes.activeTab : classes.nonActiveTab} />
                    }
                </Tabs>
                {
                    (userRoleInfo.level > 4 || userRoleInfo.roles.includes(customConfig.roleCloudAdmin)) ? <Fragment>
                        <div hidden={activeTab != 0}>
                            <CreateDealer
                                allowedDeviceCategories={allowedDeviceCategories}
                                allowedCompanyBrand={allowedCompanyBrand}
                                allowedCountries={allowedCountries}
                                setAlert={setAlert}
                                editDeviceDealerDetails={editDeviceDealerDetails}
                                deviceDealerDetails={deviceDealerDetails}
                                cancelEditDeviceDealerDetails={handleCancelEditDeviceDealerDetails}
                            ></CreateDealer>
                        </div>
                        <div hidden={activeTab != 1}>
                            <GetDealerInfo
                                allowedDeviceCategories={allowedDeviceCategories}
                                allowedCompanyBrand={allowedCompanyBrand}
                                allowedCountries={allowedCountries}
                                setAlert={setAlert}
                                onEditDeviceDealerDetails={handleEditDeviceDealerDetails}
                                showUpdatedDealerInfo={showUpdatedDealerInfo}
                                userRoleInfo={userRoleInfo}
                            ></GetDealerInfo>
                        </div>
                        <div hidden={activeTab != 2}>
                            <UploadPSNDealerMapping
                                allowedDeviceCategories={allowedDeviceCategories}
                                allowedCompanyBrand={allowedCompanyBrand}
                                allowedCountries={allowedCountries}
                                setAlert={setAlert}
                                deviceDealer={deviceDealer}
                                uploadPSNDealerFile={uploadPSNDealerFile}
                            ></UploadPSNDealerMapping>
                        </div>
                    </Fragment>
                        :
                        <Fragment>
                            <div hidden={activeTab != 0}>
                                <GetDealerInfo
                                    allowedDeviceCategories={allowedDeviceCategories}
                                    allowedCompanyBrand={allowedCompanyBrand}
                                    allowedCountries={allowedCountries}
                                    setAlert={setAlert}
                                    onEditDeviceDealerDetails={handleEditDeviceDealerDetails}
                                    showUpdatedDealerInfo={showUpdatedDealerInfo}
                                    userRoleInfo={userRoleInfo}
                                ></GetDealerInfo>
                            </div>
                        </Fragment>
                }

            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    allowedDeviceCategories: state.auth.allowedDeviceCategories,
    allowedCompanyBrand: state.auth.allowCompanyBrand,
    allowedCountries: state.auth.allowedCountries,
    deviceDealer: state.deviceDealer,
    userRoleInfo: {
        level: state.auth.userLevel,
        roles: state.auth.userRoles,
    },
});

const mapDispatchToProps = (dispatch) => ({
    setAlert: (message, status) => dispatch(setAlert(message, status)),
    uploadPSNDealerFile: (formData) => dispatch(uploadPSNDealerFile(formData))
});

export default connect(mapStateToProps, mapDispatchToProps)(DeviceDealerMgmt);