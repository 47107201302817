import React, { Fragment, useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import CircularProgress from "@material-ui/core/CircularProgress";
import Alert from "../layout/Alert";
import PhoneInput from "react-phone-input-2";
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import { createDealerInformation, updateDealerInformation } from './../../action/deviceDealer'
import { getCountryCodeISO } from "../../utils/general";
import './deviceDealersList.css'

const useStyles = makeStyles({
    selectInput: {
        height: "3rem",
        width: "100%"
    },
    selectValues: {
        width: "100%",
    },
    activeTab: {
        border: '1px solid #bdbdbd',
        backgroundColor: '#e0e0e0',
        textTransform: 'capitalize'
    },
    nonActiveTab: {
        border: 'none',
        backgroundColor: 'none',
        textTransform: 'capitalize'
    },
    deleteButton: {
        float: "left"
    },
});

const CreateDealer = ({
    allowedDeviceCategories,
    allowedCompanyBrand,
    allowedCountries,
    setAlert,
    editDeviceDealerDetails,
    deviceDealerDetails,
    cancelEditDeviceDealerDetails
}) => {

    const classes = useStyles()

    const dealerPhoneTypeFree = "free"
    const dealerPhoneTypePaid = "paid"
    const dealerPhoneDefaultCountryCode = "91"

    const [dealerId, setDealerId] = useState(deviceDealerDetails?.["id"] ?? "")
    const [dealerName, setDealerName] = useState(deviceDealerDetails?.["name"] ?? "")
    const [dealerWebsite, setDealerWesbite] = useState(deviceDealerDetails?.["webiste"] ?? "")
    const [dealerEmail, setDealerEmail] = useState(deviceDealerDetails?.["email"] ?? "")
    const [dealerPhoneNumbers, setDealerPhoneNumbers] = useState([{
        //"countryCode": dealerPhoneDefaultCountryCode,
        "number": "",
        "type": dealerPhoneTypeFree
    }])
    const [dealerCountry, setDealerCountry] = useState("")
    const [dealerAddressLine1, setDealerAddressLine1] = useState(deviceDealerDetails?.["address"]?.["line1"] ?? "")
    const [dealerAddressLine2, setDealerAddressLine2] = useState(deviceDealerDetails?.["address"]?.["line2"] ?? "")
    const [dealerCity, setDealerCity] = useState(deviceDealerDetails?.["address"]?.["city"] ?? "")
    const [dealerPincode, setDealerPincode] = useState(deviceDealerDetails?.["address"]?.["pin"] ?? "")
    const [dealerSocialFBLink, setDealerSocialFBLink] = useState(deviceDealerDetails?.["socials"]?.["facebook"] ?? "")
    const [dealerSocialInstaLink, setDealerSocialInstaLink] = useState(deviceDealerDetails?.["socials"]?.["instagram"] ?? "")
    const [dealerSocialXLink, setDealerSocialXLink] = useState(deviceDealerDetails?.["socials"]?.["x"] ?? "")

    const [createDealerButtonClicked, setCreateDealerButtonClicked] = useState(false)
    const [createDealerInfoInProgress, setCreateDealerInfoInProgress] = useState(false)

    // Adjust the height of menu items in select. Otherwise get hidden behind headers
    const menuProps = {
        style: {
            marginTop: '52px'
        },
    };

    useEffect(() => {
        if (createDealerButtonClicked) {
            handleCreateDealer()
        }
    }, [createDealerButtonClicked])


    useEffect(() => {
        if (editDeviceDealerDetails && deviceDealerDetails && Object.keys(deviceDealerDetails).length != 0) {
            setDealerId(deviceDealerDetails["id"] || "")
            setDealerName(deviceDealerDetails["name"] || "")
            setDealerWesbite(deviceDealerDetails["website"] || "")
            setDealerEmail(deviceDealerDetails["email"] || "")
            setDealerCountry(deviceDealerDetails["country"] || "")
            setDealerAddressLine1(deviceDealerDetails?.["address"]?.["line1"] ?? "")
            setDealerAddressLine2(deviceDealerDetails?.["address"]?.["line2"] ?? "")
            setDealerCity(deviceDealerDetails?.["address"]?.["city"] ?? "")
            setDealerPincode(deviceDealerDetails?.["address"]?.["pin"] ?? "")
            setDealerSocialFBLink(deviceDealerDetails?.["socials"]?.["facebook"] ?? "")
            setDealerSocialInstaLink(deviceDealerDetails?.["socials"]?.["instagram"] ?? "")
            setDealerSocialXLink(deviceDealerDetails?.["socials"]?.["x"] ?? "")

            // set phone numbers
            let arrPhoneNumbers = []
            for (let i = 0; i < deviceDealerDetails["phone"].length; i++) {
                arrPhoneNumbers.push({
                    //"countryCode": deviceDealerDetails["phone"][i],
                    "number": deviceDealerDetails["phone"][i]["number"].replace(/[+ - e E .]+/g, ''),
                    "type": deviceDealerDetails["phone"][i]["type"]
                })
            }
            setDealerPhoneNumbers(arrPhoneNumbers)

        } else {
            setDealerName("")
            setDealerWesbite("")
            setDealerEmail("")
            setDealerPhoneNumbers([{
                //"countryCode": dealerPhoneDefaultCountryCode,
                "number": "",
                "type": dealerPhoneTypeFree
            }])
            setDealerCountry("")
            setDealerAddressLine1("")
            setDealerAddressLine2("")
            setDealerCity("")
            setDealerPincode("")
            setDealerSocialFBLink("")
            setDealerSocialInstaLink("")
            setDealerSocialXLink("")
        }
    }, [editDeviceDealerDetails, deviceDealerDetails])

    function handleCreateDealer() {

        let bIsUserInputFieldError = false

        if (!dealerName || dealerName.length == 0) {
            setAlert('Dealer Name Cannot be Empty', 'danger')
            bIsUserInputFieldError = true
        }

        // Website URL is not mandatory.. Hence not checked for emptiness
        if (dealerWebsite &&
            (dealerWebsite.match(/[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/gi) == null ||
                dealerWebsite.match(/(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi) == null
            )
        ) {

            setAlert('Invalid Dealer Website URL', 'danger')
            bIsUserInputFieldError = true
        }

        // Email is not mandatory
        if (dealerEmail &&
            dealerEmail.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/) == null) {

            setAlert('Invalid Dealer Email ID', 'danger')
            bIsUserInputFieldError = true
        }

        // Phone Number Validation
        for (let index in dealerPhoneNumbers) {
            if (!dealerPhoneNumbers[index]["number"] || dealerPhoneNumbers[index]["number"].toString().length == 0) {
                setAlert('Invalid Phone Number Details', 'danger')
                bIsUserInputFieldError = true
                break
            }
        }

        if (!dealerCountry || dealerCountry.length == 0) {
            setAlert('Invalid Country Name. Cannot be Empty', 'danger')
            bIsUserInputFieldError = true
        }

        if (!dealerAddressLine1 || dealerAddressLine1.length == 0) {
            setAlert('Address Line 1 is Mandatory. Cannot be Empty', 'danger')
            bIsUserInputFieldError = true
        }

        if (!dealerCity || dealerCity.length == 0) {
            setAlert('City is Mandatory. Cannot be Empty', 'danger')
            bIsUserInputFieldError = true
        }

        if (bIsUserInputFieldError) {
            setCreateDealerButtonClicked(false)
            window.scrollTo(0, 0)
            return
        }

        setCreateDealerInfoInProgress(true)

        // modify the dealerPhoneNumber structure
        // let dealerPhoneNumbersWithCountryCode = []
        // for (let index in dealerPhoneNumbers) {
        //     dealerPhoneNumbersWithCountryCode.push({
        //         "number": dealerPhoneNumbers[index]["countryCode"] + dealerPhoneNumbers[index]["number"],
        //         "type": dealerPhoneNumbers[index]["type"]
        //     })
        // }

        let dealerInformation = {}
        dealerInformation["name"] = dealerName
        dealerInformation["phone"] = dealerPhoneNumbers
        dealerInformation["country"] = dealerCountry
        dealerInformation["address"] = {}
        dealerInformation["address"]["line1"] = dealerAddressLine1
        dealerInformation["address"]["city"] = dealerCity
        dealerInformation["socials"] = {}

        if (dealerWebsite && dealerWebsite.length > 0) {
            dealerInformation["website"] = dealerWebsite
        }
        if (dealerEmail && dealerEmail.length > 0) {
            dealerInformation["email"] = dealerEmail
        }
        if (dealerAddressLine2 && dealerAddressLine2.length > 0) {
            dealerInformation["address"]["line2"] = dealerAddressLine2
        }
        if (dealerPincode && dealerPincode.length > 0) {
            dealerInformation["address"]["pin"] = parseInt(dealerPincode)
        }
        if (dealerSocialFBLink && dealerSocialFBLink.length > 0) {
            dealerInformation["socials"]["facebook"] = dealerSocialFBLink
        }
        if (dealerSocialInstaLink && dealerSocialInstaLink.length > 0) {
            dealerInformation["socials"]["instagram"] = dealerSocialInstaLink
        }
        if (dealerSocialXLink && dealerSocialXLink.length > 0) {
            dealerInformation["socials"]["x"] = dealerSocialXLink
        }

        if (editDeviceDealerDetails) {
            updateDealerInformation(dealerId, dealerInformation)
                .then(() => {
                    setAlert("Dealer Updated Successfully", "success")
                    window.scrollTo(0, 0)

                    setCreateDealerInfoInProgress(false)
                    setCreateDealerButtonClicked(false)

                    // go back to the details page after update
                    cancelEditDeviceDealerDetails()
                })
                .catch((err) => {
                    setAlert(err?.response?.data?.message ?? "Failed to Update Device Dealer", "danger")
                    window.scrollTo(0, 0)

                    setCreateDealerInfoInProgress(false)
                    setCreateDealerButtonClicked(false)
                })
        }
        else {
            createDealerInformation(dealerInformation)
                .then(() => {
                    setAlert("Dealer Created Successfully", "success")
                    window.scrollTo(0, 0)

                    setCreateDealerInfoInProgress(false)
                    setCreateDealerButtonClicked(false)
                })
                .catch((err) => {
                    setAlert(err?.response?.data?.message ?? "Failed to Create Device Dealer", "danger")
                    window.scrollTo(0, 0)

                    setCreateDealerInfoInProgress(false)
                    setCreateDealerButtonClicked(false)
                })
        }
    }

    function handleDealerDataReset() {
        setDealerName("")
        setDealerWesbite("")
        setDealerEmail("")
        setDealerPhoneNumbers([{
            //"countryCode": dealerPhoneDefaultCountryCode,
            "number": "",
            "type": dealerPhoneTypeFree
        }])
        setDealerAddressLine1("")
        setDealerAddressLine2("")
        setDealerCity("")
        setDealerPincode("")
        setDealerSocialFBLink("")
        setDealerSocialInstaLink("")
        setDealerSocialXLink("")
        setCreateDealerButtonClicked(false)
        setCreateDealerInfoInProgress(false)
    }

    function handleAddDealerPhoneNumber() {
        let updatedDealerPhoneNumbers = [...dealerPhoneNumbers]
        updatedDealerPhoneNumbers.push({
            //"countryCode": dealerPhoneDefaultCountryCode,
            "number": "",
            "type": dealerPhoneTypeFree
        })

        setDealerPhoneNumbers(updatedDealerPhoneNumbers)
    }

    // function onChangeDealerPhoneCountryCode(value, index) {
    //     let elements = [...dealerPhoneNumbers]
    //     elements[index]["countryCode"] = value

    //     setDealerPhoneNumbers(elements)
    // }

    function onChangeDealerPhoneNumber(value, index) {
        let elements = [...dealerPhoneNumbers]
        elements[index]["number"] = value

        setDealerPhoneNumbers(elements)
    }

    function onChangeDealerPhoneNumberType(value, index) {
        let elements = [...dealerPhoneNumbers]
        elements[index]["type"] = value

        setDealerPhoneNumbers(elements)
    }

    function onDeleteDealerPhoneNumber(indexToDelete) {
        let updatedDealerPhoneNumbers = dealerPhoneNumbers.filter((element, index) => {
            return index != indexToDelete
        })

        setDealerPhoneNumbers(updatedDealerPhoneNumbers)
    }

    function handleCancelEditDeviceDealerDetails() {
        cancelEditDeviceDealerDetails()
    }

    return (
        <Fragment>
            <div className="content-block bottom-shadow">
                <Alert />
                <div className="py-15 ">
                    <Grid container style={{ marginBottom: "20px" }}>
                        <Grid item xs={2} style={{ marginLeft: "10px" }}>
                            <label className="title" style={{ color: 'black', fontSize: '14px' }}>Name *</label>
                        </Grid>
                        <Grid item xs={4}>
                            <input
                                className="add-input xl"
                                placeholder="Enter Your Name"
                                type="text"
                                style={{ width: "100% !important" }}
                                value={dealerName}
                                onChange={(e) => {
                                    setDealerName(e.target.value)
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container style={{ marginBottom: "20px" }}>
                        <Grid item xs={2} style={{ marginLeft: "10px" }}>
                            <label className="title" style={{ color: 'black', fontSize: '14px' }}>Website</label>
                        </Grid>
                        <Grid item xs={4}>
                            <input
                                className="add-input xl"
                                placeholder="Enter Your Website URL"
                                type="text"
                                style={{ width: "100% !important" }}
                                value={dealerWebsite}
                                onChange={(e) => {
                                    setDealerWesbite(e.target.value)
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container style={{ marginBottom: "20px" }}>
                        <Grid item xs={2} style={{ marginLeft: "10px", marginTop: "10px" }}>
                            <label className="title" style={{ color: 'black', fontSize: '14px' }}>Email</label>
                        </Grid>
                        <Grid item xs={4}>
                            <input
                                className="add-input xl"
                                placeholder="Enter Your Email ID"
                                type="text"
                                style={{ width: "100% !important" }}
                                value={dealerEmail}
                                onChange={(e) => {
                                    setDealerEmail(e.target.value)
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container style={{ marginBottom: "20px" }}>
                        <Grid item xs={2} style={{ marginLeft: "10px" }}>
                            <label className="title" style={{ color: 'black', fontSize: '14px' }}>Phone *</label>
                        </Grid>
                        <Grid item xs={2} style={{ marginRight: "2px" }}>
                            {/* <PhoneInput
                                enableSearch={false}
                                value={getPhoneInputCodeFromCountryISO(dealerPhoneNumbers[0]["countryCode"])}
                                onChange={(e) => {
                                    onChangeDealerPhoneCountryCode(e.target.value, 0)
                                }}
                                onlyCountries={allowedCountries}
                                containerClass="country-code-container"
                                searchClass="country-code-search"
                                dropdownClass="country-code-dropdown"
                            /> */}
                            <input
                                className="add-input xl "
                                placeholder="Enter Your Mobile Number"
                                type="number"
                                style={{ width: "100% !important" }}
                                value={dealerPhoneNumbers[0]["number"]}
                                onChange={(e) => {
                                    onChangeDealerPhoneNumber(e.target.value, 0)
                                }}
                                onKeyDown={(e) => {
                                    ['e', 'E', '-', '+', '.'].includes(e.key) && e.preventDefault();
                                }}
                            />
                        </Grid>
                        <Grid item xs={2} style={{ marginLeft: "2px" }}>
                            <FormControl variant="outlined" className={classes.selectValues}>
                                <InputLabel id="select-outlined-label-type-name" className="title">Select Type</InputLabel>
                                <Select
                                    labelId="select-outlined-label-type-name"
                                    id="select-outlined-type-name-id"
                                    value={dealerPhoneNumbers[0]["type"]}
                                    onChange={(e) => {
                                        onChangeDealerPhoneNumberType(e.target.value, 0)
                                    }}
                                    label="Select Type"
                                    className={classes.selectInput}
                                    MenuProps={menuProps}
                                >
                                    <MenuItem key={"freeType"} value={"free"}>Free</MenuItem>
                                    <MenuItem key={"paidType"} value={"paid"}>Paid</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={2} style={{ marginLeft: "5px" }}>
                            <button
                                className="btn btn-style-2 orange-bg" onClick={handleAddDealerPhoneNumber}
                            >
                                Add Phone Number
                            </button>
                        </Grid>
                    </Grid>
                    {
                        dealerPhoneNumbers && dealerPhoneNumbers.length > 1 && dealerPhoneNumbers.slice(1).map((element, index) => {
                            return <Fragment>
                                <Grid container style={{ marginBottom: "20px" }}>
                                    <Grid item xs={2} style={{ marginLeft: "10px" }}>
                                    </Grid>
                                    <Grid xs={2} style={{ marginRight: "2px" }}>
                                        {/* <PhoneInput
                                            enableSearch={false}
                                            value={getPhoneInputCodeFromCountryISO(element["countryCode"])}
                                            onChange={(e) => {
                                                onChangeDealerPhoneCountryCode(e.target.value, index + 1)
                                            }}
                                            onlyCountries={allowedCountries}
                                            containerClass="country-code-container"
                                            searchClass="country-code-search"
                                            dropdownClass="country-code-dropdown"
                                        /> */}
                                        <input
                                            className="add-input xl "
                                            placeholder="Enter Your Mobile Number"
                                            type="number"
                                            style={{ width: "100% !important" }}
                                            value={element["number"]}
                                            onChange={(e) => {
                                                onChangeDealerPhoneNumber(e.target.value, index + 1)
                                            }}
                                            onKeyDown={(e) => {
                                                ['e', 'E', '+', '-', '.'].includes(e.key) && e.preventDefault();
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={2} style={{ marginLeft: "2px" }}>
                                        <FormControl variant="outlined" className={classes.selectValues}>
                                            <InputLabel id="select-outlined-label-type-name" className="title">Select Type</InputLabel>
                                            <Select
                                                labelId="select-outlined-label-type-name"
                                                id="select-outlined-type-name-id"
                                                value={element["type"]}
                                                onChange={(e) => {
                                                    onChangeDealerPhoneNumberType(e.target.value, index + 1)
                                                }}
                                                label="Select Type"
                                                className={classes.selectInput}
                                                MenuProps={menuProps}
                                            >
                                                <MenuItem key={"freeType"} value={"free"}>Free</MenuItem>
                                                <MenuItem key={"paidType"} value={"paid"}>Paid</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <IconButton aria-label="delete" onClick={(e) => { onDeleteDealerPhoneNumber(index + 1) }}
                                            className={`${classes.deleteButton}`}>
                                            <DeleteIcon fontSize="small" />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </Fragment>
                        })
                    }
                    <Grid container style={{ marginBottom: "20px" }}>
                        <Grid item xs={2} style={{ marginLeft: "10px", marginTop: "10px" }}>
                            <label className="title" style={{ color: 'black', fontSize: '14px' }}>Country *</label>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl variant="outlined" className={classes.selectInput}>
                                <InputLabel id="select-outlined-label-values">Select Country</InputLabel>
                                <Select
                                    labelId="select-outlined-label-values"
                                    id="select-outlined-values-id"
                                    value={dealerCountry}
                                    onChange={(e) => {
                                        setDealerCountry(e.target.value)
                                    }}
                                    label="Select Country"
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    className={classes.selectInput}
                                >
                                    {
                                        allowedCountries.map(country => {
                                            return <MenuItem key={country} value={getCountryCodeISO(country.toUpperCase())}>{getCountryCodeISO(country.toUpperCase())}</MenuItem>
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container style={{ marginBottom: "20px" }}>
                        <Grid item xs={2} style={{ marginLeft: "10px" }}>
                            <label style={{ color: 'black', fontSize: '14px' }}>Address</label>
                        </Grid>
                        <Grid item xs={4} style={{ marginRight: "2px" }}>
                            <input
                                className="add-input xl"
                                placeholder="Line 1 *"
                                type="text"
                                style={{ width: "100% !important" }}
                                value={dealerAddressLine1}
                                onChange={(e) => {
                                    setDealerAddressLine1(e.target.value)
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container style={{ marginBottom: "20px" }}>
                        <Grid item xs={2} style={{ marginLeft: "10px" }}>
                        </Grid>
                        <Grid item xs={4} style={{ marginRight: "2px" }}>
                            <input
                                className="add-input xl"
                                placeholder="Line 2"
                                type="text"
                                style={{ width: "100% !important" }}
                                value={dealerAddressLine2}
                                onChange={(e) => {
                                    setDealerAddressLine2(e.target.value)
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container style={{ marginBottom: "20px" }}>
                        <Grid item xs={2} style={{ marginLeft: "10px" }}>
                            City *
                        </Grid>
                        <Grid item xs={4} style={{ width: "100% important" }}>
                            <input
                                className="add-input xl"
                                placeholder="City"
                                type="text"
                                style={{ width: "100% !important" }}
                                value={dealerCity}
                                onChange={(e) => {
                                    setDealerCity(e.target.value)
                                }}
                                onKeyDown={(e) => {
                                    if (e.key.match(/^[A-Za-z]+$/)) {
                                        // donot do anything
                                    }
                                    else {
                                        e.preventDefault()
                                    }
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container style={{ marginBottom: "20px" }}>
                        <Grid item xs={2} style={{ marginLeft: "10px" }}>
                            Pincode
                        </Grid>
                        <Grid item xs={4} style={{ width: "100% important" }}>
                            <input
                                className="add-input xl"
                                placeholder="Pincode"
                                type="number"
                                style={{ width: "100% !important" }}
                                value={dealerPincode}
                                onChange={(e) => {
                                    setDealerPincode(e.target.value)
                                }}
                                onKeyDown={(e) => {
                                    ['e', 'E', '+', '-', '.'].includes(e.key) && e.preventDefault();
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container style={{ marginBottom: "20px" }}>
                        <Grid item xs={2} style={{ marginLeft: "10px" }}>
                            Social Links
                        </Grid>
                        <Grid item xs={4} style={{ width: "100% important" }}>
                            <input
                                className="add-input xl"
                                placeholder="Facebook"
                                type="text"
                                style={{ width: "100% !important" }}
                                value={dealerSocialFBLink}
                                onChange={(e) => {
                                    setDealerSocialFBLink(e.target.value)
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container style={{ marginBottom: "20px" }}>
                        <Grid item xs={2} style={{ marginLeft: "10px" }}>
                        </Grid>
                        <Grid item xs={4} style={{ width: "100% important" }}>
                            <input
                                className="add-input xl"
                                placeholder="Instagram"
                                type="text"
                                style={{ width: "100% !important" }}
                                value={dealerSocialInstaLink}
                                onChange={(e) => {
                                    setDealerSocialInstaLink(e.target.value)
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container style={{ marginBottom: "20px" }}>
                        <Grid item xs={2} style={{ marginLeft: "10px" }}>
                        </Grid>
                        <Grid item xs={4} style={{ width: "100% important" }}>
                            <input
                                className="add-input xl"
                                placeholder="X"
                                type="text"
                                style={{ width: "100% !important" }}
                                value={dealerSocialXLink}
                                onChange={(e) => {
                                    setDealerSocialXLink(e.target.value)
                                }}
                            />
                        </Grid>
                    </Grid>
                    <div className="row">
                        <button
                            className="btn btn-style-2 orange-bg"
                            onClick={() => {
                                setCreateDealerButtonClicked(true)
                            }}
                            style={{ marginLeft: "10px" }}
                        >
                            {
                                createDealerInfoInProgress ? <Fragment>
                                    <CircularProgress size="2em" style={{ color: "#AFB42B", marginTop: "5px" }} />
                                </Fragment>
                                    : <Fragment>
                                        {
                                            editDeviceDealerDetails ? "Update Dealer" : "Create Dealer"
                                        }
                                    </Fragment>
                            }
                        </button>
                        {
                            editDeviceDealerDetails ? <button
                                className="btn btn-style-2 orange-bg" onClick={handleCancelEditDeviceDealerDetails}
                                style={{ marginLeft: "20px" }}
                            >
                                Cancel
                            </button>
                                :
                                <button
                                    className="btn btn-style-2 orange-bg" onClick={handleDealerDataReset}
                                    style={{ marginLeft: "20px" }}
                                >
                                    Reset
                                </button>
                        }
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default CreateDealer