import React, { useState , useEffect} from "react";
import M from "materialize-css/dist/js/materialize.min.js";
import {deviceCategory} from '../../Common/Metrics/GraphParameter';

function VersionDeviceCatogery({ setSelecteddevice, deviceCount, title, allowedDeviceCategories }) {

    const handleDeviceCatogery = (event) => {
        setSelecteddevice(event.target.value)
      };
      

  useEffect(() => {
    M.AutoInit();
  }, []);

  return (
    <div>
    
      <div className="control-capabilities d-flex justify-space-between">
          <div className="hd-style-5 device_main_div">
            <div className="hd-style-5 float_left d-flex">
              <div className="black-text font-600 ">
              {title}
                <div className="gray-text-light device_no">{deviceCount}  </div>
              </div>
             
            </div>
          </div>
        <div className="model-srch reset w-auto date_range_position">
            <label className="field-name mb-0">Device Category</label>
                  <select
                    className="custom-select select-reset radius-20"
                    name="searchBy"
                    onChange={handleDeviceCatogery}
                   >
                     {allowedDeviceCategories.map((device)=>(
                       <option value={device}> {device} </option>
                     ))}
                  </select>
        </div>
      </div>{" "}
    </div>
  );
}

export default VersionDeviceCatogery;
