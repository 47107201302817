import React from "react";
import BarcodeScannerComponent from "react-qr-barcode-scanner";

function BarcodeScanner({
    scanDetailsHandler,
}) {
    const onScanBarcode = (data) => {
        if (data) {
            scanDetailsHandler(data)
        }
    }

    return (
        <BarcodeScannerComponent
            //delay={500}
            width={500}
            height={400}
            onUpdate={(err, result) => {
                if (result) {
                    onScanBarcode(result.text)
                }
            }}
            style={{marginTop: '-40px !important'}}
            videoContainerStyle={{ paddingTop: '80%' }}
            torch={true}
        />
    );
}

export default BarcodeScanner;