import axios from "axios";
import { setAlert } from "./alert";
import {
  INIT_FOTA_JOBS,
  GET_FOTA_JOBS,
  GET_FOTA_JOBS_ERROR,
  ADD_FOTA_JOBS,
  // ADD_FOTA_JOBS_ERROR,
  UPDATE_FOTA_JOBS,
  // UPDATE_FOTA_JOBS_ERROR,
  DELETE_FOTA_JOBS,
  // DELETE_FOTA_JOBS_ERROR,
} from "../action/types";
// import customConfig from "../config";
import env from "../env";

export const initFOTAJobs = () => async (dispatch) => {
  dispatch({
    type: INIT_FOTA_JOBS
  })
}

export const getAllFotaJobs = () => async (dispatch) => {
  try {
    dispatch({
      type: INIT_FOTA_JOBS,
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.get(
      `${env.GET_FOTA_API_URL()}/simplifi/v1/firmwareupgrade/scheduler/getAllJobs`,
      config
    );
    dispatch({
      type: GET_FOTA_JOBS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_FOTA_JOBS_ERROR,
      payload: {
        msg: err.response
          ? err.response.data && err.response.data.message
            ? err.response.data.message
            : err.response.statusText
          : null,
        status: err.response ? err.response.status : null,
      },
    });
  }
};

export const addFotaJob =
  (jobName, endDate, dayOfWeek, hour, minute) => async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const body = {
      jobName,
      endDate,
      frequency: {
        dayOfWeek,
        hour,
        minute,
      },
    };
    try {
      const res = await axios.post(
        `${env.GET_FOTA_API_URL()}/simplifi/v1/firmwareupgrade/scheduler/addJob`,
        body,
        config
      );
      dispatch({ type: ADD_FOTA_JOBS });
    } catch (err) {
      let errMsg = err.response
        ? err.response.data && err.response.data.message
          ? err.response.data.message
          : err.response.statusText
        : config.serverDownMsg;
      errMsg =
        errMsg === undefined || errMsg === ""
          ? "Error while adding new job."
          : errMsg;
      // dispatch(setAlert(errMsg, "danger"));
      alert(errMsg);
    }
  };

export const updateFotaJob =
  (jobId, jobName, endDate, dayOfWeek, hour, minute) => async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const body = {
      jobName,
      endDate,
      frequency: {
        dayOfWeek,
        hour,
        minute,
      },
    };
    try {
      const res = await axios.put(
        `${env.GET_FOTA_API_URL()}/simplifi/v1/firmwareupgrade/scheduler/modifyJobByJobId?jobId=${jobId}`,
        body,
        config
      );
      dispatch({ type: UPDATE_FOTA_JOBS });
    } catch (err) {
      let errMsg = err.response
        ? err.response.data && err.response.data.message
          ? err.response.data.message
          : err.response.statusText
        : config.serverDownMsg;
      // dispatch(setAlert(errMsg, "danger"));
      alert(errMsg);
    }
  };

export const deleteFotaJob = (jobId) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const res = await axios.delete(
      `${env.GET_FOTA_API_URL()}/simplifi/v1/firmwareupgrade/scheduler/deleteJobById?jobId=${jobId}`,
      config
    );
    dispatch({ type: DELETE_FOTA_JOBS });
  } catch (err) {
    let errMsg = err.response
      ? err.response.data && err.response.data.message
        ? err.response.data.message
        : err.response.statusText
      : config.serverDownMsg;
    dispatch(setAlert(errMsg, "danger"));
  }
};
