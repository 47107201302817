import React, { Fragment, useState, useEffect, useRef } from "react";
import { useLocation, Redirect, Link } from "react-router-dom";
import { connect } from "react-redux";
import M from "materialize-css/dist/js/materialize.min.js";
import { makeStyles } from '@material-ui/core/styles';
import DatePicker from "react-datepicker";
import OutlinedInput from '@material-ui/core/OutlinedInput';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import CircularProgress from "@material-ui/core/CircularProgress";
import { getCountryName } from "../../utils/general.js";
import DeviceModelControlCapability from "./DeviceModelCapability/DeviceModelControlCapability.js";
import DeviceModelStatusCapability from "./DeviceModelCapability/DeviceModelStatusCapability.js";
import ChannelAndSwitches from "./DeviceModelCapability/ChannelAndSwitches.js";
import { addDeviceModel, updateDeviceModel, clearDeviceModelData, getDeviceModelsMetaData, clearDeviceModelsMetadata } from "../../action/addvcModel";
import { setAlert } from "../../action/alert";
import Alert from "./../layout/Alert";
import customConfig from "../../config.js";

const useStyles = makeStyles({
    labelColor: {
        color: "#000"
    },
    selectInput: {
        height: "3rem",
        width: "100%"
    },
    addNewTonnageButton: {
        marginTop: "10px"
    },
    tonnageCancelButton: {
        marginTop: "10px",
        marginLeft: "10px"
    }
});

const AddNewDeviceModel = ({
    allowedCountryCodes,
    allowedDeviceCategories,
    addDeviceModel,
    updateDeviceModel,
    setAlert,
    ModelData: { getModel, updvcModel, deviceModelsMetadata },
    clearDeviceModelData,
    getDeviceModelsMetaData,
    clearDeviceModelsMetadata,
    wrapperClass = "right-content-wraper"
}) => {

    const classes = useStyles()
    const location = useLocation()
    const [editDeviceModel, setEditDeviceModel] = useState(location.state ? location.state.editDeviceModel : false)
    const [deviceModelData, setDeviceModelData] = useState(location.state ? location.state.deviceModelData : null)

    const [modelID, setModelID] = useState(deviceModelData?.modelID ?? "")
    const [modelNumber, setModelNumber] = useState(deviceModelData?.modelNumber ?? "")
    const [modelName, setModelName] = useState(deviceModelData?.modelName ?? "")
    const [productCategory, setProductCategory] = useState(deviceModelData?.category ?? "")
    const [productCategoryFromMetadata, setProductCategoryFromMetadata] = useState(getProductCategoryMapping(productCategory))
    const [launchDate, setLaunchDate] = useState(deviceModelData?.launchDate ? new Date(deviceModelData.launchDate * 1000) : new Date())
    const [isActive, setIsActive] = useState(deviceModelData?.isActive ?? "true")
    const [manufacturerName, setManufacturerName] = useState(deviceModelData?.manufacturer ?? "Panasonic India Pvt Ltd")
    const [manufacturerAddress, setManufacturerAddress] = useState(deviceModelData?.manufacturerAddress ?? "GGN, India")
    const [productVersionNo, setProductVersionNo] = useState(deviceModelData?.version ?? 1)
    const [helpDocsURL, setHelpDocsURL] = useState(deviceModelData?.helpDocUrl ?? "")
    const [productManagerId, setProductManagerId] = useState(deviceModelData?.productManagerId ?? "")
    const [countries, setCountries] = useState(deviceModelData?.countries ?? [])
    const [brandName, setBrandName] = useState(
        deviceModelData?.brand ? deviceModelData.brand :
            deviceModelsMetadata?.categories?.[productCategoryFromMetadata]?.brands?.[0].toUpperCase() ?? "")

    const [tonnage, setTonnage] = useState(
        deviceModelData?.tonnage ? deviceModelData.tonnage :
            deviceModelsMetadata?.categories?.[productCategoryFromMetadata]?.tonnage?.[0] ?? "")

    const [addNewTonnageValue, setAddNewTonnageValue] = useState(false)

    const [capacity, setCapacity] = useState(
        deviceModelData?.capacity ? deviceModelData.capacity :
            deviceModelsMetadata?.categories?.[productCategoryFromMetadata]?.capacity?.[0] ?? "")

    const [channelAndSwitchDevices, setChannelAndSwitchDevices] = useState(deviceModelData?.devices ?? [])
    const [channelAndSwitchDeviceTypes, setChannelAndSwitchDeviceTypes] = useState([])

    const [activeTab, setActiveTab] = React.useState(0)
    const [randomValue, SetRandomValue] = useState(undefined)

    const [deviceCapabilitiesData, setDeviceCapabilitiesData] = useState({})
    const [isControlCapabilitiesValid, setIsControlCapabilitiesValid] = useState(false)
    const [isStatusCapabilitiesValid, setIsStatusCapabilitiesValid] = useState(false)
    const [isConfigCapabilitiesValid, setIsConfigCapabilitiesValid] = useState(false)
    const [isAddEditDeviceModelSuccessful, setIsAddEditDeviceModelSuccessful] = useState(false)

    const [isDeviceModelsMetadataLoading, setIsDeviceMetadataLoading] = useState(false)

    const hiddenDeviceMetadataUpload = useRef(null);
    const [isDeviceModelAddedUsingFileUpload, setIsDeviceModelAddedUsingFileUpload] = useState(false)

    // matter related fields
    const [isMatterEnabled, setIsMatterEnabled] = useState(deviceModelData?.isMatterEnabled ?? false)
    const [matterChipType, setMatterChipType] = useState(deviceModelData?.chipType ?? "")
    const [matterProductId, setMatterProductId] = useState(deviceModelData?.matterPID ?? "")
    const [matterVendorId, setMatterVendorId] = useState(deviceModelData?.vendorId ?? "")

    // adding the subcategory 
    const [modelSubCategory, setModelSubCategory] = useState("")

    // Adjust the height of menu items in select. Otherwise get hidden behind headers
    const menuProps = {
        style: {
            marginTop: '52px'
        },
    };

    useEffect(() => {
        M.AutoInit()
        clearDeviceModelsMetadata()
    }, []);

    useEffect(() => {
        if (deviceModelData) {
            setModelID(deviceModelData.modelId)
            setModelNumber(deviceModelData.modelNumber)
            setModelName(deviceModelData.modelName)
            setProductCategory(deviceModelData.category)
            setLaunchDate(deviceModelData?.launchDate ? new Date(deviceModelData.launchDate * 1000) : new Date())
            setIsActive(deviceModelData.isActive)
            setManufacturerName(deviceModelData.manufacturer)
            setProductVersionNo(deviceModelData.version)
            setHelpDocsURL(deviceModelData.helpDocUrl)
            setProductManagerId(deviceModelData.productManagerId)
            setCountries(deviceModelData.countries)
            setBrandName(deviceModelData.brand)
            setTonnage(deviceModelData.tonnage)
            setCapacity(deviceModelData.capacity)
            setChannelAndSwitchDevices(deviceModelData?.devices ?? [])
            setModelSubCategory(deviceModelData?.subCategory ?? "")
            setIsMatterEnabled(deviceModelData?.isMatterEnabled ?? false)
            setMatterChipType(deviceModelData?.chipType ?? "")
            setMatterProductId(deviceModelData?.matterPID ?? "")
            setMatterVendorId(deviceModelData?.vendorId ?? "")
            
        }
    }, [deviceModelData])

    useEffect(() => {
        if (productCategory && productCategory.length != 0) {
            setIsDeviceMetadataLoading(true)
            getDeviceModelsMetaData(getProductCategoryMapping(productCategory))
            setProductCategoryFromMetadata(getProductCategoryMapping(productCategory))
        }
    }, [productCategory])

    useEffect(() => {
        if (!(isControlCapabilitiesValid && isStatusCapabilitiesValid)) {
            return; //return in case of invalid capabilities data
        }

        if (!modelID || modelID == "") {
            setAlert("Invalid Model ID", "danger")
            window.scrollTo(0, 0)
        }
        else if (!modelNumber || modelNumber == "") {
            setAlert("Invalid Model Number", "danger")
            window.scrollTo(0, 0)
        }
        else if (!modelName || modelName == "") {
            setAlert("Invalid Model Name", "danger")
            window.scrollTo(0, 0)
        }
        else if (!manufacturerName || manufacturerName.length == 0) {
            setAlert("Invalid Manufacturer Name", "danger")
            window.scrollTo(0, 0)
        }
        else if (!manufacturerAddress || manufacturerAddress.length == 0) {
            setAlert("Invalid Manufacturer Address", "danger")
            window.scrollTo(0, 0)
        }
        else if (!brandName || brandName.length == 0) {
            setAlert("Invalid Brand Name", "danger")
            window.scrollTo(0, 0)
        }
        else if (!productManagerId || productManagerId.length == 0) {
            setAlert("Invalid Product Manager", "danger")
            window.scrollTo(0, 0)
        }
        else if (!countries || countries.length == 0) {
            setAlert("Please Select Country", "danger")
            window.scrollTo(0, 0)
        }
        else if (!productVersionNo || isNaN(productVersionNo)) {
            setAlert("Invalid Version Number", "danger")
            window.scrollTo(0, 0)
        }
        else if (deviceModelsMetadata?.categories?.[productCategoryFromMetadata]?.tonnage &&
            (!tonnage || tonnage.length == 0 || isNaN(tonnage) || (!isNaN(tonnage) && parseFloat(tonnage) < 0))
        ) {
            setAlert("Invalid Tonnage Value", "danger")
            window.scrollTo(0, 0)
        }
        else if (deviceModelsMetadata?.categories?.[productCategoryFromMetadata]?.capacity &&
            (!capacity || capacity.length == 0)
        ) {
            setAlert("Invalid Capacity Value", "danger")
            window.scrollTo(0, 0)
        }
        else if (!matterChipType || matterChipType == "") {
            setAlert("Invalid Chip Type", "danger")
            window.scrollTo(0, 0)
        }
        else if (isMatterEnabled == true && (!matterProductId || matterProductId == "")) {
            setAlert("Invalid Product ID", "danger")
            window.scrollTo(0, 0)
        }
        else if (isMatterEnabled == true && (!matterVendorId || matterVendorId == "" || parseInt(matterVendorId) == NaN)) {
            setAlert("Invalid Vendor ID", "danger")
            window.scrollTo(0, 0)
        }
        else {
            let deviceModelData = {
                "modelId": modelID,
                "modelNumber": modelNumber,
                "modelName": modelName,
                "category": productCategory,
                "launchDate": parseInt(launchDate.getTime() / 1000),
                "isActive": isActive,
                "manufacturer": manufacturerName,
                "manufacturerAddress": manufacturerAddress,
                "version": parseFloat(productVersionNo),
                "helpDocUrl": !helpDocsURL || helpDocsURL.length == 0 ? "http url" : helpDocsURL,
                "productManagerId": productManagerId,
                "countries": countries,
                "brand": brandName,
                ...deviceCapabilitiesData
            }

            if (deviceModelsMetadata?.categories?.[productCategoryFromMetadata]?.tonnage) {
                deviceModelData['tonnage'] = tonnage
            }

            if (deviceModelsMetadata?.categories?.[productCategoryFromMetadata]?.capacity) {
                deviceModelData['capacity'] = capacity
            }

            if (productCategoryFromMetadata == customConfig.deviceCategorySWITCHES) {
                deviceModelData['devices'] = channelAndSwitchDevices
            }

            // add matter related fields
            deviceModelData["isMatterEnabled"] = isMatterEnabled
            deviceModelData["chipType"] = matterChipType

            if (isMatterEnabled) {
                // add these fields only when device is matter enabled
                deviceModelData["matterPID"] = matterProductId
                deviceModelData["vendorId"] = parseInt(matterVendorId)
            }

            // add the subcategory
            if(modelSubCategory != ""){
                deviceModelData["subCategory"] = modelSubCategory
            }

            if (editDeviceModel) {
                // delete the launch date form payload
                delete deviceModelData["launchDate"]

                updateDeviceModel(modelID, deviceModelData)
            }
            else {
                addDeviceModel(deviceModelData)
            }
        }

        setIsConfigCapabilitiesValid(false)
        setIsControlCapabilitiesValid(false)
        setIsStatusCapabilitiesValid(false)

    }, [isControlCapabilitiesValid, isStatusCapabilitiesValid, isConfigCapabilitiesValid])

    useEffect(() => {
        if (deviceModelsMetadata && Object.keys(deviceModelsMetadata).length != 0) {
            setIsDeviceMetadataLoading(false)
        }

    }, [deviceModelsMetadata])

    useEffect(() => {
        if (!getModel || Object.keys(getModel).length == 0) {
            return;
        }

        clearDeviceModelData()
        setIsAddEditDeviceModelSuccessful(true)
    }, [getModel])

    useEffect(() => {
        if (!updvcModel || Object.keys(updvcModel).length == 0) {
            return;
        }

        clearDeviceModelData()
        setIsAddEditDeviceModelSuccessful(true)
    }, [updvcModel])

    function getProductCategoryMapping(productCategoryName) {
        if (productCategoryName == customConfig.decviceCategoryAC ||
            productCategoryName == customConfig.deviceCategoryCAC ||
            productCategoryName == customConfig.deviceCategoryIAC) {

            return customConfig.decviceCategoryAC
        }
        else if (productCategoryName == customConfig.decviceCategoryFANCONTROLLER ||
            productCategoryName == customConfig.decviceCategoryANCHORFAN) {

            return customConfig.decviceCategoryFANCONTROLLER
        }
        else if (productCategoryName == customConfig.deviceCategorySWITCHES ||
            productCategoryName == customConfig.deviceCategoryROMASWITCHES) {

            return customConfig.deviceCategorySWITCHES
        }

        return productCategoryName
    }

    function handleAddChannelsAndSwitches(devices) {
        setChannelAndSwitchDevices(devices)

        let updatedDeviceTypes = {}
        devices.map((element, index) => {
            if (element.type && element.type.length != 0) {
                updatedDeviceTypes[element.type] = ""
            }
        })

        setChannelAndSwitchDeviceTypes(Object.keys(updatedDeviceTypes))
    }

    function handleAddDeviceControlCapabilties(controlCapabilities) {

        if (!(controlCapabilities instanceof Array) && (controlCapabilities.hasOwnProperty('error'))) {
            setAlert(controlCapabilities['error'], "danger")
            setIsControlCapabilitiesValid(false)
            window.scrollTo(0, 0)
        }
        else {
            setDeviceCapabilitiesData((prevState) => {
                return {
                    ...prevState,
                    'controlCapabilities': controlCapabilities
                }
            })
            setIsControlCapabilitiesValid(true)
        }
    }

    function handleAddDeviceStatusCapabilities(statusCapabilities) {
        if (!(statusCapabilities instanceof Array) && (statusCapabilities.hasOwnProperty('error'))) {
            setAlert(statusCapabilities['error'], "danger")
            setIsStatusCapabilitiesValid(false)
            window.scrollTo(0, 0)
        }
        else {
            setDeviceCapabilitiesData((prevState) => {
                return {
                    ...prevState,
                    'statusCapabilitiesV2': statusCapabilities
                }
            })
            setIsStatusCapabilitiesValid(true)
        }
    }

    function onChangeDeviceMetadataFileUpload(event) {
        const file = event.target.files[0]
        const reader = new FileReader();
        reader.addEventListener('load', (readEvent) => {
            try {
                const parsedData = JSON.parse(readEvent.target.result)
                if (parsedData && parsedData instanceof Array) {
                    setAlert("Invalid File Uploaded. Upload Only One Device Model at a Time", "danger")
                    return
                }

                setDeviceModelData(parsedData)
                setIsDeviceModelAddedUsingFileUpload(true)
            } catch (error) {
                setAlert("Invalid File Uploaded", "danger")
            }
        });
        reader.readAsText(file);
    }

    return (
        <Fragment>
            <div className={wrapperClass}>
                <Alert />
                {
                    isAddEditDeviceModelSuccessful ? <Redirect to='/deviceDetail' /> : ""
                }
                <div className="device-detail-wrap device-info content-block">
                    <div className="row">
                        <div className="s4 col">
                            <label className={`title d-inline min-180 mb-5 ${classes.labelColor}`}>Select Category</label>
                            <Select
                                value={productCategory}
                                onChange={(e) => {
                                    setProductCategory(e.target.value)
                                }}
                                displayEmpty
                                input={<OutlinedInput />}
                                inputProps={{ 'aria-label': 'Without label' }}
                                className={classes.selectInput}
                                disabled={editDeviceModel || (productCategory && productCategory.length != 0) ? true : false}
                            >
                                {
                                    allowedDeviceCategories.map(categoryName => {
                                        return <MenuItem key={categoryName} value={categoryName}>{categoryName}</MenuItem>
                                    })
                                }
                            </Select>
                        </div>
                        {
                            productCategoryFromMetadata && productCategoryFromMetadata.length != 0 && !isDeviceModelsMetadataLoading &&
                            deviceModelsMetadata["categories"]?.[productCategoryFromMetadata]?.["subCategory"] &&

                            <div className="s4 col">
                                <label className={`title d-inline min-180 mb-5 ${classes.labelColor}`}>Select Sub Category</label>
                                <Select
                                    value={modelSubCategory}
                                    onChange={(e) => {
                                        setModelSubCategory(e.target.value)
                                    }}
                                    displayEmpty
                                    input={<OutlinedInput />}
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    className={classes.selectInput}
                                    MenuProps={menuProps}
                                >
                                    {
                                        deviceModelsMetadata?.categories?.[productCategoryFromMetadata]?.subCategory?.map((subCategoryName) => {
                                            return <MenuItem key={subCategoryName.toUpperCase()} value={subCategoryName.toUpperCase()}>{subCategoryName.toUpperCase()}</MenuItem>
                                        })
                                    }
                                </Select>
                            </div>
                        }
                        {
                            !editDeviceModel && <Fragment>
                                <input type="file" accept="application/JSON" style={{ display: "none" }} ref={hiddenDeviceMetadataUpload} onChange={(e) => {
                                    onChangeDeviceMetadataFileUpload(e)
                                }} />
                                <button
                                    className="btn btn-style-2 orange-bg ml-15 mb-15" style={{ float: "right" }}
                                    onClick={() => {
                                        hiddenDeviceMetadataUpload.current.click()
                                    }}
                                >
                                    Upload Device Model
                                </button>
                            </Fragment>
                        }
                    </div>
                    {
                        isDeviceModelsMetadataLoading &&
                        <div
                            style={{
                                display: "grid",
                                placeItems: "center",
                                height: "100%",
                            }}
                        >
                            <CircularProgress size="5em" style={{ color: "#AFB42B" }} />
                        </div>
                    }
                    {
                        productCategoryFromMetadata && productCategoryFromMetadata.length != 0 && !isDeviceModelsMetadataLoading &&
                        <Fragment>
                            <div className="row">
                                <div className="s4 col">
                                    <div className="mb-15">
                                        <label className={`title d-inline min-180 ${classes.labelColor}`}>Model ID*</label>
                                        <input
                                            placeholder="Enter Model ID"
                                            className="add-input xl"
                                            value={modelID}
                                            type="text"
                                            onChange={(e) => {
                                                setModelID(e.target.value)
                                            }}
                                            disabled={editDeviceModel ? true : false}
                                        />
                                    </div>
                                </div>
                                <div className="s4 col">
                                    <div className="mb-15">
                                        <label className={`title d-inline min-180 ${classes.labelColor}`}>Model Number*</label>
                                        <input
                                            placeholder="Enter Model Number"
                                            className="add-input xl"
                                            value={modelNumber}
                                            type="text"
                                            onChange={(e) => {
                                                setModelNumber(e.target.value)
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="s4 col">
                                    <div className="mb-15">
                                        <label className={`title d-inline min-180 ${classes.labelColor}`}>Model Name*</label>
                                        <input
                                            placeholder="Enter Model Name"
                                            className="add-input xl"
                                            value={modelName}
                                            type="text"
                                            onChange={(e) => {
                                                setModelName(e.target.value)
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="s4 col">
                                    <div className="mb-15">
                                        <label className={`title d-inline min-180 ${classes.labelColor}`}>Manufacturer Name*</label>
                                        <input
                                            placeholder="Enter Manufacturer Name"
                                            className="add-input xl"
                                            value={manufacturerName}
                                            type="text"
                                            onChange={(e) => {
                                                setManufacturerName(e.target.value)
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="s4 col">
                                    <div className="mb-15">
                                        <label className={`title d-inline min-180 ${classes.labelColor}`}>Manufacturer Address*</label>
                                        <input
                                            placeholder="Enter Manufacturer Address"
                                            className="add-input xl"
                                            value={manufacturerAddress}
                                            type="text"
                                            onChange={(e) => {
                                                setManufacturerAddress(e.target.value)
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="s4 col">
                                    <div className="mb-15">
                                        <label className={`title d-inline min-180 ${classes.labelColor}`}>Brand*</label>
                                        <Select
                                            value={brandName}
                                            onChange={(e) => { setBrandName(e.target.value) }}
                                            displayEmpty
                                            input={<OutlinedInput />}
                                            inputProps={{ 'aria-label': 'Without label' }}
                                            className={classes.selectInput}
                                        >
                                            {
                                                deviceModelsMetadata?.categories?.[productCategoryFromMetadata]?.brands?.map((brandName) => {
                                                    return <MenuItem key={brandName.toUpperCase()} value={brandName.toUpperCase()}>{brandName.toUpperCase()}</MenuItem>
                                                })
                                            }
                                        </Select>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="s4 col">
                                    <div className="mb-15">
                                        <label className={`title d-inline min-180 ${classes.labelColor}`}>Product Manager Name*</label>
                                        <input
                                            placeholder="Enter Product Manager Name"
                                            className="add-input xl"
                                            value={productManagerId}
                                            type="text"
                                            onChange={(e) => {
                                                setProductManagerId(e.target.value)
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="s4 col">
                                    <div className="mb-15">
                                        <label className={`title d-inline min-180 ${classes.labelColor}`}>Version</label>
                                        <input
                                            placeholder="Enter Version Number"
                                            className="add-input xl"
                                            value={productVersionNo}
                                            type="text"
                                            onChange={(e) => {
                                                setProductVersionNo(e.target.value)
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="s4 col">
                                    <div className="mb-15">
                                        <label className={`title d-inline min-180 ${classes.labelColor}`}>Help Docs URL</label>
                                        <input
                                            placeholder="Enter Help Docs URL"
                                            className="add-input xl"
                                            value={helpDocsURL}
                                            type="text"
                                            onChange={(e) => {
                                                setHelpDocsURL(e.target.value)
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="s4 col">
                                    <label className={`title d-inline min-180 ${classes.labelColor}`}>Launch Date</label>
                                    <br></br>
                                    <DatePicker
                                        selected={launchDate}
                                        minDate={new Date()}
                                        maxDate={new Date(new Date().setDate(new Date().getDate() + 100))}
                                        onChange={(date) => {
                                            setLaunchDate(date)
                                        }}
                                        placeholderText="Enter Launch Date"
                                        dateFormat='MM/dd/yyyy'
                                        className="add-input calendar input_phone w-full"
                                        disabled={editDeviceModel ? true : false}
                                    />
                                </div>
                                <div className="s4 col">
                                    <label className={`title d-inline min-180 mb-5 ${classes.labelColor}`}>Is Active</label>
                                    <Select
                                        value={isActive}
                                        onChange={(e) => { setIsActive(e.target.value) }}
                                        displayEmpty
                                        input={<OutlinedInput />}
                                        inputProps={{ 'aria-label': 'Without label' }}
                                        className={classes.selectInput}
                                    >
                                        <MenuItem value={"true"}>True</MenuItem>
                                        <MenuItem value={"false"}>False</MenuItem>
                                    </Select>
                                </div>
                                <div className="s4 col">
                                    <label className={`title d-inline min-180 mb-5 ${classes.labelColor}`}>Country*</label>
                                    <FormControl variant="outlined" className={classes.selectInput}>
                                        <Select
                                            multiple
                                            value={countries}
                                            onChange={(e) => {
                                                setCountries(e.target.value)
                                            }}
                                            input={<OutlinedInput />}
                                            renderValue={(selected) => {
                                                let countryNames = selected.map((countryCode) => {
                                                    return getCountryName(countryCode)
                                                })
                                                return countryNames.join(', ')
                                            }
                                            }
                                            className={classes.selectInput}
                                        >
                                            {
                                                allowedCountryCodes.sort().map((countryName) => (
                                                    <MenuItem key={countryName.toUpperCase()} value={countryName.toUpperCase()}>
                                                        <Checkbox checked={countries.indexOf(countryName.toUpperCase()) > -1} />
                                                        <ListItemText primary={getCountryName(countryName.toUpperCase())} />
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                            <div className="row">
                                {
                                    deviceModelsMetadata?.categories?.[productCategoryFromMetadata]?.tonnage &&
                                    <div className="s4 col">
                                        <label className={`title d-inline min-180 ${classes.labelColor}`}>Tonnage</label>
                                        {
                                            addNewTonnageValue == false &&
                                            <Select
                                                value={tonnage}
                                                onChange={(e) => { setTonnage(e.target.value) }}
                                                displayEmpty
                                                input={<OutlinedInput />}
                                                inputProps={{ 'aria-label': 'Without label' }}
                                                className={classes.selectInput}
                                            >
                                                {
                                                    deviceModelsMetadata?.categories?.[productCategoryFromMetadata]?.tonnage?.map((tonnageValue) => {
                                                        return <MenuItem key={tonnageValue} value={tonnageValue}>{tonnageValue}</MenuItem>
                                                    })
                                                }
                                            </Select>
                                        }
                                        {
                                            addNewTonnageValue == true && <input
                                                placeholder="Enter New Tonnage Value"
                                                className="add-input xl"
                                                value={tonnage}
                                                type="text"
                                                onChange={(e) => {
                                                    setTonnage(e.target.value)
                                                }}
                                            />
                                        }
                                        <button
                                            className={`btn btn-style-2 orange-bg mr-5 ${classes.addNewTonnageButton} d-none`}
                                            onClick={() => {
                                                setTonnage("")
                                                setAddNewTonnageValue(true)
                                            }}
                                        >
                                            Add New Tonnage Value
                                        </button>
                                        {
                                            addNewTonnageValue == true && <button
                                                className={`btn btn-style-2 orange-bg mr-5 ${classes.tonnageCancelButton}`}
                                                onClick={() => {
                                                    setTonnage(deviceModelData ? deviceModelData?.tonnage : deviceModelsMetadata?.categories?.[productCategoryFromMetadata]?.tonnage?.[0] ?? "")
                                                    setAddNewTonnageValue(false)
                                                }}
                                            >
                                                Cancel
                                            </button>
                                        }
                                    </div>
                                }
                                {
                                    deviceModelsMetadata?.categories?.[productCategoryFromMetadata]?.capacity &&
                                    <div className="s4 col">
                                        <label className={`title d-inline min-180 ${classes.labelColor}`}>Capacity</label>
                                        <Select
                                            value={capacity}
                                            onChange={(e) => { setCapacity(e.target.value) }}
                                            displayEmpty
                                            input={<OutlinedInput />}
                                            inputProps={{ 'aria-label': 'Without label' }}
                                            className={classes.selectInput}
                                        >
                                            {
                                                deviceModelsMetadata?.categories?.[productCategoryFromMetadata]?.capacity?.map((capacityValue) => {
                                                    return <MenuItem key={capacityValue} value={capacityValue}>{capacityValue}</MenuItem>
                                                })
                                            }
                                        </Select>
                                    </div>
                                }
                            </div>
                            {
                                productCategoryFromMetadata == customConfig.deviceCategorySWITCHES &&
                                <ChannelAndSwitches
                                    channelAndSwitchDevices={channelAndSwitchDevices}
                                    deviceTypes={deviceModelsMetadata?.categories?.[productCategoryFromMetadata]?.deviceTypes ?? []}
                                    addChannelAndSwitches={handleAddChannelsAndSwitches}
                                />
                            }
                            <div className="row">
                                <div className="s4 col">
                                    <label className={`title d-inline min-180 ${classes.labelColor}`}>Is Matter Enabled</label>
                                    <Select
                                        value={isMatterEnabled}
                                        onChange={(e) => {
                                            setIsMatterEnabled(e.target.value)
                                        }}
                                        displayEmpty
                                        input={<OutlinedInput />}
                                        inputProps={{ 'aria-label': 'Without label' }}
                                        className={classes.selectInput}
                                    >
                                        <MenuItem key={"matter_true"} value={true}>True</MenuItem>
                                        <MenuItem key={"matter_false"} value={false}>False</MenuItem>
                                    </Select>
                                </div>
                                <div className="s4 col">
                                    <label className={`title d-inline min-180 ${classes.labelColor}`}>Chip Type</label>
                                    <Select
                                        value={matterChipType}
                                        onChange={(e) => { setMatterChipType(e.target.value) }}
                                        displayEmpty
                                        input={<OutlinedInput />}
                                        inputProps={{ 'aria-label': 'Without label' }}
                                        className={classes.selectInput}
                                    >
                                        {
                                            customConfig.matterChipType.map((chipValue) => {
                                                return <MenuItem key={chipValue} value={chipValue}>{chipValue}</MenuItem>
                                            })
                                        }
                                    </Select>
                                </div>
                                {
                                    isMatterEnabled && <Fragment>
                                        <div className="s4 col">
                                            <label className={`title d-inline min-180 ${classes.labelColor}`}>Product ID</label>
                                            <input
                                                placeholder="Enter Matter Product ID"
                                                className="add-input xl"
                                                value={matterProductId}
                                                type="text"
                                                onChange={(e) => {
                                                    setMatterProductId(e.target.value)
                                                }}
                                            />
                                        </div>
                                    </Fragment>
                                }
                            </div>
                            {
                                isMatterEnabled && <Fragment>
                                    <div className="row">
                                        <div className="s4 col">
                                            <label className={`title d-inline min-180 ${classes.labelColor}`}>Vendor ID</label>
                                            <input
                                                placeholder="Enter Vendor ID"
                                                className="add-input xl"
                                                value={matterVendorId}
                                                type="number"
                                                onChange={(e) => {
                                                    setMatterVendorId(e.target.value)
                                                }}
                                                onKeyDown={(e)=>{
                                                    ['e', 'E', '+', '-', '.'].includes(e.key) && e.preventDefault();
                                                }}
                                            />
                                        </div>
                                    </div>
                                </Fragment>
                            }
                            <div className="row">
                                <Tabs
                                    value={activeTab}
                                    onChange={(e, newValue) => {
                                        setActiveTab(newValue)
                                    }}
                                    TabIndicatorProps={{
                                        style: {
                                            backgroundColor: "#000"
                                        }
                                    }}
                                >
                                    <Tab label="Control Capabilities" />
                                    <Tab label="Status Capabilities" />
                                    <Tab label="Config Capabilities" />
                                </Tabs>
                                <div hidden={activeTab != 0}>
                                    <DeviceModelControlCapability
                                        productCategory={productCategoryFromMetadata}
                                        isDeviceModelAddedUsingFileUpload={isDeviceModelAddedUsingFileUpload}
                                        editDeviceModel={editDeviceModel}
                                        deviceModelData={deviceModelData}
                                        triggerAddNewDeviceModel={randomValue}
                                        addDeviceControlCapabilties={handleAddDeviceControlCapabilties}
                                        channelAndSwitchDeviceTypes={channelAndSwitchDeviceTypes}
                                    />
                                </div>
                                <div hidden={activeTab != 1}>
                                    <DeviceModelStatusCapability
                                        productCategory={productCategoryFromMetadata}
                                        isDeviceModelAddedUsingFileUpload={isDeviceModelAddedUsingFileUpload}
                                        editDeviceModel={editDeviceModel}
                                        deviceModelData={deviceModelData}
                                        triggerAddNewDeviceModel={randomValue}
                                        addDeviceStatusCapabilties={handleAddDeviceStatusCapabilities}
                                    />
                                </div>
                                <div hidden={activeTab != 2}>
                                    {/*For Config Control Capability*/}
                                </div>
                            </div>
                            <div className="row">
                                {
                                    editDeviceModel ?
                                        <button className="btn btn-style-2 orange-bg" onClick={() => {
                                            SetRandomValue(Math.random())
                                        }}>Update Device Model</button>
                                        :
                                        <button className="btn btn-style-2 orange-bg" onClick={() => {
                                            SetRandomValue(Math.random())
                                        }}>Add Device Model</button>
                                }
                                <Link to={{ pathname: `/deviceDetail` }} style={{ marginLeft: "10px" }}>
                                    <button className="btn btn-style-2 orange-bg">Cancel</button>
                                </Link>
                            </div>
                        </Fragment>
                    }

                </div>
            </div>
        </Fragment >
    )
}

const mapStateToProps = (state) => ({
    allowedCountryCodes: state.auth.allowedCountryCodes,
    allowedDeviceCategories: state.auth.allowedDeviceCategories,
    ModelData: state.getModel
});

const mapDispatchToProps = (dispatch) => ({
    setAlert: (message, status) => dispatch(setAlert(message, status)),
    addDeviceModel: (deviceModelData) => dispatch(addDeviceModel(deviceModelData)),
    updateDeviceModel: (modelId, deviceModelData) => dispatch(updateDeviceModel(modelId, deviceModelData)),
    clearDeviceModelData: () => dispatch(clearDeviceModelData()),
    getDeviceModelsMetaData: (productCategory) => dispatch(getDeviceModelsMetaData(productCategory)),
    clearDeviceModelsMetadata: () => dispatch(clearDeviceModelsMetadata())
});

export default connect(mapStateToProps, mapDispatchToProps)(AddNewDeviceModel);