// Function to check valid Product Serial Number
const isValid = prdSerialNo => {
    var prdSerialNoLength = (!prdSerialNo) ? 0 : prdSerialNo.length;
    if (prdSerialNoLength > 0) {
        if (prdSerialNoLength > 1) {
            let initials = prdSerialNo.substr(0,2);
            if (initials.toUpperCase() === "PZ") {
                return false;
            }
        }
        while (prdSerialNoLength--) {
            if (prdSerialNo.charAt(prdSerialNoLength) !== 0)
                return true;
        }
    }
    return false;    
}
 

export default isValid;