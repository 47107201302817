import React, { Fragment } from 'react'
import Moment from 'react-moment'

const OnboardedHistory = ({ onBoardedHistoryData }) => {

    return (
        <Fragment>
            {(onBoardedHistoryData && onBoardedHistoryData.length > 0) ?
                (<div className="popup-content blue-modal">

                    {/* Top level detail of firmware popup */}
                    <div className="block-top">
                        <div className="block-title style-2 center-align  py-25">
                            <h3 className="mb-0">Device Onboarding / Offboarding History</h3>
                        </div>

                    </div>

                    {/* Header of firmware list */}
                    <div className="title-section">
                        <div className="px-25">
                            <div className="row mb-0">
                                <div className="s6 col"> <div className="title">User ID</div></div>
                                <div className="s3 col"> <div className="title">Time Stamp</div></div>
                                <div className="s3 col"> <div className="title">Activity</div></div>
                            </div>
                        </div>
                    </div>

                    {/* Firmware list binding*/}
                    <div className=" height-reset">
                        {onBoardedHistoryData.map((historyList, i) => (
                            <div className="block-bottom px-25 py-5" key={i}>
                                <div className="row mb-0">

                                    <div className="s6 col">
                                        <div className="boxes">
                                            <div className="info"> {" "}
                                                {historyList.userId}{" "}</div>
                                        </div>
                                    </div>

                                    <div className="s3 col">
                                        <div className="boxes">
                                            <div className="info">
                                                <Moment
                                                     format="MMMM Do YYYY, h:mm:ss a"
                                                    date={(historyList.ts) * 1000}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="s3 col">
                                        <div className="boxes">
                                            <div className="info"> {" "}
                                                {historyList.activity}{" "}</div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        ))}

                    </div>

                </div>)
                /* Error message*/
                : (onBoardedHistoryData &&
                    // <div className="popup-content  blue-modal">
                    //     <div className="s12 col">
                    //         <div className="boxes">
                    //             <div className="info error"> {" "}
                    //                 {onBoardedHistoryData.msg}{" "}
                    //             </div>
                    //         </div>
                    //     </div>
                    // </div>
                    <div className="popup-content  blue-modal blank">
                    <div className="block-title style-2 center-align  py-25">
                       <h3 className="mb-0">Device Onboarding / Offboarding History</h3>
                   </div>
                   <div className="s12 col">
                       <div className="boxes">
                           <div className="info error content-reset">
                           <div className="text-box"><i className="no-data-icon"></i><label>{(onBoardedHistoryData && onBoardedHistoryData.msg) && onBoardedHistoryData.msg} </label></div>      
                               
                           </div>
                       </div>
                   </div>
               </div>

                )
            }
        </Fragment>
    )
};

export default OnboardedHistory;

