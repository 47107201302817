import React, { Fragment, useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import M from "materialize-css/dist/js/materialize.min.js";
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import CircularProgress from "@material-ui/core/CircularProgress";
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { setAlert } from "../../../action/alert";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import CloudDownload from '@material-ui/icons/CloudDownload';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { uploadIDUConfig, updateIDUConfigByModelId, updateIDUConfigByConfigId, getIDUConfigData, downloadIDUConfigFile, getDeviceModelNumbersList, sendIDUConfigMQTTTrigger, getDeviceModelIdsUsingConfigId } from './../../../action/iduConfig'
import Alert from "../../layout/Alert";
import EditIcon from '@material-ui/icons/Edit';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const useStyles = makeStyles({
    selectInput: {
        height: "3rem",
        width: "100%"
    },
    selectValues: {
        width: "100%",
    },
    activeTab: {
        border: '1px solid #bdbdbd',
        backgroundColor: '#e0e0e0',
        textTransform: 'capitalize'
    },
    nonActiveTab: {
        border: 'none',
        backgroundColor: 'none',
        textTransform: 'capitalize'
    },
});

const headers = {
    modelId: "Model ID",
    configId: "Config ID",
    iduConfigVersion: "Config Version",
    iduConfigFileObjectBin: "Config File",
    editIDUConfig: "Edit"
};

const IDUEEPROMConfig = ({
    wrapperClass = "right-content-wraper",
    allowedDeviceCategories,
    allowedCompanyBrand,
    iduConfigStore,
    uploadIDUConfig,
    downloadIDUConfigFile,
    sendIDUConfigMQTTTrigger,
    updateIDUConfigByModelId,
    updateIDUConfigByConfigId,
    setAlert
}) => {

    const classes = useStyles()
    const [activeTab, setActiveTab] = React.useState(0)
    const [uploadedIDUConfigData, setUploadedIDUConfigData] = useState({})
    const [editIduConfig, setEditIDUConfig] = useState(false)

    useEffect(() => {
        if (activeTab != 1) {
            // active tab 1 is the upload/edit idu config tab
            // whenever the tab is changed/switched then reset the tab configs

            setEditIDUConfig(false)
            setUploadedIDUConfigData({})
        }
    }, [activeTab])

    function setIDUConfigDataForEdit(iduConfig) {
        // whenever the idu edit config icon is pressed, redirect the user
        // to upload/edit config tab
        // on edit, set the required idu configurations.

        setUploadedIDUConfigData(iduConfig)
        setEditIDUConfig(true)
        setActiveTab(1)
    }

    function handleCancelEditIduConfig() {
        // on editing the config, cancel button is shown. 
        // when user cancel the config edit, redirect user again to 
        // view page. But reset the upload/edit config page field values

        setActiveTab(0)
        setEditIDUConfig(false)
        setUploadedIDUConfigData({})
    }

    return (
        <div className={wrapperClass}>
            <Alert />
            <div className="row">
                <Tabs
                    value={activeTab}
                    onChange={(e, newValue) => {
                        setActiveTab(newValue)
                    }}
                    TabIndicatorProps={{
                        style: {
                            backgroundColor: "#bdbdbd"
                        }
                    }}
                    style={{ marginTop: '20px' }}
                >
                    <Tab label="View IDU EEFROM Config" className={activeTab == 0 ? classes.activeTab : classes.nonActiveTab} />
                    <Tab label="Upload IDU EEPROM Config" className={activeTab == 1 ? classes.activeTab : classes.nonActiveTab} />
                    <Tab label="Push Device IDU Config" className={activeTab == 2 ? classes.activeTab : classes.nonActiveTab} />
                </Tabs>
                <div hidden={activeTab != 0}>
                    <ViewIDUEEPROMConfig
                        allowedDeviceCategories={allowedDeviceCategories}
                        allowedCompanyBrand={allowedCompanyBrand}
                        downloadIDUConfigFile={downloadIDUConfigFile}
                        setAlert={setAlert}
                        setIDUConfigDataForEdit={setIDUConfigDataForEdit}
                        iduConfigStore={iduConfigStore}
                    ></ViewIDUEEPROMConfig>
                </div>
                <div hidden={activeTab != 1}>
                    <UploadIDUEEPROMConfig
                        allowedDeviceCategories={allowedDeviceCategories}
                        allowedCompanyBrand={allowedCompanyBrand}
                        iduConfigStore={iduConfigStore}
                        uploadedIDUConfigData={uploadedIDUConfigData}
                        editIDUConfig={editIduConfig}
                        uploadIDUConfig={uploadIDUConfig}
                        updateIDUConfigByModelId={updateIDUConfigByModelId}
                        updateIDUConfigByConfigId={updateIDUConfigByConfigId}
                        cancelEditIDUConfig={handleCancelEditIduConfig}
                        setAlert={setAlert}
                    ></UploadIDUEEPROMConfig>
                </div>
                <div hidden={activeTab != 2}>
                    <MQTTTriggerIDUEEPROMConfig
                        allowedDeviceCategories={allowedDeviceCategories}
                        allowedCompanyBrand={allowedCompanyBrand}
                        iduConfigStore={iduConfigStore}
                        sendIDUConfigMQTTTrigger={sendIDUConfigMQTTTrigger}
                        setAlert={setAlert}
                    ></MQTTTriggerIDUEEPROMConfig>
                </div>
            </div>
        </div>
    );
}

const ViewIDUEEPROMConfig = ({
    allowedDeviceCategories,
    allowedCompanyBrand,
    downloadIDUConfigFile,
    setAlert,
    setIDUConfigDataForEdit,
    iduConfigStore: { uploadIDUConfigInProgress, uploadIDUConfigSuccess }
}) => {

    const [categoryFilter, setCategoryFilter] = useState("Select Category")
    const [brandFilter, setBrandFilter] = useState("Select Brand")
    const [modelNumberFilterList, setModelNumberFilterList] = useState([])
    const [modelNumberFilter, setModelNumberFilter] = useState("Select Model ID")
    const [configIdFilter, setConfigIdFilter] = useState("")
    const [iduConfigData, setIDUConfigData] = useState([])
    const [showIDUConfigDataList, setShowIDUConfigDataList] = useState(false)
    const [isIDUConfigDataLoading, setIsIDUConfigDataLoading] = useState(false)
    const [updateFlag, setUpdateFlag] = useState(undefined);
    const [searchBy, setSearchBy] = useState("searchByModelId")

    useEffect(() => {
        M.AutoInit()
    }, [modelNumberFilterList, searchBy])

    useEffect(() => {
        M.AutoInit();
    }, [updateFlag, isIDUConfigDataLoading, iduConfigData])

    useEffect(()=>{
        if(!uploadIDUConfigInProgress && uploadIDUConfigSuccess) {
            setShowIDUConfigDataList(false)
        }

    }, [uploadIDUConfigInProgress, uploadIDUConfigSuccess])

    useEffect(() => {
        if (!categoryFilter || categoryFilter.length == 0 || categoryFilter == "Select Category") {
            return
        }
        if (!brandFilter || brandFilter.length == 0 || brandFilter == "Select Brand") {
            return
        }
        if (categoryFilter && categoryFilter.length != 0 && brandFilter && brandFilter.length != 0) {
            getDeviceModelNumbersList(brandFilter, categoryFilter)
                .then((data) => {
                    setModelNumberFilterList(data)
                    setShowIDUConfigDataList(false)
                    setModelNumberFilter("Select Model ID")
                })
                .catch((err) => {
                    setModelNumberFilterList([])
                    setShowIDUConfigDataList(false)
                    setModelNumberFilter("Select Model ID")
                })
        }
    }, [categoryFilter, brandFilter])

    useEffect(() => {
        if (searchBy == "searchByModelId") {
            if (categoryFilter == "Select Category" || categoryFilter.length == 0 ||
                brandFilter == "Select Brand" || brandFilter.length == 0 ||
                modelNumberFilter == "Select Model ID" || modelNumberFilter.length == 0) {

                setShowIDUConfigDataList(false)
                setModelNumberFilterList([])
                setModelNumberFilter("Select Model ID")
            }
        }
        else if (searchBy == "searchByConfigId") {
            if (!configIdFilter || configIdFilter.length == 0) {

                setShowIDUConfigDataList(false)
            }
        }
        else {
            // invalid selection filter
            setShowIDUConfigDataList(false)
        }
    }, [categoryFilter, brandFilter, modelNumberFilter, configIdFilter])

    useEffect(() => {
        if (searchBy == "searchByModelId") {
            if (!categoryFilter || categoryFilter.length == 0 || categoryFilter == "Select Category") {
                return
            }
            if (!brandFilter || brandFilter.length == 0 || brandFilter == "Select Brand") {
                return
            }
            if (!modelNumberFilter || modelNumberFilter.length == 0 || modelNumberFilter == "Select Model ID") {
                return
            }
        }
        else if (searchBy == "searchByConfigId") {
            if (!configIdFilter || configIdFilter.length == 0) {
                return
            }
        }
        else {
            return; //invalid filter selection
        }

        getIDUConfigFilterData();
    }, [updateFlag]);

    useEffect(() => {
        if (searchBy && searchBy.length != 0) {
            setShowIDUConfigDataList(false)
        }
    }, [searchBy])

    function filterIDUConfigData() {
        if (searchBy == "searchByModelId") {
            if (categoryFilter.length == 0 || categoryFilter == "Select Category") {
                setAlert("Category Cannot be Empty", 'danger')
                return
            }

            if (brandFilter.length == 0 || brandFilter == "Select Brand") {
                setAlert("Brand Cannot be Empty", 'danger')
                return
            }

            if (modelNumberFilter.length == 0 || modelNumberFilter == "Select Model ID") {
                setAlert("Model ID Cannot be Empty", 'danger')
                return
            }
        }
        else if (searchBy == "searchByConfigId") {
            if (configIdFilter.length == 0 || !configIdFilter) {
                setAlert("Config ID Cannot be Empty", 'danger')
                return
            }
        }
        else {
            setAlert("Invalid Filter", 'danger')
            return;
        }

        setUpdateFlag(Math.random());
    };

    function resetFilters() {
        setCategoryFilter("Select Category")
        setBrandFilter("Select Brand")
        setModelNumberFilter("Select Model ID")
        setConfigIdFilter("")
        setUpdateFlag(Math.random())
    };

    function downloadFile(fileData) {
        let params = {
            'fileData': fileData,
            'fileName': 'config.bin'
        }

        downloadIDUConfigFile(params)
    };

    function editIDUConfig(iduConfigVersion, iduConfigId, modelId) {
        if (searchBy == "searchByConfigId") {
            setIDUConfigDataForEdit({
                iduConfigVersion: iduConfigVersion,
                configId: iduConfigId,
                updateIDUConfigBy: "updateConfigByConfigId"
            })
        }
        else if (searchBy == "searchByModelId") {
            setIDUConfigDataForEdit({
                categoryName: categoryFilter,
                brandName: brandFilter,
                model: [{ "modelId": modelId, "modelNumber": JSON.parse(modelNumberFilter)["modelNumber"] }],
                iduConfigVersion: iduConfigVersion,
                configId: iduConfigId,
                updateIDUConfigBy: "updateConfigByModelId"
            })
        }
        else {
            //invalid selection of filters
        }
    }

    const getIDUConfigFilterData = () => {
        setIsIDUConfigDataLoading(true);

        let filteredParams = {}

        if (searchBy == "searchByConfigId") {
            // search on the basis of config id
            if (configIdFilter && configIdFilter.length != 0) {
                filteredParams['configId'] = configIdFilter
            }
        }
        else {
            // search on the bais of model id
            if (modelNumberFilter && modelNumberFilter.length != 0) {
                // get the model id only. We will show model number to users
                // but in background model id we are suing

                filteredParams['modelId'] = JSON.parse(modelNumberFilter)['modelId']
            }
        }

        getIDUConfigData(filteredParams)
            .then((res) => {
                setIsIDUConfigDataLoading(false);
                setShowIDUConfigDataList(true);
                setIDUConfigData(res);
            })
            .catch((err) => {
                setIsIDUConfigDataLoading(false);
                setShowIDUConfigDataList(false);
                setAlert("Failed to Get IDU EEPROM Config", "danger")
            });
    };

    function handleSearchByChange(event) {
        setSearchBy(event.target.value)
    }

    function handleEditByConfigId() {
        //here a single config id has the same config version

        setIDUConfigDataForEdit({
            iduConfigVersion: (iduConfigData && iduConfigData.length > 0) ? iduConfigData[0]["iduConfigVersion"] : "",
            configId: configIdFilter,
            updateIDUConfigBy: "updateConfigByConfigId"
        })
    }

    if (isIDUConfigDataLoading) {
        return <div style={{ display: "grid", placeItems: "center", height: "20vh" }}>
            <CircularProgress size="5em" style={{ color: "#AFB42B" }} />
        </div>
    }

    return (
        <Fragment>
            <div className="content-block bottom-shadow">
                <div className="py-15 ">
                    <Grid container style={{ marginBottom: "20px" }}>
                        <Grid item xs={1} style={{ marginLeft: "10px", marginTop: "10px" }}>
                            <label className="title">Search By: </label>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl component="fieldset">
                                <RadioGroup aria-label="searchBy" name="searchBy" row value={searchBy} onChange={handleSearchByChange}>
                                    <FormControlLabel value="searchByModelId" control={<Radio color="default" />} label="Model ID" />
                                    <FormControlLabel value="searchByConfigId" control={<Radio color="default" />} label="Config ID" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <div className="row mb-0">
                        {
                            searchBy == "searchByModelId" &&

                            <div className="col s2 pt-10">
                                <label className="title">Category</label>
                                <div className="model-srch reset width-md">
                                    <select
                                        className="custom-select select-reset radius-20"
                                        style={{ "display": "block !important" }}
                                        name="categoryFilter"
                                        onChange={(e) => {
                                            setCategoryFilter(e.target.value);
                                        }}
                                        value={categoryFilter}
                                    >
                                        {["Select Category", ...allowedDeviceCategories].map((element) => (
                                            <option value={element}>{element}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        }
                        {
                            searchBy == "searchByModelId" && <div className="col s2 pt-10">
                                <label className="title">Brand</label>
                                <div className="model-srch reset width-md">
                                    <select
                                        className="custom-select select-reset radius-20"
                                        style={{ "display": "block !important" }}
                                        name="brandFilter"
                                        onChange={(e) => {
                                            setBrandFilter(e.target.value);
                                        }}
                                        value={brandFilter}
                                    >
                                        {["Select Brand", ...allowedCompanyBrand].map((element) => (
                                            <option value={element}>{element}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        }
                        {
                            searchBy == "searchByModelId" && <div className="col s2 pt-10">
                                <label className="title">Model ID</label>
                                <div className="model-srch reset width-md">
                                    <select
                                        className="custom-select select-reset radius-20"
                                        style={{ "display": "block !important" }}
                                        name="modelNumberFilter"
                                        onChange={(e) => {
                                            setModelNumberFilter(e.target.value);
                                        }}
                                        value={modelNumberFilter}
                                    >
                                        {["Select Model ID", ...modelNumberFilterList].map((element) => {
                                            if (element == "Select Model ID") {
                                                return <option value={element}>{element}</option>
                                            }
                                            else {
                                                return <option value={JSON.stringify(element)}>{element["modelId"]}</option>
                                            }
                                        })}
                                    </select>
                                </div>
                            </div>
                        }
                        {
                            searchBy == "searchByConfigId" && <div className="col s2 pt-10">
                                <label className="title">Config ID</label>
                                <div className="model-srch reset width-md">
                                    <input
                                        className="add-input md border-1"
                                        placeholder="Enter Config ID"
                                        type="text"
                                        name="configIdFilter"
                                        value={configIdFilter}
                                        onChange={(e) => {
                                            setConfigIdFilter(e.target.value)
                                        }}
                                    />
                                </div>
                            </div>
                        }
                        <div className="col s2 pt-10" style={{ marginTop: '16px' }}>

                            <button
                                className="btn btn-style-2 orange-bg ml-15 mb-15"
                                onClick={filterIDUConfigData}
                            >
                                Filter
                            </button>
                            <button
                                className="btn btn-style-2 orange-bg ml-15 mb-15"
                                onClick={resetFilters}
                            >
                                Reset
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {
                searchBy == "searchByConfigId" && showIDUConfigDataList &&
                <div className="py-15 " style={{ float: 'right', marginRight: '20px' }}>
                    <button
                        className="btn btn-style-2 orange-bg ml-15 mb-15"
                        onClick={handleEditByConfigId}
                    >
                        Edit Configuration
                    </button>
                </div>
            }
            {
                showIDUConfigDataList &&
                <div className="content-block bottom-shadow py-15 mb-30">
                    <div className="row mb-0">
                        <div className="col s12">
                            <div
                                className="table-wrapper mb-20"
                                style={{
                                    overflowX: "scroll",
                                    scrollBehavior: "auto",
                                    maxHeight: "58vh",
                                    overflowY: "scroll",
                                }}
                            >
                                <table
                                    width="100%"
                                    align="center"
                                    className="table style-4 mb-20 manufacturedDevicesTable"
                                >
                                    <thead
                                        style={{ position: "sticky", top: 0, zIndex: 50, background: "white" }}
                                    >
                                        <tr>
                                            {Object.keys(headers).map((key) => (
                                                (key != 'editIDUConfig' || searchBy != 'searchByConfigId') &&
                                                <th>{headers[key]}</th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {iduConfigData.length === 0 ? (
                                            <tr>
                                                <td colSpan={Object.keys(headers).length}>
                                                    No Data found
                                                </td>
                                            </tr>
                                        ) : (
                                            iduConfigData
                                                .map((element, index) => {
                                                    return (
                                                        <tr key={index} id={index}>
                                                            {Object.keys(headers).map((key) => {
                                                                return (
                                                                    key == "iduConfigFileObjectBin" ?
                                                                        <td
                                                                            className={
                                                                                String(element[key]).length > 200
                                                                                    ? "breakWord"
                                                                                    : ""
                                                                            }
                                                                        >
                                                                            <u><a href="javascript:void(0)" onClick={() => {
                                                                                downloadFile(element["iduConfigFileObjectBin"])
                                                                            }}><CloudDownload style={{ color: "black" }}></CloudDownload></a></u>
                                                                        </td>
                                                                        :
                                                                        key == "editIDUConfig" && searchBy == "searchByModelId" ?
                                                                            <td>
                                                                                <a href="javascript:void(0)" onClick={() => {
                                                                                    editIDUConfig(element["iduConfigVersion"], element["configId"], element["modelId"], element["iduConfigFileObjectBin"])
                                                                                }}><EditIcon style={{ color: "black" }}></EditIcon></a>
                                                                            </td>
                                                                            :
                                                                            <td
                                                                                className={
                                                                                    String(element[key]).length > 200
                                                                                        ? "breakWord"
                                                                                        : ""
                                                                                }
                                                                            >
                                                                                {element[key] ? element[key] : ""}
                                                                            </td>
                                                                );
                                                            })}
                                                        </tr>
                                                    );
                                                })
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </Fragment>
    );
}

const UploadIDUEEPROMConfig = ({
    allowedDeviceCategories,
    allowedCompanyBrand,
    iduConfigStore: { uploadIDUConfigInProgress, uploadIDUConfigSuccess },
    uploadedIDUConfigData,
    editIDUConfig,
    uploadIDUConfig,
    updateIDUConfigByModelId,
    updateIDUConfigByConfigId,
    cancelEditIDUConfig,
    setAlert }) => {

    const classes = useStyles()

    const [uploadedCategoryName, setUploadedCategoryName] = useState(uploadedIDUConfigData["categoryName"] || "")
    const [uploadedBrandName, setUploadedBrandName] = useState(uploadedIDUConfigData["brandName"] || "")
    const [modelNumbersList, setModelumbersList] = useState([])
    const [uploadedModelNumbers, setUploadedModelNumbers] = useState(uploadedIDUConfigData["model"] || [])
    const [uploadedIDUConfigVersion, setUploadedIDUConfigVersion] = useState(uploadedIDUConfigData["configversion"] || "")
    const [uploadedIDUConfigImage, setUploadedIDUConfigImage] = useState("")
    const [uploadedIDUConfigId, setUploadedIDUConfigId] = useState(uploadedIDUConfigData["configId"] || "")
    const [updateIDUConfigBy, setUpdateIDUConfigBy] = useState(uploadedIDUConfigData['updateIDUConfigBy'] || "updateConfigByModelId") // by default set to this value

    const allModelNumbersSelected = modelNumbersList.length === uploadedModelNumbers.length
    const iduConfigImageInput = useRef()

    // Adjust the height of menu items in select. Otherwise get hidden behind headers
    const menuProps = {
        style: {
            marginTop: '52px'
        },
    };

    useEffect(() => {
        M.AutoInit();
    }, [])

    useEffect(() => {
        if (!uploadIDUConfigInProgress && uploadIDUConfigSuccess) {
            setUploadedCategoryName("")
            setUploadedBrandName("")
            setUploadedModelNumbers([])
            setUploadedIDUConfigVersion("")
            setUploadedIDUConfigImage("")
            setUploadedIDUConfigId("")
            setUpdateIDUConfigBy("")

            iduConfigImageInput.current.value = ""

            if (editIDUConfig) {
                cancelEditIDUConfig() //redirect the user to view page
            }
        }
    }, [uploadIDUConfigInProgress, uploadIDUConfigSuccess])

    useEffect(() => {
        if (!uploadedCategoryName || uploadedCategoryName.length == 0 || uploadedCategoryName == "Select Category") {
            return
        }

        if (!uploadedBrandName || uploadedBrandName.length == 0 || uploadedBrandName == "Select Brand") {
            return
        }

        if (uploadedBrandName && uploadedBrandName.length != 0 && uploadedCategoryName && uploadedCategoryName.length != 0) {
            getDeviceModelNumbersList(uploadedBrandName, uploadedCategoryName)
                .then((data) => {
                    setModelumbersList(data)
                    if (!editIDUConfig) {
                        setUploadedModelNumbers([])
                    }
                })
                .catch((err) => {
                    setModelumbersList([])
                    setUploadedModelNumbers([])
                })
        }
    }, [uploadedBrandName, uploadedCategoryName])

    useEffect(() => {
        if (editIDUConfig && uploadedIDUConfigData && Object.keys(uploadedIDUConfigData).length != 0) {
            setUploadedCategoryName(uploadedIDUConfigData["categoryName"] || "")
            setUploadedBrandName(uploadedIDUConfigData["brandName"] || "")
            setUploadedIDUConfigVersion(uploadedIDUConfigData["iduConfigVersion"] || "")
            setUploadedModelNumbers(uploadedIDUConfigData["model"] || [])
            setUploadedIDUConfigId(uploadedIDUConfigData["configId"])
            setUpdateIDUConfigBy(uploadedIDUConfigData['updateIDUConfigBy'] || "updateConfigByModelId")
            setUploadedIDUConfigImage("")

            iduConfigImageInput.current.value = ""
        } else {
            setUploadedCategoryName("")
            setUploadedBrandName("")
            setUploadedIDUConfigVersion("")
            setUploadedModelNumbers([])
            setUploadedIDUConfigId("")
            setUpdateIDUConfigBy("")
            setUploadedIDUConfigImage("")

            iduConfigImageInput.current.value = ""
        }

        if (uploadedIDUConfigData && uploadedIDUConfigData['updateIDUConfigBy'] == "updateConfigByConfigId") {
            //get the list of model ids..
            getDeviceModelIdsUsingConfigId(uploadedIDUConfigData["configId"])
                .then((res) => {
                    setUploadedModelNumbers(res.map((modelId) => {
                        return { 'modelId': modelId, 'modelNumber': "" }
                    }))
                })
                .catch((err) => {
                    //do nothing
                })
        }

    }, [editIDUConfig, uploadedIDUConfigData])

    const handleUploadModelNumbers = (event, selectedOptions, reason) => {
        if (reason === "select-option" || reason === "remove-option") {
            if (selectedOptions.find((option) => option === "Select All")) {

                if (!allModelNumbersSelected) {
                    setUploadedModelNumbers([...modelNumbersList]);
                } else {
                    setUploadedModelNumbers([]);
                }
            } else {
                setUploadedModelNumbers(selectedOptions)
            }
        } else if (reason === "clear") {
            setUploadedModelNumbers([]);
        }
    };

    const handleModelNumberRenderer = (option, { selected }) => {
        const selectAllProps =
            option === "Select All"
                ? { checked: allModelNumbersSelected }
                : {};
        return (
            <Fragment>
                <Checkbox
                    color="primary"
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                    {...selectAllProps}
                />
                {option["modelId"] || "Select All"}
            </Fragment>
        );
    };

    function handleUploadIDUConfig() {
        if (!editIDUConfig || (editIDUConfig && updateIDUConfigBy == "updateConfigByModelId")) {
            if (!uploadedCategoryName || uploadedCategoryName.length == 0) {
                setAlert('Category Name Cannot be Empty', 'danger')
                return
            }
            if (!uploadedBrandName || uploadedBrandName.length == 0) {
                setAlert('Brand Name Cannot be Empty', 'danger')
                return
            }
            if (!uploadedModelNumbers || uploadedModelNumbers.length == 0) {
                setAlert('Model IDs Cannot be Empty', 'danger')
                return
            }
        }

        if (!uploadedIDUConfigVersion || uploadedIDUConfigVersion.length == 0 || uploadedIDUConfigVersion.match('^[0-9]{1,2}\\.[0-9]{1,2}$') == null) {
            setAlert('Invalid IDU Config Version', 'danger')
            return
        }
        if (!uploadedIDUConfigImage || uploadedIDUConfigImage == "" || !(uploadedIDUConfigImage.name.endsWith('.bin') || uploadedIDUConfigImage.name.endsWith('.BIN'))) {
            setAlert('Invalid IDU Config Image', 'danger')
            return
        }

        if (editIDUConfig) {
            if (updateIDUConfigBy == "updateConfigByModelId") {
                let modelId = uploadedModelNumbers.map((obj) => obj["modelId"])?.join(',') ?? ''
                let formData = new FormData()
                formData.append('iduConfigVersion', uploadedIDUConfigVersion)
                formData.append('iduConfigFile', uploadedIDUConfigImage)

                updateIDUConfigByModelId(modelId, formData)
            }
            else if (updateIDUConfigBy == "updateConfigByConfigId") {
                let configId = uploadedIDUConfigId
                let formData = new FormData()
                formData.append('iduConfigVersion', uploadedIDUConfigVersion)
                formData.append('iduConfigFile', uploadedIDUConfigImage)

                updateIDUConfigByConfigId(configId, formData)
            }
        }
        else {
            let formData = new FormData()
            formData.append('category', uploadedCategoryName)
            formData.append('modelId', uploadedModelNumbers.map((obj) => obj["modelId"])?.join(',') ?? '')
            formData.append('iduConfigVersion', uploadedIDUConfigVersion)
            formData.append('iduConfigFile', uploadedIDUConfigImage)

            uploadIDUConfig(formData)
        }
    };

    function handleIDUConfigUploadReset() {
        setUploadedCategoryName("")
        setUploadedBrandName("")
        setUploadedModelNumbers([])
        setUploadedIDUConfigVersion("")
        setUploadedIDUConfigImage("")
        setUploadedIDUConfigId("")
        setUpdateIDUConfigBy("updateConfigByModelId")

        iduConfigImageInput.current.value = ""
    }

    function handleCancelEditIduConfig() {
        cancelEditIDUConfig()
    }

    const getOptionSelected = (option, anotherOption) =>
        option === anotherOption;

    const filter = createFilterOptions();

    return (<Fragment>
        <div className="content-block bottom-shadow">
            <div className="py-15 ">
                {
                    // in case of update/edit by model id or new upload same user interface is visible
                    // but in case of edit these fields are set to disabled

                    (editIDUConfig == false || (editIDUConfig == true && updateIDUConfigBy == 'updateConfigByModelId')) ?
                        <Fragment>
                            <Grid container style={{ marginBottom: "20px" }}>
                                <Grid item xs={2} style={{ marginLeft: "10px" }}>
                                    <label className="title">Category: </label>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl variant="outlined" className={classes.selectValues} disabled={editIDUConfig ? true : false}>
                                        <InputLabel id="select-outlined-label-category-name" className="title">Select Category</InputLabel>
                                        <Select
                                            labelId="select-outlined-label-category-name"
                                            id="select-outlined-ctageory-name-id"
                                            value={uploadedCategoryName}
                                            onChange={(e) => {
                                                setUploadedCategoryName(e.target.value)
                                            }}
                                            label="Select Category"
                                            className={classes.selectInput}
                                            MenuProps={menuProps}
                                        >
                                            {
                                                allowedDeviceCategories.map(categoryName => {
                                                    return <MenuItem key={categoryName} value={categoryName}>{categoryName}</MenuItem>
                                                })
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container style={{ marginBottom: "20px" }}>
                                <Grid item xs={2} style={{ marginLeft: "10px" }}>
                                    <label className="title">Brand: </label>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl variant="outlined" className={classes.selectValues} disabled={editIDUConfig ? true : false}>
                                        <InputLabel id="select-outlined-label-brand-name">Select Brand</InputLabel>
                                        <Select
                                            labelId="select-outlined-label-brand-name"
                                            id="select-outlined-brand-name-id"
                                            value={uploadedBrandName}
                                            onChange={(e) => {
                                                setUploadedBrandName(e.target.value)
                                            }}
                                            label="Select Brand"
                                            className={classes.selectInput}
                                            MenuProps={menuProps}
                                        >
                                            {
                                                allowedCompanyBrand.map(brandName => {
                                                    return <MenuItem key={brandName} value={brandName}>{brandName}</MenuItem>
                                                })
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>

                        </Fragment>
                        :
                        <Fragment>
                            <Grid container style={{ marginBottom: "20px" }}>
                                <Grid item xs={2} style={{ marginLeft: "10px" }}>
                                    <label>Config Id: </label>
                                </Grid>
                                <Grid item xs={6} style={{ width: "100% important" }}>
                                    <input
                                        className="add-input xl"
                                        placeholder="Enter IDU Config Version"
                                        type="text"
                                        style={{ width: "100% !important" }}
                                        value={uploadedIDUConfigId}
                                        onChange={(e) => {
                                            setUploadedIDUConfigId(e.target.value)
                                        }}
                                        disabled={true}
                                    />
                                </Grid>
                            </Grid>
                        </Fragment>
                }

                <Grid container style={{ marginBottom: "20px" }}>
                    <Grid item xs={2} style={{ marginLeft: "10px" }}>
                        <label className="title">Model ID: </label>
                    </Grid>
                    <Grid item xs={6}>
                        <Autocomplete
                            multiple
                            size="small"
                            limitTags={10}
                            options={modelNumbersList}
                            value={uploadedModelNumbers}
                            disableCloseOnSelect
                            getOptionLabel={(option) => option["modelId"].toString()}
                            renderOption={handleModelNumberRenderer}
                            onChange={handleUploadModelNumbers}
                            renderInput={(params) => (
                                <TextField {...params} variant="outlined" label="Select Model IDs" placeholder="Search" />
                            )}
                            filterOptions={(options, params) => {
                                const filtered = filter(options, params);
                                if (!uploadedCategoryName || uploadedCategoryName.length == 0 || !uploadedBrandName || uploadedBrandName.length == 0
                                    || uploadedCategoryName == "Select Category" || uploadedBrandName == "Select Brand") {
                                    return []
                                }
                                return ["Select All", ...filtered];
                            }}
                            getOptionSelected={getOptionSelected}
                            ListboxProps={{ style: { maxHeight: 250 } }}
                            disabled={editIDUConfig ? true : false}
                        />
                    </Grid>
                </Grid>
                <Grid container style={{ marginBottom: "20px" }}>
                    <Grid item xs={2} style={{ marginLeft: "10px" }}>
                        <label>Config Version: </label>
                    </Grid>
                    <Grid item xs={6} style={{ width: "100% important" }}>
                        <input
                            className="add-input xl"
                            placeholder="Enter IDU Config Version"
                            type="text"
                            style={{ width: "100% !important" }}
                            value={uploadedIDUConfigVersion}
                            onChange={(e) => {
                                setUploadedIDUConfigVersion(e.target.value)
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container style={{ marginBottom: "20px" }}>
                    <Grid item xs={2} style={{ marginLeft: "10px" }}>
                        <label className="title">IDU Config Image: </label>
                    </Grid>
                    <Grid item xs={6} style={{ width: "100% important" }}>
                        <input
                            className="md border-1"
                            placeholder="Upload IDU Config File"
                            type="file"
                            style={{ width: "100% !important" }}
                            name={uploadedIDUConfigImage?.name ?? uploadedIDUConfigImage}
                            onChange={(e) => {
                                setUploadedIDUConfigImage(e.target.files[0])
                            }}
                            ref={iduConfigImageInput}
                        />
                    </Grid>
                </Grid>
                <div className="row">
                    <button
                        className="btn btn-style-2 orange-bg"
                        onClick={handleUploadIDUConfig}
                        disabled={uploadIDUConfigInProgress ? true : false}
                        style={{ marginLeft: "10px" }}
                    >
                        {
                            uploadIDUConfigInProgress ? <Fragment>
                                <CircularProgress size="2em" style={{ color: "#AFB42B", marginTop: "5px" }} />
                            </Fragment>
                                : "Upload"
                        }
                    </button>
                    {
                        editIDUConfig ? <button
                            className="btn btn-style-2 orange-bg" onClick={handleCancelEditIduConfig}
                            style={{ marginLeft: "20px" }}
                        >
                            Cancel
                        </button>
                            :
                            <button
                                className="btn btn-style-2 orange-bg" onClick={handleIDUConfigUploadReset}
                                style={{ marginLeft: "20px" }}
                            >
                                Reset
                            </button>
                    }
                </div>
            </div>
        </div>
    </Fragment>
    );
}

const MQTTTriggerIDUEEPROMConfig = ({
    iduConfigStore: { iduConfigMQTTTriggerInProgress, iduConfigMQTTTriggerSuccess },
    sendIDUConfigMQTTTrigger,
    setAlert }) => {

    const classes = useStyles()
    const [uploadedDeviceId, setUploadedDeviceId] = useState("")

    useEffect(() => {
        M.AutoInit();
    }, [])

    useEffect(() => {
        if (!iduConfigMQTTTriggerInProgress && iduConfigMQTTTriggerSuccess) {
            setUploadedDeviceId("")
        }
    }, [iduConfigMQTTTriggerInProgress, iduConfigMQTTTriggerSuccess])

    function handleIDUConfigMQTTTrigger() {
        if (!uploadedDeviceId || uploadedDeviceId.length == 0) {
            setAlert('Invalid Device Id', 'danger')
            return
        }
        sendIDUConfigMQTTTrigger(uploadedDeviceId)
    };

    function handleIDUConfigTriggerReset() {
        setUploadedDeviceId("")
    }

    return (<Fragment>
        <div className="content-block bottom-shadow">
            <div className="py-15 ">
                <Grid container style={{ marginBottom: "20px" }}>
                    <Grid item xs={2} style={{ marginLeft: "10px" }}>
                        <label>Device Id: </label>
                    </Grid>
                    <Grid item xs={6} style={{ width: "100% important" }}>
                        <input
                            className="add-input xl"
                            placeholder="Enter Device Id"
                            type="text"
                            style={{ width: "100% !important" }}
                            value={uploadedDeviceId}
                            onChange={(e) => {
                                setUploadedDeviceId(e.target.value)
                            }}
                        />
                    </Grid>
                </Grid>
                <div className="row">
                    <button
                        className="btn btn-style-2 orange-bg"
                        onClick={handleIDUConfigMQTTTrigger}
                        disabled={iduConfigMQTTTriggerInProgress ? true : false}
                        style={{ marginLeft: "10px" }}
                    >
                        {
                            iduConfigMQTTTriggerInProgress ? <Fragment>
                                <CircularProgress size="2em" style={{ color: "#AFB42B", marginTop: "5px" }} />
                            </Fragment>
                                : "Send"
                        }
                    </button>
                    <button
                        className="btn btn-style-2 orange-bg" onClick={handleIDUConfigTriggerReset}
                        style={{ marginLeft: "20px" }}
                    >
                        Reset
                    </button>
                </div>
            </div>
        </div>
    </Fragment>
    );
}

const mapStateToProps = (state) => ({
    allowedDeviceCategories: state.auth.allowedDeviceCategories,
    allowedCompanyBrand: state.auth.allowCompanyBrand,
    iduConfigStore: state.iduConfig
});

const mapDispatchToProps = (dispatch) => ({
    setAlert: (message, status) => dispatch(setAlert(message, status)),
    uploadIDUConfig: (formData) => dispatch(uploadIDUConfig(formData)),
    updateIDUConfigByModelId: (modelId, formData) => dispatch(updateIDUConfigByModelId(modelId, formData)),
    updateIDUConfigByConfigId: (configId, formData) => dispatch(updateIDUConfigByConfigId(configId, formData)),
    downloadIDUConfigFile: (params) => dispatch(downloadIDUConfigFile(params)),
    sendIDUConfigMQTTTrigger: (params) => dispatch(sendIDUConfigMQTTTrigger(params))
});

export default connect(mapStateToProps, mapDispatchToProps)(IDUEEPROMConfig);

