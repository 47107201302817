export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGOUT = "LOGOUT";
export const REQUEST_USER = "REQUEST_USER";
export const GET_USER_BY_ID = "GET_USER_BY_ID";
export const USER_ERROR_BY_ID = "USER_ERROR_BY_ID";
export const UPDATE_EMAIL_CLEAR = "UPDATE_EMAIL_CLEAR";
export const UPDATE_EMAIL_ERROR = "UPDATE_EMAIL_ERROR";
export const UPDATE_EMAIL_SUCCESS = "UPDATE_EMAIL_SUCCESS";
export const GET_USER = "GET_USER";
export const USER_ERROR = "USER_ERROR";
export const CLEAR_USER = "CLEAR_USER";
export const GET_HOME = "GET_HOME";
export const HOME_ERROR = "HOME_ERROR";
export const GET_REPORT = "GET_REPORT";
export const REPORT_ERROR = "REPORT_ERROR";
export const GET_DEVICE = "GET_DEVICE";
export const GET_ALL_DEVICE = "GET_ALL_DEVICE";
export const DEVICE_ERROR = "DEVICE_ERROR";
export const REQUEST_DEVICE_USAGE = "REQUEST_DEVICE_USAGE";
export const GET_DEVICE_USAGE = "GET_DEVICE_USAGE";
export const DEVICE_USAGE_ERROR = "DEVICE_USAGE_ERROR";
export const UPLOAD_SUCCESS = "UPLOAD_SUCCESS";
export const UPLOAD_FAIL = "UPLOAD_FAIL";
export const GET_MODEL = "GET_MODEL";
export const MODEL_ERROR = "MODEL_ERROR";
export const CLEAR_DATA = "CLEAR_DATA";
export const UPDATE_DEVICE = "UPDATE_DEVICE";
export const GET_FIRMWARE_DETAILS = "GET_FIRMWARE_DETAILS";
export const DEVICE_FIRMWARE_ERROR = "DEVICE_FIRMWARE_ERROR";
export const OFFBOARD_DEVICE = "OFFBOARD_DEVICE";
export const GET_LATEST_FIRMWARE = "GET_LATEST_FIRMWARE";
export const GET_LATEST_FIRMWARE_ERROR = "GET_LATEST_FIRMWARE_ERROR";
export const UPDATE_SERIAL_NUMBER = "UPDATE_SERIAL_NUMBER";
export const UPDATE_SERIAL_NUMBER_ERROR = "UPDATE_SERIAL_NUMBER_ERROR";
export const GET_ONBOARDED_HISTORY_DETAILS = "GET_ONBOARDED_HISTORY_DETAILS";
export const GET_ONBOARDED_HISTORY_DETAILS_ERROR = "GET_ONBOARDED_HISTORY_DETAILS_ERROR";
export const DOWNLOAD_DEVICE_STATISTICS_FAIL = "DOWNLOAD_DEVICE_STATISTICS_FAIL";
export const SEND_EMAIL_RESPONSE_SUCCESS = "SEND_EMAIL_RESPONSE_SUCCESS";
export const SEND_EMAIL_RESPONSE_FAILED = "SEND_EMAIL_RESPONSE_FAILED";
export const SEND_MISMATCH_DATA_RESPONSE_SUCCESS = "SEND_MISMATCH_DATA_RESPONSE_SUCCESS";
export const SEND_MISMATCH_DATA_RESPONSE_FAILED = "SEND_MISMATCH_DATA_RESPONSE_FAILED";
export const GET_WARRANTY_TOKEN = "GET_WARRANTY_TOKEN";
export const WARRANTY_TOKEN_ERROR = "WARRANTY_TOKEN_ERROR";
export const GET_WARRANTY_APPLIANCE = "GET_WARRANTY_APPLIANCE";
export const WARRANTY_APPLIANCE_ERROR = "WARRANTY_APPLIANCE_ERROR";
export const GET_WARRANTY_DETAIL = "GET_WARRANTY_DETAIL";
export const WARRANTY_DETAIL_ERROR = "WARRANTY_DETAIL_ERROR";
export const CLEAR_WARRANTY = "CLEAR_WARRANTY";
export const GET_DIAGNOSTIC_DATA = "GET_DIAGNOSTIC_DATA";
export const GET_DIAGNOSTIC_DATA_ERROR = "GET_DIAGNOSTIC_DATA_ERROR";
export const GET_FIRMWARE_INFO = "GET_FIRMWARE_INFO";
export const FIRMWARE_INFO_ERROR = "FIRMWARE_INFO_ERROR";
export const GET_METRICS_DATA = "GET_METRICS_DATA";
export const GET_METRICS_DATA_ERROR = "GET_METRICS_DATA_ERROR"
export const INIT_FOTA_JOBS = "INIT_FOTA_JOBS";
export const GET_FOTA_JOBS = "GET_FOTA_JOBS";
export const GET_FOTA_JOBS_ERROR = "GET_FOTA_JOBS_ERROR";
export const ADD_FOTA_JOBS = "ADD_FOTA_JOBS";
export const ADD_FOTA_JOBS_ERROR = "ADD_FOTA_JOBS_ERROR";
export const UPDATE_FOTA_JOBS = "UPDATE_FOTA_JOBS";
export const UPDATE_FOTA_JOBS_ERROR = "UPDATE_FOTA_JOBS_ERROR";
export const DELETE_FOTA_JOBS = "DELETE_FOTA_JOBS";
export const DELETE_FOTA_JOBS_ERROR = "DELETE_FOTA_JOBS_ERROR";
export const GET_TENANT_INFO = "GET_TENANT_INFO";
export const TENANT_INFO_ERROR = "TENANT_INFO_ERROR";
export const GET_GROUP_USERS_SUCCESS = "GET_GROUP_USERS_SUCCESS";
export const GET_GROUP_USERS_ERROR = "GET_GROUP_USERS_ERROR";
export const GET_ONBOARDED_DEVICE_SUCCESS = "GET_ONBOARDED_DEVICE_SUCCESS";
export const GET_ONBOARDED_DEVICE_ERROR = "GET_ONBOARDED_DEVICE_ERROR";
export const GET_OFFBOARDED_DEVICE_SUCCESS = "GET_OFFBOARDED_DEVICE_SUCCESS";
export const GET_OFFBOARDED_DEVICE_ERROR = "GET_OFFBOARDED_DEVICE_ERROR";
export const GET_ONBOARDED_AC_USER_SUCCESS = "GET_ONBOARDED_AC_USER_SUCCESS";
export const GET_ONBOARDED_AC_USER_ERROR = "GET_ONBOARDED_AC_USER_ERROR";
export const GET_CONTROLLED_DEVICES_SUCCESS = "GET_CONTROLLED_DEVICES_SUCCESS";
export const GET_CONTROLLED_DEVICES_ERROR = "GET_CONTROLLED_DEVICES_ERROR";
export const GET_FIRMWARE_DEVICES_SUCCESS = "GET_FIRMWARE_DEVICES_SUCCESS";
export const GET_FIRMWARE_DEVICES_ERROR = "GET_FIRMWARE_DEVICES_ERROR";
export const GET_LOCATION_DEVICES_SUCCESS = "GET_LOCATION_DEVICES_SUCCESS";
export const GET_LOCATION_DEVICES_ERROR = "GET_LOCATION_DEVICES_ERROR";
export const UPDATE_FIRMWARE_VERSION_SUCCESS = "UPDATE_FIRMWARE_VERSION_SUCCESS";
export const UPDATE_FIRMWARE_VERSION_ERROR = "UPDATE_FIRMWARE_VERSION_ERROR";
export const RESET_GRAPH_DATA = "RESET_GRAPH_DATA";
export const GET_MODEL_WISE_ONBOARDED_DEVICE_SUCCESS = "GET_MODEL_WISE_ONBOARDED_DEVICE_SUCCESS";
export const GET_MODEL_WISE_ONBOARDED_DEVICE_ERROR = "GET_MODEL_WISE_ONBOARDED_DEVICE_ERROR";
export const GET_HOURLY_ACTIVE_DEVICE_SUCCESS = "GET_HOURLY_ACTIVE_DEVICE_SUCCESS";
export const GET_HOURLY_ACTIVE_DEVICE_ERROR = "GET_HOURLY_ACTIVE_DEVICE_ERROR";
export const GET_ONBOARDED_AC_DEVICE_SUCCESS = "GET_ONBOARDED_AC_DEVICE_SUCCESS";
export const GET_ONBOARDED_AC_DEVICE_ERROR = "GET_ONBOARDED_AC_DEVICE_ERROR";
export const GET_OFFBOARDED_AC_DEVICE_SUCCESS = "GET_OFFBOARDED_AC_DEVICE_SUCCESS";
export const GET_OFFBOARDED_AC_DEVICE_ERROR = "GET_OFFBOARDED_AC_DEVICE_ERROR";
export const GET_MQTT_USERS_SUCCESS = "GET_MQTT_USERS_SUCCESS";
export const GET_MQTT_USERS_ERROR = "GET_MQTT_USERS_ERROR";
export const GET_MQTT_USED_KEYS_SUCCESS = "GET_MQTT_USED_KEYS_SUCCESS";
export const GET_MQTT_USED_KEYS_ERROR = "GET_MQTT_USED_KEYS_ERROR";
export const UPLOAD_DEVICE_FACTORY_FIRMWARE = "UPLOAD_DEVICE_FACTORY_FIRMWARE"
export const UPLOAD_DEVICE_FACTORY_FIRMWARE_SUCCESS = "UPLOAD_DEVICE_FACTORY_FIRMWARE_SUCCESS"
export const UPLOAD_DEVICE_FACTORY_FIRMWARE_ERROR = "UPLOAD_DEVICE_FACTORY_FIRMWARE_ERROR"
export const UPLOAD_DEVICE_FOTA_FIRMWARE = "UPLOAD_DEVICE_FOTA_FIRMWARE"
export const UPLOAD_DEVICE_FOTA_FIRMWARE_SUCCESS = "UPLOAD_DEVICE_FOTA_FIRMWARE_SUCCESS"
export const UPLOAD_DEVICE_FOTA_FIRMWARE_ERROR = "UPLOAD_DEVICE_FOTA_FIRMWARE_ERROR"
export const UPLOAD_DEVICE_FOTA_FIRMWARE_INIT = "UPLOAD_DEVICE_FOTA_FIRMWARE_INIT"
export const GET_DEVICE_MODELS_METADATA = 'GET_DEVICE_MODELS_METADATA';
export const UPLOAD_IDU_CONFIG = "UPLOAD_IDU_CONFIG"
export const UPLOAD_IDU_CONFIG_SUCCESS = "UPLOAD_IDU_CONFIG_SUCCESS"
export const UPLOAD_IDU_CONFIG_ERROR = "UPLOAD_IDU_CONFIG_ERROR"
export const IDU_CONFIG_MQTT_TRIGGER = "IDU_CONFIG_MQTT_TRIGGER"
export const IDU_CONFIG_MQTT_TRIGGER_SUCCESS = "IDU_CONFIG_MQTT_TRIGGER_SUCCESS"
export const IDU_CONFIG_MQTT_TRIGGER_ERROR = "IDU_CONFIG_MQTT_TRIGGER_ERROR"
export const SEND_FOTA_MQTT_TRIGGER = "send_fota_mqtt_trigger"
export const SEND_FOTA_MQTT_TRIGGER_SUCCESS = "send_fota_mqtt_trigger_success"
export const SEND_FOTA_MQTT_TRIGGER_FAILURE = "send_fota_mqtt_trigger_failure"
export const RESET_FOTA_MQTT_TRIGGER_STATUS = "RESET_FOTA_MQTT_TRIGGER_STATUS"
export const GET_FOTA_BATCH_DATA = "GET_FOTA_BATCH_DATA"
export const GET_FOTA_BATCH_DATA_ERROR = "GET_FOTA_BATCH_DATA_ERROR"
export const GET_FOTA_BATCH_DATA_LOADING = "GET_FOTA_BATCH_DATA_LOADING"
export const DELETE_FOTA_BATCH = "DELETE_FOTA_BATCH"
export const DELETE_FOTA_BATCH_ERROR = "DELETE_FOTA_BATCH_ERROR"
export const UPLOAD_PSN_DELAER_MAPPING_FILE = "UPLOAD_PSN_DELAER_MAPPING_FILE"
export const UPLOAD_PSN_DELAER_MAPPING_FILE_SUCCESS = "UPLOAD_PSN_DELAER_MAPPING_FILE_SUCCESS"
export const UPLOAD_PSN_DELAER_MAPPING_FILE_ERROR = "UPLOAD_PSN_DELAER_MAPPING_FILE_ERROR"
export const UM_V1_AUTH_TOKEN = "UM_V1_AUTH_TOKEN"
export const SEND_NOTIFICATION = "SEND_NOTIFICATION"
export const SEND_NOTIFICATION_SUCCESS = "SEND_NOTIFICATION_SUCCESS"
export const SEND_NOTIFICATION_ERROR = "SEND_NOTIFICATION_ERROR"

export const GET_NOTIFICATION_TASK_REQUEST = 'GET_NOTIFICATION_TASK_REQUEST'
export const GET_NOTIFICATION_TASK_SUCCESS = 'GET_NOTIFICATION_TASK_SUCCESS'
export const GET_NOTIFICATION_TASK_FAILURE = 'GET_NOTIFICATION_TASK_FAILURE'

export const CANCEL_NOTIFICATION_TASK_REQUEST = 'CANCEL_NOTIFICATION_TASK_REQUEST'
export const CANCEL_NOTIFICATION_TASK_SUCCESS = 'CANCEL_NOTIFICATION_TASK_SUCCESS'
export const CANCEL_NOTIFICATION_TASK_FAILURE = 'CANCEL_NOTIFICATION_TASK_FAILURE'

export const SEARCH_NOTIFICATION_REQUEST = 'SEARCH_NOTIFICATION_REQUEST'
export const SEARCH_NOTIFICATION_SUCCESS = 'SEARCH_NOTIFICATION_SUCCESS'
export const SEARCH_NOTIFICATION_FAILURE = 'SEARCH_NOTIFICATION_FAILURE'

