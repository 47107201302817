import { LOGIN_SUCCESS, LOGIN_FAIL, LOGOUT } from "../action/types";

const initialState = {
  isAuthenticated: null,
  loading: true,
  user: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case LOGIN_SUCCESS:
      // localStorage.setItem("token", payload.accessToken);

      return {
        ...state,
        ...payload,
        isAuthenticated: true,
        loading: false,
        token: payload.accessToken,
      };
    case LOGIN_FAIL:
    case LOGOUT:
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        loading: false,
      };

    default:
      return state;
  }
}
