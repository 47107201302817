import React, { Fragment, useEffect, useState } from "react";
import M from "materialize-css/dist/js/materialize.min.js";
import { connect } from "react-redux";
import {
  downloadDeviceStatistics,
  downloadDeviceOffboardStatistics,
} from "../../action/statistics";
import Alert from "../layout/Alert";
import customConfig from "../../config";

const Statistics = ({
  stats,
  downloadDeviceStatistics,
  downloadDeviceOffboardStatistics,
  allowedDeviceCategories,
  wrapperClass = "right-content-wraper",
  ...props
}) => {
  useEffect(() => {
    M.AutoInit();
  }, []);

  const [category, setCategory] = useState(allowedDeviceCategories[0]);
  const [allowedCategories, setAllowedCategories] = useState([]);

  useState(() => {
    let buffer = [];
    let categories = [];
    if (props.permissions && props.permissions.length > 0) {
      props.permissions.forEach((permission) => {
        if (permission.categories && permission.categories.length > 0) {
          permission.categories.forEach((cat) => {
            if (["AC", "AC2"].includes(cat) && !buffer.includes(cat)) {
              buffer = [...buffer, "AC", "AC2"];
              categories.push({ label: "AC", value: "AC" });
            } else if (
              ["FANCONTROLLER1", "FANCONTROLLER"].includes(cat) &&
              !buffer.includes(cat)
            ) {
              buffer = [...buffer, "FANCONTROLLER1", "FANCONTROLLER"];
              categories.push({
                label: "Fan Controller",
                value: "FANCONTROLLER",
              });
            } else if (
              ["ODMFRONTLOADWM"].includes(cat) &&
              !buffer.includes(cat)
            ) {
              buffer = [...buffer, "ODMFRONTLOADWM"];
              categories.push({
                label: "Washing Machine",
                value: "ODMFRONTLOADWM",
              });
            } else if (
              ["REFRIGERATOR"].includes(cat) &&
              !buffer.includes(cat)
            ) {
              buffer = [...buffer, "REFRIGERATOR"];
              categories.push({
                label: "Refrigerator",
                value: "REFRIGERATOR",
              });
            } else if (
              [
                "SWITCHES",
                "SWITCH",
                "DIMMERSWITCH",
                "FANSWITCH",
              ].includes(cat) &&
              !buffer.includes(cat)
            ) {
              buffer = [
                ...buffer,
                "SWITCHES",
                "SWITCH",
                "DIMMERSWITCH",
                "FANSWITCH",
              ];
              categories.push({
                label: "Switches",
                value: "SWITCHES",
              });
            }
            else if (
              [
                "ROMASWITCHES",
              ].includes(cat) &&
              !buffer.includes(cat)
            ) {
              buffer = [
                ...buffer,
                "ROMASWITCHES",
                
              ];
              categories.push({
                label: "Romaswitches",
                value: "ROMASWITCHES",
              });
            }
            
            
            else if (
              ["ANCHOR", "ANCHORFAN"].includes(cat) &&
              !buffer.includes(cat)
            ) {
              buffer = [...buffer, "ANCHOR", "ANCHORFAN"];
              categories.push({
                label: "Fans",
                value: "ANCHORFAN",
              });
            } else if (["TV"].includes(cat) && !buffer.includes(cat)) {
              buffer = [...buffer, "TV"];
              categories.push({
                label: "VDP",
                value: "VDP",
              });
            }
          });
        }
      });
    }
    setAllowedCategories(categories);
  }, [props.permissions]);


  return (
    <Fragment>
      <div className={wrapperClass}>
        <Alert />
        <div className="container">
          <div className="page-title">
            <h3>Download Statistics </h3>
          </div>
          <div className="content-block bottom-shadow">
            <div className="row">
              <div className="s12 p-0 col">
                <div className="table-wrapper">
                  <table
                    width="100%"
                    align="left"
                    className="table statistic style-4"
                  >
                    <thead>
                      <tr>
                        <th>Files description</th>
                        <th width="160">Device Category</th>
                        <th width="150">Download</th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr>
                        <td>Device Statistics</td>
                        <td width="160">
                          <div className="control-capabilities">
                            <div className="model-srch reset">
                              <select
                                name="searchBy"
                                value={category}
                                onChange={(e) => setCategory(e.target.value)}
                              >
                                {allowedDeviceCategories.length && allowedDeviceCategories.map((element) => {
                                  return (
                                    <option value={element}>
                                      {element}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </div>
                        </td>
                        <td width="150">
                          <button
                            className="lnkBtn download-icn"
                            onClick={() => downloadDeviceStatistics(category)}
                          >
                            <i className="material-icons reset-icon ">
                              file_download
                            </i>
                          </button>
                        </td>
                      </tr>

                      {/* <tr>
                        <td>Device Offboard Statistics</td>
                        <td width="160"></td>
                        <td width="150">
                          <button
                            className="lnkBtn download-icn"
                            onClick={() => downloadDeviceOffboardStatistics()}
                          >
                            <i className="material-icons reset-icon">
                              file_download
                            </i>
                          </button>
                        </td>
                      </tr> */}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  stats: state.statistics,
  permissions: state.auth.permissions,
  allowedDeviceCategories:state.auth.allowedDeviceCategories,
});

export default connect(mapStateToProps, {
  downloadDeviceStatistics,
  downloadDeviceOffboardStatistics,
})(Statistics);
