let d = new Date();
let n = d.getFullYear();
export const monthNames = [
  `Jan ${n}`,
  `Feb ${n}`,
  `Mar ${n}`,
  `Apr ${n}`,
  `May ${n}`,
  `Jun ${n}`,
  `Jul ${n}`,
  `Aug ${n}`,
  `Sep ${n}`,
  `Oct ${n}`,
  `Nov ${n}`,
  `Dec ${n}`,
];

export const selectMonth = [
                              {month:"January",value:"january"},
                              {month:"February",value:"february"},
                              {month:"March",value:"march"},
                              {month:"April",value:"april"},
                              {month:"May",value:"may"},
                              {month:"June",value:"june"},
                              {month:"July",value:"july"},
                              {month:"August",value:"august"},
                              {month:"September",value:"september"},
                              {month:"October",value:"october"},
                              {month:"November",value:"november"},
                              {month:"December",value:"december"},
                            ]

export const seriesData = (data, type) => {
  let arr = [];
  if (type === "1") {
    for (let i = 0; i < data.length; i++) {
      if (data[i].totalCount > 0) arr.push(data[i].totalCount);
    }
    let seriesDataGraph = [
      {
        name: "Onboard Ac's device",
        data: arr,
      },
    ];
    return seriesDataGraph;
  }
   else if (type === "3") {
    for (let i = 0; i < data.length; i++) {
      if (data[i].users > 0) arr.push(data[i].users);
    }
    let seriesDataGraph = [
      {
        name: "Onboard Ac users device",
        data: arr,
      },
    ];
    return seriesDataGraph;
  } else if (type === "11") {
    for (let i = 0; i < data.length; i++) {
      if (data[i].totalCount > 0) arr.push(data[i].totalCount);
    }
    let seriesDataGraph = [
      {
        name: "Onboard Ac's device",
        data: arr,
      },
    ];
    return seriesDataGraph;
  }else if (type === "12") {
    for (let i = 0; i < data.length; i++) {
      if (data[i].totalCount > 0) arr.push(data[i].totalCount);
    }
    let seriesDataGraph = [
      {
        name: "Offboard Ac's device",
        data: arr,
      },
    ];
    return seriesDataGraph;
  }else if (type === "13") {
    for (let i = 0; i < data.length; i++) {
      arr.push(data[i].averageDuration.toFixed(2));
    }
    let seriesDataGraph = [
      {
        name: "Mqtt time Duration",
        data: arr,
      },
    ];
    return seriesDataGraph;
  } else return [];
};

export const seriesXaxisData = (data, graphType) => { 
  let arr = [];
  for (let i = 0; i < data.length; i++) {
    if (graphType === "1") {
      if(data[i].totalCount > 0)  arr.push(data[i].date);
    } else if (graphType === "3") {
      if (data[i].users > 0) arr.push(data[i].date);
    } else if ( graphType === "13") {
       arr.push(data[i].date);
    }else if (graphType === "11" || graphType === "12" || graphType === "14" || graphType === "2") {
      if (data[i].totalCount > 0) arr.push(data[i].date);
   }
  }
  return arr;
};

export const totalDevices = (data, type) => {
  let count = 0;
  if (type === "1" || type === "2" || type === "8" ) {
    for (let i = 0; i < data.length; i++) {
      count = count + data[i].deviceCount;
    }
  } else if (type === "3" || type === "7") {
    for (let i = 0; i < data.length; i++) {
      count = count + data[i].users;
    }
  }

  return count;
};

export const initial_filter = {
  "an": true,
  "ios": true,
  "ava": true,
  "rem": true,
  "gva": true
}
export const initial_cat_filter = {
  ODMFRONTLOADWM: true,
  REFRIGERATOR: true,
  SWITCHES: true,
  FANCONTROLLER: true,
  ANCHORFAN: true,
  VDP: true,
  PLUG: true,
};
export const initialCatFilterVal = (arr) => {
  return arr.reduce((a, v) => ({ ...a, [v]: true}), {}) 
};
let timeLabel = ["averageDuration", "totalDuration"]
let device = ["an","ios","rem","gva","ava"];
let catDevice = ["ODMFRONTLOADWM","REFRIGERATOR","SWITCHES","FANCONTROLLER","ANCHORFAN","VDP","PLUG","ROMASWITCHES"]
export const mqttSeriesData = (arr) => {
  let seriesDataGraph = []
  if(arr.length){
    for(let i = 0 ; i < 2 ; i ++) {
      let diviceData = []
      for(let j = 0 ; j < arr.length ; j++){
        diviceData.push(arr[j][timeLabel[i]]?arr[j][timeLabel[i]].toFixed(1):0)
      }
      let obj = {
        name:timeLabel[i],
        data:diviceData
      }
      seriesDataGraph.push(obj)
    }
    return seriesDataGraph;
  }
  return []

}
export const catogariesSeriesData = (arr) => {
  let seriesDataGraph = []
  if(arr.length){
    for(let i = 0 ; i < 8 ; i ++) {
      let diviceData = []
      for(let j = 0 ; j < arr.length ; j++){
        if(arr[j]?.totalCount > 0 ) {
        diviceData.push(arr[j]?.deviceCategories[catDevice[i]]?arr[j]?.deviceCategories[catDevice[i]]:0)}
      }
      let obj = {
        name:catDevice[i],
        data:diviceData
      }
      seriesDataGraph.push(obj)
    }
    return seriesDataGraph;
  }
  return []

}
export const controllerSeriesData = (arr) => {
  let seriesDataGraph = []
  if(arr.length){
    for(let i = 0 ; i < 5 ; i ++) {
      let diviceData = []
      for(let j = 0 ; j < arr.length ; j++){

        diviceData.push(arr[j]?.deviceControllers[device[i]]?arr[j]?.deviceControllers[device[i]]:0)
      }
      let obj = {
        name:device[i],
        data:diviceData
      }
      seriesDataGraph.push(obj)
    }
    return seriesDataGraph;
  }
  return []

}

export const controllerX_Data = (data) => {
  let x_data = [];
  if(data.length > 0) {
    for(let i = 0 ; i < data.length ; i++) {
      x_data.push(data[i].date)
    }
    return x_data;
  }
  return []
}

export const filterArr = (data,filter) => {
  let filterData = []
   if(data.length > 0) {
      filterData = data.filter((obj)=>filter[obj.name])
      return filterData
   }
   return [];
}
export const hours = ['00','01','02','03','04','05','06','07','08','09','10','11','12','13','14','15','16','17','18','19','20','21','22','23']
export const month_val = ["JANUARY","FEBRUARY","MARCH","APRIL","MAY","JUNE","JULY","AUGUST","SEPTEMBER","OCTOBER","NOVEMBER","DECEMBER"]
export const offBoardData = (res) => {
  let data = [];
  if(res.length){
    if(res[0].dateType === "monthly"){
    for (let index = 0; index < month_val.length; index++) {
      let obj = res.find(o => o.date === month_val[index]);
      if(obj) {
        data.push(obj)
      }   
    }}
    else return res;
    return data;
  }
return data;
}
export const time_filter_data = (data, filter) => {
  let seriesDataGraph = []
  let objArr = []
  if(data.length > 0 ){
    for(let i = 0 ; i< hours.length ; i++) {
      seriesDataGraph.push(data[0]["usersActiveHourly"][hours[i]]?data[0]["usersActiveHourly"][hours[i]]:0)
    }
    objArr = [{
      name:"Devices",
      data:seriesDataGraph
      }
    ]
    return objArr;
  }
  return []
}



export const modalWiseSeriesData = (data,filter) => {
  const filter_keys = Object.keys(filter) 
  if(filter_keys.length){
  let seriesDataGraph = []
  for(let i = 0 ; i < filter_keys.length ; i++) {
    if(filter[filter_keys[i]]) {
      let modalData = []
      for (let index = 0; index < data.length; index++) {
        modalData.push(data[index]?.modelCategories[filter_keys[i]]?data[index]?.modelCategories[filter_keys[i]]:0)
      }
      let obj = {
        name:filter_keys[i],
        data:modalData
      }
      seriesDataGraph.push(obj)
    }
  }
  return seriesDataGraph;
  }
  return []
}

export const groupUserSeries = (data) => {
  let groupArr = [];
  let objArr = []
  if(data.length) {
    for (let i = 0; i < data.length; i++) {
        groupArr.push(data[i]["users"])
    }
    objArr = [{
      name:"Users",
      data:groupArr
      }
    ]

    return objArr;
  }
  return []
}
export const groupUserX_data = (data) => {
  let x_arr = [];
  if(data.length) {
    for (let i = 0; i < data.length; i++) {
      x_arr.push(data[i]["name"])
  }
  return x_arr;
  }
  return x_arr;
  
}

export const totalFilterCount = (data) => {
  let count = 0 ;
  if(data.length > 0 ) {
    for(let i = 0 ; i < data.length ; i++) {
      count = count +  data[i].data.reduce((a, c) => parseInt(a) + parseInt(c), 0);
    }
    return count;

  }
    return count;
}

export const seriesPieData = (data,category) => {
  let array = [];
  if(data.length > 0 ) {
  let tempArr = data.filter((firmware)=>firmware.category === category);
  for(let i = 0 ; i < tempArr.length ; i++) {
    array.push(tempArr[i].count)
  }
  return array;
}
return array;
}

export const filterFirmwareData = (data,category) => {
  let array = [];
  if(data.length > 0 && category.length > 0 ) {
    for(let i = 0 ; i < category.length ; i++) {
      let tempArr = data.filter((firmware)=>firmware.category === category[i]);
      array = [...array,...tempArr]
    }
  return array;
}
return array;
}


export const labelPieData = (data,category) => {
  let array = [];
  if(data.length > 0 ) {
  let tempArr = data.filter((firmware)=>firmware.category === category);
  for(let i = 0 ; i < tempArr.length ; i++) {
    array.push(`version ${tempArr[i].firmwareVersion}`)
  }
  return array;
}
return array;
}

export const series_x_data = (array) => {
  const data = JSON.parse(JSON.stringify(array))
  let arr = [];
  for(let i = 0 ; i < data.length ; i++) {
    let tempArr = Object.keys(data[i].mqttKeysCount)
    arr.push(...tempArr)
  }
  return [...new Set(arr)]
}

export const mqttKeysSeriesData = (data,keys_val) => {
  if(keys_val.length){
  let seriesDataGraph = []
  for(let i = 0 ; i < keys_val.length ; i++) {
      let modalData = []
      for (let index = 0; index < data.length; index++) {
        modalData.push(data[index]?.mqttKeysCount[keys_val[i]]?data[index]?.mqttKeysCount[keys_val[i]]:0)
      }
      let obj = {
        name:keys_val[i],
        data:modalData
      }
      seriesDataGraph.push(obj)
  }
  return seriesDataGraph;


  }
  return []
}

export const colour = ["#a1469e", "#83c7d5", "#02a699","#9E7777","#fee251","#77dd77","#836953","#89cff0","#99c5c4","#9adedb","#aa9499","#aaf0d1","#b2fba5","#b39eb5","#befd73","#c1c6fc","#fdfd96","#ff9899"]

