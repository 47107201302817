import React, { useEffect, useState } from "react";
import M from "materialize-css/dist/js/materialize.min.js";
import OnboardOffboardAGM from "../../Common/Metrics/OnboardOffboardAGM";
import OnboardAcUsers from "../../Common/Metrics/OnboardAcUsers";
import OnboardAcDevice from "../../Common/Metrics/OnboardAcDevice";
import DevicesControlled from "../../Common/Metrics/DevicesControlled";
import GroupingUser from "../../Common/Metrics/GroupingUser";
import DeviceGeoLocation from "../../Common/Metrics/DeviceGeoLocation";
import FirmWareChart from "../../Common/Metrics/FirmWareChart";
import OffboardDevice from "../../Common/Metrics/OffboardDevice";
import ModalwiseOnboard from "../../Common/Metrics/ModalwiseOnboard";
import HourlyActiveDevice from "../../Common/Metrics/HourlyActiveDevice";
import OffboardAcDevice from "../../Common/Metrics/OffboardAcDevice";
import ActiveTimeDevice from './ActiveTimeDevice';
import Alert from "../../layout/Alert";
import { setAlert } from "../../../action/alert";
import { getOnBoardDevices, ResetGraphData, getOnBoardAcUser,getOffBoardDevices,getOffBoardAcDevices,getHourlyDevices,getModelwiseOnBoardDevices, getControllerDevices, getGeoLocationDevices, getOnBoardAcDevices } from "../../../action/metrics";
import { connect } from "react-redux";
import Filter from "./Filter";
import CheckFilter from "./CheckFilter";
import {totalDevices,initial_filter,initialCatFilterVal} from '../../Common/Metrics/GraphParameter';
import DeviceFilter from './DeviceFilter';
import VersionDeviceCatogery from "./VersionDeviceCatogery";
import UserControlled from "../../Common/Metrics/UserControlled";
import MqttChart from "../../Common/Metrics/MqttChart";
import MqttUsedKeysChart from "../../Common/Metrics/MqttUsedKeysChart";


function MetricsChart({allowedDeviceCategories, setCustomAlert, getOnBoardDevices,getOffBoardDevices,getOffBoardAcDevices,getHourlyDevices, onBordedDevices,getModelwiseOnBoardDevices, ResetGraphData, onBordedAcUsers, getOnBoardAcUser, deviceList, offBordedDevices, modelwiseOnboardDevices, groupUsers, getControllerDevices,getOnBoardAcDevices , getGeoLocationDevices}) {
  const [selectedFilter, setSelectedFilter] = useState("1");
  const [selectedGraph, setSelectedGraph] = useState("1");
  const [activeFilter, setActiveFilter] = useState(["once","twice","thrice","weekly"]);
  const [totalUserCount, setTotalUserCount] = useState(0)
  const [deviceCount, setDeviceCount] = useState(0)
  const [controllerDevicesFilter, setControllerDevicesFilter] = useState(initial_filter)
  const [devicesCatogaryFilter, setDevicesCatogaryFilter] = useState(initialCatFilterVal(allowedDeviceCategories))
  const [modalFilterType, setModalFilterType] = useState({})
  const [devicesCatogary, setDevicesCatogary] = useState("AC")
  const [TimeFilterType, setTimeFilterType] = useState({})
  const [deviceversion, setSelecteddevice] = useState("AC")

  useEffect(() => {
    M.AutoInit();
  }, []);

  useEffect(()=>{
    
    if(onBordedDevices.length) setDeviceCount(totalDevices(onBordedDevices,selectedGraph))
    else if(onBordedAcUsers?.data?.length){
      setDeviceCount(totalDevices(onBordedAcUsers?.data,selectedGraph));
      setTotalUserCount(onBordedAcUsers?.totalRegisteredUser)
    } 
    // else if(offBordedDevices.length) setDeviceCount(totalDevices(offBordedDevices,selectedGraph))
    else if(modelwiseOnboardDevices.length) setDeviceCount(totalDevices(modelwiseOnboardDevices,selectedGraph))
    else if(groupUsers.length)setDeviceCount(totalDevices(groupUsers,selectedGraph))

  },[onBordedDevices, onBordedAcUsers, offBordedDevices, modelwiseOnboardDevices,groupUsers])


  const diffDays = (start, end) => {
    const date1 = new Date(start);
    const date2 = new Date(end);
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  };

  const formateDate = (dateFilter) => {
      if(dateFilter) {
      const dateVal = dateFilter.getDate() < 10 ? `0${dateFilter.getDate()}` : dateFilter.getDate()
      const monthVal = dateFilter.getMonth() < 9 ? `0${dateFilter.getMonth()+1}` : dateFilter.getMonth()+1
      return `${dateFilter.getFullYear()}-${monthVal}-${dateVal}`
    }
  }

  const ActiveDeviceFilter = (startDate) => {
      if(startDate){
      const que= {
        "dateType":"daily",
        "startDate":formateDate(startDate),
      }
      if(selectedGraph === "10" || selectedGraph === "4") getControllerDevices(que) 
      else setCustomAlert("Dta not found");
     // else getOnBoardAcUser(que)
    }
  
  };

  const ActiveGeolocation = (startDate) => {
    if(startDate){
    const que= {
      "onDate":formateDate(startDate),
    }
    if(selectedGraph === "5") getGeoLocationDevices(que) 
    else setCustomAlert("Dta not found");
   // else getOnBoardAcUser(que)
  }

};
  
  const handleDataFilter = (startDate, endDate) => {
    const days = diffDays(startDate, endDate);
    if (days > 90) {
      setCustomAlert("The date range cannot exceed 3 months.");
    } else if(startDate > endDate) {
      setCustomAlert("End date must be after start date.");
    }
    else{
      if(startDate && endDate){
      const que= {
        "startDate":formateDate(startDate),
        "endDate":formateDate(endDate),
        "dateType":'Date Range'
      }
      if(selectedGraph === "1") getOnBoardDevices(que)
      else if (selectedGraph === "11") getOnBoardAcDevices(que)
      else if (selectedGraph === "12") getOffBoardAcDevices(que)
      else if (selectedGraph === "2") getOffBoardDevices(que)
      else if (selectedGraph === "8") {
        const que= {
          "startDate":formateDate(startDate),
          "endDate":formateDate(endDate),
          "devicesCatogary":devicesCatogary,
          "type":selectedFilter
        }
        getModelwiseOnBoardDevices(que)}
      else getOnBoardAcUser(que)
    }
    else{
      setCustomAlert("Start date and end date can not be empty.");
    }
    } 
  };
  const handleGetGraphDetail = (event) => {
    ResetGraphData();
    setSelectedGraph(event.target.value);
    setSelectedFilter("1")
    setDeviceCount('0');
    setDevicesCatogary("AC")
    setSelecteddevice("AC")
  };

  const handleUserCount = (user) => {
    // setUserCount(user)
  }

  const handleControllerDevice = (obj) => {
    setControllerDevicesFilter(obj)
  }
  const handleDeviceCatogary = (obj) => {
    setDevicesCatogaryFilter(obj)
  }
  const handleDateHourFilter = (date) => {
    const que= {
      "startDate":formateDate(date)
    }
    getHourlyDevices(que)

  }

  return (
    <div className="right-content-wraper">
      <div className="container">
        <div className="content-block col bottom-shadow p-15">
          <div className="row reset-device-block mb-0">
            <div className="s6 col">
              <div className="page-title">
                <h3>Metrics Chart</h3>
              </div>
            </div>

            <div className="s6 col">
              <div className="control-capabilities">
                <div className="model-srch reset full-w">
                  <label className="field-name mb-0">Graph Type</label>
                  <select
                    className="custom-select select-reset radius-20"
                    name="searchBy"
                    onChange={handleGetGraphDetail}
                  >
                    <option value="1">Onboard Statistics - Others </option>
                    <option value="11">Onboard Statistics - AC  </option>
                    <option value="2">Offboard Statistics - Others </option>
                    <option value="12">Offboard Statistics - AC </option>
                    <option value="3">User Registration and Onboard Count  </option>
                    <option value="4">Device Count controlled by controllers</option>
                    <option value="10">User Count controlled by controllers</option>
                    <option value="5">Device and User Count by Geographical distribution</option>
                    <option value="6">Firmware distribution Analytics </option>
                    <option value="7">Usage Frequency</option>
                    <option value="8">Onboarded device Count by Model Number  </option>
                    <option value="9">Hourly Device Count  </option>
                    <option value="13"> Mqtt Time Duration   </option>
                    <option value="14"> Frequently Used Funtion Based on Mqtt   </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Alert />
        <div className="content-block col bottom-shadow mb-0 p-15">
        {(selectedGraph === "1" || selectedGraph === "3" || selectedGraph === "2" || selectedGraph === "8" || selectedGraph === "11" ||  selectedGraph === "12" ) && <Filter
              title = {selectedGraph === "1"?"Onboarded Device Count":(selectedGraph === "3"? "Onboarded User Count":(selectedGraph === "2"? "Offboard Device Count":(selectedGraph === "8"? "Onboarded Device Count":(selectedGraph === "11" ? "Onboarded  AC Count" : "Offboarded  AC Count"))))}
              handleDataFilter={handleDataFilter}
              setSelectedFilter={setSelectedFilter}
              selectedFilter={selectedFilter}
              deviceCount = {deviceCount}
              selectedGraph = {selectedGraph}
              handleDeviceCatogary = {handleDeviceCatogary}
              setModalFilterType = {setModalFilterType}
              setDevicesCatogary = {setDevicesCatogary}
              totalUserCount = {onBordedAcUsers?.totalRegisteredUser}
            />}
            {selectedGraph === "7" && <CheckFilter activeFilter = {activeFilter} setActiveFilter = {setActiveFilter} deviceCount = {deviceCount} />}
            {selectedGraph === "4" && <DeviceFilter title = {"Active Device Count"} deviceCount = {deviceCount} handleControllerDevice = {handleControllerDevice} deviceList = {deviceList} ActiveDeviceFilter={ActiveDeviceFilter} selectedGraph = {selectedGraph} />}
            {selectedGraph === "10" && <DeviceFilter title = {"Active User Count"} deviceCount = {deviceCount} handleControllerDevice = {handleControllerDevice} deviceList = {deviceList}  ActiveDeviceFilter={ActiveDeviceFilter} selectedGraph = {selectedGraph}  />}

            {selectedGraph === "6" && <VersionDeviceCatogery allowedDeviceCategories = {allowedDeviceCategories}  setSelecteddevice = {setSelecteddevice} deviceCount = {deviceCount} title = {"Version Count"}  />}
            {selectedGraph === "9" && <ActiveTimeDevice handleDateHourFilter = {handleDateHourFilter} setTimeFilterType = {setTimeFilterType} deviceCount = {deviceCount} title = {'Active Device Count'}   />}
            <div className="chart-wrap pt-20 min-h-250">
            {selectedGraph === "1" && <OnboardOffboardAGM wrapperClass="" type={selectedFilter} graphType = {selectedGraph} devicesCatogaryFilter = {devicesCatogaryFilter} setDeviceCount = {setDeviceCount} />}
            {selectedGraph === "7" && <GroupingUser activeFilter = {activeFilter} setDeviceCount = {setDeviceCount} />}
            {selectedGraph === "3" && <OnboardAcUsers wrapperClass="" type={selectedFilter} graphType = {selectedGraph} setDeviceCount = {setDeviceCount}/>}
            {selectedGraph === "4" && <DevicesControlled wrapperClass="" setDeviceCount = {setDeviceCount} type={selectedFilter} graphType = {selectedGraph} controllerDevicesFilter = {controllerDevicesFilter} />}
            {selectedGraph === "10" && <UserControlled wrapperClass="" setDeviceCount = {setDeviceCount} type={selectedFilter} graphType = {selectedGraph} controllerDevicesFilter = {controllerDevicesFilter} />}
            {selectedGraph === "5" && <DeviceGeoLocation formateDate = {formateDate}  selectedGraph = {selectedGraph} ActiveGeolocation = {ActiveGeolocation}  />}
            {selectedGraph === "6" && <FirmWareChart setDeviceCount = {setDeviceCount} deviceversion = {deviceversion} graphType = {selectedGraph} controllerDevicesFilter = {controllerDevicesFilter} />}
            {selectedGraph === "2" && <OffboardDevice type={selectedFilter} graphType = {selectedGraph} devicesCatogaryFilter = {devicesCatogaryFilter} setDeviceCount = {setDeviceCount}  />}
            {selectedGraph === "8" && <ModalwiseOnboard modalFilterType = {modalFilterType} devicesCatogary = {devicesCatogary} setDeviceCount = {setDeviceCount} selectedFilter = {selectedFilter} />}
            {selectedGraph === "9" && <HourlyActiveDevice setDeviceCount = {setDeviceCount} formateDate = {formateDate} TimeFilterType = {TimeFilterType} />}
            {selectedGraph === "11" && <OnboardAcDevice wrapperClass="" type={selectedFilter} graphType = {selectedGraph} setDeviceCount = {setDeviceCount} />}
            {selectedGraph === "12" && <OffboardAcDevice wrapperClass="" type={selectedFilter} graphType = {selectedGraph} setDeviceCount = {setDeviceCount} />}
            {selectedGraph === "13" && <MqttChart formateDate = {formateDate}  selectedGraph = {selectedGraph}  />}
            {selectedGraph === "14" && <MqttUsedKeysChart formateDate = {formateDate}  selectedGraph = {selectedGraph}  />}


          </div>
          
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) =>{
  return{
    onBordedDevices:state.metrics.onBordedDevices, 
    offBordedDevices:state.metrics.offBordedDevices, 
    onBordedAcUsers:state.metrics.onBordedAcUsers,
    deviceList:state.metrics.deviceList,
    modelwiseOnboardDevices:state.metrics.modelwiseOnboardDevices,   
    groupUsers:state.metrics.groupUsers,
    allowedDeviceCategories:state.auth.allowedDeviceCategories,

  }
}
const mapDispatchToProps = (dispatch) => ({
  setCustomAlert: (message) => dispatch(setAlert(message, "warning")),
  getOnBoardDevices: (req) => dispatch(getOnBoardDevices(req)),
  getOnBoardAcUser: (req) => dispatch(getOnBoardAcUser(req)),
  getControllerDevices : (req) => dispatch (getControllerDevices(req)),
  getGeoLocationDevices : (req) => dispatch (getGeoLocationDevices(req)),
  getOnBoardAcDevices : (req) => dispatch (getOnBoardAcDevices(req)),
  getModelwiseOnBoardDevices : (req) => dispatch (getModelwiseOnBoardDevices(req)),
  getHourlyDevices : (req) => dispatch (getHourlyDevices(req)),
  getOffBoardDevices : (req) => dispatch (getOffBoardDevices(req)),
  getOffBoardAcDevices : (req) => dispatch (getOffBoardAcDevices(req)),
  ResetGraphData: () => dispatch(ResetGraphData())

});

export default connect(mapStateToProps, mapDispatchToProps)(MetricsChart);
