import React, { Fragment, useState, useContext, useEffect } from "react";
import { connect } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import Moment from "react-moment";
import { setAlert } from "./../../action/alert";
import { getDeviceDACLogsData } from "../../action/devicelogs";
import Alert from "../layout/Alert";
import './DeviceDACLogs.css'

const DeviceDACLogs = ({
    deviceDetails,
    setCustomAlert
}) => {

    const [deviceDACLogsData, setDeviceDACLogsData] = useState([]);
    const [updateFlag, setUpdateFlag] = useState(undefined);
    const [isLoading, setIsLoading] = useState(false);
    const headers = {
        serialNumber: "S.No",
        timestamp: 'Timestamp'
    };

    // useEffect(() => {
    //     filter()
    // }, [])

    useEffect(() => {
        if (deviceDetails && deviceDetails instanceof Object && deviceDetails.hasOwnProperty('macAddress')) {
            getDeviceDACLogs()
        }
    }, [deviceDetails])

    useEffect(() => {
        if (updateFlag) {
            getDeviceDACLogs()
        }
    }, [updateFlag])

    const filter = () => {
        setUpdateFlag(Math.random());
    }

    const getDeviceDACLogs = () => {
        setIsLoading(true)
        getDeviceDACLogsData(deviceDetails?.macAddress ?? "")
            .then((res) => {
                setIsLoading(false);
                setDeviceDACLogsData(res)
            })
            .catch((err) => {
                setIsLoading(false);
                setDeviceDACLogsData([])
                setCustomAlert("Error while fetching device DAC logs data.", "danger");
            });
    }

    if (isLoading) {
        return (
            <div
                style={{
                    display: "grid",
                    placeItems: "center",
                    height: "80vh",
                }}
            >
                <CircularProgress size="5em" style={{ color: "#AFB42B" }} />
            </div>
        );
    }

    return (
        <Fragment>
            <Alert/>
            <div className="content-block bottom-shadow py-15 mb-30">
                <div className="row mb-0">
                    <div className="col s12">
                        <div
                            className="table-wrapper mb-20"
                            style={{
                                scrollBehavior: "auto",
                                maxHeight: "58vh",
                            }}
                        >
                            <table
                                width="100%"
                                align="center"
                                className="table style-4 mb-20 deviceDACLogsTable"
                            >
                                <thead
                                    style={{ position: "sticky", top: 0, zIndex: 50, background: "white" }}
                                >
                                    <tr>
                                        {Object.keys(headers).map((key) => (
                                            <th>{headers[key]}</th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {deviceDACLogsData && deviceDACLogsData.length === 0 ? (
                                        <tr>
                                            <td colSpan={Object.keys(headers).length}>
                                                No Data found
                                            </td>
                                        </tr>
                                    ) : (
                                        deviceDACLogsData && deviceDACLogsData.length > 0 && deviceDACLogsData.map((element, index) => {
                                            return (
                                                <tr key={index} id={index}>
                                                    {Object.keys(headers).map((key) => {
                                                        return (
                                                            key == "serialNumber" ?
                                                                <td className={String(element[index]).length > 60 ? "breakWord" : ""}>
                                                                    {index + 1}
                                                                </td>
                                                                :
                                                                <td className={String(element[index]).length > 200 ? "breakWord" : ""}>
                                                                    {element ? <Moment
                                                                        format="DD-MM-YYYY HH:mm:ss"
                                                                        date={element}
                                                                    /> : ""}
                                                                </td>
                                                        );
                                                    })}
                                                </tr>
                                            );
                                        })
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = (dispatch) => ({
    setCustomAlert: (message, alertType) => dispatch(setAlert(message, alertType))
})

export default connect(mapStateToProps, mapDispatchToProps)(DeviceDACLogs);
