import uuid from "uuid";
import { SET_ALERT, REMOVE_ALERT } from "./types";

let isVisible = false;
let timerID;
export const setAlert = (msg, alertType) => (dispatch) => {
  const id = uuid.v4();
  if (isVisible) {
    return;
  }
  isVisible = true;
  startTimer();
  dispatch({
    type: SET_ALERT,
    payload: { msg, alertType, id },
  });

  function startTimer() {
    timerID = setInterval(() => clearTimer(), 3000);
  }

  function clearTimer() {
    setTimeout(() =>
      dispatch({
        type: REMOVE_ALERT,
        payload: id,
      })
    );
    isVisible = false;
    clearInterval(timerID);
  }

  // setTimeout(() => dispatch({
  //   type: REMOVE_ALERT,
  //   payload: id
  // }),
  //   timeout,
  //   isVisible = false
  // );
};
