import axios from "axios";
import { setAlert } from "./alert";
import customConfig from "../config";
import {
    UPLOAD_DEVICE_FACTORY_FIRMWARE,
    UPLOAD_DEVICE_FACTORY_FIRMWARE_SUCCESS,
    UPLOAD_DEVICE_FACTORY_FIRMWARE_ERROR,
    UPLOAD_DEVICE_FOTA_FIRMWARE,
    UPLOAD_DEVICE_FOTA_FIRMWARE_SUCCESS,
    UPLOAD_DEVICE_FOTA_FIRMWARE_ERROR,
    UPLOAD_DEVICE_FOTA_FIRMWARE_INIT,
    SEND_FOTA_MQTT_TRIGGER,
    SEND_FOTA_MQTT_TRIGGER_SUCCESS,
    SEND_FOTA_MQTT_TRIGGER_FAILURE,
    RESET_FOTA_MQTT_TRIGGER_STATUS,
    GET_FOTA_BATCH_DATA,
    GET_FOTA_BATCH_DATA_ERROR,
    GET_FOTA_BATCH_DATA_LOADING,
    DELETE_FOTA_BATCH,
    DELETE_FOTA_BATCH_ERROR,
} from "./types";
import env from "../env";

export const intiFOTAFirmwareStatusInfo = () => async (dispatch) => {
    dispatch({
        type: UPLOAD_DEVICE_FOTA_FIRMWARE_INIT
    })
}

export const uploadDeviceFactoryFirmware = (formData) => async (dispatch) => {
    try {
        dispatch({
            type: UPLOAD_DEVICE_FACTORY_FIRMWARE,
        });

        const config = {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        };

        const res = await axios.post(
            `${env.GET_FOTA_API_URL()}/simplifi/v1/fota/firmware`, formData, config);


        if (res && res.data && res.data[0].status != "CREATED") {
            dispatch({
                type: UPLOAD_DEVICE_FACTORY_FIRMWARE_ERROR,
                payload: {},
            });

            dispatch(setAlert(res?.data?.[0]?.message ?? "Sucessfully Uploaded the Device Factory Firmware", "danger"));
        }
        else {
            dispatch({
                type: UPLOAD_DEVICE_FACTORY_FIRMWARE_SUCCESS,
                payload: {},
            });

            dispatch(setAlert("Sucessfully Uploaded the Device Factory Firmware", "success"));
        }

        window.scrollTo(0, 0);

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message ?? "Failed to Upload the Factory Firmware", "danger"));
        window.scrollTo(0, 0);
        dispatch({
            type: UPLOAD_DEVICE_FACTORY_FIRMWARE_ERROR,
            payload: {},
        });
    }
};

export const getDeviceFactoryFirmwareData = (params) => {

    const config = {
        params: params
    }

    return new Promise((resolve, reject) => {
        axios
            .get(
                `${env.GET_FOTA_API_URL()}/simplifi/v1/fota/device/statistics/factory`,
                config
            )
            .then((res) => resolve(res.data))
            .catch((err) => reject(err));
    });
};

export const downloadDeviceFactoryFirmwareFile = (params, fileType) => async (dispatch) => {
    try {
        let { fileName, ...args } = params
        const config = {
            params: args
        };

        const res = await axios.get(
            `${env.GET_FOTA_API_URL()}/simplifi/v1/fota/firmware/download/factory`,
            config
        );

        console.log(JSON.stringify(res))
        console.log(res)

        let url = ""
        if (fileType == "firmwareImage") {
            url = res.data["url"]
        }
        else {
            url = res.data["signatureUrl"]
        }

        const link = document.createElement('a');
        link.href = url;
        document.body.appendChild(link);
        link.click();

        dispatch(setAlert("Successfully Downloaded the Factory Firmware", "success"))

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message ?? "Failed to Download the Factory Firmware", "danger"))
    }
};

export const getDeviceModelNumbersList = (brandName, categoryName) => {
    const config = {
        headers: {
            "Content-Type": "application/json",
        },
        params: {
            "brandName": brandName,
            "categoryName": categoryName
        }
    }

    return new Promise((resolve, reject) => {
        axios
            .get(
                `${env.GET_DEVICEMANAGEMENT_API_URL()}/simplifi/v1/deviceManagement/deviceModels/modelNumbers`,
                config
            )
            .then((res) => resolve(res.data))
            .catch((err) => reject(err));
    });
}

export const uploadDeviceFOTAFirmware = (formData) => async (dispatch) => {
    try {
        dispatch({
            type: UPLOAD_DEVICE_FOTA_FIRMWARE,
        });

        const config = {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        };

        const res = await axios.post(
            `${env.GET_FOTA_API_URL()}/simplifi/v1/fota/firmware`, formData, config);

        if (res && res.data && res.data[0].status != "CREATED") {
            dispatch({
                type: UPLOAD_DEVICE_FOTA_FIRMWARE_ERROR,
                payload: {},
            });

            dispatch(setAlert(res?.data?.[0]?.message ?? "Sucessfully Uploaded the FOTA Firmware", "danger"));
        }
        else {
            dispatch({
                type: UPLOAD_DEVICE_FOTA_FIRMWARE_SUCCESS,
                payload: {}
            });

            dispatch(setAlert("Sucessfully Uploaded the Device FOTA Firmware", "success"));
        }

        window.scrollTo(0, 0);

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message ?? "Failed to Upload the FOTA Firmware", "danger"));
        window.scrollTo(0, 0);
        dispatch({
            type: UPLOAD_DEVICE_FOTA_FIRMWARE_ERROR,
            payload: {},
        });
    }
};

export const getDeviceFOTAFirmwareData = (params) => {
    let filteredParams = {};
    if (params) {
        Object.keys(params).forEach((key) => {
            filteredParams[key] = params[key];
        });
    }
    return new Promise((resolve, reject) => {
        axios
            .get(
                `${env.GET_FOTA_API_URL()}/simplifi/v1/fota/device/statistics/fota`,
                {
                    params: filteredParams,
                }
            )
            .then((res) => resolve(res.data))
            .catch((err) => reject(err));
    });
};

export const downloadDeviceFotaFirmwareFile = (params, fileType) => async (dispatch) => {
    try {
        const { fileName, ...args } = params
        const config = {
            params: args
        };

        const res = await axios.get(
            `${env.GET_FOTA_API_URL()}/simplifi/v1/fota/firmware/download/fota`,
            config
        );

        let url = ""
        if (fileType == "firmwareImage") {
            url = res.data["url"]
        }
        else {
            url = res.data["signatureUrl"]
        }

        const link = document.createElement('a');
        link.href = url;
        document.body.appendChild(link);
        link.click();

        dispatch(setAlert("Successfully Downloaded the FOTA Firmware", "success"))

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message ?? "Failed to Download the Factory Firmware", "danger"))
    }
};

export const sendFOTAMQTTTrigger = (body) => async (dispatch) => {
    try {
        dispatch({
            type: SEND_FOTA_MQTT_TRIGGER,
        });
        const config = {
            headers: {
                "Content-Type": "application/json",
            }
        };
        const res = await axios.post(
            `${env.GET_FOTA_API_URL()}/simplifi/v1/firmwareupgrade/devices/upgrade`, body, config);
        dispatch({
            type: SEND_FOTA_MQTT_TRIGGER_SUCCESS,
            payload: {},
        });
        dispatch(setAlert(res?.data?.message ?? "Sucessfully Sent FOTA MQTT Trigger", "success"));
    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message ?? "Failed to Send the FOTA MQTT Trigger", "danger"))
        dispatch({
            type: SEND_FOTA_MQTT_TRIGGER_FAILURE,
            payload: {},
        });
    }
}
export const resetFOTAMQTTTriggerStatus = () => async (dispatch) => {
    dispatch({
        type: RESET_FOTA_MQTT_TRIGGER_STATUS
    })
}

export const getFOTABatchList = (params) => async (dispatch) => {
    try {
        dispatch({ type: GET_FOTA_BATCH_DATA_LOADING })
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
            params: params
        };
        const res = await axios.get(
            `${env.GET_FOTA_API_URL()}/simplifi/v1/firmwareupgrade/batch/list`,
            config
        )
        dispatch({ type: GET_FOTA_BATCH_DATA, payload: res.data })

    } catch (err) {
        dispatch({
            type: GET_FOTA_BATCH_DATA_ERROR,
            payload: {
                msg: err.response
                    ? err.response.data && err.response.data.message
                        ? err.response.data.message
                        : err.response.statusText
                    : null,
                status: err.response ? err.response.status : null,
            },
        });
    }
};

export const deleteFOTABatchById = (params) => async (dispatch) => {
    try {
        const config = {
            params: params
        };
        const res = await axios.delete(
            `${env.GET_FOTA_API_URL()}/simplifi/v1/firmwareupgrade/batch/deleteById`,
            config
        );
        dispatch({ type: DELETE_FOTA_BATCH })
        dispatch(setAlert("Batch Deleted Successfully", "success"));

    } catch (err) {
        let errMsg = err.response
            ? err.response.data && err.response.data.message
                ? err.response.data.message
                : err.response.statusText
            : customConfig.serverDownMsg;
        dispatch(setAlert(errMsg, "danger"))
        dispatch({ type: DELETE_FOTA_BATCH_ERROR })
    }
};


export const downloadFirmwareUpgradeReportFile = (batchId) => async (dispatch) => {
    try {
        const config = {
            params: {
                batchId: batchId
            },
            responseType: 'blob', // important
        };
        const res = await axios.get(
            `${env.GET_FOTA_API_URL()}/simplifi/v1/firmwareupgrade/batch/downloadReport`,
            config
        );
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `MQTTStatusReport.xlsx`);
        document.body.appendChild(link);
        link.click();
        dispatch(setAlert("Successfully Downloaded Firmware Upgrade Report", "success"))

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message ?? "Failed to Download Firmware Upgrade Report", "danger"))
    }
}

export const getUpgradableDeviceCountInfo = (params) => {
    const config = {
        params: params
    }

    return new Promise((resolve, reject) => {
        axios
            .get(
                `${env.GET_FOTA_API_URL()}/simplifi/v1/firmwareupgrade/fota/upgrade/deviceCount`,
                config
            )
            .then((res) => resolve(res.data))
            .catch((err) => reject(err));
    });
}