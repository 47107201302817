import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import customConfig from "../../../config";

const useStyles = makeStyles({
    labelColor: {
        color: "#000"
    },
    selectInput: {
        height: "3rem",
        width: "100%"
    },
    capabilityListDropdown: {
        marginTop: "20px",
        marginBottom: "20px"
    },
    addCapabilityButton: {
        marginTop: "45px",
        marginBottom: "20px"
    },
    capabilityCard: {
        marginBottom: "15px",
        backgroundColor: "#f2f2f2"
    },
    attributeKey: {
        marginRight: '20px'
    },
    attributeValues: {
        width: "100%"
    },
    deleteCapabilityCardButton: {
        float: "right"
    },
    customValueKey: {
        marginLeft: "20px !important"
    }
});

const DeviceModelControlCapability = ({
    productCategory,
    isDeviceModelAddedUsingFileUpload,
    editDeviceModel,
    deviceModelData,
    triggerAddNewDeviceModel,
    addDeviceControlCapabilties,
    ModelData: { deviceModelsMetadata },
    channelAndSwitchDeviceTypes
}) => {

    const classes = useStyles();
    const [controlCapabiltiesList, setControlCapabiltiesList] = useState(Object.keys(deviceModelsMetadata?.categories?.[productCategory]?.controlCapabilities ?? {}).sort())
    const [selectedControlCapability, setSelectedControlCapability] = useState(Object.keys(deviceModelsMetadata?.categories?.[productCategory]?.controlCapabilities ?? {}).sort()?.[0] ?? "")
    const [addedControlCapabilities, setAddedControlCapabilities] = useState([])
    const [controlCapabilitiesAttributes, setControlCapabilitiesAttributes] = useState({})

    const [controlCapabilitySelectedValueType, setControlCapabilitySelectedValueType] = useState({})

    useEffect(() => {
        if (deviceModelsMetadata?.categories?.[productCategory]?.controlCapabilities ?? null) {

            let controlCapabilities = deviceModelsMetadata.categories[productCategory].controlCapabilities
            for (let capabilityName in controlCapabilities) {
                for (let index in controlCapabilities[capabilityName].attributes) {
                    if (controlCapabilities[capabilityName].attributes[index].valueType instanceof Object &&
                        Array.isArray(controlCapabilities[capabilityName].attributes[index].valueType)
                    ) {
                        controlCapabilitySelectedValueType[controlCapabilities[capabilityName].attributes[index].key] = ""
                    }
                }
            }

            setControlCapabilitySelectedValueType(controlCapabilitySelectedValueType)
        }
    }, [])

    useEffect(() => {
        let ctrlCapabilitiesAttributes = {}

        if (editDeviceModel || isDeviceModelAddedUsingFileUpload) {
            let controlCapabilities = deviceModelData["controlCapabilities"]
            for (let capability of controlCapabilities) {

                let capabilityName = capability['capability']
                ctrlCapabilitiesAttributes[capabilityName] = {}
                let attributes = capability['attributes'];

                ctrlCapabilitiesAttributes[capabilityName]["attributes"] = {}

                for (let index in attributes) {
                    ctrlCapabilitiesAttributes[capabilityName]["attributes"][index] = {}
                    ctrlCapabilitiesAttributes[capabilityName]["attributes"][index]['key'] = attributes[index]['key']

                    if (attributes[index].hasOwnProperty('values')) {
                        ctrlCapabilitiesAttributes[capabilityName]["attributes"][index]['values'] = JSON.parse(JSON.stringify(attributes[index]['values']))
                        ctrlCapabilitiesAttributes[capabilityName]["attributes"][index]['customValue'] = ''

                        controlCapabilitySelectedValueType[attributes[index]['key']] = "values"
                    }
                    if (attributes[index].hasOwnProperty('intValues')) {
                        ctrlCapabilitiesAttributes[capabilityName]["attributes"][index]['intValues'] = JSON.parse(JSON.stringify(attributes[index]['intValues']))
                        ctrlCapabilitiesAttributes[capabilityName]["attributes"][index]['customValue'] = ''

                        controlCapabilitySelectedValueType[attributes[index]['key']] = "intValues"
                    }
                    if (attributes[index].hasOwnProperty('range')) {
                        ctrlCapabilitiesAttributes[capabilityName]["attributes"][index]['range'] = JSON.parse(JSON.stringify(attributes[index]["range"]))
                    
                        controlCapabilitySelectedValueType[attributes[index]['key']] = "range"
                    }
                }
            }
        }

        if (deviceModelsMetadata?.categories?.[productCategory]?.controlCapabilities) {
            let controlCapabilities = deviceModelsMetadata.categories[productCategory].controlCapabilities

            for (let capabilityName in controlCapabilities) {

                if (ctrlCapabilitiesAttributes.hasOwnProperty(capabilityName)) {
                    continue;
                }

                ctrlCapabilitiesAttributes[capabilityName] = {}
                let attributes = controlCapabilities[capabilityName]['attributes'];

                ctrlCapabilitiesAttributes[capabilityName]["attributes"] = {}

                for (let index in attributes) {
                    ctrlCapabilitiesAttributes[capabilityName]["attributes"][index] = {}
                    ctrlCapabilitiesAttributes[capabilityName]["attributes"][index]['key'] = attributes[index]['key'] ? attributes[index]['key'] : ""

                    if (attributes[index].valueType == 'values') {
                        ctrlCapabilitiesAttributes[capabilityName]["attributes"][index]['values'] = []
                    }

                    if (attributes[index].valueType == 'intValues') {
                        ctrlCapabilitiesAttributes[capabilityName]["attributes"][index]['intValues'] = []
                    }

                    if (attributes[index].valueType == 'range') {
                        ctrlCapabilitiesAttributes[capabilityName]["attributes"][index]['range'] = JSON.parse(JSON.stringify(attributes[index]["range"]))
                    }

                    if (attributes[index].valueType instanceof Object && Array.isArray(attributes[index].valueType)) {

                        for (let valueIndex in attributes[index].valueType) {
                            let valueName = attributes[index].valueType[valueIndex]

                            if (valueName == 'values') {
                                ctrlCapabilitiesAttributes[capabilityName]["attributes"][index][valueName] = []
                            }
        
                            if (valueName == 'intValues') {
                                ctrlCapabilitiesAttributes[capabilityName]["attributes"][index][valueName] = []
                            }
        
                            if (valueName == 'range') {
                                ctrlCapabilitiesAttributes[capabilityName]["attributes"][index][valueName] = JSON.parse(JSON.stringify(attributes[index][valueName]))
                            }
                        }
                    }
                }
            }
        }
        setControlCapabilitiesAttributes(ctrlCapabilitiesAttributes)

    }, [productCategory, editDeviceModel, isDeviceModelAddedUsingFileUpload])

    useEffect(() => {
        if (editDeviceModel || isDeviceModelAddedUsingFileUpload) {
            let addedControlCapabilitiesList = []
            for (let controlCapability of deviceModelData['controlCapabilities']) {
                addedControlCapabilitiesList.push(controlCapability['capability'])
            }

            setAddedControlCapabilities(addedControlCapabilitiesList)
            setControlCapabiltiesList((prevState) => {
                return prevState.filter((capabilityName) => !(addedControlCapabilitiesList.includes(capabilityName))).sort()
            })
        }
        else {
            setControlCapabiltiesList(deviceModelsMetadata["categories"][productCategory] ? Object.keys(deviceModelsMetadata["categories"][productCategory]["controlCapabilities"]) : [])
            setAddedControlCapabilities([])
        }

    }, [productCategory, editDeviceModel, isDeviceModelAddedUsingFileUpload])

    useEffect(() => {
        let nextSelectedCapability = ""
        if (controlCapabiltiesList && controlCapabiltiesList.length > 0) {
            nextSelectedCapability = controlCapabiltiesList[0]
        }
        setSelectedControlCapability(nextSelectedCapability)
    }, [controlCapabiltiesList])


    useEffect(() => {
        if (triggerAddNewDeviceModel) {
            let controlCapabilitiesData = getControlCapabilityData();
            addDeviceControlCapabilties(controlCapabilitiesData)
        }
    }, [triggerAddNewDeviceModel])

    function handleSelectControlCapability(selectedCapability) {
        setSelectedControlCapability(selectedCapability)
    }

    function handleAddControlCapability(addedCapabilityName) {
        setAddedControlCapabilities((prevState) => {
            return [...prevState, addedCapabilityName]
        })

        setControlCapabiltiesList((prevState) => {
            return prevState.filter((capabilityName) => capabilityName != selectedControlCapability).sort()
        })
    }

    function handleRemoveControlCapability(removedCapabilityName) {
        setAddedControlCapabilities((prevState) => {
            return prevState.filter((capabilityName) => capabilityName != removedCapabilityName).sort()
        })

        setControlCapabiltiesList((prevState) => {
            return [...prevState, removedCapabilityName]
        })
    }

    function handleControlCapabilitiesAttributes(capabilityAttributes) {
        setControlCapabilitiesAttributes(capabilityAttributes)
    }

    function onChangeAttributeKeyName(capabilityName, attributeIndex, keyName) {
        let capabilityAttributes = { ...controlCapabilitiesAttributes }
        capabilityAttributes[capabilityName]["attributes"][attributeIndex]["key"] = keyName

        handleControlCapabilitiesAttributes(capabilityAttributes)
    }

    function onChangeAttributeValues(capabilityName, attributeIndex, values) {
        let capabilityAttributes = { ...controlCapabilitiesAttributes }

        let customValue = capabilityAttributes[capabilityName]["attributes"][attributeIndex]['customValue']
        if (customValue && customValue.length != 0) {
            values.push(customValue)
        }

        capabilityAttributes[capabilityName]["attributes"][attributeIndex]["values"] = values

        handleControlCapabilitiesAttributes(capabilityAttributes)
    }

    function onChangeAttributeIntValues(capabilityName, attributeIndex, values) {
        let capabilityAttributes = { ...controlCapabilitiesAttributes }

        capabilityAttributes[capabilityName]["attributes"][attributeIndex]["intValues"] = values

        handleControlCapabilitiesAttributes(capabilityAttributes)
    }

    function onChangeAttributeCustomValue(capabilityName, attributeIndex, value) {
        let capabilityAttributes = { ...controlCapabilitiesAttributes }

        capabilityAttributes[capabilityName]["attributes"][attributeIndex]["customValue"] = value

        handleControlCapabilitiesAttributes(capabilityAttributes)
    }

    function onChangeAttributeRangeValue(capabilityName, attributeIndex, rangeName, rangeValueIndex, value) {
        let capabilityAttributes = { ...controlCapabilitiesAttributes }
        capabilityAttributes[capabilityName]["attributes"][attributeIndex]['range'][rangeName][rangeValueIndex]['value'] = value

        handleControlCapabilitiesAttributes(capabilityAttributes)
    }

    function onChangeAttributeRangeUnit(capabilityName, attributeIndex, rangeName, rangeValueIndex, uintName) {
        let capabilityAttributes = { ...controlCapabilitiesAttributes }
        capabilityAttributes[capabilityName]["attributes"][attributeIndex]['range'][rangeName][rangeValueIndex]['unit'] = uintName

        handleControlCapabilitiesAttributes(capabilityAttributes)
    }

    function getControlCapabilitySupportedDeviceTypes(capabilityName) {
        let capability = deviceModelsMetadata['categories'][productCategory]['controlCapabilities'][capabilityName];

        if (capability.hasOwnProperty('supportedDeviceTypes')) {
            let supportedDeviceTypes = capability['supportedDeviceTypes']

            return supportedDeviceTypes.filter((deviceType) => {
                return channelAndSwitchDeviceTypes.includes(deviceType) != false
            })
        }
        else {
            return channelAndSwitchDeviceTypes;
        }
    }

    function getControlCapabilityData() {
        let controlCapabilities = []
        let uniqueKeys = {};

        for (let capabilityName of addedControlCapabilities) {
            let capabilityData = {}
            capabilityData['capability'] = capabilityName
            capabilityData['attributes'] = []

            if (productCategory == customConfig.deviceCategorySWITCHES) {
                capabilityData['devices'] = getControlCapabilitySupportedDeviceTypes(capabilityName)
            }

            let attributes = controlCapabilitiesAttributes[capabilityName]['attributes'];
            for (let index in attributes) {
                let attributeData = {}
                attributeData['key'] = attributes[index]['key']

                // if (uniqueKeys.hasOwnProperty(attributes[index]['key'])) {
                //     controlCapabilities = {}
                //     controlCapabilities['error'] = 'Control Capabilities Cannot Have the Same Key Name'
                //     return controlCapabilities;
                // }

                uniqueKeys[attributes[index]['key']] = "";

                if (attributes[index].hasOwnProperty('values')) {
                    attributeData['values'] = attributes[index]['values']

                    let customValue = attributes[index]['customValue']
                    if (customValue && customValue.length != 0 && !isNaN(customValue)) {
                        attributeData['values'].push(customValue)
                    }
                }
                else if (attributes[index].hasOwnProperty('intValues')) {
                    attributeData['intValues'] = attributes[index]['intValues']

                    let customValue = attributes[index]['customValue']
                    if (customValue && customValue.length != 0 && !isNaN(customValue)) {
                        attributeData['intValues'].push(parseInt(customValue))
                    }
                }
                else if (attributes[index].hasOwnProperty('range')) {
                    attributeData['range'] = attributes[index]['range']
                }

                capabilityData['attributes'].push(attributeData)
            }

            controlCapabilities.push(capabilityData)
        }

        return controlCapabilities;
    }

    function onChangeControlCapabilitySelectedValueType(capabilityName, key, valueTypeSelected) {

        let previousSelectedValueType = controlCapabilitySelectedValueType

        setControlCapabilitySelectedValueType((prevState) => {
            return {
                ...prevState,
                [key]: valueTypeSelected
            }
        })

        let capabilityAttributes = { ...controlCapabilitiesAttributes }

        // get information from metdata
        let metadataAttributes = deviceModelsMetadata['categories'][productCategory]['controlCapabilities'][capabilityName]['attributes']
        for (let index in metadataAttributes) {

            if (metadataAttributes[index].valueType instanceof Object && Array.isArray(metadataAttributes[index].valueType)
            ) {
                for (let attr in capabilityAttributes[capabilityName]["attributes"][index]) {

                    // delete those unnecessary attributes
                    if (!['key', valueTypeSelected].includes(attr)) {
                        delete capabilityAttributes[capabilityName]["attributes"][index][attr];
                    }
                }
            }
        }

        // add those attributes which are not part of capability attributes that are deleted earlier
        for (let index in capabilityAttributes[capabilityName]["attributes"]) {
            if (!capabilityAttributes[capabilityName]["attributes"][index].hasOwnProperty(valueTypeSelected)) {

                for (let metaAttrIndex in metadataAttributes) {

                    if (metadataAttributes[metaAttrIndex].hasOwnProperty(valueTypeSelected)) {

                        capabilityAttributes[capabilityName]["attributes"][index][valueTypeSelected] =
                            metadataAttributes[metaAttrIndex][valueTypeSelected]

                        break
                    }
                }
            }
        }
        setControlCapabilitiesAttributes(capabilityAttributes)
    }

    return (
        <Fragment>
            <div className="row">
                <div className={`s4 col ${classes.capabilityListDropdown}`}>
                    <label className="title d-inline  min-180 mb-5 mt-20">Select Capability</label>
                    <Select
                        value={selectedControlCapability}
                        onChange={(e) => {
                            handleSelectControlCapability(e.target.value)
                        }}
                        displayEmpty
                        input={<OutlinedInput />}
                        inputProps={{ 'aria-label': 'Without label' }}
                        className={classes.selectInput}
                    >
                        {
                            controlCapabiltiesList?.sort().map((capabilityName) => {
                                return <MenuItem key={capabilityName} value={capabilityName}>{capabilityName}</MenuItem>
                            })
                        }
                    </Select>
                </div>
                <div className={`s4 col`}>
                    <button
                        className={`btn btn-style-2 orange-bg mr-5 ${classes.addCapabilityButton}`}
                        onClick={() => {
                            handleAddControlCapability(selectedControlCapability)
                        }}
                        disabled={controlCapabiltiesList && controlCapabiltiesList.length == 0}
                    >
                        Add Capability
                    </button>
                </div>
            </div>
            {
                addedControlCapabilities.map((capabilityName) => {
                    return <Fragment key={productCategory + '_' + capabilityName}>
                        <Card variant="outlined" className={`${classes.capabilityCard}`} key={capabilityName}>
                            <CardContent>
                                <Grid container xs={12}>
                                    <Grid item xs={11}>
                                        <Typography variant="button">{capabilityName}</Typography>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <IconButton aria-label="delete" onClick={(e) => {
                                            handleRemoveControlCapability(capabilityName)
                                        }} className={`${classes.deleteCapabilityCardButton}`}>
                                            <DeleteIcon fontSize="small" />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                                {deviceModelsMetadata?.categories?.[productCategory]?.controlCapabilities?.[capabilityName]?.attributes?.map((element, index) => {
                                    return <Grid key={capabilityName + '_' + index} container>
                                        <Grid item xs={1}>
                                            <Typography>Attribute</Typography>
                                        </Grid>
                                        <Grid item xs={2} className={classes.attributeKey}>
                                            <input
                                                placeholder="Enter Key Name"
                                                className="add-input xl"
                                                value={controlCapabilitiesAttributes[capabilityName].attributes[index].key}
                                                type="text"
                                                disabled={true}
                                                onChange={(e) => {
                                                    onChangeAttributeKeyName(capabilityName, index, e.target.value)
                                                }}
                                            />
                                        </Grid>
                                        {
                                            element.valueType === 'values' &&
                                            <Fragment>
                                                <Grid item xs={4}>
                                                    <FormControl variant="outlined" className={classes.attributeValues}>
                                                        <InputLabel id="select-outlined-label-values">Values</InputLabel>
                                                        <Select
                                                            labelId="select-outlined-label-values"
                                                            id="select-outlined-values-id"
                                                            value={controlCapabilitiesAttributes[capabilityName].attributes[index].values}
                                                            multiple
                                                            onChange={(e) => {
                                                                onChangeAttributeValues(capabilityName, index, e.target.value)
                                                            }}
                                                            label="Values"
                                                            renderValue={(selected) => selected.join(', ')}
                                                            disabled={element.editable == false ? true : false}
                                                            className={classes.selectInput}
                                                        >
                                                            {element.values.map((listVal) => (
                                                                <MenuItem key={listVal} value={listVal}>
                                                                    <Checkbox checked={controlCapabilitiesAttributes[capabilityName].attributes[index].values.indexOf(listVal) > -1} />
                                                                    <ListItemText primary={listVal} />
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                {
                                                    element.addCustomValue == true && <Grid item xs={4}>
                                                        <input
                                                            placeholder="Enter New Value (if any)"
                                                            className={`add-input xl ${classes.customValueKey}`}
                                                            value={controlCapabilitiesAttributes[capabilityName].attributes[index].customValue}
                                                            type="text"
                                                            onChange={(e) => {
                                                                onChangeAttributeCustomValue(capabilityName, index, e.target.value)
                                                            }}
                                                        />
                                                    </Grid>
                                                }
                                            </Fragment>
                                        }
                                        {
                                            element.valueType === 'intValues' &&
                                            <Fragment>
                                                <Grid item xs={4}>
                                                    <FormControl variant="outlined" className={classes.attributeValues}>
                                                        <InputLabel id="select-outlined-label-intValues">Values</InputLabel>
                                                        <Select
                                                            labelId="select-outlined-label-intValues"
                                                            id="select-outlined-intValues-id"
                                                            value={controlCapabilitiesAttributes[capabilityName].attributes[index].intValues}
                                                            multiple
                                                            onChange={(e) => {
                                                                onChangeAttributeIntValues(capabilityName, index, e.target.value)
                                                            }}
                                                            label="Values"
                                                            renderValue={(selected) => selected.join(', ')}
                                                            disabled={element.editable == false ? true : false}
                                                            className={classes.selectInput}
                                                        >
                                                            {element.intValues.map((value) => (
                                                                <MenuItem key={value} value={value}>
                                                                    <Checkbox checked={controlCapabilitiesAttributes[capabilityName].attributes[index].intValues.indexOf(value) > -1} />
                                                                    <ListItemText primary={value} />
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                {
                                                    element.addCustomValue == true && <Grid item xs={4}>
                                                        <input
                                                            placeholder="Enter New Value (if any)"
                                                            className={`add-input xl ${classes.customValueKey}`}
                                                            //style={{marginLeft: "20px"}}
                                                            value={controlCapabilitiesAttributes[capabilityName].attributes[index].customValue}
                                                            type="text"
                                                            onChange={(e) => {
                                                                onChangeAttributeCustomValue(capabilityName, index, e.target.value)
                                                            }}
                                                        />
                                                    </Grid>
                                                }
                                            </Fragment>
                                        }
                                        {
                                            element.valueType === 'range' &&
                                            <Fragment>
                                                <Grid container xs={6}>
                                                    <Typography>Range</Typography>
                                                    {
                                                        Object.keys(element.range).map((rangeName) => {
                                                            return <Grid container xs={12}>
                                                                <Grid item xs={12}>
                                                                    <Typography>{rangeName}</Typography>
                                                                </Grid>
                                                                <Grid container xs={12}>
                                                                    {
                                                                        element.range[rangeName].map((rangeValue, rangeValueIndex) => {
                                                                            return <Fragment>
                                                                                <Grid item xs={4} className={classes.attributeKey}>
                                                                                    <input
                                                                                        placeholder="Enter Value"
                                                                                        className="add-input xl"
                                                                                        value={controlCapabilitiesAttributes[capabilityName].attributes[index].range[rangeName][rangeValueIndex].value}
                                                                                        type="text"
                                                                                        onChange={(e) => {
                                                                                            onChangeAttributeRangeValue(capabilityName, index, rangeName, rangeValueIndex, e.target.value)
                                                                                        }}
                                                                                        disabled={element.editable == false ? true : false}
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item xs={4} className={classes.attributeKey}>
                                                                                    {
                                                                                        rangeValue.hasOwnProperty('unit') &&

                                                                                        <input
                                                                                            placeholder="Enter Uint"
                                                                                            className="add-input xl"
                                                                                            value={controlCapabilitiesAttributes[capabilityName].attributes[index].range[rangeName][rangeValueIndex].unit}
                                                                                            type="text"
                                                                                            onChange={(e) => {
                                                                                                onChangeAttributeRangeUnit(capabilityName, index, rangeName, rangeValueIndex, e.target.value)
                                                                                            }}
                                                                                            disabled={element.editable == false ? true : false}
                                                                                        />
                                                                                    }
                                                                                </Grid>
                                                                            </Fragment>
                                                                        })
                                                                    }
                                                                </Grid>
                                                            </Grid>
                                                        })
                                                    }
                                                </Grid>
                                            </Fragment>
                                        }
                                        {
                                            (element.valueType instanceof Object && Array.isArray(element.valueType)) &&
                                            <Fragment>
                                                <Grid item xs={4}>
                                                    <FormControl variant="outlined" className={classes.attributeValues}>
                                                        <InputLabel id="select-outlined-label-values-types-array">Value Type</InputLabel>
                                                        <Select
                                                            labelId="select-outlined-label-values-types-array"
                                                            id="select-outlined-values-id-values-types-array"
                                                            value={controlCapabilitySelectedValueType[element.key]}
                                                            onChange={(e) => {
                                                                onChangeControlCapabilitySelectedValueType(capabilityName, element.key, e.target.value)
                                                            }}
                                                            label="Value Type"
                                                            className={classes.selectInput}
                                                        >
                                                            {element.valueType.map((valueTypeElement, index) => (
                                                                <MenuItem key={valueTypeElement} value={valueTypeElement}>
                                                                    <ListItemText primary={valueTypeElement} />
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                {
                                                    controlCapabilitySelectedValueType[element.key] == 'intValues' &&
                                                    <Grid item xs={4} style={{ marginLeft: "20px" }}>
                                                        <FormControl variant="outlined" className={classes.attributeValues}>
                                                            <InputLabel id="select-outlined-label-intValues">Values</InputLabel>
                                                            <Select
                                                                labelId="select-outlined-label-intValues"
                                                                id="select-outlined-intValues-id"
                                                                value={controlCapabilitiesAttributes[capabilityName].attributes[index].intValues}
                                                                multiple
                                                                onChange={(e) => {
                                                                    onChangeAttributeIntValues(capabilityName, index, e.target.value)
                                                                }}
                                                                label="Values"
                                                                renderValue={(selected) => selected.join(', ')}
                                                                disabled={element.editable == false ? true : false}
                                                                className={classes.selectInput}
                                                            >
                                                                {element.intValues.map((value) => (
                                                                    <MenuItem key={value} value={value}>
                                                                        <Checkbox checked={controlCapabilitiesAttributes[capabilityName].attributes[index].intValues.indexOf(value) > -1} />
                                                                        <ListItemText primary={value} />
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                }
                                                {
                                                    controlCapabilitySelectedValueType[element.key] == 'range' && <Fragment>
                                                        <Grid container xs={4} style={{ marginLeft: '20px' }}>
                                                            <Typography>Range</Typography>
                                                            {
                                                                Object.keys(element.range).map((rangeName) => {
                                                                    return <Grid container xs={12}>
                                                                        <Grid item xs={12}>
                                                                            <Typography>{rangeName}</Typography>
                                                                        </Grid>
                                                                        <Grid container xs={12}>
                                                                            {
                                                                                element.range[rangeName].map((rangeValue, rangeValueIndex) => {
                                                                                    return <Fragment>
                                                                                        <Grid item xs={4} className={classes.attributeKey}>
                                                                                            <input
                                                                                                placeholder="Enter Value"
                                                                                                className="add-input xl"
                                                                                                value={controlCapabilitiesAttributes[capabilityName].attributes[index].range[rangeName][rangeValueIndex].value}
                                                                                                type="text"
                                                                                                onChange={(e) => {
                                                                                                    onChangeAttributeRangeValue(capabilityName, index, rangeName, rangeValueIndex, e.target.value)
                                                                                                }}
                                                                                                disabled={element.editable == false ? true : false}
                                                                                            />
                                                                                        </Grid>
                                                                                        <Grid item xs={4} className={classes.attributeKey}>
                                                                                            {
                                                                                                rangeValue.hasOwnProperty('unit') &&

                                                                                                <input
                                                                                                    placeholder="Enter Uint"
                                                                                                    className="add-input xl"
                                                                                                    value={controlCapabilitiesAttributes[capabilityName].attributes[index].range[rangeName][rangeValueIndex].unit}
                                                                                                    type="text"
                                                                                                    onChange={(e) => {
                                                                                                        onChangeAttributeRangeUnit(capabilityName, index, rangeName, rangeValueIndex, e.target.value)
                                                                                                    }}
                                                                                                    disabled={element.editable == false ? true : false}
                                                                                                />
                                                                                            }
                                                                                        </Grid>
                                                                                    </Fragment>
                                                                                })
                                                                            }
                                                                        </Grid>
                                                                    </Grid>
                                                                })
                                                            }
                                                        </Grid>
                                                    </Fragment>
                                                }
                                            </Fragment>
                                        }
                                    </Grid>
                                })}
                            </CardContent>
                        </Card>
                    </Fragment>
                })
            }
        </Fragment >
    )
}

const mapStateToProps = (state) => ({
    ModelData: state.getModel
});

export default connect(mapStateToProps)(DeviceModelControlCapability);