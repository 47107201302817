import React, { Fragment } from "react";

const DiagnosticData = ({ diagnosticData, deviceID, modelNumber }) => {
  return (
    <Fragment>
      <div className="popup-content blue-modal">
        <div className="block-top">
          <div className="block-title style-2 center-align  py-25">
            <h3 className="mb-0">Diagnostics Data</h3>
          </div>
          <div className="valign-wrapper info-reset justify-content-center lite-bg">
            <div className="d-inline-flex pr-20">
              <label className="title">Device ID:</label>
              <div className="device-info">{deviceID}</div>
            </div>

            <div className="d-inline-flex pl-20">
              <label className="title">Model Number:</label>
              <div className="device-info">{modelNumber}</div>
            </div>
          </div>
        </div>

        <div className="value-wrap">
          <div className="value-title bottom-gray-border pl-50">
            <div className="row mb-10">
              <div className="s9 col">
                <h6 className="orange-text">Parameter</h6>
              </div>
              <div className="s3 col">
                <h6 className="orange-text">Value</h6>
              </div>
            </div>
          </div>
          <div className="vertical-wrapper mb-10">
            <div className="value-list pl-50 py-10 min-200 position-relative">
              <div className="vertical-block-tile">
                <span>Indoor Unit Side</span>
              </div>
              {/* <span className="logo-water-mark"></span> */}

              <div className="row mb-10">
                <div className="s9 col">Indoor Room Air Temperature</div>
                <div className="s3 col">
                  {diagnosticData.Tr_i}
                  <span className="gray-text">°C</span>
                </div>
              </div>

              <div className="row mb-10">
                <div className="s9 col">Evaporator Pipe Temperature</div>
                <div className="s3 col">
                  {diagnosticData.Te_i}
                  <span className="gray-text">°C</span>
                </div>
              </div>

              <div className="row mb-10">
                <div className="s9 col">Set Temperature by User</div>
                <div className="s3 col">
                  {diagnosticData.Tu_i}
                  <span className="gray-text">°C</span>
                </div>
              </div>

              <div className="row mb-10">
                <div className="s9 col">IDU Fan Speed , 0..6</div>
                <div className="s3 col">
                  {diagnosticData.Flvl_i}
                  <span className="gray-text"></span>
                </div>
              </div>

              <div className="row mb-10">
                <div className="s9 col">RPM of IDU Fan Motor</div>
                <div className="s3 col">
                  {diagnosticData.Frpm_i}
                  <span className="gray-text"></span>
                </div>
              </div>

              <div className="row mb-10">
                <div className="s9 col">Indoor PM2.5 Sensor Reading</div>
                <div className="s3 col">
                  {diagnosticData.PM25_i}
                  <span className="gray-text">ug/m3</span>
                </div>
              </div>

              <div className="row mb-10">
                <div className="s9 col">Filter Status</div>
                <div className="s3 col">
                  {diagnosticData.Fsta_i}
                  <span className="gray-text"></span>
                </div>
              </div>
            </div>

            <div className="value-list pl-50 py-10 position-relative min-200 top-gray-border">
              <div className="vertical-block-tile">
                <span>Outdoor Unit Side</span>
              </div>
              <div className="row mb-10">
                <div className="s9 col">Suction Pipe Temperature</div>
                <div className="s3 col">
                  {diagnosticData.Tsu_o}
                  <span className="gray-text">°C</span>
                </div>
              </div>

              <div className="row mb-10">
                <div className="s9 col">Outdoor Air Temperature</div>
                <div className="s3 col">
                  {diagnosticData.Ta_o}
                  <span className="gray-text">°C</span>
                </div>
              </div>

              <div className="row mb-10">
                <div className="s9 col">Condenser Pipe Temperature</div>
                <div className="s3 col">
                  {diagnosticData.Tc_o}
                  <span className="gray-text">°C</span>
                </div>
              </div>

              <div className="row mb-10">
                <div className="s9 col">Compressor Top Pipe Temperature</div>
                <div className="s3 col">
                  {diagnosticData.Td_o}
                  <span className="gray-text">°C</span>
                </div>
              </div>

              <div className="row mb-10">
                <div className="s9 col">Outdoor Fan Speed in RPM</div>
                <div className="s3 col">
                  {diagnosticData.Frpm_o}
                  <span className="gray-text">RPM</span>
                </div>
              </div>

              <div className="row mb-10">
                <div className="s9 col">
                  DC Current of U,V,W Phase to compressor
                </div>
                <div className="s3 col">
                  {diagnosticData.Pcur_o}
                  <span className="gray-text">Ampere</span>
                </div>
              </div>

              <div className="row mb-10">
                <div className="s9 col">DC Voltage of Power rail</div>
                <div className="s3 col">
                  {diagnosticData.DCv_o}
                  <span className="gray-text">Volts</span>
                </div>
              </div>

              <div className="row mb-10">
                <div className="s9 col">
                  Total Input AC Current Taken by Outdoor Unit
                </div>
                <div className="s3 col">
                  {diagnosticData.ACc_o}
                  <span className="gray-text">Ampere</span>
                </div>
              </div>

              <div className="row mb-10">
                <div className="s9 col">
                  AC Voltage available at Input Power Supply
                </div>
                <div className="s3 col">
                  {diagnosticData.ACv_o}
                  <span className="gray-text">Volts</span>
                </div>
              </div>

              <div className="row mb-10">
                <div className="s9 col">
                  Temperature of IPM (Driving Circuit of Compressor)
                </div>
                <div className="s3 col">
                  {diagnosticData.Tipm_o}
                  <span className="gray-text">°C</span>
                </div>
              </div>
            </div>

            <div className="value-list pl-50 py-10 position-relative min-200 top-gray-border">
              <div className="vertical-block-tile">
                <span>Compressor RPM</span>
              </div>
              <div className="row mb-10">
                <div className="s9 col">
                  The target RPM of Compressor decided by Algorithm
                </div>
                <div className="s3 col">
                  {diagnosticData.Trpm_c}
                  <span className="gray-text"></span>
                </div>
              </div>

              <div className="row mb-10">
                <div className="s9 col">
                  The RPM of Compressor set as per current situation
                </div>
                <div className="s3 col">
                  {diagnosticData.Srpm_c}
                  <span className="gray-text"></span>
                </div>
              </div>

              <div className="row mb-10">
                <div className="s9 col">
                  Realtime RPM of Compressor on which it is Running
                </div>
                <div className="s3 col">
                  {diagnosticData.Rrpm_c}
                  <span className="gray-text">RPM</span>
                </div>
              </div>
            </div>

            <div className="value-list pl-50 py-10 position-relative min-200 top-gray-border">
              <div className="vertical-block-tile">
                <span>Abnormal Operation</span>
              </div>
              <div className="row mb-10">
                <div className="s9 col">
                  Reason due to Which RPM of Comp. has been Limited
                </div>
                <div className="s3 col">
                  {diagnosticData.Lrpm_a}
                  <span className="gray-text">Byte</span>
                </div>
              </div>

              <div className="row mb-10">
                <div className="s9 col">Fault types</div>
                <div className="s3 col">
                  {diagnosticData.Falt_a}
                  <span className="gray-text"></span>
                </div>
              </div>

              <div className="row mb-10">
                <div className="s9 col">
                  Temporary Protections due to which the Unit has stopped
                  Working
                </div>
                <div className="s3 col">
                  {diagnosticData.Prot_a}
                  <span className="gray-text"></span>
                </div>
              </div>

              <div className="row mb-10">
                <div className="s9 col">Total Power On Time in Seconds</div>
                <div className="s3 col">
                  {diagnosticData.POn_t}
                  <span className="gray-text"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default DiagnosticData;
