import React, { Fragment } from "react";

const NotFound = () => {
  return (
    <Fragment>
      <div className="right-content-wraper">
        <div className="container">
          <div className="no-results-wrap"></div>
          <div className="row mb-0">
            <div className="s12 p-0 col">
              <div className="content-block">
                <div className="no-results d-flex min-height">
                  <div className="not-found-text">
                    <h3>Page Not Found</h3>
                    {/* <p>Device Id or Model Number does not exist</p> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default NotFound;
