import React, {useEffect,useState} from 'react';
import { connect } from "react-redux";
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
  } from "@material-ui/core";
  import DatePicker from "react-datepicker";
  import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
  import { Checkbox, FormControlLabel } from "@material-ui/core";

function ActiveTimeDevice({setTimeFilterType, hourlyActiveDevices,handleDateHourFilter}) {
  const [startDate, setStartDate] = useState(new Date());

    const [timeFilter, setTimeFilter] = useState({});
    useEffect(() => {
        setTimeFilterType(timeFilter)
    
      }, [timeFilter]);

      useEffect(() => {
        if(hourlyActiveDevices.length) {
          let fil = {};
          let tempArr = ['up_To_1Hour','up_To_2Hour','up_To_6Hour','up_To_12Hour','up_To_24Hour']
          for(let i = 0 ; i < tempArr.length ; i++) {
            fil[tempArr[i]] = true
          }
          setTimeFilter(fil)
    
        }
          }, [hourlyActiveDevices]);
      
      const handleControllerChange = (event) => {
        setTimeFilter({...timeFilter , [event.target.name]:event.target.checked})
      }
      const handleStartDateChange = (date) => {
        setStartDate(date);
      };
      const handleDataFilter = () => {
        handleDateHourFilter(startDate)
      }
    return (
        <div className="container">
      <div className="row ">
        <div className="col s12">
          <div className="content-block mb-0 p-15 radius-6">
            <div className="search-user-wraper d-flex justify-content-between align-items-center">
              <div className="page-title min-180">
              <div className="test_class">
                {/* <Accordion className="model_select">
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>Select Time Interval</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                  {hourlyActiveDevices?.length > 0 &&Object.keys(timeFilter).map((time)=>(
                          <FormControlLabel
                          control={
                            <Checkbox
                              checked={timeFilter[time]}
                              onChange={handleControllerChange}
                              name={time}
                              color="primary"
                            />
                          }
                          label={time}
                        />
                      ))}
                  </AccordionDetails>
                </Accordion> */}
              </div>
              </div>

              <div className="control-capabilities">
                <div className="model-srch reset date__picker">
                <DatePicker
                    selected={startDate}
                    maxDate={new Date()}
                    onChange={handleStartDateChange}
                    placeholderText="Start Date"
                    dateFormat="dd/MM/yyyy"
                    className="add-input calendar input_date"
                  />
                  <button
                className="date_button orange-bg filter_btn w-100"
                onClick={() => handleDataFilter(startDate)}

              >
                Search
              </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    )
}

const mapStateToProps = (state) => {
  return {
    hourlyActiveDevices: state.metrics.hourlyActiveDevices,
  };
};

export default connect(mapStateToProps, {})(ActiveTimeDevice);
