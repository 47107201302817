import React, { useState, useEffect } from 'react'

function Popup({ visible = false, wide = false, notifyPopupClose, children }) {
    const [show, setShow] = useState(visible)
    useEffect(() => {
        setShow(visible);
        if (!show) {
            notifyPopupClose();
        }
    }, [show])
    return (
        show ?
            <div className='ls-popup'>
                <div className={wide ? "modal-reset modal-broad" : "modal-reset"}>
                    <div className="modal-content p-0 pin-top">
                        <button onClick={() => setShow(false)} className="lnkBtn modal-close waves-effect waves-green btn-flat"><i className="material-icons">close</i></button>
                        {children}

                    </div>
                </div>
            </div>
            : null
    );
}

export default Popup
