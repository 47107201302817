import { GET_TENANT_INFO, TENANT_INFO_ERROR } from "../action/types";
const initialState = {
  tenantInfo: {
    auth: {
      baseUrl: process.env.REACT_APP_API_USERMANAGEMENT_URL,
      webClientId: process.env.REACT_APP_CLIENT_ID,
      baseUrlV2: process.env?.REACT_APP_API_USERMANAGEMENT_URL_V2 ?? "",
      webClientIdV2: process.env?.REACT_APP_CLIENT_ID_V2 ?? "",
      webClientIdFlipkartV2: process.env?.REACT_APP_CLIENT_ID_FLIPKART_V2 ?? "",
      webClientIdAmazonV2: process.env?.REACT_APP_CLIENT_ID_AMAZON_V2 ?? ""
    },
    app: {
      baseUrl: process.env.REACT_APP_API_DEVICEMANAGEMENT_URL,
    },
  },
  tenantError: false,
};

export default function Tenant(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_TENANT_INFO:
      return {
        ...state,
        tenantInfo: {...initialState.tenantInfo, ...payload},
        tenantError: false,
      };
    case TENANT_INFO_ERROR:
      return {
        ...state,
        tenantInfo: initialState.tenantInfo,
        tenantError: true,
      };
    default:
      return state;
  }
}
