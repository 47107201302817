import React, {useEffect} from "react";
import { connect } from "react-redux";
import {getModelwiseOnBoardDevices} from '../../../action/metrics';
import {modalWiseSeriesData, controllerX_Data, totalFilterCount} from './GraphParameter';
import Graph from "./Graph";

function ModalwiseOnboard({modelwiseOnboardDevices, getModelwiseOnBoardDevices, modalFilterType, devicesCatogary,setDeviceCount,selectedFilter}) {

  useEffect(()=>{
    if(selectedFilter !== "4") {
      const que= {
        "dataType":selectedFilter === "1" ? "daily" : selectedFilter === "2" ? "weekly" : "monthly",
        // "X-Tenant-ID":'panasonic',
        "devicesCatogary":devicesCatogary
      }
      getModelwiseOnBoardDevices(que)}
      // else {
      //   if(devicesCatogary !== "ac") {
      //     const que= {
      //       "dataType":selectedFilter === "1" ? "daily" : selectedFilter === "2" ? "weekly" : "monthly",
      //       // "X-Tenant-ID":'panasonic',
      //       "devicesCatogary":devicesCatogary
      //     }
      //     getModelwiseOnBoardDevices(que)
      //   }
      // }
},[getModelwiseOnBoardDevices,devicesCatogary,selectedFilter])
const filterArray = modelwiseOnboardDevices.length> 0 ? modalWiseSeriesData(modelwiseOnboardDevices,modalFilterType) : []
    // const filter_Array = filterArr(arr,modalFilterType)
    const intervalData =  modelwiseOnboardDevices.length > 0 ? controllerX_Data(modelwiseOnboardDevices) : []

    // let arr = offBordedDevices.length> 0 ? catogariesSeriesData(offBordedDevices) : []
    let totalCount = totalFilterCount(filterArray)

    useEffect(()=>{
      setDeviceCount(totalCount)
    },[totalCount,modalFilterType])

  return (
    <div>
      <div className="chart_view">
        <Graph
          text={" Devices"}
          x_data={intervalData}
          title={"Number Of onboarded Device "}
          y_data={filterArray}
          stacked = {true}
          color = {["#a1469e", "#83c7d5", "#02a699","#9E7777","#fee251"]}
        />
      </div>
    </div>
  );
}
const mapStateToProps = (state) =>{
  return{
    modelwiseOnboardDevices:state.metrics.modelwiseOnboardDevices     
  }
}

export default connect(mapStateToProps, { getModelwiseOnBoardDevices })(ModalwiseOnboard);













