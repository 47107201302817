import React, { Fragment, useState } from "react";
import DatePicker from "react-datepicker";
import { getQRCodeCountFilter } from "../../action/qrcode";
import Alert from "../layout/Alert";

const QRCodeFilter = ({
    setAlert,
}) => {
    const [startDateFilter, setStartDateFilter] = useState(undefined);
    const [endDateFilter, setEndDateFilter] = useState(undefined);
    const [showQRCodeResult, setShowQRCodeResult] = useState(false)
    const [totalQRCodeCount, setTotalQRCodeCount] = useState(0)

    function handleFilterReset() {
        setStartDateFilter("")
        setEndDateFilter("")
        setShowQRCodeResult(false)
        setTotalQRCodeCount(0)
    }

    function hanldeFilter() {
        if (!startDateFilter || startDateFilter == "") {
            setAlert("Invalid Start Date. Start Date Cannot be Empty", "danger")
            return
        }

        if (!endDateFilter || endDateFilter == "") {
            setAlert("Invalid End Date. End Date Cannot be Empty", "danger")
            return
        }

        let formmattedStartDate = formateDate(startDateFilter)
        let formattedEndDate = formateDate(endDateFilter)

        getQRCodeCountFilter({
            'startTs': formmattedStartDate,
            'endTs': formattedEndDate
        })
        .then((data)=>{
            setShowQRCodeResult(true)
            setTotalQRCodeCount(data.count)
        })
        .catch((err)=>{
            setShowQRCodeResult(false)
            setAlert(err?.response?.data?.message ?? err.message, "danger")
        })
    }

    const formateDate = (dateFilter) => {
        if (dateFilter) {
            const dateVal = dateFilter.getDate() < 10 ? `0${dateFilter.getDate()}` : dateFilter.getDate()
            const monthVal = dateFilter.getMonth() < 9 ? `0${dateFilter.getMonth() + 1}` : dateFilter.getMonth() + 1
            return `${dateVal}-${monthVal}-${dateFilter.getFullYear()}`
        }
    }

    return (
        <Fragment>
            <div className="container">
                <Alert/>
                <div className="content-block bottom-shadow">
                    <div className="py-15 ">
                        <div className="row mb-0">
                            <div className="col">
                                <DatePicker
                                    selected={startDateFilter}
                                    maxDate={new Date()}
                                    onChange={(date) => {
                                        setStartDateFilter(date)
                                    }}
                                    placeholderText="Start Date"
                                    dateFormat='MM/dd/yyyy'
                                    className="add-input calendar input_phone"
                                />
                            </div>
                            <div className="col">
                                <DatePicker
                                    selected={endDateFilter}
                                    maxDate={new Date()}
                                    onChange={(date) => {
                                        setEndDateFilter(date)
                                    }}
                                    placeholderText="End Date"
                                    dateFormat='MM/dd/yyyy'
                                    className="add-input calendar input_phone"
                                />
                            </div>
                            <div className="col pt-10">
                                <button
                                    className="btn btn-style-3  sm  mr-10 font-400"
                                    onClick={hanldeFilter}
                                >
                                    Filter
                                </button>
                                <button
                                    className="btn btn-style-3 sm font-400"
                                    onClick={handleFilterReset}
                                >
                                    Reset
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    showQRCodeResult && <Fragment>
                        <div className="content-block bottom-shadow py-15 mb-30">
                            <div className="row mb-0">
                                <div className="s6 col">
                                    <div className="unique-box mb-15">
                                        <label className="title d-inline  min-180" style={{ fontSize: '14px' }}>Total QR Code Printed</label>
                                        <div className="device-info d-inline">
                                            {totalQRCodeCount}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Fragment>
                }
            </div>
        </Fragment>
    );
}

export default QRCodeFilter