import React from "react";
import ManufacturedDevices from "../../device/ManufacturedDevices";

const ManufacturedDevicesPage = () => {
  return (
    <div className="content-wrapper">
      <ManufacturedDevices />
    </div>
  );
};

export default ManufacturedDevicesPage;
