import axios from "axios";
import env from "../env";

export const getDeviceDACLogsData = (macAddress) => {
    return new Promise((resolve, reject) => {
        axios
            .get(
                `${env.GET_DEVICEMANAGEMENT_API_URL()}/simplifi/v1/certificateManager/dac/${macAddress}/log`
            )
            .then((res) => resolve(res.data))
            .catch((err) => reject(err));
    });
};

export const getDeviceLogsData = (deviceId) => {
    return new Promise((resolve, reject) => {
        axios
            .get(
                `${env.GET_DEVICEMANAGEMENT_API_URL()}/simplifi/v1/deviceservices/device/${deviceId}/logs`
            )
            .then((res) => resolve(res.data))
            .catch((err) => reject(err));
    });
};