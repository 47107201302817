import React, { useEffect, useState } from "react";
import M from "materialize-css/dist/js/materialize.min.js";
import CircularProgress from "@material-ui/core/CircularProgress";
import './notification.css'
import { Fragment } from "react";
import { Box, makeStyles, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { notificationChannelTypes, notificationListPageSize, notificationStatusHeaders } from "./notificationConstant";
import Alert from "../layout/Alert";
import PhoneInput from "react-phone-input-2";
import { format } from "date-fns";

const searchOptions = [{ label: "User ID", value: "userId", placeholder: "Enter User ID" },
{ label: "User E-mail ID", value: 'email', placeholder: "Enter User Email ID" },
{ label: 'User Mobile no', value: "mobile", placeholder: "Enter Mobile no without code" },
{ label: "Task ID", value: "taskId", placeholder: "Enter Task ID" }]

const severityOptions = [
    { label: "None", value: null },
    { label: "Info", value: "info" },
    { label: "Alert", value: 'alert' },
    { label: 'Recommendation', value: "recommendation" },
    { label: "Promotion", value: "promotion" }]

const FindNotification = ({ notificationData = {},
    getNotificationTask = () => { },
    searchNotificationsByUserId = () => { },
    searchNotifications = () => { },
    allowedCountries,
    userData,
    tenantData,
    setDialog,
    setDialogContents,
    setDialogTab
    //setCustomAlert
}) => {

    // add key value pair for any new column to be added to the table. 
    // Key should be same as the key in the response and value should be what you want the header for the column to be
    const notificationHeaders =
    {
        taskId: { label: "Task Id" },
        notificationType: {  label: "Type" },
        sentAt: { label: "Created At" },
        severity: { label: "Severity" },
        action: { label: "Actions", style: { display: 'flex', justifyContent: "flex-end" } }
    }

    const [notificationList, setNotificationList] = useState([]);
    const [updateFlag, setUpdateFlag] = useState(Math.random());
    const [isLoading, setIsLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [paginationStartIndex, setPaginationStartIndex] = useState(0);
    const [lastApiPageFetched, setLastApiPageFetched] = useState(0);
    const [pageEndReached, setPageEndReached] = useState(false);
    const [taskId, setTaskId] = useState({ value: "", textValue: "" });
    const [showNotificationList, setShowNotificationList] = useState(false);
    const [searchBy, setSearchBy] = useState("userId")
    const [countryCode, setCountryCode] = useState("91");
    const [searchStr, setSearchStr] = useState("");
    const [tableHeaders, setTableHeaders] = useState({});
    const [pageIndex, setPageIndex] = useState(1);
    const [severityFilter, setSeverityFilter] = useState(null);

    const pageSize = 20;
    const totalPageCount = Math.ceil(notificationList?.length / pageSize);
    const startIndex = pageSize * currentPage;
    const endIndex = startIndex + pageSize;

    const useStyles = makeStyles({
        "NotificationAnalytics": {
            "& .displayEnd": { display: "flex", justifyContent: "flex-end" },
            "& .search-user": {
                "& .MuiInput-underline:before": { display: 'none' },
                "& .MuiInput-underline:after": { display: 'none' },
                "& .MuiAutocomplete-root": {
                    "& .MuiFormControl-root": {
                        borderTop: "#ccc solid 1px !important",
                        borderBottom: "#ccc solid 1px !important",
                    },
                    "& .MuiInputBase-input": {
                        padding: "0px",
                        height: "38px",
                        width: "152px !important",
                        marginLeft: "8px"
                    },
                    "& .MuiInputBase-input:focus": {
                        borderBottom: "none"
                    }
                }

                // "& .MuiAutocomplete-inputRoot": { border: "none !important" },
                // "& input":{borderBottom:"none !important"}
            },
        },
    });
    const classes = useStyles();

    const searchNotificationData = (pg, severity = null) => {
        switch (searchBy) {
            case "userId":
                searchNotificationsByUserId(searchStr, pg, severity)
                break;
            case "mobile":
            case "email":
                searchNotificationsByUserId(userData?.user?.userId, pg, severity)
                break;
        }
    }

    const getNextPage = () => {
        const newPg = pageIndex + 1
        setPageIndex(newPg);
        searchNotificationData(newPg, severityFilter);
    }

    const getPrevPage = () => {
        const newPg = pageIndex - 1;
        setPageIndex(newPg);
        searchNotificationData(newPg, severityFilter);
    }

    const pageNumbersToShow = () => {
        if (totalPageCount === 0) {
            return [0];
        } else {
            let i = paginationStartIndex;
            let pagesToShow = [];
            while (i < totalPageCount && i < paginationStartIndex + 5) {
                pagesToShow.push(i);
                i += 1;
            }
            return pagesToShow;
        }
    };

    const onSubmit = (e) => {
        e?.preventDefault();
        setShowNotificationList(true);
        setPageIndex(1);

        switch (searchBy) {
            case "userId":
                searchNotificationsByUserId(searchStr)
                break;
            case "taskId":
                getNotificationTask(taskId?.textValue);
                break;
            case "mobile":
                searchNotifications(searchBy, `+${countryCode}${searchStr}`, 1, tenantData?.tenantId, userData?.umV1AuthToken)
                break;
            case "email":
                searchNotifications(searchBy, searchStr, 1, tenantData?.tenantId, userData?.umV1AuthToken)
                break;
        }
    }
    useEffect(() => {
        M.AutoInit();
    }, [showNotificationList]);

    useEffect(() => {
        if (showNotificationList) {
            if (notificationData?.lastAction?.includes("get_notification_task") && taskId?.textValue !== "" && notificationData?.lastAction?.includes(taskId?.textValue)) {
                // dont remove second and third condititon-check after setTimeout call from previous page is executed and get empty data after data is populated in table
                setNotificationList(JSON.stringify(notificationData?.notificationDetails) !== "{}" ? [{
                    fullTaskId: notificationData?.notificationDetails?.taskId,
                    taskId: <div title={notificationData?.notificationDetails?.taskId}>
                        {
                            "..." + notificationData?.notificationDetails?.taskId?.slice(notificationData?.notificationDetails?.taskId?.length - 6, notificationData?.notificationDetails?.taskId?.length)
                        }
                    </div>,
                    userCount: <div title="The expected number of users that will receive the  notification.">
                        {
                            notificationData?.notificationDetails?.status?.notificationProcessStatus?.userSentCount
                        }
                    </div>,
                    expectedUserCount: <div title={"The number of users that received the notification."}>
                        {
                            notificationData?.notificationDetails?.status?.taskProcessStatus?.userCount + notificationData?.notificationDetails?.status?.taskProcessStatus?.userFailedCount
                        }
                    </div>,
                    taskStatus: notificationData?.notificationDetails?.status?.taskStatus,
                    failureCount: <div title="The number of users the notification failed to reach.">
                        {notificationData?.notificationDetails?.status?.notificationProcessStatus?.failedUserSentCount + notificationData?.notificationDetails?.status?.notificationProcessStatus?.tokenNotFoundUserCount + notificationData?.notificationDetails?.status?.taskProcessStatus?.userFailedCount
                        }
                    </div>,
                    notificationType: notificationChannelTypes[notificationData?.notificationDetails?.channel?.type + "Notification"]?.label,
                    severity: notificationData?.notificationDetails?.severity,
                    createdAt: format(new Date(notificationData?.notificationDetails?.creationDate * 1000), "dd MMM yyyy 'AT' hh:mm a"),
                    expiryDate: format(new Date(notificationData?.notificationDetails?.expiry), "dd MMM yyyy 'AT' hh:mm a"),
                    payload: { ...notificationData?.notificationDetails },
                    msg: notificationData?.notificationDetails?.message?.payload,
                    action: (data) => {
                        return <button
                            className="btn btn-style-3 sm font-400"
                            onClick={e => {
                                setDialogContents({ title: "Notification Details", content: "notificationDetails", data })
                                setDialog(true);
                                setDialogTab(0);
                            }}
                        >
                            View
                        </button>
                    }

                }] : [])
            }
            else if (notificationData?.lastAction?.includes("search_notification")) {
                setNotificationList(notificationData?.notificationDetails?.map(n => {
                    return {
                        sentAt: format(new Date(n?.ts * 1000), "dd MMM yyyy 'AT' hh:mm a"),
                        severity: n?.severity,
                        payload: { ...n },
                        taskId: <div title={n?.taskId}>
                            {
                                "..." + n?.taskId?.substring(n?.taskId?.length - 6, n?.taskId?.length)
                            }
                        </div>,
                        msg: n?.message?.payload,
                        action: (data) => {
                            return <button
                                className="btn btn-style-3 sm font-400"
                                onClick={e => {
                                    setDialogContents({ title: "Notification Details", content: "userNotificationDetails", data })
                                    setDialog(true);
                                    setDialogTab(0);
                                }}
                            >
                                View
                            </button>
                        }

                    }
                }))
            }
            setTableHeaders(searchBy === "taskId" ? notificationStatusHeaders : notificationHeaders)
        }
    }, [notificationData?.lastAction])



    const reset = () => {
        setUpdateFlag(Math.random());
    };

    const filter = () => {

        setCurrentPage(0);
        setPaginationStartIndex(0);
        setPageEndReached(false);
        setLastApiPageFetched(0);
        setUpdateFlag(Math.random());
        setShowNotificationList(true);
    };

    if (isLoading) {
        return (
            <div
                style={{
                    display: "grid",
                    placeItems: "center",
                    height: "80vh",
                }}
            >
                <CircularProgress size="5em" style={{ color: "#AFB42B" }} />
            </div>
        );
    }

    return (
        <Fragment>
            <div className={classes?.NotificationAnalytics + " container"}>

                <div className="row">
                    <div className="s12  col">
                        <div className="content-block bottom-shadow mb-0">
                            <div className="p-15">
                                <div className="row reset-device-block mb-0">
                                    <div className="s4 col ">
                                        <div className="page-title">
                                            <label className="black-text font-600">
                                                Search Notification
                                            </label>
                                            <div className="sub-title d-none">
                                                Search by
                                            </div>
                                        </div>
                                    </div>

                                    <div className="s4 col">
                                        <form className="from">
                                            <div className="control-capabilities">
                                                <div className="model-srch reset">
                                                    <label className="field-name active">
                                                        Search By
                                                    </label>
                                                    <select
                                                        className="custom-select select-reset"
                                                        name="searchBy"
                                                        value={searchBy}
                                                        onChange={(e) => {
                                                            setSearchStr("")
                                                            setShowNotificationList(false)
                                                            setSearchBy(e?.target?.value)
                                                        }}
                                                    >
                                                        {searchOptions?.map((o, i) => {
                                                            return <option value={o?.value}>{o?.label}</option>
                                                        })}
                                                    </select>
                                                </div>
                                            </div>
                                        </form>
                                    </div>

                                    <div className="s4 col">
                                        <form className="search-user"
                                            onSubmit={(e) => onSubmit(e)}
                                        >
                                            <div
                                                className={
                                                    searchBy === "mobile"
                                                        ? "search-device not-found right no-left-pad"
                                                        : "search-device not-found right"
                                                }
                                            >
                                                {searchBy === "mobile" ?
                                                    <PhoneInput
                                                        // country={allowedCountries[0]}
                                                        enableSearch={false}
                                                        value={countryCode}
                                                        onChange={setCountryCode}
                                                        containerClass="country-code-container"
                                                        searchClass="country-code-search"
                                                        dropdownClass="country-code-dropdown"
                                                        onlyCountries={allowedCountries}
                                                    />
                                                    :
                                                    <i className="material-icons search-icon">
                                                        {" "}
                                                        search
                                                    </i>
                                                }

                                                {searchBy !== "taskId" ?
                                                    < input
                                                        className="add-input md "
                                                        required
                                                        placeholder={searchOptions?.find(o => o?.value === searchBy)?.placeholder}
                                                        type={
                                                            searchBy === "mobile" ? "number" :
                                                                // searchBy === "userId" ? 
                                                                "text"
                                                            // : "email"
                                                        }
                                                        name="text"
                                                        value={searchStr}
                                                        onChange={(e) => {
                                                            setSearchStr(e?.target?.value)
                                                            setShowNotificationList(false);
                                                        }}
                                                    />
                                                    :
                                                    <Autocomplete
                                                        freeSolo
                                                        id="asynchronous-demo"
                                                        value={taskId?.value}
                                                        inputValue={taskId?.textValue || ""}
                                                        onInputChange={(e, value) => {
                                                            value = value ?? ""
                                                            setTaskId({ ...taskId, textValue: value })
                                                            setShowNotificationList(false)
                                                        }}
                                                        onChange={(e, value) => {
                                                            setTaskId({ value, textValue: value })
                                                            value = value ?? "";
                                                            setShowNotificationList(false)
                                                        }}
                                                        options={notificationData?.taskList}
                                                        renderInput={(params) => {
                                                            return <TextField
                                                                placeholder="Enter Task ID"
                                                                {...params}
                                                            />
                                                        }
                                                        }
                                                    />
                                                }
                                                <input
                                                    type="submit"
                                                    value="Search"
                                                    className="btn orange-bg btn-block md no-shadow"
                                                />
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Alert />
                {
                    showNotificationList &&
                    < div className="content-block bottom-shadow py-15 mb-30">
                        {
                            <Box className="displayEnd" style={{ marginBottom: "16px", padding: "16px" }}>
                                {
                                    searchBy === "taskId" ?
                                        showNotificationList &&
                                        <button
                                            disabled={notificationList[0]?.taskStatus === "cancelled" || !notificationList[0]?.hasOwnProperty("taskId")}
                                            className="btn btn-style-3 sm font-400"
                                            onClick={(e) => {
                                                setDialogContents({ title: "Cancel Notification", content: "cancelNotification", data: notificationList[0] })
                                                setDialog(true);
                                            }}
                                        >
                                            Cancel Notification
                                        </button>
                                        :
                                        showNotificationList &&
                                        <div className="model-srch reset FilterBox">
                                            <label className="field-name active">
                                                Filter By Severity
                                            </label>
                                            <select
                                                className="custom-select select-reset"
                                                name="filter"
                                                value={severityFilter}
                                                onChange={(e) => {
                                                    setSeverityFilter(e?.target?.value === "None" ? null : e?.target?.value);
                                                    setPageIndex(1);
                                                    searchNotificationData(1, e?.target?.value === "None" ? null : e?.target?.value);
                                                }}
                                            >
                                                {severityOptions?.map((o, i) => {
                                                    return <option value={o?.value}>{o?.label}</option>
                                                })}
                                            </select>
                                        </div>
                                }
                            </Box>
                        }
                        <div className="row mb-0">
                            <div className="col s12">
                                <div
                                    className="table-wrapper mb-20"
                                    style={{
                                        overflowX: "scroll",
                                        scrollBehavior: "auto",
                                        maxHeight: "58vh",
                                        overflowY: "scroll",
                                    }}
                                >
                                    <table
                                        width="100%"
                                        align="center"
                                        className="table style-4 mb-20 notificationDataTable"
                                    >
                                        <thead
                                            style={{ position: "sticky", top: 0, zIndex: 50, background: "white" }}
                                        >
                                            <tr>
                                                {Object.keys(tableHeaders).map((key, ind) => (
                                                    <th style={tableHeaders[key]?.hasOwnProperty("style") ? tableHeaders[key]?.style : {}} key={ind}>{tableHeaders[key]?.label}</th>
                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {notificationList.length === 0 ? (
                                                <tr>
                                                    <td colSpan={Object.keys(tableHeaders).length}>
                                                        No Data found
                                                    </td>
                                                </tr>
                                            ) : (
                                                notificationList
                                                    .slice(startIndex, endIndex)
                                                    .map((element, index) => {
                                                        return (
                                                            <tr key={index % pageSize} id={index % pageSize}>
                                                                {Object.keys(tableHeaders).map((key, ind) => {
                                                                    return (
                                                                        <td key={ind}
                                                                            style={tableHeaders[key]?.hasOwnProperty("style") ? tableHeaders[key]?.style : {}}
                                                                            className={
                                                                                String(element[key]).length > 200
                                                                                    ? "breakWord"
                                                                                    : ""
                                                                            }
                                                                        >
                                                                            {element?.hasOwnProperty(key) ? typeof element[key] === 'function' ? element[key](element) : element[key] : "-"}
                                                                        </td>
                                                                    );
                                                                })}
                                                            </tr>
                                                        );
                                                    })
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                                {
                                    searchBy !== "taskId" &&
                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <button
                                            className="btn btn-style-3  sm  mr-10 font-400"
                                            onClick={e => getPrevPage()}
                                            style={{ marginBottom: "20px" }}
                                            disabled={pageIndex === 1}
                                        >
                                            Previous
                                        </button>
                                        <button
                                            className="btn btn-style-3  sm  mr-10 font-400"
                                            onClick={e => getNextPage()}
                                            style={{ marginBottom: "20px" }}
                                            disabled={notificationList?.length < notificationListPageSize}
                                        >
                                            Next
                                        </button>
                                    </div>}
                            </div>
                        </div>
                    </div>
                }
            </div>
        </Fragment >
    );
};

export default FindNotification;
