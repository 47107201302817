import { 
    INIT_FOTA_JOBS,
    GET_FOTA_JOBS,
    GET_FOTA_JOBS_ERROR,
    ADD_FOTA_JOBS,
    ADD_FOTA_JOBS_ERROR,
    UPDATE_FOTA_JOBS,
    UPDATE_FOTA_JOBS_ERROR,
    DELETE_FOTA_JOBS,
    DELETE_FOTA_JOBS_ERROR,
    GET_FOTA_BATCH_DATA,
    GET_FOTA_BATCH_DATA_ERROR,
    GET_FOTA_BATCH_DATA_LOADING,
    DELETE_FOTA_BATCH,
    DELETE_FOTA_BATCH_ERROR
} from "../action/types";

const initialState = {
    fotaJobs: [],
    fotaJobsLoading: false,
    fotaJobsError: false,
    fotaJobAddSucces: false,
    fotaJobAddError: false,
    fotaJobUpdateSucces: false,
    fotaJobUpdateError: false,
    fotaJobDeleteSucces: false,
    fotaJobDeleteError: false,
    fotaBatchData: [],
    fotaBatchDataLoading: false,
    fotaBatchError: false,
    fotaBatchDeleteSuccess: false,
    fotaBatchDeleteError: false
    
};

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case INIT_FOTA_JOBS:
            return {
                ...state,
                fotaJobsLoading: true,
                fotaJobAddSucces: false,
                fotaJobAddError: false,
                fotaJobUpdateSucces: false,
                fotaJobUpdateError: false,
                fotaJobDeleteSucces: false,
                fotaJobDeleteError: false,
                fotaBatchDataLoading: false,
                fotaBatchError: false,
                fotaBatchDeleteSuccess: false,
                fotaBatchDeleteError: false
            };

        case GET_FOTA_JOBS:
            return {
                ...state,
                fotaJobs: payload,
                fotaJobsError: false,
                fotaJobsLoading: false
            };

        case GET_FOTA_JOBS_ERROR:
            return {
                ...state,
                fotaJobs: payload,
                fotaJobsError: true,
                fotaJobsLoading: false
            };

        case ADD_FOTA_JOBS:
            return {
                ...state,
                fotaJobAddSucces: true
            };
        case ADD_FOTA_JOBS_ERROR:
            return {
                ...state,
                fotaJobAddError: true
            };
        case UPDATE_FOTA_JOBS:
            return {
                ...state,
                fotaJobUpdateSucces: true
            };
        case UPDATE_FOTA_JOBS_ERROR:
            return {
                ...state,
                fotaJobUpdateError: true
            };
        case DELETE_FOTA_JOBS:
            return {
                ...state,
                fotaJobDeleteSucces: true
            };
        case DELETE_FOTA_JOBS_ERROR:
            return {
                ...state,
                fotaJobDeleteError: true
            };

        case GET_FOTA_BATCH_DATA:
            return {
                ...state,
                fotaBatchData: payload,
                fotaBatchDataLoading: false,
                fotaBatchError: false
            }
        case GET_FOTA_BATCH_DATA_LOADING:
            return {
                ...state,
                fotaBatchDataLoading: true,
                fotaBatchError: false
            }
        case GET_FOTA_BATCH_DATA_ERROR:
            return {
                ...state,
                fotaBatchDataLoading: false,
                fotaBatchError: true
            }
        case DELETE_FOTA_BATCH:
            return {
                ...state,
                fotaBatchDeleteError: false,
                fotaBatchDeleteSuccess: true
            }
        case DELETE_FOTA_BATCH_ERROR:
            return {
                ...state,
                fotaBatchDeleteSuccess: false,
                fotaBatchDeleteError: true
            }
        default:
            return state;
    }
}