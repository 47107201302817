import React, { Fragment } from "react";
import Moment from 'react-moment';

const FirmwareDetails = ({ firmwareData, deviceID }) => {
    return (
        <Fragment>
            {(firmwareData.length > 0) ?
                (<div className="popup-content blue-modal">

                    {/* Top level detail of firmware popup */}
                    <div className="block-top">
                        <div className="block-title style-2 center-align  py-25">
                            <h3 className="mb-0">Device Firmware Upgrade History</h3>
                        </div>

                        <div className="valign-wrapper info-reset justify-content-center lite-bg">
                            <div className="d-inline-flex pr-20">
                                <label className="title">Device ID : </label>
                                <div className="device-info">{deviceID}</div>
                            </div>

                            <div className="d-inline-flex pl-20">
                                <label className="title">Model Number :</label>
                                <div className="device-info">{firmwareData[0].modelNumber}</div>
                            </div>
                        </div>
                    </div>

                    {/* Header of firmware list */}
                    <div className="title-section">
                    <div className="px-25">
                        <div className="row mb-0">
                            <div className="s4 col"> <div className="title">Firmware Version</div></div>
                            <div className="s4 col"> <div className="title">Download Date</div></div>
                            <div className="s4 col"> <div className="title">Device Status</div></div>
                        </div>
                    </div></div>

                    {/* Firmware list binding*/}
                    <div className=" height-reset">
                        {firmwareData.map((firmwareInfo, i) => (
                            <div className="block-bottom px-25 py-5" key={i}>
                                <div className="row mb-0">

                                    <div className="s4 col">
                                        <div className="boxes">
                                            <div className="info"> {" "}
                                                {firmwareInfo.firmwareVersion}{" "}</div>
                                        </div>
                                    </div>

                                    <div className="s4 col">
                                        <div className="boxes">
                                            <div className="info">
                                                <Moment
                                                    format="MMMM Do, YYYY"
                                                    date={firmwareInfo.downloadDate}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="s4 col">
                                        <div className="boxes">
                                            <div className="info"> {" "}
                                                {firmwareInfo.deviceStatus}{" "}</div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        ))}

                    </div>

                </div>)
                /* Error message*/
                : (
                    // <div className="popup-content  blue-modal">
                    //     <div className="s12 col">
                    //         <div className="boxes">
                    //             <div className="info error"> {" "}
                    //                 {firmwareData.msg}{" "}
                    //             </div>
                    //         </div>
                    //     </div>
                    // </div>
                     <div className="popup-content  blue-modal blank">
                     <div className="block-title style-2 center-align  py-25">
                        <h3 className="mb-0">Device Firmware Upgrade History</h3>
                    </div>
                    <div className="s12 col">
                        <div className="boxes">
                            <div className="info error content-reset">
                            <div className="text-box"><i className="no-data-icon"></i><label>{(firmwareData && firmwareData.msg) && firmwareData.msg} </label></div>      
                            </div>
                        </div>
                    </div>
                </div>

                )
            }
        </Fragment>
    )
};
 
export default FirmwareDetails;