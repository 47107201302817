import {
  GET_GROUP_USERS_SUCCESS,
  GET_GROUP_USERS_ERROR,
  GET_ONBOARDED_DEVICE_SUCCESS,
  GET_ONBOARDED_DEVICE_ERROR,
  GET_OFFBOARDED_DEVICE_SUCCESS,
  GET_OFFBOARDED_DEVICE_ERROR,
  RESET_GRAPH_DATA,
  GET_ONBOARDED_AC_USER_SUCCESS,
  GET_ONBOARDED_AC_USER_ERROR,
  GET_CONTROLLED_DEVICES_SUCCESS,
  GET_CONTROLLED_DEVICES_ERROR,
  GET_FIRMWARE_DEVICES_SUCCESS,
  GET_FIRMWARE_DEVICES_ERROR,
  GET_LOCATION_DEVICES_SUCCESS,
  GET_LOCATION_DEVICES_ERROR,
  GET_MODEL_WISE_ONBOARDED_DEVICE_SUCCESS,
  GET_MODEL_WISE_ONBOARDED_DEVICE_ERROR,
  GET_HOURLY_ACTIVE_DEVICE_SUCCESS,
  GET_HOURLY_ACTIVE_DEVICE_ERROR,
  GET_ONBOARDED_AC_DEVICE_SUCCESS,
  GET_ONBOARDED_AC_DEVICE_ERROR,
  GET_OFFBOARDED_AC_DEVICE_SUCCESS,
  GET_OFFBOARDED_AC_DEVICE_ERROR,
  GET_MQTT_USERS_SUCCESS,
  GET_MQTT_USERS_ERROR,
  GET_MQTT_USED_KEYS_SUCCESS,
  GET_MQTT_USED_KEYS_ERROR,
} from "../action/types";

const initialState = {
  groupUsers: [],
  onBordedDevices: [],
  offBordedDevices: [],
  onBordedAcUsers: [],
  deviceList: [],
  firmWareDevices:[],
  geoLocationDevices:[],
  modelwiseOnboardDevices:[],
  hourlyActiveDevices:[],
  onBordedAcDevice:[],
  offBordedAcDevice:[],
  mqttUsersDuration:[],
  mqttKeysUsed:[]

};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case RESET_GRAPH_DATA:
      return {
        ...state,
        groupUsers: [],
        onBordedDevices: [],
        onBordedAcUsers:[],
        deviceList:[],
        firmWareDevices:[],
        geoLocationDevices:[],
        offBordedDevices:[],
        modelwiseOnboardDevices:[],
        hourlyActiveDevices:[],
        onBordedAcDevice:[],
        offBordedAcDevice:[],
        mqttUsersDuration:[],
        mqttKeysUsed:[]

      };
    case GET_GROUP_USERS_SUCCESS:
      return {
        ...state,
        groupUsers: payload,
      };
    case GET_GROUP_USERS_ERROR:
      return {
        ...state,
        groupUsers: payload,
      };
    case GET_ONBOARDED_DEVICE_SUCCESS:
      return {
        ...state,
        onBordedDevices: payload,
      };
    case GET_ONBOARDED_DEVICE_ERROR:
      return {
        ...state,
        onBordedDevices: payload,
      };
    case GET_OFFBOARDED_DEVICE_SUCCESS:
      return {
        ...state,
        offBordedDevices: payload,
      };
    case GET_OFFBOARDED_DEVICE_ERROR:
      return {
        ...state,
        offBordedDevices: payload,
      };
    case GET_ONBOARDED_AC_USER_SUCCESS:
      return {
        ...state,
        onBordedAcUsers: payload,
      };
    case GET_ONBOARDED_AC_USER_ERROR:
      return {
        ...state,
        onBordedAcUsers: payload,
      };
      case GET_CONTROLLED_DEVICES_SUCCESS:
      return {
        ...state,
        deviceList: payload,
      };
    case GET_CONTROLLED_DEVICES_ERROR:
      return {
        ...state,
        deviceList: payload,
      }; 
    case GET_FIRMWARE_DEVICES_SUCCESS:
    return {
      ...state,
      firmWareDevices: payload,
    };
  case GET_FIRMWARE_DEVICES_ERROR:
    return {
      ...state,
      firmWareDevices: payload,
    };
  case GET_LOCATION_DEVICES_SUCCESS:
    return {
      ...state,
      geoLocationDevices: payload,
    };
  case GET_LOCATION_DEVICES_ERROR:
    return {
      ...state,
      geoLocationDevices: payload,
    };   
  case GET_MODEL_WISE_ONBOARDED_DEVICE_SUCCESS:
    return {
      ...state,
      modelwiseOnboardDevices: payload,
    };
  case GET_MODEL_WISE_ONBOARDED_DEVICE_ERROR:
    return {
      ...state,
      modelwiseOnboardDevices: payload,
    }; 
    case GET_HOURLY_ACTIVE_DEVICE_SUCCESS:
    return {
      ...state,
      hourlyActiveDevices: payload,
    };
  case GET_HOURLY_ACTIVE_DEVICE_ERROR:
    return {
      ...state,
      hourlyActiveDevices: payload,
    }; 
  case GET_ONBOARDED_AC_DEVICE_SUCCESS:
    return {
      ...state,
      onBordedAcDevice: payload,
    };
  case GET_ONBOARDED_AC_DEVICE_ERROR:
    return {
      ...state,
      onBordedAcDevice: payload,
    };
  case GET_OFFBOARDED_AC_DEVICE_SUCCESS:
  return {
    ...state,
    offBordedAcDevice: payload,
  };
  case GET_OFFBOARDED_AC_DEVICE_ERROR:
    return {
      ...state,
      offBordedAcDevice: payload,
    };
  case GET_MQTT_USERS_SUCCESS:
    return {
      ...state,
      mqttUsersDuration: payload,
    };
  case GET_MQTT_USERS_ERROR:
    return {
      ...state,
      mqttUsersDuration: payload,
    }; 
  case GET_MQTT_USED_KEYS_SUCCESS:
    return {
      ...state,
      mqttKeysUsed: payload,
    };
  case GET_MQTT_USED_KEYS_ERROR:
    return {
      ...state,
      mqttKeysUsed: payload,
    };       
    default:
      return state;
  }
}
