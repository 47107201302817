import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getOffBoardAcDevices } from "../../../action/metrics";
import { seriesData, seriesXaxisData,totalFilterCount } from "./GraphParameter";
import Graph from "./Graph";

function OffboardAcDevice({getOffBoardAcDevices,offBordedAcDevice,graphType,setDeviceCount,type }) {
  useEffect(() => {
    if (type !== "4" ) {
      const que = {
        dateType: type === "1" ? "daily" : type === "2" ? "weekly" : "monthly",
      };
      getOffBoardAcDevices(que);
    }
  }, [getOffBoardAcDevices,type,graphType]);
  let seriesDataDevices = [];
  let intervalData = [];
  if (offBordedAcDevice.length) {
    seriesDataDevices = seriesData(offBordedAcDevice,graphType);
    intervalData = seriesXaxisData(offBordedAcDevice, graphType);
  }
  const totalCount = totalFilterCount(seriesDataDevices)
    useEffect(()=>{
      setDeviceCount(totalCount)
    },[totalCount,type])

  return (
    <div>
      <div id="chart">
        <Graph
          text={"Ac"}
          x_data={intervalData}
          title={"Number Of Ac's device"}
          y_data={seriesDataDevices}
          x_title = {"Offboarded Date"}
        />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    offBordedAcDevice: state.metrics.offBordedAcDevice,
  };
};
export default connect(mapStateToProps, {getOffBoardAcDevices})(OffboardAcDevice);