import React, {useEffect} from "react";
import { connect } from "react-redux";
import Chart from "react-apexcharts";
import {getfirmwareInfo} from '../../../action/report';
import {seriesPieData, labelPieData} from '../Metrics/GraphParameter';

function FirmWareChart({getfirmwareInfo , deviceversion, firmwareInfo,setDeviceCount }) {
  useEffect(()=>{
      getfirmwareInfo();
},[getfirmwareInfo])
const seriesData = seriesPieData(firmwareInfo,deviceversion)
const labelDate = labelPieData(firmwareInfo,deviceversion)
    const series = seriesData;
    const options = {
        chart: {
            width: "50%",
            type: 'pie',
          },
        labels: labelDate,
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
      };
      useEffect(()=>{
        setDeviceCount(labelDate.length)
      },[labelDate])
  return (
    <div>
      <div className = "pie_chart">

      <Chart options={options} series={series} type="pie" width = {"70%"}  />
        
      </div>
    </div>
  );
}
const mapStateToProps = (state) =>{
  return{
    firmwareInfo:state.report.firmwareInfo    
  }
}

export default connect(mapStateToProps, { getfirmwareInfo })(FirmWareChart);