import React from "react";
import PropTypes from "prop-types";
import LeftNav from "../../layout/LeftNav";
import NavWrapper from "../../Common/user/NavWrapper";
import Nav from "../../layout/Nav";

const NavBar = (props) => {
  return (
    <NavWrapper>
      {({ auth, isAuthenticated, tenantLogoUrl, onLogout }) => {
        return (
          <>
            <Nav
              auth={auth}
              tenantLogoUrl={tenantLogoUrl}
              onLogout={onLogout}
            />
            <LeftNav auth={auth} />
          </>
        );
      }}
    </NavWrapper>
  );
};

NavBar.propTypes = {};

export default NavBar;
