import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import DeviceList from "./DeviceList";
import M from "materialize-css/dist/js/materialize.min.js";
import DeviceDetails from "../../../device/DeviceDetails";
import customConfig from "../../../../config";
// import DeviceDetails from "./DeviceDetails";
import {
  getDevice,
  getDeviceUsagebyId,
  getFirmwareHistory,
  getOnboardedHistory,
  getReportbyId,
  getDiagnosticsData,
} from "../../../../action/report";

const HomeSpaceDetails = ({
  getDevice,
  report,
  selectedData,
  getDeviceUsagebyId,
  getFirmwareHistory,
  getOnboardedHistory,
  getDiagnosticsData,
  getReportbyId,
  homeId,
  userRoleInfo,
  user: { user, umV1AuthToken },
  tenant: { tenantInfo },
}) => {
  let elem = document.getElementById("homeSpaceDetailsSelect");
  if (elem) {
    const selects = elem.querySelectorAll("select");
    M.FormSelect.init(selects);
  }
  const [selectedSpaceId, setSelectedSpaceId] = useState(null);
  const [selectedDeviceId, setSelectedDeviceId] = useState(null);
  const [selectedSpaceData, setSelectedSpaceData] = useState(null);
  const [selectedDeviceData, setSelectedDeviceData] = useState(null);
  const [showData, setShowData] = useState(false);
  const {
    device,
    deviceUsage,
    latestFirmwareData,
    firmwareData,
    onBoardedHistoryData,
    diagnosticData,
  } = report;

  const onSpaceIdSelect = (e) => {
    const value = e.target.value;
    setSelectedSpaceId(value);
    report.report.spaces.forEach((element) => {
      if (element.spaceId === value) {
        setSelectedDeviceData(null);
        setSelectedSpaceData(element);
        return;
      }
    });
    setShowData(true);
  };
  const onDeviceIdSelect = (value) => {
    setSelectedDeviceId(value);
    report.allDevice.forEach((element) => {
      if (
        element &&
        value &&
        (element.deviceId.includes(value) || value.includes(element.deviceId))
      ) {
        setSelectedDeviceData(element);
        return;
      }
    });
    setShowData(true);
    console.log("calling getDevice- " + umV1AuthToken + " " +tenantInfo.tenantId)
    getDevice(value, customConfig.deviceSearchByDeviceId,  userRoleInfo, umV1AuthToken, tenantInfo.tenantId);
    // setId(value);
    getFirmwareHistory(value);
    getDeviceUsagebyId(value);
    getOnboardedHistory(value);
    getDiagnosticsData(value);
  };

  const handleNotifyOffboard = () => {
    getReportbyId(homeId);
  };

  return (
    <div className="block mb-30">
      <div className="row">
        <div className="col s12">
          <div className="content-block bottom-shadow-1 radius-6">
            <div className="bottom-gray-border mb-20">
              <div className="d-flex align-items-center p-15">
                <h3 className="font-20 dark-gray font-700">
                  Choose Home Space:
                </h3>

                <div
                  id="homeSpaceDetailsSelect"
                  className="input-field mt-0 ml-15"
                >
                  <select
                    className="style-2"
                    onChange={onSpaceIdSelect}
                    // style={{ display: "block" }}
                  >
                    <option disabled selected={selectedSpaceId === null}>
                      Choose your option
                    </option>
                    {report.report.spaces.map((element) => {
                      return (
                        <option
                          value={element.spaceId}
                          selected={selectedSpaceId === element.spaceId}
                        >
                          {element.spaceName}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>

            <div className="tab-wrap">
              {selectedSpaceData && showData && (
                <DeviceList
                  selectedSpaceData={selectedSpaceData}
                  showData={showData}
                  allDevices={report.allDevice}
                  onDeviceIdSelect={onDeviceIdSelect}
                  selectedDeviceId={selectedDeviceId}
                />
              )}
              {selectedDeviceData && (
                <div style={{ padding: "25px" }}>
                  <DeviceDetails
                    showSeparator={true}
                    device={device}
                    deviceUsage={deviceUsage}
                    latestFirmwareData={latestFirmwareData}
                    firmwareData={firmwareData}
                    onBoardedHistoryData={onBoardedHistoryData}
                    diagnosticData={diagnosticData}
                    notifyOffboard={handleNotifyOffboard}
                    selectedDeviceData={selectedDeviceData}
                    key={device[0] ? device[0].deviceId : ""}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

HomeSpaceDetails.propTypes = {};

const mapStateToProps = (state) => ({
  report: state.report,
  userRoleInfo: {
    level: state.auth.userLevel,
    roles: state.auth.userRoles,
  },
  user: state.user,
  tenant: state.tenant,
});

export default connect(mapStateToProps, {
  getDevice,
  getDeviceUsagebyId,
  getFirmwareHistory,
  getOnboardedHistory,
  getReportbyId,
  getDiagnosticsData,
})(HomeSpaceDetails);
