import React, { Fragment, useState, useEffect, useRef } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import CircularProgress from "@material-ui/core/CircularProgress";
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { generateQRCode, fetchQRCode } from "../../action/qrcode";
import { getDeviceModelNumbersList } from './../../action/addvcModel'
import { useReactToPrint } from 'react-to-print';
import Alert from "../layout/Alert";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { saveAs } from 'file-saver';
import CloudDownload from '@material-ui/icons/CloudDownload';

const maxPSNToSentInParallel = 100

const useStyles = makeStyles({
    selectInput: {
        height: "3rem",
        width: "100%"
    },
    selectValues: {
        width: "100%",
    },
    activeTab: {
        border: '1px solid #bdbdbd',
        backgroundColor: '#e0e0e0',
        textTransform: 'capitalize'
    },
    nonActiveTab: {
        border: 'none',
        backgroundColor: 'none',
        textTransform: 'capitalize'
    },
});

const headers = {
    modelId: "Model ID",
    productSerialNumber: "Product Serial Number",
    status: "QR Code Print Status",
    reasonOfFailure: "Reason of Failure"
};

const CreateQRCode = ({
    allowedDeviceCategories,
    allowedCompanyBrand,
    setAlert,
}) => {

    const classes = useStyles()

    const [category, setCategory] = useState("")
    const [brandName, setBrandName] = useState("")
    const [searchModelIdBy, setSearchModelIdBy] = useState("searchModelIdByMatter")
    const [modelId, setModelId] = useState("")
    const [startProductSerialNumber, setStartProductSerialNumber] = useState("")
    const [totalProductSerialNumbers, setTotalProductSerialNumbers] = useState(0)
    const [qrCodeCopies, setQRCodeCopies] = useState(4)
    const [qrSpacing, setQRSpacing] = useState(0.94)
    const [verticalSpacing, setVerticalSpacing] = useState(2.9)
    const [horizontalSpacing, setHorizontalSpacing] = useState(2.9)
    const [dpi, setDPI] = useState(500)

    const [uploadedPSNFileInput, setUploadedPSNFileInput] = useState("")
    const psnCSVFileInputRef = useRef()
    const [uploadedPSNListFromCSV, setUploadedPSNListFromCSV] = useState([])

    const [modelIdList, setModelIdList] = useState([])
    const [resultQRCode, setResultQRCode] = useState({})
    const [printQRCodes, setPrintQRCodes] = useState(false)
    const [totalQRCodeResponseReceived, setTotalQRCodeResponseReceived] = useState(0)
    const [validQRCodeResponseReceived, setValidQRCodeResponseReceived] = useState(0)
    const [qrCodeCreationInProgress, setQRCodeCreationInProgress] = useState(false)
    const [psnIndex, setPSNIndex] = useState(0)

    const [generateQRCodeButtonClicked, setGenerateQRCodeButtonClicked] = useState(false)

    const [psnQrStringMappingData, setPsnQrStringMappingData] = useState([["Product Serial Number", "QR", "Manual Code"]])

    // Adjust the height of menu items in select. Otherwise get hidden behind headers
    const menuProps = {
        style: {
            marginTop: '52px'
        },
    };

    useEffect(() => {
        if (!category || category.length == 0 || category == "Select Category") {
            return
        }

        if (!brandName || brandName.length == 0 || brandName == "Select Brand") {
            return
        }

        if (brandName && brandName.length != 0 && category && category.length != 0) {

            let isMatterEnabled = searchModelIdBy == "searchModelIdByMatter" ? 'true' : 'false'

            getDeviceModelNumbersList(brandName, category, isMatterEnabled)
                .then((data) => {
                    setModelIdList(data)
                    setModelId("")
                })
                .catch((err) => {
                    console.log('Error in Receiving the Model Number List For Category ' + category +
                        'Brand Name ' + brandName)

                    setModelId("")
                    setModelIdList([])
                })
        }
    }, [brandName, category, searchModelIdBy])

    useEffect(() => {
        if (totalQRCodeResponseReceived == 0 || totalProductSerialNumbers == 0) {
            return;
        }

        if (totalQRCodeResponseReceived == totalProductSerialNumbers) {
            console.log('All Response of PSN Received...')

            setQRCodeCreationInProgress(false)

            setGenerateQRCodeButtonClicked(false)

            if (validQRCodeResponseReceived > 0) {
                // print QR Codes only when the sucessful response of atleast one
                // PSN is received

                setPrintQRCodes(true)

                // also prepare the downloadable excel file of PSN and QR String Mapping
                createPSNQRStringMappingData()
            }
            else {
                // also prepare the downloadable excel file of PSN and QR String Mapping
                createPSNQRStringMappingData()

                setAlert("No Product Serial Number is Eligible for Print", 'danger')
                window.scrollTo(0, 0);
            }
        }
        else if (totalQRCodeResponseReceived % maxPSNToSentInParallel == 0) {
            console.log('Sending Request for Next set of PSN, Total Response Received ' + totalQRCodeResponseReceived)

            // still PSN are pending for QR Code generation
            generateQRCodeInParallel()
        }
    }, [totalQRCodeResponseReceived])

    useEffect(() => {
        if (!printQRCodes) {
            return
        }

        // after the response of all the QR Codes are now received then go for 
        // printing the QR Codes.

        combineAndPrintImages()

    }, [printQRCodes])

    // use this effect when all the data is loaded in the array from CSV File
    useEffect(() => {
        if (uploadedPSNListFromCSV && uploadedPSNListFromCSV.length != 0) {
            generateMultipleQRCodes()
        }
    }, [uploadedPSNListFromCSV])

    useEffect(() => {
        if (generateQRCodeButtonClicked) {

            if (psnIndex == 0 && printQRCodes == false && totalProductSerialNumbers == 0 && validQRCodeResponseReceived == 0 && totalQRCodeResponseReceived == 0) {
                handleGenerateQRCode()
            }
            else {
                // reset other required parameters before generating QR Code
                setPSNIndex(0)

                // reset print QR Codes variable to false
                setPrintQRCodes(false)

                // First Clear the Table which shows the results of QR Codes
                setResultQRCode({})

                // set total Product Serial Numbers to 0
                setTotalProductSerialNumbers(0)

                // reset valid product serial numbers result
                setValidQRCodeResponseReceived(0)

                // Clear the Total Response received
                setTotalQRCodeResponseReceived(0)

                // reset the psn & qr string mapping data
                setPsnQrStringMappingData([["Product Serial Number", "QR", "Manual Code"]])
            }
        }
    }, [generateQRCodeButtonClicked])

    useEffect(() => {
        if (generateQRCodeButtonClicked) {
            if (psnIndex == 0 && printQRCodes == false && totalProductSerialNumbers == 0 && validQRCodeResponseReceived == 0 && totalQRCodeResponseReceived == 0) {
                handleGenerateQRCode()
            }
        }
    }, [psnIndex, printQRCodes, totalProductSerialNumbers, validQRCodeResponseReceived, totalQRCodeResponseReceived])

    function handleGenerateQRCodeReset() {
        setCategory("")
        setBrandName("")
        setModelId("")
        setSearchModelIdBy("searchModelIdByMatter")
        setModelIdList([])
        setStartProductSerialNumber("")
        setTotalProductSerialNumbers(0)
        setResultQRCode({})
        setPrintQRCodes(false)
        setValidQRCodeResponseReceived(0)
        setTotalQRCodeResponseReceived(0)
        setQRCodeCreationInProgress(false)

        // reset the file input states
        setUploadedPSNFileInput("")
        psnCSVFileInputRef.current.value = ""
        setUploadedPSNListFromCSV([])

        // set the default values of other input fields as default values
        setQRCodeCopies(4)
        setQRSpacing(0.94)
        setVerticalSpacing(2.9)
        setHorizontalSpacing(2.9)
        setDPI(500)

        setPSNIndex(0)

        setGenerateQRCodeButtonClicked(false)

        setPsnQrStringMappingData([["Product Serial Number", "QR", "Manual Code"]])
    }

    function handleGenerateQRCode() {

        let bIsUserInputFieldError = false

        // perform validation checks
        if (!category || category.length == 0) {
            bIsUserInputFieldError = true
            setAlert('Category Name Cannot be Empty', 'danger')
        }
        else if (!brandName || brandName.length == 0) {
            bIsUserInputFieldError = true
            setAlert('Brand Name Cannot be Empty', 'danger')
        }
        else if (!modelId || modelId.length == 0) {
            bIsUserInputFieldError = true
            setAlert('Model ID Cannot be Empty', 'danger')
        }
        else if (!qrCodeCopies || qrCodeCopies <= 0 || qrCodeCopies > 5 || qrCodeCopies == "" || parseInt(qrCodeCopies) == NaN) {
            bIsUserInputFieldError = true
            setAlert('Invalid QR Code Copies Value. Should be Greater than 0 and Less than 5', 'danger')
        }
        else if (qrSpacing < 0 || parseInt(qrSpacing) == NaN) {
            bIsUserInputFieldError = true
            setAlert('Invalid QR Spacing Value. Should be Greater than  or Equal to 0', 'danger')
        }
        else if (!dpi || dpi <= 0 || dpi == "" || parseInt(dpi) == NaN) {
            bIsUserInputFieldError = true
            setAlert('Invalid DPI Value. Must be Greater than or Equal to 1', 'danger')
        }
        else if (verticalSpacing < 0 || parseInt(verticalSpacing) == NaN) {
            bIsUserInputFieldError = true
            setAlert('Invalid Vertical Spacing', 'danger')
        }
        else if (horizontalSpacing < 0 || parseInt(horizontalSpacing) == NaN) {
            bIsUserInputFieldError = true
            setAlert('Invalid Horizontal Spacing', 'danger')
        }

        // check for user infput fields error. If error then return
        if (bIsUserInputFieldError) {
            setGenerateQRCodeButtonClicked(false)
            window.scrollTo(0, 0);
            return
        }

        // Priority is given to file - means if file is not provided then single product serial number
        // otherwise file is considered

        if (!uploadedPSNFileInput || uploadedPSNFileInput == "") {

            setTotalProductSerialNumbers(1)

            // for single QR Code Generation mark QR Code creation process as in progress...
            setQRCodeCreationInProgress(true)

            generateSingleQRCode(startProductSerialNumber)
        }
        else {
            if (uploadedPSNFileInput.type == 'text/csv') {
                generateMultipleQRCodes()
            }
            else {
                setAlert('Invalid File Type. Only CSV Files are supported.', 'danger')
                setGenerateQRCodeButtonClicked(false)
                window.scrollTo(0, 0);
                return
            }
        }
    }

    function generateSingleQRCode(productSerialNumber) {
        let bodyData = {
            'category': category,
            'modelId': JSON.parse(modelId)["modelId"],
            "dpi": parseInt(dpi),
            "count": parseInt(qrCodeCopies)
        }

        if (productSerialNumber && productSerialNumber.length > 0) {
            bodyData['productSerialNumber'] = productSerialNumber
        }

        if (parseInt(qrSpacing) > 0) {
            bodyData["horizontalSpacingInMM"] = parseInt(qrSpacing)
        }

        generateQRCode(bodyData)
            .then((data) => {
                setResultQRCode(prevState => {
                    return {
                        ...prevState,
                        [productSerialNumber]: {
                            'productSerialNumber': data?.productSerialNumber ?? "",
                            'status': 'Success',
                            'reasonOfFailure': "",
                            'qrImage': data.qrImage,
                            'modelId': data?.modelId ?? "",
                            'qrString': data?.qrString ?? "",
                            'manualCode': data?.manualCode ?? ""
                        }
                    }
                })
                setValidQRCodeResponseReceived(prevState => {
                    return prevState + 1
                })
                setTotalQRCodeResponseReceived(prevState => {
                    return prevState + 1
                })
            })
            .catch((err) => {
                setResultQRCode(prevState => {
                    return {
                        ...prevState,
                        [productSerialNumber]: {
                            'productSerialNumber': productSerialNumber && productSerialNumber.length > 0 ? productSerialNumber : "",
                            'status': 'Failure',
                            'reasonOfFailure': err?.response?.data?.message ?? err.message,
                            'modelId': JSON.parse(modelId)["modelId"],
                            'qrString': "",
                            'manualCode': ""
                        }
                    }
                })
                setTotalQRCodeResponseReceived(prevState => {
                    return prevState + 1
                })
            })
    }

    function generateMultipleQRCodes() {

        if (!uploadedPSNListFromCSV || uploadedPSNListFromCSV.length == 0) {
            // get the list of product serial numbers if list of product serial 
            // number is not populated

            parseUploadedPSNFile()
            return
        }

        if (uploadedPSNListFromCSV && uploadedPSNListFromCSV.length == 0) {
            setAlert('No Product Serial Number is Present in CSV File', 'danger')
            return
        }

        // set total product serial numbers in a range as 
        setTotalProductSerialNumbers(uploadedPSNListFromCSV.length)

        // mark QR Code Creation Process as in progress.. only after validation
        setQRCodeCreationInProgress(true)

        generateQRCodeInParallel()

    }

    function generateQRCodeInParallel() {

        let psnCountToGenerateQRCode = 0
        if (uploadedPSNListFromCSV.length - totalQRCodeResponseReceived > maxPSNToSentInParallel) {
            psnCountToGenerateQRCode = maxPSNToSentInParallel
        }
        else {
            psnCountToGenerateQRCode = uploadedPSNListFromCSV.length - totalQRCodeResponseReceived
        }

        console.log('Sending Request for PSN Count ' + psnCountToGenerateQRCode)

        for (let i = psnIndex; i < psnIndex + psnCountToGenerateQRCode; i++) {
            let psn = uploadedPSNListFromCSV[i]

            generateSingleQRCode(psn) // generate QR Code for each PSN one by one
        }

        // update the index for processing next set of PSN in parallel
        setPSNIndex((prevState) => {
            return prevState + psnCountToGenerateQRCode
        })
    }

    function parseUploadedPSNFile() {
        // parse uploaded file to get the list of product serial numbers
        const reader = new FileReader();
        reader.onload = function (event) {
            let textData = event.target.result

            let arrFileData = csvToArray(textData)
            if (arrFileData.length == 0) {

                setQRCodeCreationInProgress(false)
                setGenerateQRCodeButtonClicked(false)

                setAlert("Empty File of Product Serial Numbers Uploaded", "danger")
                return
            }

            arrFileData = arrFileData.filter(element => Object.values(element)[0] && Object.values(element)[0] != "" && Object.values(element)[0].length != 0).map((element) => {
                return Object.values(element)[0].replace(/(\r\n|\n|\r)/gm, '')
            })

            // set the product serial number data in state variable
            setUploadedPSNListFromCSV(arrFileData)
        };

        reader.readAsText(uploadedPSNFileInput);
    }

    function csvToArray(str, delimiter = ",") {

        const headers = str.slice(0, str.indexOf("\n")).split(delimiter);

        const rows = str.slice(str.indexOf("\n") + 1).split("\n");
        if (rows && rows.length > 0) {
            if (rows[rows.length - 1] == "") {
                rows.splice(-1) //removing the last element with empty values from array
            }
        }

        if (!rows || rows.length == 0) {
            return []
        }

        const arr = rows.map(function (row) {
            const values = row.split(delimiter);
            const el = headers.reduce(function (object, header, index) {
                object[header] = values[index];
                return object;
            }, {});
            return el;
        });

        return arr;
    }


    // Function to convert the array to a CSV string
    const arrayToCSV = (data) => {
        return data.map(row => row.map(String).map(value => `"${value}"`).join(',')).join('\n');
    };

    // Function to download the CSV file
    const downloadCSV = () => {
        let csvData = ""
        if ((uploadedPSNListFromCSV && uploadedPSNListFromCSV.length > 0) || (startProductSerialNumber && startProductSerialNumber.length > 0)) {
            csvData = arrayToCSV(psnQrStringMappingData);
        }
        else {
            csvData = arrayToCSV([["Model Number", "Model Id", "QR", "Manual Code"], ...psnQrStringMappingData.slice(1)])
        }

        const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
        saveAs(blob, 'qrcode.csv');
    };

    function combineAndPrintImages() {
        handlePrint()
    }

    // Printing Process
    const componentToPrintRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => {
            return componentToPrintRef.current
        }
    });

    async function createPSNQRStringMappingData() {
        if (uploadedPSNListFromCSV && uploadedPSNListFromCSV.length > 0) {
            uploadedPSNListFromCSV.map((element, index) => {
                setPsnQrStringMappingData((prevState) => [
                    ...prevState,
                    [resultQRCode[element].productSerialNumber,
                    resultQRCode[element].qrString,
                    resultQRCode[element].manualCode + "\t"
                    ]
                ])
            })
        }
        else {
            if ((uploadedPSNListFromCSV && uploadedPSNListFromCSV.length > 0) || (startProductSerialNumber && startProductSerialNumber.length > 0)) {
                Object.keys(resultQRCode).map((element, index) => {
                    setPsnQrStringMappingData((prevState) => [
                        ...prevState,
                        [resultQRCode[element].productSerialNumber,
                        resultQRCode[element].qrString,
                        resultQRCode[element].manualCode + "\t"
                        ]
                    ])
                })
            }
            else {
                Object.keys(resultQRCode).map((element, index) => {
                    setPsnQrStringMappingData((prevState) => [
                        ...prevState,
                        [JSON.parse(modelId)["modelNumber"],
                        resultQRCode[element].modelId,
                        resultQRCode[element].qrString,
                        resultQRCode[element].manualCode + "\t"
                        ]
                    ])
                })
            }
        }
    }

    return (
        <Fragment>
            <div className="content-block bottom-shadow">
                <Alert />
                <div className="py-15 ">
                    <Grid container style={{ marginBottom: "20px" }}>
                        <Grid item xs={2} style={{ marginLeft: "10px" }}>
                            <label className="title" style={{ color: 'black', fontSize: '14px' }}>Category: </label>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl variant="outlined" className={classes.selectValues}>
                                <InputLabel id="select-outlined-label-category-name" className="title">Select Category</InputLabel>
                                <Select
                                    labelId="select-outlined-label-category-name"
                                    id="select-outlined-ctageory-name-id"
                                    value={category}
                                    onChange={(e) => {
                                        setCategory(e.target.value)
                                    }}
                                    label="Select Category"
                                    className={classes.selectInput}
                                    MenuProps={menuProps}
                                >
                                    {
                                        allowedDeviceCategories.map(categoryName => {
                                            return <MenuItem key={categoryName} value={categoryName}>{categoryName}</MenuItem>
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container style={{ marginBottom: "20px" }}>
                        <Grid item xs={2} style={{ marginLeft: "10px" }}>
                            <label className="title" style={{ color: 'black', fontSize: '14px' }}>Brand: </label>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl variant="outlined" className={classes.selectValues}>
                                <InputLabel id="select-outlined-label-category-name" className="title">Select Brand</InputLabel>
                                <Select
                                    labelId="select-outlined-label-category-name"
                                    id="select-outlined-ctageory-name-id"
                                    value={brandName}
                                    onChange={(e) => {
                                        setBrandName(e.target.value)
                                    }}
                                    label="Select Brand"
                                    className={classes.selectInput}
                                    MenuProps={menuProps}
                                >
                                    {
                                        allowedCompanyBrand.map(brand => {
                                            return <MenuItem key={brand} value={brand}>{brand}</MenuItem>
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container style={{ marginBottom: "20px" }}>
                        <Grid item xs={2} style={{ marginLeft: "10px", marginTop: "10px" }}>
                            <label className="title" style={{ color: 'black', fontSize: '14px' }}>Search Model Number By: </label>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl component="fieldset">
                                <RadioGroup aria-label="searchBy" name="searchBy" row value={searchModelIdBy} onChange={(e) => {
                                    setSearchModelIdBy(e.target.value)
                                }}>
                                    <FormControlLabel value="searchModelIdByMatter" control={<Radio color="default" />} label="Matter Enabled" />
                                    <FormControlLabel value="searchModelIdByNonMatter" control={<Radio color="default" />} label="Non Matter Enabled" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container style={{ marginBottom: "20px" }}>
                        <Grid item xs={2} style={{ marginLeft: "10px" }}>
                            <label className="title" style={{ color: 'black', fontSize: '14px' }}>Model Number: </label>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl variant="outlined" className={classes.selectValues}>
                                <InputLabel id="select-outlined-label-category-name" className="title">Select Mode Number</InputLabel>
                                <Select
                                    labelId="select-outlined-label-category-name"
                                    id="select-outlined-ctageory-name-id"
                                    value={modelId}
                                    onChange={(e) => {
                                        setModelId(e.target.value)
                                    }}
                                    label="Select Model Number"
                                    className={classes.selectInput}
                                    MenuProps={menuProps}
                                >
                                    {
                                        modelIdList.map(model => {
                                            return <MenuItem key={model['modelId']}
                                                value={JSON.stringify({ 'modelId': model['modelId'], 'modelNumber': model['modelNumber'] })}>{model['modelNumber']}</MenuItem>
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                        {
                            modelId.length != 0 && <Fragment>
                                <Grid item xs={1} style={{ marginLeft: "15px", marginTop: "10px" }}>
                                    <label className="title" style={{ color: 'black', fontSize: '14px', fontWeight: "bold" }}>Model ID: </label>
                                </Grid>
                                <Grid item xs={1} style={{ marginLeft: "5px", marginTop: "10px" }}>
                                    <label className="title" style={{ color: 'black', fontSize: '14px' }}>{JSON.parse(modelId)?.modelId ?? ""}</label>
                                </Grid>
                            </Fragment>
                        }
                    </Grid>
                    <Grid container style={{ marginBottom: "20px" }}>
                        <Grid item xs={2} style={{ marginLeft: "10px" }}>
                            <label style={{ color: 'black', fontSize: '14px' }}>Product Serial Number: </label>
                        </Grid>
                        <Grid item xs={4} style={{ marginRight: "2px" }}>
                            <input
                                className="add-input xl"
                                placeholder="Enter Product Serial Number"
                                type="text"
                                style={{ width: "100% !important" }}
                                value={startProductSerialNumber}
                                onChange={(e) => {
                                    setStartProductSerialNumber(e.target.value)
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container style={{ marginBottom: "20px" }}>
                        <Grid item xs={2} style={{ marginLeft: "10px" }}>
                        </Grid>
                        <Grid item xs={6} style={{ width: "100% important" }}>
                            <label>OR</label>
                        </Grid>
                    </Grid>
                    <Grid container style={{ marginBottom: "20px" }}>
                        <Grid item xs={2} style={{ marginLeft: "10px" }}>
                        </Grid>
                        <Grid item xs={4} style={{ width: "100% important" }}>
                            <label>Upload CSV File  </label>
                            <input
                                className="md border-1"
                                placeholder="Upload Firmware Image"
                                accept=".csv"
                                type="file"
                                style={{ width: "100% !important" }}
                                name={uploadedPSNFileInput?.name ?? uploadedPSNFileInput}
                                onChange={(e) => {
                                    setUploadedPSNListFromCSV([])
                                    setUploadedPSNFileInput(e.target.files[0])
                                }}
                                ref={psnCSVFileInputRef}
                            />
                        </Grid>
                    </Grid>
                    <Grid container style={{ marginBottom: "20px" }}>
                        <Grid item xs={2} style={{ marginLeft: "10px" }}>
                            <label style={{ color: 'black', fontSize: '14px' }}>QR Code Copies: </label>
                        </Grid>
                        <Grid item xs={4} style={{ width: "100% important" }}>
                            <input
                                className="add-input xl"
                                type="number"
                                style={{ width: "100% !important" }}
                                value={qrCodeCopies}
                                onChange={(e) => {
                                    setQRCodeCopies(e.target.value)
                                }}
                                onKeyDown={(e) => {
                                    ['e', 'E', '+', '-', '.'].includes(e.key) && e.preventDefault();
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container style={{ marginBottom: "20px" }}>
                        <Grid item xs={2} style={{ marginLeft: "10px" }}>
                            <label style={{ color: 'black', fontSize: '14px' }}>QR Spacing (In mm): </label>
                        </Grid>
                        <Grid item xs={4} style={{ width: "100% important" }}>
                            <input
                                className="add-input xl"
                                type="number"
                                style={{ width: "100% !important" }}
                                value={qrSpacing}
                                onChange={(e) => {
                                    setQRSpacing(e.target.value)
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container style={{ marginBottom: "20px" }}>
                        <Grid item xs={2} style={{ marginLeft: "10px" }}>
                            <label style={{ color: 'black', fontSize: '14px' }}>Spacing (In mm): </label>
                        </Grid>
                        <Grid item xs={2} style={{ marginRight: "2px" }}>
                            <label>Vertical Spacing</label>
                            <input
                                className="add-input xl"
                                placeholder="Enter Vertcial Spacing"
                                type="number"
                                style={{ width: "100% !important" }}
                                value={verticalSpacing}
                                onChange={(e) => {
                                    setVerticalSpacing(e.target.value)
                                }}
                            />
                        </Grid>
                        <Grid item xs={2} style={{ marginLeft: "2px" }}>
                            <label>Horizontal Spacing</label>
                            <input
                                className="add-input xl"
                                placeholder="Enter Horizontal Spacing"
                                type="number"
                                style={{ width: "100% !important" }}
                                value={horizontalSpacing}
                                onChange={(e) => {
                                    setHorizontalSpacing(e.target.value)
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container style={{ marginBottom: "20px" }}>
                        <Grid item xs={2} style={{ marginLeft: "10px" }}>
                            <label style={{ color: 'black', fontSize: '14px' }}>DPI: </label>
                        </Grid>
                        <Grid item xs={4} style={{ width: "100% important" }}>
                            <input
                                className="add-input xl"
                                type="number"
                                style={{ width: "100% !important" }}
                                value={dpi}
                                onChange={(e) => {
                                    setDPI(e.target.value)
                                }}
                            />
                        </Grid>
                    </Grid>
                    <div className="row">
                        <button
                            className="btn btn-style-2 orange-bg"
                            onClick={() => {
                                setGenerateQRCodeButtonClicked(true)
                            }}
                            disabled={qrCodeCreationInProgress ? true : false}
                            style={{ marginLeft: "10px" }}
                        >
                            {
                                qrCodeCreationInProgress ? <Fragment>
                                    <CircularProgress size="2em" style={{ color: "#AFB42B", marginTop: "5px" }} />
                                </Fragment>
                                    : "Generate QR Code"
                            }
                        </button>
                        <button
                            className="btn btn-style-2 orange-bg" onClick={handleGenerateQRCodeReset}
                            style={{ marginLeft: "20px" }}
                            disabled={qrCodeCreationInProgress ? true : false}
                        >
                            Reset
                        </button>
                    </div>
                </div>
            </div>
            {/*Component to Print QR Code. Here Print the QR Codes but donot display it to users.
                Directly print them in the print console window
            */}
            <div style={{ display: 'none' }}>
                <ComponentToPrint ref={componentToPrintRef} uploadedPSNListFromCSV={uploadedPSNListFromCSV} resultQRCode={resultQRCode} vSpacing={verticalSpacing} hSpacing={horizontalSpacing} dpi={dpi} />
            </div>
            {
                resultQRCode && Object.keys(resultQRCode).length > 0 &&
                <Fragment>
                    <div className="content-block position-relative">
                        <div className="reset-device-detail">
                            <div className="row">
                                <div className="s12 col">
                                    <div
                                        className={`block-title style-4`}
                                        style={{ marginTop: '20px' }}
                                    >
                                        <h3>QR Code Print Status Details</h3>
                                    </div>
                                </div>
                            </div>

                            <div className="device-detail">
                                <div className="row">
                                    <div className="s6 col">
                                        <div className="unique-box mb-15">
                                            <label className="title d-inline  min-180">Total QR Codes</label>
                                            <div className="device-info d-inline">
                                                {totalQRCodeResponseReceived}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="s6 col">
                                        <div className="unique-box mb-15">
                                            <label className="title d-inline  min-180">Success</label>
                                            <div className="device-info d-inline">
                                                {validQRCodeResponseReceived}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="s6 col">
                                        <div className="unique-box mb-15">
                                            <label className="title d-inline  min-180">Failed</label>
                                            <div className="device-info d-inline">
                                                {totalQRCodeResponseReceived - validQRCodeResponseReceived}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="s6 col">
                                        <div className="unique-box mb-15">
                                            <label className="title d-inline  min-180">Download Result</label>
                                            <div className="device-info d-inline">
                                                {
                                                    totalQRCodeResponseReceived == totalProductSerialNumbers
                                                        ? <button
                                                            className="btn btn-style-2 orange-bg" onClick={downloadCSV}
                                                        >
                                                            Download
                                                            <span style={{ marginLeft: '10px' }}><CloudDownload style={{ color: "white", verticalAlign: 'sub' }}></CloudDownload></span>
                                                        </button>
                                                        : "File Generation In Progress"
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="content-block bottom-shadow">
                        <div className="row mb-0">
                            <div className="col s12">
                                <div
                                    className="table-wrapper mb-20"
                                    style={{
                                        overflowX: "scroll",
                                        scrollBehavior: "auto",
                                        maxHeight: "58vh",
                                        overflowY: "scroll",
                                    }}
                                >
                                    <table
                                        width="100%"
                                        align="center"
                                        className="table style-4 mb-20 manufacturedDevicesTable"
                                    >
                                        <thead
                                            style={{ position: "sticky", top: 0, zIndex: 50, background: "white" }}
                                        >
                                            <tr>
                                                {
                                                    ((!uploadedPSNFileInput || uploadedPSNFileInput == "") &&
                                                        (!startProductSerialNumber || startProductSerialNumber.trim() == "")) ?
                                                        <Fragment>
                                                            {
                                                                Object.keys(headers).filter((key) => key != "productSerialNumber").map((key) => (
                                                                    <th>{headers[key]}</th>
                                                                ))
                                                            }
                                                        </Fragment>
                                                        :
                                                        <Fragment>
                                                            {
                                                                Object.keys(headers).filter((key) => key != "modelId").map((key) => (
                                                                    <th>{headers[key]}</th>
                                                                ))
                                                            }
                                                        </Fragment>
                                                }
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                uploadedPSNListFromCSV && uploadedPSNListFromCSV.length > 0 ?
                                                    (totalProductSerialNumbers == totalQRCodeResponseReceived) && uploadedPSNListFromCSV.map((element, index) => {
                                                        return (
                                                            <tr key={index} id={index}>
                                                                {Object.keys(headers).filter((key) => key != "modelId").map((key) => {
                                                                    return (
                                                                        <td
                                                                            className={
                                                                                String(resultQRCode[element][key]).length > 200
                                                                                    ? "breakWord"
                                                                                    : ""
                                                                            }
                                                                        >
                                                                            {resultQRCode[element][key]}
                                                                        </td>
                                                                    )
                                                                })}
                                                            </tr>
                                                        )
                                                    })
                                                    :
                                                    Object.keys(resultQRCode)
                                                        .map((element, index) => {
                                                            return (
                                                                <tr key={index} id={index}>
                                                                    {
                                                                        !startProductSerialNumber || startProductSerialNumber.trim() == "" ?
                                                                            <Fragment>
                                                                                {
                                                                                    Object.keys(headers).filter((key) => key != "productSerialNumber").map((key) => {
                                                                                        return (
                                                                                            <td
                                                                                                className={
                                                                                                    String(resultQRCode[element][key]).length > 200
                                                                                                        ? "breakWord"
                                                                                                        : ""
                                                                                                }
                                                                                            >
                                                                                                {resultQRCode[element][key]}
                                                                                            </td>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </Fragment>
                                                                            :
                                                                            <Fragment>
                                                                                {
                                                                                    Object.keys(headers).filter((key) => key != "modelId").map((key) => {
                                                                                        return (
                                                                                            <td
                                                                                                className={
                                                                                                    String(resultQRCode[element][key]).length > 200
                                                                                                        ? "breakWord"
                                                                                                        : ""
                                                                                                }
                                                                                            >
                                                                                                {resultQRCode[element][key]}
                                                                                            </td>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </Fragment>
                                                                    }
                                                                </tr>
                                                            )
                                                        })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            }
        </Fragment>
    );
}

const ComponentToPrint = React.forwardRef((props, ref) => (
    <div ref={ref}>
        {
            props.uploadedPSNListFromCSV && props.uploadedPSNListFromCSV.length > 0 ?
                props.uploadedPSNListFromCSV.map((psn) => {
                    if (props.resultQRCode[psn] && props.resultQRCode[psn].qrImage && props.resultQRCode[psn].qrImage != "") {
                        return <PrintTemplate qrCodeDetails={props.resultQRCode[psn]} vSpacing={parseInt(props.vSpacing)} hSpacing={parseInt(props.hSpacing)} dpi={parseInt(props.dpi)} />
                    }
                })
                :
                props.resultQRCode && Object.keys(props.resultQRCode).map((psn) => {
                    if (props.resultQRCode[psn] && props.resultQRCode[psn].qrImage && props.resultQRCode[psn].qrImage != "") {
                        return <PrintTemplate qrCodeDetails={props.resultQRCode[psn]} vSpacing={parseInt(props.vSpacing)} hSpacing={parseInt(props.hSpacing)} dpi={parseInt(props.dpi)} />
                    }
                })
        }
    </div>
));

const PrintTemplate = ({ qrCodeDetails, vSpacing, hSpacing, dpi }) => {

    function getContent() {
        let vSpacingInPixels = "", hSpacingInPixels = ""

        if (parseInt(vSpacing) == 0) {
            vSpacingInPixels = "0px"
        }
        else {
            vSpacingInPixels = (vSpacing * (dpi / 25.4)) + "px"
        }

        if (parseInt(hSpacing) == 0) {
            hSpacingInPixels = "0px"
        }
        else {
            hSpacingInPixels = (hSpacing * (dpi / 25.4)) + "px"
        }

        return <div>
            <img src={"data:image/png;base64," + qrCodeDetails.qrImage}
                style={{ position: 'relative', marginTop: vSpacingInPixels, marginLeft: hSpacingInPixels }} />
        </div>
    }

    return (
        <Fragment>
            <div>
                {getContent()}
            </div>
        </Fragment>
    )
};

export default CreateQRCode