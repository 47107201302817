import { GET_WARRANTY_TOKEN, WARRANTY_TOKEN_ERROR, GET_WARRANTY_APPLIANCE, WARRANTY_APPLIANCE_ERROR, GET_WARRANTY_DETAIL, WARRANTY_DETAIL_ERROR, CLEAR_WARRANTY } from "../action/types";

const initialState = {
  warrantyToken: null,
  applianceData: null,
  warrantyDetails: {},
  tokenError: false,
  applianceError: false,
  warrantyError: false
};

export default function Warranty(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_WARRANTY_TOKEN:
      return {
        ...state,
        warrantyToken: payload,
        tokenError: false
      };
    case WARRANTY_TOKEN_ERROR:
      return {
        ...state,
        warrantyToken: payload,
        tokenError: true
      };
    case GET_WARRANTY_APPLIANCE:
      return {
        ...state,
        applianceData: payload,
        applianceError: false,
        warrantyDetails: {}
      };
    case WARRANTY_APPLIANCE_ERROR:
      return {
        ...state,
        applianceData: payload,
        applianceError: true
      };
    case GET_WARRANTY_DETAIL:
      let warranty = state.warrantyDetails;
      if (payload && payload.data && payload.data.id) {
        warranty[payload.data.id] = payload.data;
      }
      return {
        ...state,
        warrantyDetails: warranty,
        warrantyError: false
      };
    case WARRANTY_DETAIL_ERROR:
      return {
        ...state,
        warrantyError: true
      };
    case CLEAR_WARRANTY:
      return {
        warrantyToken: null,
        applianceData: null,
        warrantyDetails: {},
        tokenError: false,
        applianceError: false,
        warrantyError: false
      };
    default:
      return state;
  }
}
