import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { QrReader } from 'react-qr-reader';
import { getQRDetailsUsingScanner } from "../../action/qrcode";
import { setAlert } from "../../action/alert";
import Alert from "../layout/Alert";

const QRScanner = ({
    qrCodeScanDetailsHandler,
    setAlert,

}) => {

    const handleScanResult = (data) => {
        if (data) {
            getQRDetailsUsingScanner(data.text)
                .then((data) => {
                    //setAlert("QR Code Scan Successful", "success")
                    qrCodeScanDetailsHandler(data)
                })
                .catch((error) => {
                    console.log('QR Code Scan Error' + error)
                    qrCodeScanDetailsHandler(undefined, error)
                    //setAlert(error, "danger")
                })
        }
    };

    const handleError = (err) => {
        console.error(err);
    };

    return (
        <div>
            <QrReader
                delay={300}
                onError={handleError}
                onResult={handleScanResult}
                style={{ width: '100%' }}
                videoContainerStyle={{ paddingTop: '80%' }}
            />
        </div>
    );
};

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = (dispatch) => ({
    setAlert: (message, status) => dispatch(setAlert(message, status)),
})

export default connect(mapStateToProps, mapDispatchToProps)(QRScanner);


