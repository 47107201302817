import React from 'react';
import Chart from "react-apexcharts";
import {colour} from './GraphParameter';

function Graph({text,title,x_data,y_data, stacked, titleName, color,x_title}) {
    const options = {
        chart: {
          type: "bar",
          height: 350,
          stacked: stacked
        },
        title:{
          text:titleName ? titleName : ""
        },
        colors:  colour,
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          categories: x_data.length ? x_data : [""],
          title:{
            text:x_title?x_title:""
          }
        },
        yaxis: {
          title: {
            text: title,
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return +val +" "+ text;
            },
          },
        },
      };
    return (
        <div>
            <Chart options={options} series={y_data} type="bar" height={350} />
        </div>
    )
}

export default Graph;
