import { GET_TENANT_INFO, TENANT_INFO_ERROR } from "./types";
import axios from "axios";
import { setAlert } from "./alert";
import customConfig from "../config";
import env from "../env";
import loginLogo from "../../src/assets/black_logo.png";
import setTenantId from "../utils/setTenantId";

let static_tenant_response = {
  tenantId: "panasonic",
  tenantExternal: "voltassmartiot",
  tenantDisplayName: "Voltas Smart IoT",
  tenantLogoUrl: loginLogo,
  selectedTheme: "rectangular",
  primaryColor: "grey",
  secondaryColor: "black",
  tenantFavicon: "",
};
export const getTenantInfo = (dispatch) => () => {
  return new Promise((resolve, reject) => {
    const host = window.location.host;

    let tenantId = "";
    if (host.includes('smarthomeplus')) {
      tenantId = 'flipkart'

      static_tenant_response = {
        tenantId: "flipkart",
        tenantExternal: "Miraie",
        tenantDisplayName: "Flipkart",
        tenantLogoUrl: "https://web.miraie.in/smarthomeplus.png",
        selectedTheme: "newLayout",
        primaryColor: "blue",
        secondaryColor: "orange",
        tenantFavicon: "https://web.miraie.in/smarthome.png",
      };
    }
    else if (host.includes('appliancehub')) {
      tenantId = 'amazon'
    }
    else {
      tenantId = 'panasonic'

      static_tenant_response = {
        tenantId: "panasonic",
        tenantExternal: "Miraie",
        tenantDisplayName: "Panasonic",
        tenantLogoUrl:  "https://www.panasonic.com/content/panasonic/in/en/_jcr_content/masthead/primaryLogo.img.png/1605870828565.png",
        selectedTheme: "rectangular",
        primaryColor: "grey",
        secondaryColor: "orange",
        tenantFavicon: "https://www.panasonic.com/etc/designs/panasonic/common-clientlibs/images/favicon.ico",
      };
    }

    // let data = res.data;
    dispatch({
      type: GET_TENANT_INFO,
      payload: static_tenant_response,
    });
    setTenantId(tenantId);
    resolve(static_tenant_response);
  });
};
