import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import M from "materialize-css/dist/js/materialize.min.js";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import {deviceCategory} from '../../Common/Metrics/GraphParameter';
function Device_model_filter({resetDate,modelwiseOnboardDevices, setModalFilterType, setDevicesCatogary,setSelectedFilter, allowedDeviceCategories}) {
  const [modalFilter, setModalFilter] = useState({})
  useEffect(() => {
    M.AutoInit();
  }, []);
  useEffect(() => {
    setModalFilterType(modalFilter)
  }, [modalFilter]);

  useEffect(() => {
    if(modelwiseOnboardDevices.length){
      const data = JSON.parse(JSON.stringify(modelwiseOnboardDevices))
      let arr = [];
      for(let i = 0 ; i < data.length ; i++) {
        let tempArr = Object.keys(data[i].modelCategories)
        arr.push(...tempArr)
      }
      let unique = [...new Set(arr)];
      let checkArr = []
      unique.forEach(v => checkArr[v] = true)
      setModalFilter(checkArr)
    }
  }, [modelwiseOnboardDevices]);
  const handleControllerChange = (event) => {
    modalFilter[event.target.name] = event.target.checked;
    setModalFilter({...modalFilter , [event.target.name]:event.target.checked})
  }
  const handleDeviceCategory = (event) => {
    setDevicesCatogary(event.target.value);
    setSelectedFilter("1");
    resetDate();
  }
  return (
    <div className="container">
      <div className="content-block col pb-15">
        <div className="row reset-device-block mb-0">
          <div className="s6 col">
            <div className="page-title">
              <div className="test_class">
                <Accordion className="model_select">
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>Device Model</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                      {modelwiseOnboardDevices.length > 0 && Object.keys(modalFilter).map((model)=>(
                          <FormControlLabel
                          control={
                            <Checkbox
                              checked={modalFilter[model]}
                              onChange={handleControllerChange}
                              name={model}
                              color="primary"
                            />
                          }
                          label={model}
                        />
                      ))}
                  </AccordionDetails>
                </Accordion>
              </div>
            </div>
          </div>

          <div className="s6 col">
            <div className="control-capabilities">
              <div className="model-srch reset full-w">
                <label className="field-name mb-0">Device Category </label>
                <select
                  className="custom-select select-reset radius-20"
                  name="searchBy"
                    onChange={handleDeviceCategory}
                >
                  {allowedDeviceCategories.map((category)=>(
                  <option value={category}>{category}</option>
                  ))}
                  {/* <option value="ac">AC</option>
                  <option value="tv">TV</option>
                  <option value="switch">Switch</option>
                  <option value="wh">WATERHEATER</option> */}
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    modelwiseOnboardDevices: state.metrics.modelwiseOnboardDevices,
  allowedDeviceCategories:state.auth.allowedDeviceCategories,
  };
};

export default connect(mapStateToProps, {})(Device_model_filter);
