import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

const DeviceList = ({
  selectedSpaceData,
  showData,
  allDevices = [],
  onDeviceIdSelect,
  selectedDeviceId,
}) => {
  let devices = selectedSpaceData.devices || [];
  useEffect(() => {
    let tempDevices = [];
    Object.assign(tempDevices, devices);
    for (let i = 0; i < allDevices.length; i++) {
      let currentDevice = allDevices[i];
      for (let j = 0; j < tempDevices.length; j++) {
        if (tempDevices[j].deviceId === currentDevice.deviceId) {
          tempDevices[j].category = currentDevice.category;
        }
      }
    }
    devices = tempDevices;
  }, [selectedSpaceData, allDevices]);

  return (
    <>
      {!devices || devices.length === 0 ? (
        <div className="font-16 p-15 pt-0 pb-0">
          No Devices Added in Selected Home Space
        </div>
      ) : (
        <>
          <div className="font-16 p-15 pt-0 pb-0">
            Select an option to view device detail reports
          </div>
          <div className="p-15 tab-header">
            <ul className="tabs detail-tab reset-thumb scroll-set">
              {devices.map((mydevice) => {
                return (
                  <>
                    <li
                      className="tab"
                      onClick={() => onDeviceIdSelect(mydevice.deviceId)}
                      key={mydevice.deviceId}
                    >
                      <a
                        className={
                          mydevice.deviceId === selectedDeviceId ? "active" : ""
                        }
                      >
                        <i
                          className={`tabs-icon ${
                            mydevice.categoy === "AC"
                              ? "smart-room-ac"
                              : mydevice.category === "Panasonic Fan"
                              ? "smart-fan"
                              : mydevice.category === "Panasonic Television" ||
                                mydevice.category === "TV"
                              ? "television"
                              : mydevice.category === "ODMFRONTLOADWM"
                              ? "washing-maching"
                              : mydevice.category === "REFRIGERATOR"
                              ? "refrigerator"
                              : mydevice.category === "Panasonic Smart Plug"
                              ? "smart-plug"
                              : mydevice.category === "Panasonic Switch Device"
                              ? "switch-device"
                              : mydevice.category === "Panasonic door-phone"
                              ? "door-phone"
                              : "default-device"
                          }`}
                        ></i>
                        <div className="title">{mydevice.deviceName}</div>
                      </a>
                    </li>
                  </>
                );
              })}
            </ul>
          </div>
        </>
      )}
    </>
  );
};

DeviceList.propTypes = {};

export default DeviceList;
