import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Spinner from "../../../layout/Spinner";

const HomeId = ({ report, primaryOrSecondary, onPrimaryOrSecondaryClick }) => {
  const primaryOrSecondaryMapper = {
    primaryUserHomeIds: "Primary",
    secondaryUserHomeIds: "Secondary",
  };
  const mappedUserHomeId = primaryOrSecondaryMapper[primaryOrSecondary];
  
  return (
    <>
      {report.loading === true ? (
        <div>
          <Spinner />
        </div>
      ) : (
        <div className="col s8">
          <div className="content-block mb-0 bottom-shadow-1 radius-6">
            <div className="tab-blue">
              <div className="d-flex px-15 space-fix justify-content-between bottom-gray-border">
                <h3 className="font-20 dark-gray font-700">Home ID(s)</h3>
                <ul className="tabs">
                  <li className="tab">
                    <button
                      className={mappedUserHomeId === "Primary" ? "active" : ""}
                      onClick={() =>
                        onPrimaryOrSecondaryClick("primaryUserHomeIds")
                      }
                    >
                      Primary
                    </button>
                  </li>
                  <li className="tab">
                    <button
                      className={
                        mappedUserHomeId === "Secondary" ? "active" : ""
                      }
                      onClick={() =>
                        onPrimaryOrSecondaryClick("secondaryUserHomeIds")
                      }
                    >
                      Secondary
                    </button>
                  </li>
                </ul>
              </div>
              <div id="primary" className="p-15 tab-content">
                <div className="table-style-2">
                  <table className="table centered responsive-table">
                    <thead>
                      <tr>
                        <th>S. No.</th>
                        <th>Home ID</th>
                        <th>Home Name</th>
                        <th>Address</th>
                      </tr>
                    </thead>
                    <tbody>
                      {report.report === null ||
                      Object.keys(report.report).length < 2 ? (
                        <tr>
                          <td colSpan="4">Data Not Found</td>
                        </tr>
                      ) : (
                        <>
                          <tr>
                            <td>
                              {" "}
                              <i
                                className="material-icons tbl-srch "
                                style={{ color: "grey" }}
                              >
                                search
                              </i>
                            </td>
                            <td>
                              <input
                                className="tble-input"
                                placeholder="Search ID"
                                type="text"
                                name="text"
                                disabled
                              />
                            </td>
                            <td>
                              <input
                                className="tble-input"
                                placeholder="Search Name"
                                type="text"
                                name="text"
                                disabled
                              />
                            </td>
                            <td>
                              <input
                                className="tble-input"
                                placeholder="Search Address"
                                type="text"
                                name="text"
                                disabled
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>1</td>
                            <td>{report.report.homeId}</td>
                            <td>{report.report.homeName}</td>
                            <td>
                              {report &&
                                report.report.address &&
                                report.report.address.addressLine1}
                              ,{" "}
                              {report &&
                                report.report.address &&
                                report.report.address.addressLine2}
                              ,{" "}
                              {report &&
                                report.report.address &&
                                report.report.address.city}
                              ,{" "}
                              {report &&
                                report.report.address &&
                                report.report.address.pincode}
                            </td>
                          </tr>
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

HomeId.propTypes = {};

export default connect((state) => ({
  report: state.report,
}))(HomeId);
