import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {getControllerDevices} from '../../../action/metrics';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@material-ui/core";
import DatePicker from "react-datepicker";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Checkbox, FormControlLabel } from "@material-ui/core";

function DeviceFilter({ handleControllerDevice, title, ActiveDeviceFilter, selectedGraph , getControllerDevices, deviceCount }) {
  const [checkAnd, setCheckAnd] = useState(true);
  const [checkIos, setCheckIos] = useState(true);
  const [checkAVA, setCheckAVA] = useState(true);
  const [checkGVA, setCheckGVA] = useState(true);
  const [checkREM, setCheckREM] = useState(true);
  const [disableDaily, setDisableDaily] = useState(true);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  let initialFilter =
  {
    "an": checkAnd,
    "ios": checkIos,
    "ava": checkAVA,
    "rem": checkREM,
    "gva": checkGVA
  }

  const handleDeviceControl = () => {
    setDisableDaily(true)
    const que= { 
      "dateType":'daily',
      "startDate":null,
    }
  getControllerDevices(que)
  resetDate("")
  }


  const handleControllerChange = (event) => {
    if (event.target.name === "an") {
      setCheckAnd(event.target.checked);
    } else if (event.target.name === "ios") {
      setCheckIos(event.target.checked);
    } else if (event.target.name === "ava") {
      setCheckAVA(event.target.checked);
    } else if (event.target.name === "rem") {
      setCheckREM(event.target.checked);
    } else if (event.target.name === "gva") {
      setCheckGVA(event.target.checked);
    } else return;
    let filterValue = initialFilter;
    filterValue[event.target.name] = event.target.checked
    setTimeout(() => {
      handleControllerDevice(filterValue)
    }, 10)
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const HandleDeviceControlFilter  = (startDate) => {
    if((selectedGraph === "10" || selectedGraph === "4") && startDate) {
      ActiveDeviceFilter(startDate)
    setDisableDaily(false)
      
    }
    
    
    else {
      resetDate();
    }
  }

  useEffect(() => {
    resetDate();
    // setShowDateCalendar(false)
  }, [selectedGraph]);

  const resetDate = () =>{
    setStartDate("");
  }

  return (
    <div>
      <div className="row reset-device-block mb-15">
        <div className="s3 col m-0">
          <div className="hd-style-5 device_main_div">
            <div className="hd-style-5 float_left d-flex">
              <div className="black-text font-600 ">
                {title}
                <div className="gray-text-light device_no">{deviceCount} </div>
              </div>
            </div>
          </div>
        </div>
        <div className="s9 col  ">
          <div className="control-capabilities">
            <div className="model-srch reset w-auto date_range_position">
              
              <Accordion className="over_float_second r-5">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography> Controller </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checkAnd}
                        onChange={handleControllerChange}
                        name={"an"}
                        color="primary"
                      />
                    }
                    label={"Android"}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checkIos}
                        onChange={handleControllerChange}
                        name={"ios"}
                        color="primary"
                      />
                    }
                    label={"iOS"}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checkAVA}
                        onChange={handleControllerChange}
                        name={"ava"}
                        color="primary"
                      />
                    }
                    label={"AVA"}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checkGVA}
                        onChange={handleControllerChange}
                        name={"gva"}
                        color="primary"
                      />
                    }
                    label={"GVA"}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checkREM}
                        onChange={handleControllerChange}
                        name={"rem"}
                        color="primary"
                      />
                    }
                    label={"REM"}
                  />
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
        </div>
      </div>
      <div style={{ marginTop: "20px" }}>
        <div className="row">
          <div className="s6 col m-0"></div>
          <div className="control-capabilities">
            <div className="model-srch reset full-w justify_content_end date__picker">
              {/* <label className="field-name mb-0">Select Category Type</label> */}
              <DatePicker
                selected={startDate}
                maxDate={new Date()}
                onChange={handleStartDateChange}
                placeholderText="Search Date"
                dateFormat="dd/MM/yyyy"
                className="add-input calendar input_date"
              />
              <button
                className="date_button orange-bg filter_btn w-100"
                onClick={() => HandleDeviceControlFilter (startDate)}
              >
                Search data
              </button>
              {!disableDaily &&<button
                className="date_button orange-bg filter_btn w-100 ml-20"
                onClick={handleDeviceControl}
              >
                Daily
              </button>}
            </div>
          </div>


        </div>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
  };
};

export default connect(mapStateToProps, {getControllerDevices})(DeviceFilter);
