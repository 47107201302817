import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Moment from "react-moment";
import customConfig from "../../config";

const WarrantyInfo = ({
  products,
  showProductInfo,
  WarrantyData: { warrantyDetails, warrantyError },
}) => {
  const [selectedProduct, setSelectedProduct] = useState(
    products && products.length > 0 ? products[0] : null
  );
  const [selectedWarranty, setSelectedWarranty] = useState(
    products &&
      products.length > 0 &&
      warrantyDetails &&
      warrantyDetails[products[0].id] &&
      warrantyDetails[products[0].id].warranties
      ? warrantyDetails[products[0].id].warranties
      : null
  );

  const changeTab = (p) => {
    setSelectedProduct(p);
    setSelectedWarranty(warrantyDetails[p.id].warranties);
  };

  const isExpirySoon = (expiryDate) => {
    const day = Math.floor(
      (Date.parse(expiryDate) - new Date().getTime()) / (1000 * 3600 * 24)
    );

    if (day <= 30 && day >= 0) {
      return `EXPIRY SOON: ${day} Days Left`;
    }
    if (day < 0) {
      return `EXPIRED`;
    }
    if (day > 30) {
      return null;
    }
  };

  return (
    <Fragment>
      {!warrantyError ? (
        <div className="popup-content  blue-modal blank">
          <div className="block-title style-2 center-align  py-25">
            <h3 className="mb-0">View Warranty Info</h3>
          </div>
          <div className="list-modal px-15">
            {showProductInfo && (
              <div className="col s12">
                <ul className="tabs mb-10 tab-icon-style">
                  {products &&
                    products.length > 0 &&
                    products.map((product, i) => (
                      <li key={product.id} className="tab">
                        <Link
                          to={"#"}
                          onClick={() => changeTab(product)}
                          className={
                            selectedProduct.id === product.id ? "active" : ""
                          }
                        >
                          <img src={product.icon} />
                        </Link>
                      </li>
                    ))}
                </ul>
              </div>
            )}

            <div className="col s12">
              <div className="list-wrap pb-15">
                {showProductInfo && (
                  <div className="product-detail">
                    <div className="row valign-wrapper">
                      <div className="col s5">
                        <div className="unique-box mb-10">
                          <label className="title d-inline  min-140">
                            Product Category
                          </label>
                          <div className="device-info d-inline">
                            {selectedProduct &&
                              selectedProduct.product_name &&
                              selectedProduct.product_name}
                          </div>
                        </div>

                        <div className="unique-box mb-10">
                          <label className="title d-inline min-140">
                            Modal Name
                          </label>
                          <div className="device-info d-inline">
                            {selectedProduct &&
                              selectedProduct.model_name &&
                              selectedProduct.model_name}
                          </div>
                        </div>
                      </div>

                      <div className="col s5">
                        <div className="unique-box mb-10">
                          <label className="title d-inline  min-140">
                            Sub Category
                          </label>
                          <div className="device-info d-inline">
                            {selectedProduct &&
                              selectedProduct.sub_product_name &&
                              selectedProduct.sub_product_name}
                          </div>
                        </div>

                        <div className="unique-box mb-10">
                          <label className="title d-inline  min-140">
                            Serial Number
                          </label>
                          <div className="device-info d-inline">
                            {selectedProduct &&
                              selectedProduct.serial_no &&
                              selectedProduct.serial_no}
                          </div>
                        </div>
                      </div>

                      <div className="col s2">
                        <div className="product-img">
                          {selectedProduct && selectedProduct.image && (
                            <img src={selectedProduct.image} />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div className="vertical-wrapper">
                  {selectedWarranty && selectedWarranty.length > 0 ? (
                    selectedWarranty.map((warranty, i) => (
                      <div className="listing-modal mb-10">
                        <div className="title-wrap">
                          <label>Warranty ID :</label> {warranty.id}
                        </div>

                        <div className="row">
                          <div className="p-15">
                            <div className="col s6">
                              <div className="unique-box mb-10">
                                <label className="title d-inline  min-140">
                                  Warranty Start Date
                                </label>
                                <div className="device-info d-inline">
                                  <div className="info">
                                    {warranty.WarrantyStartDate && (
                                      <Moment
                                        format="MMMM Do, YYYY"
                                        date={warranty.WarrantyStartDate}
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>

                              <div className="unique-box">
                                <label className="title d-inline  min-140">
                                  Warranty Expiry Date
                                </label>
                                <div className="device-info d-inline">
                                  <div className="info">
                                    {warranty.WarrantyExpiryDate && (
                                      <Moment
                                        format="MMMM Do, YYYY"
                                        date={warranty.WarrantyExpiryDate}
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col s6">
                              <div className="unique-box mb-10">
                                <label className="title d-inline  min-140">
                                  Type Of Warranty
                                </label>
                                <div className="device-info d-inline">
                                  {warranty.TypeOfWarranty}
                                </div>
                              </div>

                              {isExpirySoon(warranty.WarrantyExpiryDate) && (
                                <div className="unique-box">
                                  <div className="device-info d-inline">
                                    <div className="expiry-box">
                                      {isExpirySoon(
                                        warranty.WarrantyExpiryDate
                                      )}
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="boxes">
                      <div className="info error content-reset">
                        <div className="text-box">
                          <i className="no-data-icon"></i>
                          <label>{customConfig.noWarrantyRecordsMsg} </label>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="popup-content  blue-modal blank">
          <div className="block-title style-2 center-align  py-25">
            <h3 className="mb-0">Warranty Details</h3>
          </div>
          <div className="s12 col">
            <div className="boxes">
              <div className="info error content-reset">
                <div className="text-box">
                  <i className="no-data-icon"></i>
                  <label>{warrantyError} </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};
const mapStateToProps = (state) => ({
  WarrantyData: state.warranty,
});

export default connect(mapStateToProps)(WarrantyInfo);
