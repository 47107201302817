import React, { Fragment, useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import Grid from '@material-ui/core/Grid';
import CircularProgress from "@material-ui/core/CircularProgress";
import { fetchQRCode } from "../../action/qrcode";
import { useReactToPrint } from 'react-to-print';
import Alert from "../layout/Alert";
import QRScanner from './QRCodeScanner';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { getDeviceModelNumbersList, getModelId } from './../../action/addvcModel'

const useStyles = makeStyles({
    selectInput: {
        height: "3rem",
        width: "100%"
    },
    selectValues: {
        width: "100%",
    },
    activeTab: {
        border: '1px solid #bdbdbd',
        backgroundColor: '#e0e0e0',
        textTransform: 'capitalize'
    },
    nonActiveTab: {
        border: 'none',
        backgroundColor: 'none',
        textTransform: 'capitalize'
    },
});

const FetchQRCode = ({
    allowedDeviceCategories,
    allowedCompanyBrand,
    setAlert,
    //ModelData: { ModelData, loading },
    ModelData,
    getModelId
}) => {

    const classes = useStyles()

    // Adjust the height of menu items in select. Otherwise get hidden behind headers
    const menuProps = {
        style: {
            marginTop: '52px'
        },
    };

    const fetchByPSN = "fetchByPSN"
    const fetchByModelId = "fetchByModelId"

    const [fetchQRCodeBy, setFetchQRCodeBy] = useState(fetchByPSN)
    const [productSerialNumber, setProductSerialNumber] = useState("")
    const [resultQRCode, setResultQRCode] = useState(undefined)
    const [totalProductSerialNumbers, setTotalProductSerialNumbers] = useState(0)
    const [totalQRCodeResponseReceived, setTotalQRCodeResponseReceived] = useState(0)
    const [validQRCodeResponseReceived, setValidQRCodeResponseReceived] = useState(0)
    const [qrCodeFetchingInProgress, setQRCodeFetchingInProgress] = useState(false)
    const [verticalSpacing, setVerticalSpacing] = useState(2.9)
    const [horizontalSpacing, setHorizontalSpacing] = useState(2.9)
    const [dpi, setDPI] = useState(500)
    const [qrCodeCopies, setQRCodeCopies] = useState(4)
    const [qrSpacing, setQRSpacing] = useState(0.94)
    const [displayQRCodeScanner, setDisplayQRCodeScanner] = useState(false)

    const [category, setCategory] = useState("")
    const [brandName, setBrandName] = useState("")
    const [searchModelIdBy, setSearchModelIdBy] = useState("searchModelIdByMatter")
    const [modelId, setModelId] = useState("")
    const [modelIdList, setModelIdList] = useState([])

    const [getDataUsingQRCodeScanning, setGetDataUsingQRCodeScanning] = useState(false)

    useEffect(() => {
        ModelData = {}
    }, [])

    useEffect(() => {
        if (ModelData && ModelData.ModelData) {
            setModelId(JSON.stringify({ 'modelId': ModelData?.ModelData.modelId, 'modelNumber': ModelData?.ModelData?.modelNumber ?? "" }))
            setModelIdList([{ 'modelId': ModelData?.ModelData.modelId, 'modelNumber': ModelData?.ModelData?.modelNumber ?? "" }])
            setBrandName(ModelData?.ModelData?.brand ?? "")
        }
    }, [ModelData])

    useEffect(() => {
        if (totalQRCodeResponseReceived == 0 || totalProductSerialNumbers == 0) {
            return;
        }

        if (totalQRCodeResponseReceived == totalProductSerialNumbers) {
            setQRCodeFetchingInProgress(false)
        }
    }, [totalQRCodeResponseReceived, validQRCodeResponseReceived])

    useEffect(() => {
        if (!category || category.length == 0 || category == "Select Category") {
            return
        }

        if (!brandName || brandName.length == 0 || brandName == "Select Brand") {
            return
        }

        if (brandName && brandName.length != 0 && category && category.length != 0) {

            let isMatterEnabled = searchModelIdBy == "searchModelIdByMatter" ? 'true' : 'false'

            getDeviceModelNumbersList(brandName, category, isMatterEnabled)
                .then((data) => {
                    setModelIdList(data)

                    if (!getDataUsingQRCodeScanning) {
                        setModelId("")
                    }
                })
                .catch((err) => {
                    console.log('Error in Receiving the Model Number List For Category ' + category +
                        'Brand Name ' + brandName)

                    setModelId("")
                    setModelIdList([])
                })
        }
    }, [brandName, category, searchModelIdBy])

    function handleGenerateQRCodeReset() {
        setProductSerialNumber("")
        setResultQRCode(undefined)
        setTotalProductSerialNumbers(0)
        setTotalQRCodeResponseReceived(0)
        setQRCodeFetchingInProgress(false)
        setValidQRCodeResponseReceived(0)
        setVerticalSpacing(2.9)
        setHorizontalSpacing(2.9)
        setQRCodeCopies(4)
        setQRSpacing(0.94)
        setDPI(500)

        setCategory("")
        setBrandName("")
        setModelId("")
        setSearchModelIdBy("searchModelIdByMatter")

        setGetDataUsingQRCodeScanning(false)
        setDisplayQRCodeScanner(false)
    }

    function handleGenerateQRCode() {

        // First Clear the Section which shows the results of QR Codes
        setResultQRCode(undefined)

        // set total Product Serial Numbers to 0
        setTotalProductSerialNumbers(0)

        // reset valid product serial numbers result
        setValidQRCodeResponseReceived(0)

        // Clear the Total Response received
        setTotalQRCodeResponseReceived(0)

        // perform validation checks
        if (fetchQRCodeBy == fetchByModelId) {
            if (!modelId || modelId.length == 0) {
                setAlert('Model ID Cannot be Empty', 'danger')
                window.scrollTo(0, 0);
                return
            }
        }
        else {
            if (!productSerialNumber || productSerialNumber.length == 0) {
                setAlert('Product Serial Number Cannot be Empty', 'danger')
                window.scrollTo(0, 0);
                return
            }
        }

        if (!qrCodeCopies || qrCodeCopies <= 0 || qrCodeCopies > 5 || qrCodeCopies == "" || parseInt(qrCodeCopies) == NaN) {
            setAlert('Invalid QR Code Copies Value. Should be Greater than 0 and Less than 5', 'danger')
            window.scrollTo(0, 0);
            return
        }
        if (qrSpacing < 0 || parseInt(qrSpacing) == NaN) {
            setAlert('Invalid QR Spacing Value. Should be Greater than  or Equal to 0', 'danger')
            window.scrollTo(0, 0);
            return
        }
        if (!dpi || dpi <= 0 || dpi == "" || parseInt(dpi) == NaN) {
            setAlert('Invalid DPI Value. Must be Greater than or Equal to 1', 'danger')
            window.scrollTo(0, 0);
            return
        }
        if (verticalSpacing < 0 || parseInt(verticalSpacing) == NaN) {
            setAlert('Invalid Vertical Spacing', 'danger')
            window.scrollTo(0, 0);
            return
        }
        if (!horizontalSpacing < 0 || parseInt(horizontalSpacing) == NaN) {
            setAlert('Invalid Horizontal Spacing', 'danger')
            window.scrollTo(0, 0);
            return
        }

        setTotalProductSerialNumbers(1)

        let reqParams = {
            'dpi': parseInt(dpi),
            'count': parseInt(qrCodeCopies),
            'qrimage': true
        }

        if (fetchQRCodeBy == fetchByModelId) {
            reqParams['modelId'] = JSON.parse(modelId)?.["modelId"] ?? ""
        }
        else {
            reqParams['psn'] = productSerialNumber
        }

        if (parseInt(qrSpacing) > 0) {
            reqParams["horizontalSpacingInMM"] = parseInt(qrSpacing)
        }

        fetchSingleQRCode(reqParams)
    }

    function fetchSingleQRCode(data) {

        // mark the QR Code Fetching in progress
        setQRCodeFetchingInProgress(true)

        fetchQRCode(data)
            .then((result) => {
                setResultQRCode(result)
                setValidQRCodeResponseReceived(prevState => {
                    return prevState + 1
                })
                setTotalQRCodeResponseReceived(prevState => {
                    return prevState + 1
                })
            })
            .catch((err) => {
                setResultQRCode(undefined)
                setTotalQRCodeResponseReceived(prevState => {
                    return prevState + 1
                })
                setAlert(err?.response?.data?.message ?? err.message, "danger")
                window.scrollTo(0, 0);
            })
    }

    // Printing Process
    const componentToPrintRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => {
            return componentToPrintRef.current
        }
    });

    function handleScanQRCode() {
        setDisplayQRCodeScanner(!displayQRCodeScanner)
    }

    function handleQRCodeScanDetails(qrCodeScanDetails, error) {
        setGetDataUsingQRCodeScanning(true)
        setResultQRCode(undefined)

        if(error){
            setAlert(error, "danger")
            setCategory("")
            setProductSerialNumber("")
            setBrandName("")
            setModelId("")
            window.scrollTo(0, 0);
            return
        }

        if (fetchQRCodeBy == fetchByModelId) {

            // check if the qr code is PSN based or Model Based
            if (qrCodeScanDetails && qrCodeScanDetails.productSerialNumber) {
                setAlert("Scanned QR Code is PSN Based QR Code. Use Model ID Based QR Codes", "danger")
                window.scrollTo(0, 0);
                return
            }

            setAlert("QR Code Scan Successful", "success")
            window.scrollTo(0, 0);

            getModelId(qrCodeScanDetails.modelId);
            setCategory(qrCodeScanDetails.category)

            if (qrCodeScanDetails.isMatter) {
                setSearchModelIdBy("searchModelIdByMatter")
            }
            else {
                setSearchModelIdBy("searchModelIdByNonMatter")
            }
        }
        else {
            // check if the qr code is PSN based or Model Based
            if (qrCodeScanDetails && !qrCodeScanDetails.productSerialNumber) {
                setAlert("Scanned QR Code is not a PSN Based QR Code.", "danger")
                window.scrollTo(0, 0);
                return
            }

            setAlert("QR Code Scan Successful", "success")
            window.scrollTo(0, 0);

            setProductSerialNumber(qrCodeScanDetails.productSerialNumber)
        }
    }

    function onChangeSearchQrCodeBy(searchQrCodeBy) {
        setFetchQRCodeBy(searchQrCodeBy)
        handleGenerateQRCodeReset()
    }

    return (
        <Fragment>
            <div className="content-block bottom-shadow">
                <Alert />
                <div className="py-15 ">
                    <Grid container style={{ marginBottom: "20px" }}>
                        <Grid item xs={2} style={{ marginLeft: "10px", marginTop: "10px" }}>
                            <label className="title" style={{ color: 'black', fontSize: '14px' }}>Fetch QR Code By: </label>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl component="fieldset">
                                <RadioGroup aria-label="searchBy" name="searchBy" row value={fetchQRCodeBy} onChange={(e) => {
                                    onChangeSearchQrCodeBy(e.target.value)
                                }}>
                                    <FormControlLabel value={fetchByPSN} control={<Radio color="default" />} label="Search by PSN" />
                                    <FormControlLabel value={fetchByModelId} control={<Radio color="default" />} label="Search By Model Number" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Grid>
                    {
                        fetchQRCodeBy == fetchByPSN && <Grid container style={{ marginBottom: "20px" }}>
                            <Grid item xs={2} style={{ marginLeft: "10px" }}>
                                <label style={{ color: 'black', fontSize: '14px' }}>Product Serial Number: </label>
                            </Grid>
                            <Grid item xs={4} style={{ marginRight: "2px" }}>
                                <input
                                    className="add-input xl"
                                    placeholder="Enter Product Serial Number"
                                    type="text"
                                    style={{ width: "100% !important" }}
                                    value={productSerialNumber}
                                    onChange={(e) => {
                                        setProductSerialNumber(e.target.value)
                                    }}
                                />
                            </Grid>
                            <Grid item xs={2} style={{ marginLeft: '15px' }}>
                                <i class="fa fa-qrcode fa-3x" aria-hidden="true" onClick={handleScanQRCode} style={{ cursor: 'pointer' }} title="Click to Scan QR Code"></i>
                            </Grid>
                        </Grid>
                    }
                    {
                        fetchQRCodeBy == fetchByModelId && <Grid container style={{ marginBottom: "20px" }}>
                            <Grid container style={{ marginBottom: "20px" }}>
                                <Grid item xs={2} style={{ marginLeft: "10px" }}>
                                    <label className="title" style={{ color: 'black', fontSize: '14px' }}>Category: </label>
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControl variant="outlined" className={classes.selectValues}>
                                        <InputLabel id="select-outlined-label-category-name" className="title">Select Category</InputLabel>
                                        <Select
                                            labelId="select-outlined-label-category-name"
                                            id="select-outlined-ctageory-name-id"
                                            value={category}
                                            onChange={(e) => {
                                                setCategory(e.target.value)
                                                setGetDataUsingQRCodeScanning(false)
                                            }}
                                            label="Select Category"
                                            className={classes.selectInput}
                                            MenuProps={menuProps}
                                        >
                                            {
                                                allowedDeviceCategories.map(categoryName => {
                                                    return <MenuItem key={categoryName} value={categoryName}>{categoryName}</MenuItem>
                                                })
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container style={{ marginBottom: "20px" }}>
                                <Grid item xs={2} style={{ marginLeft: "10px" }}>
                                    <label className="title" style={{ color: 'black', fontSize: '14px' }}>Brand: </label>
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControl variant="outlined" className={classes.selectValues}>
                                        <InputLabel id="select-outlined-label-category-name" className="title">Select Brand</InputLabel>
                                        <Select
                                            labelId="select-outlined-label-category-name"
                                            id="select-outlined-ctageory-name-id"
                                            value={brandName}
                                            onChange={(e) => {
                                                setBrandName(e.target.value)
                                                setGetDataUsingQRCodeScanning(false)
                                            }}
                                            label="Select Brand"
                                            className={classes.selectInput}
                                            MenuProps={menuProps}
                                        >
                                            {
                                                allowedCompanyBrand.map(brand => {
                                                    return <MenuItem key={brand} value={brand}>{brand}</MenuItem>
                                                })
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container style={{ marginBottom: "20px" }}>
                                <Grid item xs={2} style={{ marginLeft: "10px", marginTop: "10px" }}>
                                    <label className="title" style={{ color: 'black', fontSize: '14px' }}>Search Model Number By: </label>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl component="fieldset">
                                        <RadioGroup aria-label="searchBy" name="searchBy" row value={searchModelIdBy} onChange={(e) => {
                                            setSearchModelIdBy(e.target.value)
                                            setGetDataUsingQRCodeScanning(false)
                                        }}>
                                            <FormControlLabel value="searchModelIdByMatter" control={<Radio color="default" />} label="Matter Enabled" />
                                            <FormControlLabel value="searchModelIdByNonMatter" control={<Radio color="default" />} label="Non Matter Enabled" />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container style={{ marginBottom: "20px" }}>
                                <Grid item xs={2} style={{ marginLeft: "10px" }}>
                                    <label className="title" style={{ color: 'black', fontSize: '14px' }}>Model Number: </label>
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControl variant="outlined" className={classes.selectValues}>
                                        <InputLabel id="select-outlined-label-category-name" className="title">Select Model Number</InputLabel>
                                        <Select
                                            labelId="select-outlined-label-category-name"
                                            id="select-outlined-ctageory-name-id"
                                            value={modelId}
                                            onChange={(e) => {
                                                setModelId(e.target.value)
                                            }}
                                            label="Select Model Number"
                                            className={classes.selectInput}
                                            MenuProps={menuProps}
                                        >
                                            {
                                                modelIdList.map(model => {
                                                    return <MenuItem key={model['modelId']}
                                                        value={JSON.stringify({ 'modelId': model['modelId'], 'modelNumber': model['modelNumber'] })}>{model['modelNumber']}</MenuItem>
                                                })
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={2} style={{ marginLeft: '15px' }}>
                                    <i class="fa fa-qrcode fa-3x" aria-hidden="true" onClick={handleScanQRCode} style={{ cursor: 'pointer' }} title="Click to Scan QR Code"></i>
                                </Grid>
                            </Grid>

                        </Grid>
                    }

                    {
                        displayQRCodeScanner &&
                        <Grid container style={{ marginBottom: "20px" }}>
                            <Grid item xs={2} style={{ marginLeft: "10px" }}>
                                Scan QR Code Using System's Camera
                            </Grid>
                            <Grid item xs={4} style={{ width: "100% important" }}>
                                <QRScanner qrCodeScanDetailsHandler={handleQRCodeScanDetails}/>
                            </Grid>
                        </Grid>
                    }
                    <Grid container style={{ marginBottom: "20px" }}>
                        <Grid item xs={2} style={{ marginLeft: "10px" }}>
                            <label style={{ color: 'black', fontSize: '14px' }}>QR Code Copies: </label>
                        </Grid>
                        <Grid item xs={4} style={{ width: "100% important" }}>
                            <input
                                className="add-input xl"
                                type="number"
                                style={{ width: "100% !important" }}
                                value={qrCodeCopies}
                                onChange={(e) => {
                                    setQRCodeCopies(e.target.value)
                                }}
                                onKeyDown={(e) => {
                                    ['e', 'E', '+', '-', '.'].includes(e.key) && e.preventDefault();
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container style={{ marginBottom: "20px" }}>
                        <Grid item xs={2} style={{ marginLeft: "10px" }}>
                            <label style={{ color: 'black', fontSize: '14px' }}>QR Spacing (In mm): </label>
                        </Grid>
                        <Grid item xs={4} style={{ width: "100% important" }}>
                            <input
                                className="add-input xl"
                                type="number"
                                style={{ width: "100% !important" }}
                                value={qrSpacing}
                                onChange={(e) => {
                                    setQRSpacing(e.target.value)
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container style={{ marginBottom: "20px" }}>
                        <Grid item xs={2} style={{ marginLeft: "10px" }}>
                            <label style={{ color: 'black', fontSize: '14px' }}>DPI: </label>
                        </Grid>
                        <Grid item xs={4} style={{ width: "100% important" }}>
                            <input
                                className="add-input xl"
                                type="number"
                                style={{ width: "100% !important" }}
                                value={dpi}
                                onChange={(e) => {
                                    setDPI(e.target.value)
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container style={{ marginBottom: "20px" }}>
                        <Grid item xs={2} style={{ marginLeft: "10px" }}>
                            <label style={{ color: 'black', fontSize: '14px' }}>Spacing: </label>
                        </Grid>
                        <Grid item xs={2} style={{ marginRight: "2px" }}>
                            <label>Vertical Spacing</label>
                            <input
                                className="add-input xl"
                                placeholder="Enter Vertcial Spacing"
                                type="number"
                                style={{ width: "100% !important" }}
                                value={verticalSpacing}
                                onChange={(e) => {
                                    setVerticalSpacing(e.target.value)
                                }}
                            />
                        </Grid>
                        <Grid item xs={2} style={{ marginLeft: "2px" }}>
                            <label>Horizontal Spacing</label>
                            <input
                                className="add-input xl"
                                placeholder="Enter Horizontal Spacing"
                                type="number"
                                style={{ width: "100% !important" }}
                                value={horizontalSpacing}
                                onChange={(e) => {
                                    setHorizontalSpacing(e.target.value)
                                }}
                            />
                        </Grid>
                    </Grid>

                    <div className="row">
                        <button
                            className="btn btn-style-2 orange-bg"
                            onClick={handleGenerateQRCode}
                            disabled={qrCodeFetchingInProgress ? true : false}
                            style={{ marginLeft: "10px" }}
                        >
                            {
                                qrCodeFetchingInProgress ? <Fragment>
                                    <CircularProgress size="2em" style={{ color: "#AFB42B", marginTop: "5px" }} />
                                </Fragment>
                                    : "Get QR Code Details"
                            }
                        </button>
                        <button
                            className="btn btn-style-2 orange-bg" onClick={handleGenerateQRCodeReset}
                            style={{ marginLeft: "20px" }}
                            disabled={qrCodeFetchingInProgress ? true : false}
                        >
                            Reset
                        </button>
                    </div>
                </div>
            </div>
            {/*Component to Print QR Code. Here Print the QR Codes but donot display it to users.
                Directly print them in the print console window
            */}
            <div style={{ display: 'none' }}>
                <ComponentToPrint ref={componentToPrintRef} qrCodeDetails={resultQRCode} vSpacing={verticalSpacing} hSpacing={horizontalSpacing} dpi={dpi} />
            </div>
            {
                resultQRCode && resultQRCode != undefined &&
                <Fragment>
                    <div className="content-block position-relative">
                        <div className="reset-device-detail">
                            <div className="row">
                                <div className="s12 col">
                                    <div
                                        className={`block-title style-4`}
                                        style={{ marginTop: '20px' }}
                                    >
                                        <h3>Product Serial Number Details</h3>
                                    </div>
                                </div>
                            </div>

                            <div className="device-detail">
                                <div className="row">
                                    <div className="s6 col">
                                        <div className="unique-box mb-15">
                                            <label className="title d-inline  min-180">Category</label>
                                            <div className="device-info d-inline">
                                                {resultQRCode.category}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="s6 col">
                                        <div className="unique-box mb-15">
                                            <label className="title d-inline  min-180">Model ID</label>
                                            <div className="device-info d-inline">
                                                {resultQRCode.modelId}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="s6 col">
                                        <div className="unique-box mb-15">
                                            <label className="title d-inline  min-180">Matter Enabled</label>
                                            <div className="device-info d-inline">
                                                {resultQRCode.isMatter ? "True" : "False"}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="s6 col">
                                        <div className="unique-box mb-15">
                                            <label className="title d-inline  min-180">Product ID</label>
                                            <div className="device-info d-inline">
                                                {resultQRCode.productId}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="s6 col">
                                        <div className="unique-box mb-15">
                                            <label className="title d-inline  min-180">QR Code</label>
                                            <div className="device-info d-inline">
                                                {resultQRCode.qrCode}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="s6 col">
                                        <div className="unique-box mb-15">
                                            <label className="title d-inline  min-180">QR Image</label>
                                            <div className="device-info d-inline">
                                                <button
                                                    className="btn btn-style-2 orange-bg" onClick={() => {
                                                        handlePrint()
                                                    }}
                                                >
                                                    Print QR Code
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            }
        </Fragment>
    );
}

const ComponentToPrint = React.forwardRef((props, ref) => (
    <div ref={ref}>
        {
            props.qrCodeDetails && props.qrCodeDetails.qrImage && props.qrCodeDetails.qrImage != "" &&
            <PrintTemplate qrCodeDetails={props.qrCodeDetails} vSpacing={parseInt(props.vSpacing)} hSpacing={parseInt(props.hSpacing)} dpi={props.dpi} />
        }
    </div>
));

const PrintTemplate = ({ qrCodeDetails, vSpacing, hSpacing, dpi }) => {

    function getContent() {
        let vSpacingInPixels = "", hSpacingInPixels = ""

        if (parseInt(vSpacing) == 0) {
            vSpacingInPixels = "0px"
        }
        else {
            vSpacingInPixels = (vSpacing * (dpi / 25.4)) + "px"
        }

        if (parseInt(hSpacing) == 0) {
            hSpacingInPixels = "0px"
        }
        else {
            hSpacingInPixels = (hSpacing * (dpi / 25.4)) + "px"
        }

        return <div>
            <img src={"data:image/png;base64," + qrCodeDetails.qrImage}
                style={{ position: 'relative', marginTop: vSpacingInPixels, marginLeft: hSpacingInPixels }} />
        </div>
    }

    return (
        <Fragment>
            <div>
                {getContent()}
            </div>
        </Fragment>
    )
};

const mapStateToProps = (state) => ({
    ModelData: state.getModel
});

export default connect(mapStateToProps, {
    getModelId,
})(FetchQRCode);