import {
    CANCEL_NOTIFICATION_TASK_FAILURE,
    CANCEL_NOTIFICATION_TASK_REQUEST,
    CANCEL_NOTIFICATION_TASK_SUCCESS,
    GET_NOTIFICATION_TASK_FAILURE,
    GET_NOTIFICATION_TASK_REQUEST,
    GET_NOTIFICATION_TASK_SUCCESS,
    SEARCH_NOTIFICATION_FAILURE,
    SEARCH_NOTIFICATION_REQUEST,
    SEARCH_NOTIFICATION_SUCCESS,
    SEND_NOTIFICATION,
    SEND_NOTIFICATION_ERROR,
    SEND_NOTIFICATION_SUCCESS
} from "../action/types";

const initialState = {
    sendNotificationInProgress: false,
    sendNotificationSuccess: false,
    sendNotificationFailure: false,
    notificationDetails: {},
    lastNotificationDetails: {},
    taskList: [],
    lastAction: ""
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case SEND_NOTIFICATION:
            return {
                ...state,
                sendNotificationInProgress: true,
                sendNotificationSuccess: false,
                sendNotificationFailure: false,
            }
        case GET_NOTIFICATION_TASK_REQUEST:
        case CANCEL_NOTIFICATION_TASK_REQUEST:
        case SEARCH_NOTIFICATION_REQUEST:
            return {
                ...state,
            }
        case GET_NOTIFICATION_TASK_SUCCESS:
            return {
                ...state,
                ...(() => {
                    if (action?.payload?.action === "updateSentNotification") {
                        return { lastNotificationDetails: action?.payload?.data }
                    }
                    else {
                        return { notificationDetails: action?.payload?.data }
                    }
                })(),
                lastAction: "get_notification_task" + action?.payload?.taskId + Date?.now()
            }
        case SEARCH_NOTIFICATION_SUCCESS:
            return {
                ...state,
                notificationDetails: action?.payload,
                lastAction: "search_notification_success" + Date?.now()
            }
        case SEND_NOTIFICATION_SUCCESS:
            return {
                ...state,
                sendNotificationInProgress: false,
                sendNotificationSuccess: true,
                sendNotificationFailure: false,
                lastNotificationDetails: payload,
                taskList: [...state?.taskList, payload?.taskId],
                lastAction: 'send_notification_success' + Date?.now()
            };
        case GET_NOTIFICATION_TASK_FAILURE:
            return {
                ...state,
                notificationDetails: {},
                lastAction: "get_notification_task_failure" + action?.payload?.taskId + Date?.now()
            }
        case SEARCH_NOTIFICATION_FAILURE:
            return {
                ...state,
                notificationDetails: [],
                lastAction: "search_notification_failure" + Date?.now()
            }
        case CANCEL_NOTIFICATION_TASK_SUCCESS:
            return {
                ...state
            }
        case CANCEL_NOTIFICATION_TASK_FAILURE:
            return {
                ...state
            }
        case SEND_NOTIFICATION_ERROR:
            return {
                ...state,
                sendNotificationInProgress: false,
                sendNotificationSuccess: false,
                sendNotificationFailure: true,
            };
        default:
            return state;
    }
}
