import React, { useState, useEffect } from "react";
import M from "materialize-css/dist/js/materialize.min.js";
import {selectMonth} from '../../Common/Metrics/GraphParameter';

function CheckFilter({activeFilter, setActiveFilter, deviceCount }) {
  const [selectedMonth, setSelectedMonth] = useState(selectMonth[new Date().getMonth()].value)

  useEffect(() => {
    M.AutoInit();
  }, []);
  const handleFilterToggle = (day) => {
    if (activeFilter.includes(day)) {
      setActiveFilter(activeFilter.filter((d) => d !== day));
    } else {
      setActiveFilter([...activeFilter, day]);
    }
  };
  let d = new Date();
  let m = d.getMonth();
  const handleChangeMonth = (event) => {
    setSelectedMonth(event.target.value)
  }
  return (
    <div>
      <div>
        <div className="">
          <div className="row reset-device-block mb-0">
            <div className="s3 col mr-30 m-0">
              <div className="hd-style-5 device_main_div">
                <div class="black-text font-600 ">
                User Count
                  <div className="gray-text-light device_no"> { deviceCount?deviceCount:'0'} </div>
                </div>
                {/* <div className = "black-text font-600">Device Offboard
                      <div className = "gray-text-light device_no">12354{" "}</div>
                    </div> */}
              </div>
            </div>
            <div className="s3 col mr-30 m-0">
              <div className="hd-style-5 device_main_div">
              <div className="model-srch reset full-w">
                
              </div>
              </div>
            </div>
             <div className="s6 col">
              
            </div> 
          </div>
        </div>
      </div>
    </div>
  );
}

export default CheckFilter;
