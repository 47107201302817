import React, { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import UserSearchInputWrapper from "../../Common/user/UserSearchInputWrapper";
import PhoneInput from "react-phone-input-2";
import Alert from "../../layout/Alert";
import { connect } from "react-redux";
import SearchUser from "../../dashboard/SearchUser";
import M from "materialize-css/dist/js/materialize.min.js";

const UserSearchBar = (props) => {
  const user = props.user.user;
  useEffect(() => {
    M.AutoInit();
  }, []);
  return (
    <Fragment>
      <UserSearchInputWrapper onSubmitSuccess={props.onSubmitSuccess}>
        {({
          searchValue,
          searchBy,
          countryCode,
          onSubmit,
          setCountryCode,
          onSearchByChange,
          onSearchValueChange,
          showText = props.showText,
          allowedCountries,
        }) => {
          return (
            <div className="right-content-wraper">
              <div className="container">
                <div className="row">
                  <div className="s12 col set-space">
                    <div className="content-block bottom-shadow">
                      <div className="p-15">
                        <div className="row mb-0">
                          <div className="s4 col">
                            <div className="page-title">
                              <label className="black-text font-600">
                                Search User
                              </label>
                              <div className="sub-title d-none">
                                Search User by Mobile Number or Email Id{" "}
                              </div>
                            </div>
                          </div>

                          <div className="s4 col">
                            <div className="control-capabilities">
                              <div className="model-srch reset">
                                <label className="field-name active">
                                  Search By
                                </label>
                                <select
                                  className="custom-select select-reset radius-20"
                                  name="searchBy"
                                  value={searchBy}
                                  onChange={(e) => onSearchByChange(e)}
                                >
                                  <option value="mobile">Mobile No</option>
                                  <option value="email">Email Id</option>
                                </select>
                              </div>
                            </div>
                          </div>

                          <div className="s4 col">
                            <form className="from" onSubmit={onSubmit}>
                              <div
                                className={
                                  searchBy === "mobile"
                                    ? "search-device not-found right no-left-pad"
                                    : "search-device not-found right"
                                }
                              >
                                {searchBy === "mobile" && (
                                  <PhoneInput
                                    // country={allowedCountries[0]}
                                    enableSearch={false}
                                    value={countryCode}
                                    onChange={setCountryCode}
                                    containerClass="country-code-container"
                                    searchClass="country-code-search"
                                    dropdownClass="country-code-dropdown"
                                    onlyCountries={allowedCountries}
                                  />
                                )}
                                {searchBy !== "mobile" && (
                                  <i className="material-icons search-icon">
                                    {" "}
                                    search
                                  </i>
                                )}
                                <input
                                  className="add-input md "
                                  placeholder={
                                    searchBy === "mobile"
                                      ? "Mobile No without country code"
                                      : "Enter Email Id"
                                  }
                                  type="text"
                                  name="text"
                                  value={searchValue}
                                  onChange={onSearchValueChange}
                                />
                                <input
                                  type="submit"
                                  value="Search"
                                  className="btn orange-bg btn-block md no-shadow"
                                />
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <Alert />
                {showText && user ? <SearchUser user={user} /> : null}
              </div>
            </div>
          );
        }}
      </UserSearchInputWrapper>
    </Fragment>
  );
};

UserSearchBar.propTypes = {};

export default connect((state) => ({ user: state.user }))(UserSearchBar);
