import React, { Fragment } from "react";
import { connect } from "react-redux";
import { getHome } from "../../action/home";
import HomeItem from "./HomeItem";
import UserInfo from "./UserInfo";
import Spinner from "../layout/Spinner";

const SearchUser = ({
  getHome,
  home,
  user,
  firstName,
  lastName,
  email,
  mobile,
  loading,
}) => {
  if (loading || user == null) {
    return <Spinner />;
  }
  return (
    <Fragment>
      <UserInfo user={user}></UserInfo>
      <Fragment>
        {home.home !== null ? (
          <div className="s12 p-0 col">
            <div className="content-block bottom-shadow">
              <div className="p-15">
                <div className="id-block">
                  <Fragment>
                    <div className="hd-style-2">
                      <h5>Primary Home ID(s)</h5>
                    </div>
                    <HomeItem
                      home={home}
                      homeData={home.home.primaryUserHomeIds}
                    />
                  </Fragment>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <Fragment>
            <Spinner />
          </Fragment>
        )}
      </Fragment>
      <Fragment>
        {home.home !== null ? (
          <div className="s12 p-0 col">
            <div className="content-block bottom-shadow">
              <div className="p-15">
                <div className="id-block">
                  <Fragment>
                    <div className="hd-style-2">
                      <h5>Secondary Home ID(s)</h5>
                    </div>

                    <HomeItem
                      home={home}
                      homeData={home.home.secondaryUserHomeIds}
                    />
                  </Fragment>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <Fragment>
            <Spinner />
          </Fragment>
        )}
      </Fragment>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  home: state.home,
});

export default connect(mapStateToProps, { getHome })(SearchUser);
