import React from "react";
import customConfig from "../../config";
import { updateEmailIDbyUserID } from "../../action/user";
import { connect } from "react-redux";
import UserInfoWrapper from "../Common/user/UserInfoWrapper";

function UserInfo(props) {
  const userById = props.user;
  const withoutOnboard = props?.withoutOnboard ?? false
  // const [showUpdateEmailID, setShowUpdateEmailID] = useState(false);
  // const [disabledEmailUpdateButton, setDisabledEmailUpdateButton] = useState(false);
  // const [emailValidationMessage, setEmailValidationMessage] = useState(false);
  // let [userEmailId, setUserEmailId] = useState(user && user.email ? user.email : "");
  // const userRoleInfo = useContext(UserContext);

  // useEffect(() => {
  //   if (emailUpdateError) {
  //     cancelEmailPopup();
  //   }
  // }, [emailUpdateError]);

  // useEffect(() => {
  //   if (emailUpdateSuccess) {
  //     user.email = userEmailId;
  //   }
  // }, [emailUpdateSuccess]);

  // // Visible email update form
  // const showUpdateEmailPopup = () => {
  //   setDisabledEmailUpdateButton(userEmailId.length <= 0);
  //   setShowUpdateEmailID(!showUpdateEmailID);
  // }

  // const cancelEmailPopup = () => {
  //   setUserEmailId(user && user.email ? user.email.trim() : "");
  //   setShowUpdateEmailID(false);
  //   setDisabledEmailUpdateButton(false);
  //   setEmailValidationMessage(false);
  // }

  // // Hit api to update email id
  // const updateEmailIdClick = () => {
  //   const updatedEmailID = userEmailId.trim();
  //   const isValid = (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(updatedEmailID));
  //   if (!isValid) {
  //     setEmailValidationMessage(true);
  //     return;
  //   }
  //   setEmailValidationMessage(false);
  //   updateEmailIDbyUserID(user.userId, updatedEmailID);
  //   setShowUpdateEmailID(false);
  // }

  // const onChange = (e) => {
  //   if (e.target.value.trim().length > 0)
  //     setDisabledEmailUpdateButton(false);
  //   else
  //     setDisabledEmailUpdateButton(true);

  //   setUserEmailId(e.target.value);
  // };

  return (
    <UserInfoWrapper userById={userById} withoutOnboard={withoutOnboard}>
      {({
        showUpdateEmailID,
        disabledEmailUpdateButton,
        emailValidationMessage,
        userRoleInfo,
        showUpdateEmailPopup,
        cancelEmailPopup,
        updateEmailIdClick,
        onChange,
        user,
        userEmailId,
      }) => (
        <div className="s12 p-0 col">
          <div className="content-block bottom-shadow">
            <div className="p-15">
              <div className="hd-style-2">
                <h5>User Information</h5>
              </div>
              <div className="user-information">
                {(user && user.userName && withoutOnboard == false) ? (
                  <div className="row mb-0">
                    <div className="s4 col">
                      <div className="d-flex info-box">
                        <div className="info">
                          <label className="label">Name</label>
                          <div className="user-about medium">
                            {" "}
                            {user.firstName} {user.lastName}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="emailPopup">
                      <div className="s4 col">
                        <div className="d-flex info-box">
                          <div className="info">
                            <label className="label">
                              Email Id
                              {userRoleInfo.level > 1 && !showUpdateEmailID && (
                                <button
                                  className="edit-link"
                                  onClick={showUpdateEmailPopup}
                                >
                                  <i className="material-icons icn">
                                    mode_edit
                                  </i>
                                </button>
                              )}
                            </label>
                            {!showUpdateEmailID && (
                              <div className="user-about medium">
                                {" "}
                                {userEmailId}{" "}
                              </div>
                            )}
                          </div>
                          {showUpdateEmailID && (
                            <div className="emailPopup-set">
                              <div className="d-flex info-box flex-end">
                                <div className="update-bx">
                                  <div>
                                    <input
                                      placeholder="Enter Email Id"
                                      className="font-16 update-inpt medium"
                                      value={userEmailId}
                                      type="text"
                                      onChange={onChange}
                                    />
                                    {emailValidationMessage && (
                                      <p className="mtopBot10 is-danger emailError">
                                        Email is not valid
                                      </p>
                                    )}
                                  </div>
                                  <button
                                    disabled={disabledEmailUpdateButton}
                                    className="btn btn-style-2 orange-bg mr-10"
                                    onClick={() => updateEmailIdClick()}
                                  >
                                    Update
                                  </button>
                                  <button
                                    className="btn btn-style-2 dark-text gray-bg"
                                    onClick={() => cancelEmailPopup()}
                                  >
                                    Cancel
                                  </button>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="s4 col">
                      <div className="d-flex info-box flex-end">
                        <div className="info">
                          <label className="label">Phone Number</label>
                          <div className="user-about medium">
                            {" "}
                            {user.mobile}{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <p className="center">
                    {" "}
                    {withoutOnboard
                      ? customConfig.noOnboardMsg
                      : customConfig.noDataMsg}{" "}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </UserInfoWrapper>
  );
}

const mapStateToProps = (state) => ({
  UserData: state.user,
});

export default connect(mapStateToProps, { updateEmailIDbyUserID })(UserInfo);
